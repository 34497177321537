import Moment from 'moment-timezone';
import { flatten, isEmpty, uniq } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import CalendarResourceSelectInput from '../../inputs/CalendarResourceSelectInput';
import Modal from '../../layout/Modal';
import ResourceCalendarSchedules from '../ResourceCalendarSchedules';
import { VideoConferencingTool } from '../../../../types';
import { useEvents } from '../../../../hooks/use-events';
import { useSession } from '../../../../hooks/use-session';

import type { MouseEventHandler, ReactElement } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../inputs/SelectInput/types';
import type { Schedule } from '../CalendarSchedule/types';

const getInterviewers = (schedule: Schedule, includeZoomHost: boolean): string[] => uniq(flatten([
  ...schedule.interviews.map(({ interviewers }) => {
    return (interviewers || [])
    .map(({ selected_user }) => selected_user.user_id);
  }),
  includeZoomHost && schedule.selected_zoom_host ? `${schedule.selected_zoom_host.id}:zoom` : null,
]
.filter((id): id is string => Boolean(id))
));

interface Props {
  // This should be a CalendarSchedule element, but we can't type it correctly.
  calendarSchedule: ReactElement;
  defaultSelectedUserIds?: () => string[];
  isOpen?: boolean;
  onToggle: MouseEventHandler<HTMLButtonElement>;
  schedule: Schedule;
  title: string;
}

const CalendarCompareModal = ({ calendarSchedule, defaultSelectedUserIds, isOpen = false, onToggle, schedule, title }: Props) => {
  const { account } = useSession();
  const date = useMemo(() => Moment(schedule.interviews[0].start_time).tz(schedule.timezone).startOf('day').format('YYYY-MM-DD'), [schedule.interviews[0].start_time, schedule.timezone]);

  const { fetchEvents } = useEvents();

  const [isRendered, setIsRendered] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState(() => {
    if (defaultSelectedUserIds) {
      return defaultSelectedUserIds();
    }
    return getInterviewers(schedule, account?.video_conferencing_type === VideoConferencingTool.Zoom);
  });

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isRendered) {
      const firstInterviewDiv = ref.current?.querySelector('.calendar-event');
      if (firstInterviewDiv) {
        if ('scrollIntoViewIfNeeded' in firstInterviewDiv) {
          // scrollIntoViewIfNeeded is a Webkit-only function, but there isn't a
          // general equivalent. This is why we're casting it as any and
          // attempting to call it. I tried using the
          // "scroll-into-view-if-needed" package, but it didn't seem to work at
          // all.
          (firstInterviewDiv as any).scrollIntoViewIfNeeded();
        } else {
          // This is not a Webkit browser (e.g. Firefox).
          firstInterviewDiv.scrollIntoView({ block: 'center', inline: 'center' });
        }
      }
    }
  }, [isRendered]);

  useEffect(() => {
    setIsRendered(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setSelectedUserIds(defaultSelectedUserIds?.() || getInterviewers(schedule, account?.video_conferencing_type === VideoConferencingTool.Zoom));
  }, [isOpen]);

  useEffect(() => {
    fetchEvents({
      date,
      timezone: schedule.timezone,
      user_ids: selectedUserIds.filter((id) => !id.endsWith(':zoom')),
      zoom_host_ids: selectedUserIds.filter((id) => id.endsWith(':zoom')).map((id) => id.replaceAll(':zoom', '')),
    });
  }, [date, selectedUserIds]);

  const handleUsersChange = (options: OnChangeValue<Option<string>, true>) => {
    setSelectedUserIds(options ? options.map((option) => option.value) : []);
  };

  return (
    <Modal
      cancelButtonValue="Continue editing"
      className="calendar-compare-modal"
      isOpen={isOpen}
      onToggle={onToggle}
      showSubmitButton={false}
      title={title}
    >
      <CalendarResourceSelectInput
        label="Calendars to compare"
        onChange={handleUsersChange}
        selectedUserIds={selectedUserIds}
      />
      <div className="calendar-schedules-container" ref={ref}>
        {calendarSchedule}
        {isEmpty(selectedUserIds) ?
          <div className="empty-resource-calendars">
            No calendars selected
          </div> :
          <ResourceCalendarSchedules
            maxTime={calendarSchedule.props.maxTime}
            minTime={calendarSchedule.props.minTime}
            schedule={schedule}
            selectedUserIds={selectedUserIds}
          />
        }
      </div>
    </Modal>
  );
};

export default CalendarCompareModal;
