import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import CalendarEventsSection from './CalendarEventsSection';
import CandidateConfirmationEmailSection from './CandidateConfirmationEmailSection';
import PreferencesSection from './PreferencesSection';
import SchedulingSection from './SchedulingSection';
import SelfSchedulingPreferencesSection from './SelfSchedulingPreferencesSection';
import useScrollToLocation from '../../../../../../../hooks/use-scroll-to-location';
import { useStage } from '../../../../../../../hooks/queries/stages';
import { correctPath } from 'libraries/gem';

const JobStageSchedulingPreferences = () => {
  const { id, stageId } = useParams<{ id: string; stageId: string }>();

  const {
    data: stage,
    isLoading,
  } = useStage(id, stageId);

  useScrollToLocation();

  if (isLoading) {
    return null;
  }

  const isSchedulable = stage && Boolean(stage.schedule_template);

  return (
    <Breadcrumb
      data={{
        title: 'Scheduling Preferences',
        pathname: correctPath(`/app/jobs/${id}/stages/${stageId}/scheduling-preferences`),
      }}
    >
      <div className="job-stage-scheduling-preferences-container">
        <Helmet>
          <title>Scheduling Preferences | {stage?.name} | {stage?.job.name} | InterviewPlanner</title>
        </Helmet>
        <SchedulingSection />
        {isSchedulable && <PreferencesSection />}
        {isSchedulable && <CalendarEventsSection />}
        {isSchedulable && <CandidateConfirmationEmailSection />}
        {isSchedulable && <SelfSchedulingPreferencesSection />}
      </div>
    </Breadcrumb>
  );
};

export default JobStageSchedulingPreferences;
