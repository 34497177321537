import styled from 'styled-components';

import UserSelectInput from 'components/library/inputs/UserSelectInput';

export const StyledUserSelectInput = styled(UserSelectInput)`
  width: 100%;

  .select-input {
    min-width: auto;

    .select-input-container {
      background: transparent;
      position: relative;
    }

    .select-input__indicator {
      padding: 0 0 0 2px !important;
    }

    .select-input__control {
      border-color: transparent !important;
      cursor: pointer;

      .select-input__value-container {
        padding: 0 !important;

        .select-input__placeholder {
          font-style: italic;
        }
      }

      &.select-input__control--is-focused {
        background: white;
        border-color: ${({ theme }) => theme.colors.PRODUCT.PRIMARY_BORDER} !important;

        .select-input__value-container {
          padding: 0 0 0 15px !important;
        }
      }
    }
  }
`;
