import Moment from 'moment-timezone';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import CalendarEventPreviewPopover from '../CalendarEventPreviewPopover';
import Tooltip from '../../utils/Tooltip';
import { directoryCalendarLabels } from '../../../../types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useSession } from '../../../../hooks/use-session';

import type { EventProps } from 'libraries/react-big-calendar';
import type { ResourceCalendarEvent } from './types';

const ResourceCalendarScheduleEvent = ({ event }: EventProps<ResourceCalendarEvent>) => {
  const { account } = useSession();

  const isMultiDayEvent = event.duration >= 24 * 60;

  const customStyle = event.color ? {
    borderLeft: `5px solid ${event.color}`,
  } : undefined;

  // Microsoft 365 calendar event IDs contain the '=' sign, which are not allowed in CSS selectors
  const eventContentDivId = `event-${event.id}-content`.replace(/=/g, '');

  return (
    <div className={classNames('calendar-event', event.isConflict && 'conflict', event.isInterview && 'interview')} style={customStyle}>
      <div className="calendar-event-content" id={eventContentDivId}>
        {event.duration > 20 &&
          <div className="calendar-event-time">
            {formatMoment(Moment.tz(event.start, event.timezone), isMultiDayEvent ? TimeFormat.ShortMonthDayWithTimeAndComma : TimeFormat.Time)} - {formatMoment(Moment.tz(event.end, event.timezone), isMultiDayEvent ? TimeFormat.ShortMonthDayWithTimeAndTimezoneAndComma : TimeFormat.TimeWithTimezone)}
          </div>
        }
        <div className="calendar-event-title">
          {event.private ? (
            <span
              className="calendar-event-title-private"
              data-for={`${event.id}-private-tooltip`}
              data-tip
            >
              busy
              <FontAwesomeIcon icon={faLock} />
              <Tooltip
                id={`${event.id}-private-tooltip`}
                value={`You don't have access to view this event in ${directoryCalendarLabels[account?.directory_type!]}.`}
              />
            </span>
          ) : `${event.buffer_time ? 'Buffer window for ' : ''}${event.title}`}
        </div>
      </div>
      <CalendarEventPreviewPopover
        event={event}
        target={eventContentDivId}
      />
    </div>
  );
};

export default ResourceCalendarScheduleEvent;
