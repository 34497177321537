import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Flash from '../../../../library/utils/Flash';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import Section from '../../../../library/layout/Section';
import TrainingProgramInterviewHistoryTable from './TrainingProgramInterviewHistoryTable';
import useSyncStateWithQuery from '../../../../../hooks/use-sync-state-with-query';
import { StyledTabContainer } from '../styles';
import { useTrainingProgram } from '../../../../../hooks/queries/training-programs';
import { useTrainingSessions } from '../../../../../hooks/queries/training-sessions';
import { correctPath } from 'libraries/gem';

const TrainingProgramInterviewHistory = () => {
  const { id: trainingProgramId } = useParams<{ id: string }>();

  const [pageNumber, queryPageNumber, setPageNumber] = useSyncStateWithQuery<string>('page', '1');

  const trainingProgram = useTrainingProgram(trainingProgramId).data!;

  const {
    data: trainingSessions,
    error,
    isFetching,
  } = useTrainingSessions(trainingProgramId, {
    limit: 10,
    offset: 10 * (parseInt(queryPageNumber, 10) - 1),
    manual: false,
  });

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value.toString());
  }, []);

  return (
    <Breadcrumb
      data={{
        title: 'Interview History',
        pathname: correctPath(`/app/training-programs/${trainingProgramId}/interview-history`),
      }}
    >
      <StyledTabContainer>
        <Helmet>
          <title>{trainingProgram.eligibility} | Interviewers | Training Programs | InterviewPlanner</title>
        </Helmet>
        <Section
          title="Interview history"
        >
          <Flash
            message={<>This includes all past and future interviews where a {trainingProgram.eligibility} <b>trainee</b> was scheduled.</>}
            showFlash
            type="info"
          />
          <Flash
            message={error?.message}
            showFlash={Boolean(error)}
            type="danger"
          />
          {isFetching && <LoadingSpinner />}
          {trainingSessions && (
            <TrainingProgramInterviewHistoryTable
              onPageNumberChange={handlePageNumberChange}
              pageNumber={parseInt(pageNumber, 10)}
              totalCount={trainingSessions.total}
              trainingSessions={trainingSessions.training_sessions}
            />
          )}
        </Section>
      </StyledTabContainer>
    </Breadcrumb>
  );
};

export default TrainingProgramInterviewHistory;
