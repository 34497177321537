import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { capitalize, find, keyBy, orderBy, pick } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import BusinessHoursInput from '../../../../library/inputs/BusinessHoursInput';
import CheckboxInput from '../../../../library/inputs/CheckboxInput';
import DayDurationInput from '../../../../library/inputs/DayDurationInput';
import DurationInput from '../../../../library/inputs/DurationInput';
import EditorInput from '../../../../library/inputs/EditorInput';
import EmailTemplateForm from '../../../../library/inputs/EmailTemplateForm';
import ErrorTokenFlash from '../../../../library/utils/ErrorTokenFlash';
import ExpandableCheckboxInput from '../../../../library/inputs/ExpandableCheckboxInput';
import FeedbackFormSelectInput from '../../../../library/inputs/FeedbackFormSelectInput';
import Flash from '../../../../library/utils/Flash';
import MultiStepFormStep from '../../../../library/inputs/MultiStepFormStep';
import OutboundLink from '../../../../library/navigation/OutboundLink';
import SelectInput from '../../../../library/inputs/SelectInput';
import Tag from '../../../../library/data-display/Tag';
import TextInput from '../../../../library/inputs/TextInput';
import ZoomHostFiltersBuilder from '../../../../library/inputs/ZoomHostFiltersBuilder';
import { AtsHrefType, constructAtsHref } from '../../../../../libraries/candidates';
import { Step, useNewSelfSchedulingLink } from './use-new-self-scheduling-link';
import { constructSelfSchedulingTokensPayload, defaultValuesForInterviewer, defaultValuesForStageInterview } from './helpers';
import { createEmptyZoomHostFilter } from '../../../../../libraries/zoom-hosts';
import { liveCodingLabels } from '../../../../../types';
import { slateValueToHtml } from '../../../../../libraries/editor/slate-value-to-html';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useLDFlags } from '../../../../../hooks/use-ld-flags';
import { useSession } from '../../../../../hooks/use-session';
import { useSlateEditor } from '../../../../../hooks/use-slate-editor';
import { useTokens } from '../../../../../hooks/queries/tokens';
import { useUsersMap } from '../../../../../hooks/queries/users';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Group, Option } from '../../../../library/inputs/SelectInput/types';
import type { Token, EditableZoomHostFilter, StageInterview } from '../../../../../types';
import { correctPath } from 'libraries/gem';

const exampleCandidateFacingDetails = '<span>This will be a conversation on company values, operating principles, and your past experiences.</span>';

const PreferencesStep = () => {
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();

  const { candidateFacingInterviewDetails } = useLDFlags();

  const { account, currentUser } = useSession();
  const application = useApplication(id).data!;
  const users = useUsersMap({ archived: true });

  const {
    selfSchedulingLink,
    setCompletedStep,
    setSelfSchedulingLink,
  } = useNewSelfSchedulingLink();

  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);

  const [error, setError] = useState<Error | null>(null);
  const [stageInterviewId, setStageInterviewId] = useState(selfSchedulingLink.stage_interview.id || '');
  const [durationMinutes, setDurationMinutes] = useState(selfSchedulingLink.stage_interview.interview_template.duration_minutes);
  const [interviewerPoolIds, setInterviewerPoolIds] = useState(selfSchedulingLink.stage_interview.interview_template.interviewer_templates?.[0].interviewer_pool_ids || []);
  const [interviewerId, setInterviewerId] = useState(selfSchedulingLink.stage_interview.interview_template.interviewer_templates?.[0].interviewer_filters?.[0]?.interviewer_filter_expressions[0].filterable_id || '');
  const [businessHours, setBusinessHours] = useState(selfSchedulingLink.schedule_template.business_hours);
  const [candidateFacingName, setCandidateFacingName] = useState(selfSchedulingLink.stage_interview.interview_template.candidate_facing_name || '');
  const [candidateFacingDetailsSlateEditor, candidateFacingDetailsSlateValue, setCandidateFacingDetailsSlateValue, setCandidateFacingDetails] = useSlateEditor(selfSchedulingLink.stage_interview.interview_template.candidate_facing_details || '');
  const [feedbackFormId, setFeedbackFormId] = useState(selfSchedulingLink.stage_interview.feedback_form_id);
  const [advancedNoticeHours, setAdvancedNoticeHours] = useState(selfSchedulingLink.schedule_template.self_scheduling_advanced_notice_hours);
  const [schedulingIntervalMinutes, setSchedulingIntervalMinutes] = useState(selfSchedulingLink.schedule_template.scheduling_interval_minutes);
  const [isRescheduleEnabled, setIsRescheduleEnabled] = useState(selfSchedulingLink.schedule_template.self_scheduling_reschedule_enabled);
  const [rescheduleNoticeHours, setRescheduleNoticeHours] = useState(selfSchedulingLink.schedule_template.self_scheduling_reschedule_notice_hours || 0);
  const [isCancelEnabled, setIsCancelEnabled] = useState(selfSchedulingLink.schedule_template.self_scheduling_cancel_enabled);
  const [cancelNoticeHours, setCancelNoticeHours] = useState(selfSchedulingLink.schedule_template.self_scheduling_cancel_notice_hours || 0);
  const [isFollowUpEnabled, setIsFollowUpEnabled] = useState(Boolean(selfSchedulingLink.schedule_template.self_scheduling_email_follow_up_templates?.[0]));
  const [followUpDelayDays, setFollowUpDelayDays] = useState(selfSchedulingLink.schedule_template.self_scheduling_email_follow_up_templates?.[0].delay_days || 0);
  const [isVideoConferencingEnabled, setIsVideoConferencingEnabled] = useState(selfSchedulingLink.schedule_template.video_conferencing_enabled);
  const [zoomHostFilters, setZoomHostFilters] = useState<EditableZoomHostFilter[]>(selfSchedulingLink.schedule_template.zoom_host_filters || createEmptyZoomHostFilter());
  const [isLiveCodingEnabled, setIsLiveCodingEnabled] = useState(selfSchedulingLink.stage_interview.interview_template.live_coding_enabled);
  const [isEmailEnabled, setIsEmailEnabled] = useState(canSendEmail && Boolean(selfSchedulingLink.schedule_template.self_scheduling_request_email_template));
  const [emailTemplate, setEmailTemplate] = useState(selfSchedulingLink.schedule_template.self_scheduling_request_email_template);
  const [errorTokens, setErrorTokens] = useState<Token[]>([]);

  const stageInterviewsById = useMemo(() => keyBy(application.current_stage!.stage_interviews, 'id'), [application.current_stage!.stage_interviews]);
  const stageInterview = useMemo<StageInterview | undefined>(() => stageInterviewsById[stageInterviewId], [stageInterviewsById, stageInterviewId]);
  // We can't wrap this in a useMemo since candidateFacingDetailsSlateValue doesn't change its value as contents are updated.
  const candidateFacingDetails = slateValueToHtml(candidateFacingDetailsSlateValue);

  const { data: tokens } = useTokens({
    type: 'self_scheduling_request_email',
    self_scheduling_link: constructSelfSchedulingTokensPayload(application, stageInterview?.name || '', durationMinutes, candidateFacingName, candidateFacingDetails, users),
  });

  const stageInterviewOptions = useMemo<Option<string>[]>(() => {
    return (application.current_stage!.stage_interviews || [])
    .filter(({ ats_schedulable, deleted }) => !deleted && (ats_schedulable === null || ats_schedulable))
    .map((stageInterview) => ({
      value: stageInterview.id,
      label: stageInterview.name,
    }));
  }, [application.current_stage?.stage_interviews]);

  const interviewerOptions = useMemo<Group<string, Option<string>>[]>(() => [{
    label: 'Interviewer Pool',
    options: orderBy(interviewerPoolIds.map((id) => ({
      value: id,
      label: users[id]?.name || users[id]?.email,
    })), ['label']),
  }, {
    label: 'All Team Members',
    options: orderBy(Object.values(users).filter(({ ats_id, id, user_archived, directory_archived }) => Boolean(ats_id && !user_archived && !directory_archived) && !interviewerPoolIds.includes(id)).map(({ id }) => ({
      value: id,
      label: users[id]?.name || users[id]?.email,
    })), ['label']),
  }], [interviewerPoolIds, users]);

  useEffect(() => {
    if (error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [error]);

  const handleStageInterviewChange = async (option: OnChangeValue<Option<string>, false>) => {
    setStageInterviewId(option!.value);

    const stageInterview = stageInterviewsById[option!.value];
    if (!stageInterview) {
      return;
    }

    const {
      businessHours,
      duration,
      feedbackFormId,
      interviewerPoolIds,
      interviewerId,
      liveCodingEnabled,
      candidateFacingName,
      candidateFacingDetails,
    } = await defaultValuesForStageInterview(queryClient, account!, application, users, stageInterview);

    setBusinessHours(businessHours);
    setDurationMinutes(duration);
    setCandidateFacingName(candidateFacingName || '');
    setCandidateFacingDetails(candidateFacingDetails || '');
    setFeedbackFormId(feedbackFormId);
    setInterviewerPoolIds(interviewerPoolIds);
    setInterviewerId(interviewerId || '');
    setIsLiveCodingEnabled(liveCodingEnabled);
  };

  const handleInterviewerChange = (option: OnChangeValue<Option<string>, false>) => {
    setInterviewerId(option!.value);

    const {
      businessHours,
    } = defaultValuesForInterviewer(application, users, option!.value);

    setBusinessHours(businessHours);
  };

  const handleDurationChange = (duration: number) => setDurationMinutes(duration);
  const handleCandidateFacingNameChange = (e: ChangeEvent<HTMLInputElement>) => setCandidateFacingName(e.target.value);
  const handleFeedbackFormIdChange = (option: OnChangeValue<Option<string>, false>) => setFeedbackFormId(option?.value);
  const handleAdvancedNoticeHoursChange = (duration: number) => setAdvancedNoticeHours(duration / 60);
  const handleSchedulingIntervalMinutesChange = (duration: number) => setSchedulingIntervalMinutes(duration);
  const handleRescheduleEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsRescheduleEnabled(e.target.checked);
  const handleRescheduleNoticeHoursChange = (duration: number) => setRescheduleNoticeHours(duration / 60);
  const handleCancelEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsCancelEnabled(e.target.checked);
  const handleCancelNoticeHoursChange = (duration: number) => setCancelNoticeHours(duration / 60);
  const handleFollowUpEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsFollowUpEnabled(e.target.checked);
  const handleFollowUpDelayDaysChange = (duration: number) => setFollowUpDelayDays(duration);
  const handleVideoConferencingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsVideoConferencingEnabled(e.target.checked);
  const handleZoomHostFiltersChange = (filters: EditableZoomHostFilter[]) => setZoomHostFilters(filters);
  const handleLiveCodingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsLiveCodingEnabled(e.target.checked);

  const handleNext = () => {
    setError(null);

    // Check that self-scheduling request link is included in self-scheduling request email
    if (isEmailEnabled && !/{{\s*SelfSchedulingRequest\.Link\s*}}/.test(emailTemplate!.body)) {
      setError(new Error('The template must include the SelfSchedulingRequest.Link token.'));
      return false;
    }

    // Check that there is at least one set of business hours.
    if (businessHours.length === 0) {
      setError(new Error('At least one day is required for working hours.'));
      return false;
    }

    setSelfSchedulingLink((prev) => ({
      ...prev,
      schedule_template: {
        ...prev.schedule_template,
        business_hours: businessHours,
        scheduling_interval_minutes: schedulingIntervalMinutes,
        video_conferencing_enabled: isVideoConferencingEnabled,
        zoom_host_filters: isVideoConferencingEnabled && account?.video_conferencing_type === 'zoom' && (zoomHostFilters || []).map(({ zoom_host_filter_expressions }) => ({
          zoom_host_filter_expressions: zoom_host_filter_expressions.map((exp) => ({
            filterable_id: exp.filterable_id,
            filterable_type: exp.filterable_type,
            negated: exp.negated,
          })),
        })) || undefined,
        self_scheduling_advanced_notice_hours: advancedNoticeHours,
        self_scheduling_reschedule_enabled: isRescheduleEnabled,
        self_scheduling_reschedule_notice_hours: rescheduleNoticeHours,
        self_scheduling_cancel_enabled: isCancelEnabled,
        self_scheduling_cancel_notice_hours: cancelNoticeHours,
        self_scheduling_email_follow_up_templates: isFollowUpEnabled ? [{
          delay_days: followUpDelayDays,
        }] : undefined,
        self_scheduling_request_email_template: isEmailEnabled ? {
          ...pick(emailTemplate!, [
            'id',
            'name',
            'subject',
            'sender_name',
            'sender_email',
            'cc_emails',
            'bcc_emails',
            'body',
            'attachments',
          ]),
          name: emailTemplate!.name || 'Self-Scheduling Request Email',
        } : undefined,
      },
      stage_interview: {
        ...prev.stage_interview,
        id: stageInterviewId,
        feedback_form_id: feedbackFormId,
        interview_template: {
          ...prev.stage_interview.interview_template,
          duration_minutes: durationMinutes,
          live_coding_enabled: isLiveCodingEnabled,
          candidate_facing_name: candidateFacingName,
          candidate_facing_details: candidateFacingDetails && candidateFacingDetails !== '<br>' ? candidateFacingDetails : undefined,
          interviewer_templates: [{
            optional: false,
            interviewer_pool_ids: interviewerPoolIds,
            interviewer_filters: [{
              interviewer_filter_expressions: [{
                negated: false,
                filterable_id: interviewerId,
                filterable_type: 'user',
              }],
            }],
          }],
        },
      },
    }));
    setCompletedStep(Step.Preferences + 1);
  };

  return (
    <Breadcrumb
      data={{
        title: '1. Preferences',
        pathname: correctPath(`/app/candidates/${id}/self-schedule/preferences`),
      }}
    >
      <MultiStepFormStep
        className="form-step-preferences"
        isFirstStep
        nextButtonIsDisabled={!application.candidate.email && errorTokens.length > 0}
        nextButtonValue="Events & Emails"
        nextLocation={correctPath(`/app/candidates/${id}/self-schedule/events-and-emails`)}
        onNext={handleNext}
      >
        <Helmet>
          <title>1. Preferences | Send {application.candidate.name || 'Unknown'} a link to schedule {application.current_stage?.name} | InterviewPlanner</title>
        </Helmet>
        <Flash
          message={<>This candidate doesn&apos;t have an email address associated to them, so we can&apos;t send calendar invitations or emails. You can add an email in <OutboundLink externalLinkIcon={false} href={constructAtsHref(account!, AtsHrefType.Candidate, { applicationAtsId: application.id, candidateAtsId: application.candidate.ats_id })} label="Add Email Warning">{capitalize(account?.ats_type)}</OutboundLink>.</>}
          showFlash={!application.candidate.email}
          type="danger"
        />
        <Flash
          message={<>This stage doesn&apos;t have any schedulable interviews configured. You can add interviews <Link to={constructAtsHref(account!, AtsHrefType.InterviewPlan, { jobAtsId: application.job.ats_id }) || correctPath(`/app/jobs/${application.job_id}/stages/${application.current_stage_id}/interviews`)}>here</Link>.</>}
          showFlash={stageInterviewOptions.length === 0}
          type="warning"
        />
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message="Select the interview and interviewer to schedule. Fill out the email that we will send to the candidate to book their interview."
          showFlash
          type="info"
        />
        <div className="form-container self-scheduling-interview-preferences">
          <SelectInput
            className="interview-select-input"
            isRequired
            label="Interview"
            onChange={handleStageInterviewChange}
            options={stageInterviewOptions}
            value={find(stageInterviewOptions, ['value', stageInterviewId])}
          />
          <DurationInput
            className="duration-minutes-input"
            id="duration-minutes-input"
            isAbbreviatedUnits
            isRequired
            label="Duration"
            onChange={handleDurationChange}
            value={durationMinutes}
          />
        </div>
        {account?.ats_type === 'lever' && (
          <div className="form-container self-scheduling-interview-preferences">
            <FeedbackFormSelectInput
              className="feedback-form-select-input"
              label="Feedback Form"
              onChange={handleFeedbackFormIdChange}
              selectedFeedbackFormId={feedbackFormId}
            />
            {/*This is added to make the feedback form input line up with the
            interview and interviewer inputs.*/}
            <div className="empty-slot" />
          </div>
        )}
        <div className="form-container self-scheduling-interview-preferences">
          <SelectInput<string>
            className="user-select-input interviewer-select-input"
            formatOptionLabel={(option) => <Tag type="user" value={option.value} />}
            isRequired
            label="Interviewer"
            onChange={handleInterviewerChange}
            options={interviewerOptions}
            value={interviewerId ? { value: interviewerId } : null}
          />
        </div>
        <div className="form-container self-scheduling-interview-preferences">
          <BusinessHoursInput
            businessHours={businessHours}
            helperText={<>If the selected interviewer has their Working Hours set, we&apos;ll use those. Otherwise, we&apos;ll use the Scheduling Windows for this stage.<br />Leave start and end blank to allow the entire day, and a timezone is always required.</>}
            label="Working Hours"
            setBusinessHours={setBusinessHours}
          />
        </div>
        <div className="form-container self-scheduling-interview-preferences">
          <TextInput
            className="candidate-facing-name-text-input"
            helperText={<>This is the name of the interview in the <b>Job.Stage.Agenda</b> and <b>Schedule.Agenda</b> tokens. Recommended if you want a more candidate-friendly name for your interview.</>}
            id="advanced-settings-candidate-facing-name"
            label="Candidate-Facing Interview Name"
            onChange={handleCandidateFacingNameChange}
            placeholder={stageInterview?.name}
            value={candidateFacingName}
          />
        </div>
        {candidateFacingInterviewDetails && (
          <div className="form-container self-scheduling-interview-preferences">
            <EditorInput
              allowTokens={false}
              className="candidate-facing-details"
              editor={candidateFacingDetailsSlateEditor}
              exampleHtmlContent={exampleCandidateFacingDetails}
              helperText={<>This will be included in the <b>Job.Stage.Agenda</b> and <b>Schedule.Agenda</b> tokens. Recommended if you want to prep the candidate for what to expect.</>}
              label="Candidate-Facing Details"
              pendingPreviewMessage="This token will be filled in when the candidate schedules their interview."
              setValue={setCandidateFacingDetailsSlateValue}
              type="candidate_calendar_event"
              value={candidateFacingDetailsSlateValue}
            />
          </div>
        )}
        <div className="form-container self-scheduling-interview-preferences">
          <DurationInput
            className="advanced-notice-hours-input"
            helperText="The candidate must submit times that are at least this far in the future."
            id="advanced-notice-hours-input"
            isHoursOnly
            isRequired
            label="Advanced Notice"
            maxMinutes={178 * 60}
            onChange={handleAdvancedNoticeHoursChange}
            value={advancedNoticeHours * 60}
          />
        </div>
        <div className="form-container self-scheduling-interview-preferences">
          <DurationInput
            className="scheduling-interval-minutes-input"
            helperText="The interval that we'll use to generate possible slots for the candidate."
            id="scheduling-interval-minutes-input"
            isRequired
            label="Scheduling Options Interval"
            maxMinutes={55}
            minMinutes={5}
            onChange={handleSchedulingIntervalMinutesChange}
            value={schedulingIntervalMinutes}
          />
        </div>
        <ExpandableCheckboxInput
          isChecked={isRescheduleEnabled}
          label="Allow candidate to reschedule."
          onChange={handleRescheduleEnabledChange}
        >
          <DurationInput
            additionalText="before scheduled time"
            className="reschedule-notice-hours-input"
            id="reschedule-notice-hours-input"
            isHoursOnly
            isRequired
            label="Minimum Reschedule Notice"
            maxMinutes={178 * 60}
            onChange={handleRescheduleNoticeHoursChange}
            value={rescheduleNoticeHours * 60}
          />
        </ExpandableCheckboxInput>
        <ExpandableCheckboxInput
          isChecked={isCancelEnabled}
          label="Allow candidate to cancel."
          onChange={handleCancelEnabledChange}
        >
          <DurationInput
            additionalText="before scheduled time"
            className="cancel-notice-hours-input"
            id="cancel-notice-hours-input"
            isHoursOnly
            isRequired
            label="Minimum Cancel Notice"
            maxMinutes={178 * 60}
            onChange={handleCancelNoticeHoursChange}
            value={cancelNoticeHours * 60}
          />
        </ExpandableCheckboxInput>
        <ExpandableCheckboxInput
          isChecked={isFollowUpEnabled}
          label="Automatically follow up with the candidate if they don't schedule an interview."
          onChange={handleFollowUpEnabledChange}
        >
          <DayDurationInput
            additionalText="after initial request"
            className="follow-up-days-input"
            dayLabel="weekday"
            id="follow-up-days-input"
            isRequired
            label="Days Before Sending Follow-up"
            min={1}
            onChange={handleFollowUpDelayDaysChange}
            value={followUpDelayDays}
          />
        </ExpandableCheckboxInput>
        <Flash
          message={<>To schedule video interviews, you must set up a video conferencing integration <Link to={correctPath('/app/integrations')}>here</Link>.</>}
          showFlash={!account?.video_conferencing_type && isVideoConferencingEnabled}
          type="warning"
        />
        <ExpandableCheckboxInput
          isChecked={isVideoConferencingEnabled}
          label="Include video conferencing."
          onChange={handleVideoConferencingEnabledChange}
        >
          {isVideoConferencingEnabled && account?.video_conferencing_type === 'zoom' && (
            <ZoomHostFiltersBuilder
              filters={zoomHostFilters}
              helperText="We will consider Zoom conflicts of the Zoom Host when displaying available times to the candidate."
              label="Zoom Meeting Host"
              onChange={handleZoomHostFiltersChange}
            />
          )}
        </ExpandableCheckboxInput>
        {account?.live_coding_type && (
          <CheckboxInput
            className="live-coding-enabled-checkbox-input"
            id="live-coding-enabled-checkbox-input"
            isChecked={isLiveCodingEnabled}
            label={`Include ${liveCodingLabels[account.live_coding_type]} link.`}
            onChange={handleLiveCodingEnabledChange}
          />
        )}
        <ErrorTokenFlash errorTokens={errorTokens} />
        <EmailTemplateForm
          emailTemplate={emailTemplate}
          isDisabled={!canSendEmail}
          isEnabled={isEmailEnabled}
          pendingPreviewMessage="This token will be filled in when the request is sent to the candidate."
          setEmailTemplate={setEmailTemplate}
          setErrorTokens={setErrorTokens}
          setIsEnabled={setIsEmailEnabled}
          tokens={tokens}
          type="self_scheduling_request_email"
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default PreferencesStep;
