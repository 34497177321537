import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, useParams } from 'react-router-dom';
import { capitalize, find, isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import Button from 'components/library/inputs/Button';
import { calculateSuggestedUpdatesToStageSettings } from './UpdateStageSettingsModal/helpers';
import CandidateCalendarEventSection from './CandidateCalendarEventSection';
import CandidateConfirmationEmailSection from './CandidateConfirmationEmailSection';
import CandidateSelfSchedulingRequestEmailSection from './CandidateSelfSchedulingRequestEmailSection';
import Flash from '../../../../../library/utils/Flash';
import ImportedScheduleInterviewsSection from './ImportedScheduleInterviewsSection';
import LoadingSpinner from '../../../../../library/utils/LoadingSpinner';
import OutboundLink from '../../../../../library/navigation/OutboundLink';
import ScheduleSection from './ScheduleSection';
import ScheduleSummarySection from './ScheduleSummarySection';
import SelfSchedulingLinkPreferencesSection from './SelfSchedulingLinkPreferencesSection';
import SelfSchedulingLinkScheduleSection from './SelfSchedulingLinkScheduleSection';
import SelfSchedulingLinkSummarySection from './SelfSchedulingLinkSummarySection';
import UpdateStageSettingsModal from './UpdateStageSettingsModal';
import { AtsHrefType, constructAtsHref } from '../../../../../../libraries/candidates';
import { ScheduleType } from '../types';
import { formatMoment, TimeFormat } from '../../../../../../libraries/time';
import { useApplication } from '../../../../../../hooks/queries/applications';
import { useLDFlags } from 'hooks/use-ld-flags';
import useQueryState from 'hooks/use-query-state';
import { useSchedule } from '../../../../../../hooks/queries/schedules';
import { useSelfSchedulingLink } from '../../../../../../hooks/queries/self-scheduling-links';
import { useSession } from '../../../../../../hooks/use-session';

import type { ScheduleWithType } from './types';
import { correctPath } from 'libraries/gem';

const CandidateSchedule = () => {
  const { saveSettingsInWorkflow } = useLDFlags();

  const [successQueryParam] = useQueryState<string>('success', '');
  const isCreateSuccess = successQueryParam === 'true';

  const { id, scheduleId } = useParams<{ id: string; scheduleId: string }>();

  const [showUpdateStageSettingsModal, setShowUpdateStageSettingsModal] = useState<boolean>(false);

  const { account } = useSession();
  const { data: application } = useApplication(id);

  const scheduleType = useMemo<ScheduleType | ''>(() => {
    const selfSchedulingLink = find(application?.all_self_scheduling_links, ['id', scheduleId]);
    if (selfSchedulingLink) {
      return ScheduleType.SelfSchedulingLink;
    }

    const schedule = find(application?.all_schedules, ['id', scheduleId]);
    if (schedule) {
      return ScheduleType.Schedule;
    }

    // Don't assume what type it is until we can confirm it. This can happen if
    // the application hasn't been refreshed yet. However, this results in the issue
    // where if the user is viewing a held schedule and then cancels it, they'll only
    // see a loading spinner, which isn't great.
    return '';
  }, [application, scheduleId]);

  const {
    data: schedule,
    isLoading: scheduleIsLoading,
    isSuccess: scheduleIsSuccess,
    isError: scheduleIsError,
    error: scheduleError,
  } = useSchedule(scheduleId, {
    enabled: scheduleType === 'schedule',
    // We don't want to keep the previous data, otherwise, navigating to other
    // schedules produces weird results.
    keepPreviousData: false,
  });

  const {
    data: selfSchedulingLink,
    isLoading: selfSchedulingLinkIsLoading,
    isSuccess: selfSchedulingLinkIsSuccess,
    isError: selfSchedulingLinkIsError,
    error: selfSchedulingLinkError,
  } = useSelfSchedulingLink(scheduleId, {
    enabled: scheduleType === 'self_scheduling_link',
    // We don't want to keep the previous data, otherwise, navigating to other
    // schedules produces weird results.
    keepPreviousData: false,
  });

  const resource = useMemo<ScheduleWithType | undefined>(() => {
    if (schedule) {
      return { ...schedule, type: ScheduleType.Schedule };
    } else if (selfSchedulingLink) {
      return { ...selfSchedulingLink, type: ScheduleType.SelfSchedulingLink };
    }
  }, [schedule, selfSchedulingLink]);

  const suggestedUpdatesToStageSettings = useMemo(() => {
    if (isCreateSuccess && application?.current_stage && resource) {
      return calculateSuggestedUpdatesToStageSettings(resource, application.current_stage);
    }
    return [];
  }, [isCreateSuccess, resource, application]);

  // A proxy for whether the stage has been set up at all previously.
  // We prompt a "first-time setup" if the stage has no interview templates,
  // no candidate calendar event template, and no confirmation email template.
  const stageHasTemplates = useMemo(() => {
    if (scheduleType === 'schedule') {
      return Boolean(
        (application?.current_stage?.stage_interviews || []).some((stageInterview) => Boolean(stageInterview.interview_template_id) || !isEmpty(stageInterview.ats_interviewer_ids)) ||
        application?.current_stage?.schedule_template?.candidate_calendar_event_template_id ||
        application?.current_stage?.schedule_template?.confirmation_email_template_id
      );
    }
    // TODO: Self-scheduling link
    return true;
  }, [application?.current_stage, scheduleType]);

  // Prompt to set up the stage if it has not been set up at all previously
  useEffect(() => {
    if (!isEmpty(suggestedUpdatesToStageSettings) && !stageHasTemplates) {
      setShowUpdateStageSettingsModal(true);
    }
  }, [stageHasTemplates, suggestedUpdatesToStageSettings]);

  const isLoading = !scheduleType || scheduleIsLoading || selfSchedulingLinkIsLoading;
  const isError = (scheduleIsError || selfSchedulingLinkIsError) && !(scheduleIsSuccess || selfSchedulingLinkIsSuccess);
  const error = scheduleError || selfSchedulingLinkError;

  if (isLoading) {
    return (
      <div className="candidate-schedule-container">
        <LoadingSpinner />
      </div>
    );
  }

  if (selfSchedulingLink?.status === 'completed') {
    return <Redirect to={correctPath(`/app/candidates/${id}/schedules/${selfSchedulingLink.schedule?.id}`)} />;
  }

  const isMultiBlock = Boolean(schedule?.block_id);
  const interviewDate = scheduleType === ScheduleType.Schedule && schedule?.interviews ?
    schedule.imported_from_ats ?
      formatMoment(Moment.tz(schedule.interviews[0].start_time, schedule.timezone), TimeFormat.ShortMonthDayYear) :
      formatMoment(Moment.tz(schedule.interviews[0].start_time, schedule.timezone), TimeFormat.ShortMonthDayYear) :
    '';

  return (
    <Breadcrumb
      data={{
        title: isError ? scheduleId : `${(schedule || selfSchedulingLink)?.stage.name} (${scheduleType === ScheduleType.Schedule ? interviewDate : 'Self-scheduling request'})`,
        pathname: correctPath(`/app/candidates/${application?.id}/schedules/${scheduleId}`),
      }}
    >
      {isError ?
        <Flash
          message={error?.message}
          showFlash={isError}
          type="danger"
        /> :
        <div className="candidate-schedule-container">
          {saveSettingsInWorkflow && application?.current_stage && (
            <UpdateStageSettingsModal
              isOpen={showUpdateStageSettingsModal}
              onToggle={() => {
                setShowUpdateStageSettingsModal(!showUpdateStageSettingsModal);
              }}
              stage={application?.current_stage}
              suggestedUpdates={suggestedUpdatesToStageSettings}
            />
          )}
          <Helmet>
            {
              scheduleType === ScheduleType.Schedule && schedule?.interviews ?
                <title>{schedule.stage.name} ({interviewDate}) | {application?.candidate.name || 'Unknown'} ({application?.job.name}) | Gem Scheduling</title> :
                null
            }
            {
              scheduleType === ScheduleType.SelfSchedulingLink && selfSchedulingLink ?
                <title>{selfSchedulingLink.stage.name} (Self-scheduling request) | {application?.candidate.name || 'Unknown'} ({application?.job.name}) | Gem Scheduling</title> :
                null
            }
          </Helmet>
          <Flash
            message={(
              <>
                {schedule?.interviews.length === 1 ? 'This interview was' : 'These interviews were'} scheduled in {capitalize(account?.ats_type)} and <b>cannot be managed in InterviewPlanner</b>. You can edit {schedule?.interviews.length === 1 ? 'it' : 'them'} in <OutboundLink href={constructAtsHref(account!, AtsHrefType.Candidate, { applicationAtsId: schedule?.application?.ats_id, candidateAtsId: schedule?.application?.candidate.ats_id })} label={'Open in ATS - Imported Schedule'}>{capitalize(account?.ats_type)}</OutboundLink>.
              </>
            )}
            showFlash={Boolean(schedule?.imported_from_ats)}
            type="info"
          />
          {saveSettingsInWorkflow && (
            <>
              <Flash
                isDismissible
                message="🎉 Candidate scheduled!"
                showFlash={isCreateSuccess}
                type="success"
              />
              <Flash
                message={
                  <span>
                    You manually edited some preferences when scheduling this candidate. If any of these should be default stage settings,&nbsp;
                    <Button
                      className="btn btn-link"
                      color="no-outline"
                      onClick={() => setShowUpdateStageSettingsModal(true)}
                      value="update them here"
                    />
                    .
                  </span>
                }
                showFlash={isCreateSuccess && !isEmpty(suggestedUpdatesToStageSettings) && stageHasTemplates}
                type="info"
              />
            </>
          )}
          <Flash
            message="This schedule has been cancelled and all calendar events have been deleted."
            showFlash={Boolean(!schedule?.imported_from_ats && (schedule?.status === 'cancelled' || schedule?.status === 'cancelling'))}
            type="danger"
          />
          <Flash
            message={<>This schedule is {isMultiBlock ? 'part of a multi-block schedule that is on hold' : 'on hold'}. We have not created the schedule in {capitalize(account?.ats_type)} or notified the candidate. <Link to={correctPath(`/app/candidates/${id}/schedule/review?schedule=${scheduleId}`)}>Go here to confirm and create this {isMultiBlock ? 'multi-block' : ''} schedule.</Link></>}
            showFlash={Boolean(schedule?.hold && application?.current_stage_id === schedule?.stage_id)}
            type="info"
          />
          <Flash
            message={(
              <>
                This held schedule is for <b>{schedule?.stage.name}</b>, but the candidate is currently {application?.current_stage_id ? <>in <b>{application.current_stage?.name}</b></> : 'not in a stage'}. To confirm this schedule, move the candidate back to <b>{schedule?.stage.name}</b> in <OutboundLink href={constructAtsHref(account!, AtsHrefType.Candidate, { candidateAtsId: application?.candidate.ats_id, applicationAtsId: application?.ats_id })} label="Change Candidate Stage">{capitalize(account?.ats_type)}</OutboundLink>.
              </>
            )}
            showFlash={Boolean(schedule?.hold && application?.current_stage_id !== schedule?.stage_id)}
            type="warning"
          />
          {!schedule?.imported_from_ats ? (
            <>
              {scheduleType === ScheduleType.Schedule ?
                <ScheduleSummarySection /> :
                <>
                  <SelfSchedulingLinkSummarySection />
                  <SelfSchedulingLinkPreferencesSection />
                </>
              }
              {scheduleType === ScheduleType.Schedule ? <ScheduleSection /> : <SelfSchedulingLinkScheduleSection />}
              {(scheduleType === ScheduleType.SelfSchedulingLink || schedule?.self_scheduling_link_id) && (
                <CandidateSelfSchedulingRequestEmailSection
                  selfSchedulingLinkId={selfSchedulingLink?.id || schedule?.self_scheduling_link_id}
                />
              )}
              <CandidateCalendarEventSection
                isEditable={Boolean((scheduleType === ScheduleType.SelfSchedulingLink && selfSchedulingLink?.status === 'requested') || schedule?.hold)}
                resource={resource!}
              />
              <CandidateConfirmationEmailSection
                isEditable={Boolean((scheduleType === ScheduleType.SelfSchedulingLink && selfSchedulingLink?.status === 'requested') || schedule?.hold)}
                resource={resource!}
              />
            </>
          ) : (
            <ImportedScheduleInterviewsSection />
          )}
        </div>
      }
    </Breadcrumb>
  );
};

export default CandidateSchedule;
