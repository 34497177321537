import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import InterviewsSection from './InterviewsSection';
import { useJob } from '../../../../../../../hooks/queries/jobs';
import { useStage } from '../../../../../../../hooks/queries/stages';
import { correctPath } from 'libraries/gem';

const JobStageInterviews = () => {
  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { data: job } = useJob(jobId);
  const {
    data: stage,
    isLoading,
  } = useStage(jobId, stageId);

  if (isLoading) {
    return null;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Interviews',
        pathname: correctPath(`/app/jobs/${jobId}/stages/${stageId}/interviews`),
      }}
    >
      <div className="job-stage-interviews-container">
        <Helmet>
          <title>Interviews | {stage?.name} | {job?.name} | Gem Scheduling</title>
        </Helmet>
        <InterviewsSection />
      </div>
    </Breadcrumb>
  );
};

export default JobStageInterviews;
