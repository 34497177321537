import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import EmailPreview from '../../../../library/data-display/EmailPreview';
import Flash from '../../../../library/utils/Flash';
import Section from '../../../../library/layout/Section';
import Table from '../../../../library/data-display/Table';
import useSyncStateWithQuery from '../../../../../hooks/use-sync-state-with-query';
import { emailTemplateTypeLabels } from '../../../../../types';
import { formatMoment, TimeFormat } from '../../../../../libraries/time';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useEmails } from '../../../../../hooks/queries/emails';

import type { Email } from '../../../../../types';
import type { TableSchema } from '../../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const schema: TableSchema<Email> = [{
  header: 'Subject',
  displayValue: ({ subject }) => subject,
}, {
  header: 'Type',
  displayValue: ({ email_template }) => emailTemplateTypeLabels[email_template.type],
}, {
  header: 'Sent At',
  displayValue: ({ sent_at }) => sent_at ? formatMoment(Moment.tz(sent_at, Moment.tz.guess()), TimeFormat.ShortMonthDayYearWithTimeAndTimezoneAndComma) : <span className="email-sending">Sending</span>,
}];

const CandidateEmails = () => {
  const { id } = useParams<{ id: string }>();

  const [pageNumber, queryPageNumber, setPageNumber] = useSyncStateWithQuery<string>('page', '1');

  const { data: application } = useApplication(id);
  const emailsQuery = useEmails(application!.id, {
    limit: 10,
    offset: 10 * (parseInt(queryPageNumber, 10) - 1),
  });

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value.toString());
  }, []);

  return (
    <Breadcrumb
      data={{
        title: 'Emails',
        pathname: correctPath(`/app/candidates/${id}/emails`),
      }}
    >
      <Section
        className="candidate-emails"
        title="Emails"
      >
        <Helmet>
          <title>Emails | {application?.candidate.name || 'Unknown'} ({application?.job.name}) | InterviewPlanner</title>
        </Helmet>
        <Flash
          message={emailsQuery.error?.message}
          showFlash={Boolean(emailsQuery.error)}
          type="danger"
        />
        {emailsQuery.isSuccess &&
          <Table
            data={emailsQuery.data.emails}
            dataDescriptor="emails"
            displayExpandedContent={(email) => (
              <EmailPreview
                attachments={email.email_template.attachments}
                bccEmails={email.bcc_emails}
                body={email.body}
                ccEmails={email.cc_emails}
                senderEmail={email.sender_email}
                senderName={email.sender_name}
                sentAt={email.sent_at}
                subject={email.subject}
                to={email.recipient_email}
              />
            )}
            isLoading={emailsQuery.isLoading}
            isPaginated
            layout="vertical"
            onPageNumberChange={handlePageNumberChange}
            pageNumber={parseInt(pageNumber, 10)}
            schema={schema}
            showResultsCount
            totalCount={emailsQuery.data.total}
          />
        }
      </Section>
    </Breadcrumb>
  );
};

export default CandidateEmails;
