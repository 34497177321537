import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router-dom';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { orderBy, pick } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import CalendarEventTemplateForm from '../../../../library/inputs/CalendarEventTemplateForm';
import CheckboxInput from '../../../../library/inputs/CheckboxInput';
import EmailTemplateForm from '../../../../library/inputs/EmailTemplateForm';
import ErrorTokenFlash from '../../../../library/utils/ErrorTokenFlash';
import Flash from '../../../../library/utils/Flash';
import MultiStepFormStep from '../../../../library/inputs/MultiStepFormStep';
import SchedulingCalendarSelectInput from '../../../../library/inputs/SchedulingCalendarSelectInput';
import pluralize from 'libraries/pluralize';
import { Directory, liveCodingLabels } from '../../../../../types';
import { Step, useNewSchedule } from './use-new-schedule';
import { constructScheduleTokens } from './helpers';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useCalendars } from '../../../../../hooks/queries/calendars';
import { useRoomsMap } from '../../../../../hooks/queries/rooms';
import { useSession } from '../../../../../hooks/use-session';
import { useTokens } from '../../../../../hooks/queries/tokens';
import { useUsersMap } from '../../../../../hooks/queries/users';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../library/inputs/SelectInput/types';
import type { ScheduleOption } from './types';
import type { Token } from '../../../../../types';
import { correctPath } from 'libraries/gem';

const EventsAndEmailsStep = () => {
  const { id } = useParams<{ id: string }>();

  const { account, currentUser } = useSession();
  const application = useApplication(id).data!;
  const { data: calendars, isLoading: calendarsIsLoading } = useCalendars();
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const {
    completedStep,
    schedule,
    setCompletedStep,
    setSchedule,
  } = useNewSchedule();

  const selectedScheduleOptions = useMemo<ScheduleOption[]>(() => {
    const schedules: ScheduleOption[] = [];
    Object.values(schedule.generatedOptionsByNumberOfBlocks).forEach(({ scheduleOptions, selectedScheduleOptionIndices }) => {
      selectedScheduleOptionIndices.forEach((index) => {
        schedules.push(scheduleOptions[index]);
      });
    });
    return schedules;
  }, [schedule.generatedOptionsByNumberOfBlocks]);

  const numberOfBlocks = selectedScheduleOptions[0]?.blocks.length;
  const isMultiBlock = numberOfBlocks > 1;
  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);

  const [error, setError] = useState<Error | null>(null);
  // calendar event
  const [schedulingCalendar, setSchedulingCalendar] = useState(schedule.scheduling_calendar_email || '');
  const [candidateSchedulingCalendar, setCandidateSchedulingCalendar] = useState(schedule.candidate_scheduling_calendar_email || '');
  const [candidateCalendarEventTemplateId, setCandidateCalendarEventTemplateId] = useState(schedule.schedule_template.candidate_calendar_event_template?.id);
  const [candidateCalendarEventTemplateTitle, setCandidateCalendarEventTemplateTitle] = useState(schedule.schedule_template.candidate_calendar_event_template?.title || '');
  const [candidateCalendarEventTemplateDescription, setCandidateCalendarEventTemplateDescription] = useState(schedule.schedule_template.candidate_calendar_event_template?.description || '');
  const [candidateCalendarEventTemplateLocation, setCandidateCalendarEventTemplateLocation] = useState(schedule.schedule_template.candidate_calendar_event_template?.location);
  const [candidateCalendarEventTemplateAdditionalAttendees, setCandidateCalendarEventTemplateAdditionalAttendees] = useState(schedule.schedule_template.candidate_calendar_event_template?.additional_attendees || []);
  const [candidateCalendarEventTemplateAdditionalOptionalAttendees, setCandidateCalendarEventTemplateAdditionalOptionalAttendees] = useState(schedule.schedule_template.candidate_calendar_event_template?.additional_optional_attendees || []);
  const [calendarErrorTokens, setCalendarErrorTokens] = useState<Token[]>([]);
  const [calendarIncludedTokens, setCalendarIncludedTokens] = useState<Token[]>([]);
  // confirmation email
  const [isConfirmationEmailEnabled, setIsConfirmationEmailEnabled] = useState(canSendEmail && (schedule.id ?
    Boolean(schedule.schedule_template[isMultiBlock ? 'multi_block_confirmation_email_template' : 'confirmation_email_template']) :
    Boolean(schedule.schedule_template.confirmation_email_template)
  ));
  const [emailTemplate, setEmailTemplate] = useState(schedule.id ?
    schedule.schedule_template[isMultiBlock ? 'multi_block_confirmation_email_template' : 'confirmation_email_template'] :
    schedule.schedule_template.confirmation_email_template
  );
  const [emailErrorTokens, setEmailErrorTokens] = useState<Token[]>([]);
  const [emailHasRequiredTokens, setEmailHasRequiredTokens] = useState(false);
  // other
  const [createHiringChannel, setCreateHiringChannel] = useState(schedule.schedule_template.create_hiring_channel);
  const [markInterviewerEventsAsPrivate, setMarkInterviewerEventsAsPrivate] = useState(schedule.schedule_template.mark_interviewer_events_as_private);
  const [markCandidateEventsAsPrivate, setMarkCandidateEventsAsPrivate] = useState(schedule.schedule_template.mark_candidate_events_as_private);

  const hasLiveCodingLinks = useMemo<boolean>(() => Boolean(account?.live_coding_type) && schedule.stage_interviews.some((stageInterview) => {
    return stageInterview.in_schedule && stageInterview.interview_template.live_coding_enabled;
  }), [account?.live_coding_type, schedule.stage_interviews]);

  const {
    data: calendarTokens,
    error: calendarTokensError,
  } = useTokens({
    type: 'candidate_calendar_event',
    schedule: constructScheduleTokens(
      application,
      schedule,
      // The candidate_calendar_event email template type only accepts tokens for a single block
      // of interviews (since there is a separate calendar event for each block).
      // So, we only pass in the first block and can thus only preview values from the first block on this step.
      [selectedScheduleOptions[0]?.blocks[0]].filter(Boolean),
      rooms,
      users
    )?.[0],
  }, {
    enabled: completedStep >= Step.EventsAndEmails,
  });

  const {
    data: emailTokens,
    error: emailTokensError,
  } = useTokens({
    type: isMultiBlock ? 'multi_block_confirmation_email' : 'confirmation_email',
    ...(isMultiBlock ? {
      schedules: constructScheduleTokens(application, schedule, selectedScheduleOptions[0]?.blocks, rooms, users),
    } : {
      schedule: constructScheduleTokens(application, schedule, selectedScheduleOptions[0]?.blocks, rooms, users)?.[0],
    }),
  }, {
    enabled: completedStep >= Step.EventsAndEmails,
  });

  useEffect(() => {
    if (error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [error]);

  const handleSchedulingCalendarChange = (option: OnChangeValue<Option<string>, false>) => setSchedulingCalendar(option?.value || '');
  const handleCandidateSchedulingCalendarChange = (option: OnChangeValue<Option<string>, false>) => setCandidateSchedulingCalendar(option?.value || '');
  const handleCreateHiringChannelChange = (e: ChangeEvent<HTMLInputElement>) => setCreateHiringChannel(e.target.checked);
  const handleMarkInterviewerEventsAsPrivateChange = (e: ChangeEvent<HTMLInputElement>) => setMarkInterviewerEventsAsPrivate(e.target.checked);
  const handleMarkCandidateEventsAsPrivateChange = (e: ChangeEvent<HTMLInputElement>) => setMarkCandidateEventsAsPrivate(e.target.checked);

  const errorTokens = useMemo(() => {
    return orderBy([
      ...calendarErrorTokens,
      ...emailErrorTokens,
    ]);
  }, [calendarErrorTokens, emailErrorTokens]);
  const descriptionHasAgenda = useMemo(() => calendarIncludedTokens.some((name) => name.startsWith('Schedule.Agenda')), [calendarIncludedTokens]);

  const handleNext = () => {
    setError(null);

    // If there are any live coding links, make sure the agenda is being sent in some form. Exabeam doesn't want the
    // links in their agendas, so we exclude this check for them.
    const isExabeam = account!.id === '8fac4b62-daf0-44ea-a357-33997a854c43' || account!.name?.toLowerCase().includes('exabeam');
    if (!isExabeam && hasLiveCodingLinks && !descriptionHasAgenda && (!isConfirmationEmailEnabled || !emailHasRequiredTokens)) {
      setError(new Error(`The calendar description or confirmation email must include a Schedule.Agenda token since that's where the ${liveCodingLabels[account!.live_coding_type!]} links will be included.`));
      return false;
    }

    setSchedule((prev) => ({
      ...prev,
      scheduling_calendar_email: schedulingCalendar,
      candidate_scheduling_calendar_email: candidateSchedulingCalendar,
      schedule_template: {
        ...prev.schedule_template,
        candidate_calendar_event_template: {
          id: candidateCalendarEventTemplateId,
          title: candidateCalendarEventTemplateTitle,
          description: candidateCalendarEventTemplateDescription,
          location: candidateCalendarEventTemplateLocation,
          additional_attendees: candidateCalendarEventTemplateAdditionalAttendees,
          additional_optional_attendees: candidateCalendarEventTemplateAdditionalOptionalAttendees,
        },
        confirmation_email_template: isConfirmationEmailEnabled && !isMultiBlock ? {
          ...pick(emailTemplate!, [
            'id',
            'name',
            'subject',
            'sender_name',
            'sender_email',
            'cc_emails',
            'bcc_emails',
            'body',
            'attachments',
          ]),
          name: emailTemplate!.name || 'Confirmation Email',
        } : undefined,
        multi_block_confirmation_email_template: isConfirmationEmailEnabled && isMultiBlock ? {
          ...pick(emailTemplate!, [
            'id',
            'name',
            'subject',
            'sender_name',
            'sender_email',
            'cc_emails',
            'bcc_emails',
            'body',
            'attachments',
          ]),
          name: emailTemplate!.name || 'Confirmation Email',
        } : undefined,
        create_hiring_channel: createHiringChannel,
        mark_interviewer_events_as_private: markInterviewerEventsAsPrivate,
        mark_candidate_events_as_private: markCandidateEventsAsPrivate,
      },
    }));
    setCompletedStep(Step.EventsAndEmails + 1);
  };

  const isVideoConferencingEnabled = schedule.schedule_template.video_conferencing_enabled;
  const isConfirmingHeldSchedule = Boolean(schedule.id);

  if (completedStep < Step.EventsAndEmails) {
    return <Redirect to={correctPath(`/app/candidates/${id}/schedule/interview-plan`)} />;
  }

  return (
    <Breadcrumb
      data={{
        title: '5. Events & Emails',
        pathname: correctPath(`/app/candidates/${id}/schedule/events-and-emails`),
      }}
    >
      <MultiStepFormStep
        backLocation={correctPath(`/app/candidates/${id}/schedule/schedule${schedule.id ? `?schedule=${schedule.id}` : ''}`)}
        className="form-step-calendar-events"
        nextButtonIsDisabled={errorTokens.length > 0 || calendarsIsLoading || Boolean(schedulingCalendar && calendars && !calendars.calendars[schedulingCalendar]) || Boolean(candidateSchedulingCalendar && calendars && !calendars.calendars[candidateSchedulingCalendar])}
        nextButtonValue={calendarsIsLoading ? 'Loading...' : 'Review'}
        nextLocation={correctPath(`/app/candidates/${id}/schedule/review${schedule.id ? `?schedule=${schedule.id}` : ''}`)}
        onNext={handleNext}
      >
        <Helmet>
          <title>5. Events &amp; Emails | Schedule {application.candidate.name || 'Unknown'} for {application.current_stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message={calendarTokensError?.message}
          showFlash={Boolean(calendarTokensError)}
          type="danger"
        />
        <Flash
          message={emailTokensError?.message}
          showFlash={Boolean(emailTokensError)}
          type="danger"
        />
        <Flash
          message="You don't have access to the currently set scheduling calendar. Please select a different one or ask someone who does have access to it to share it with you."
          showFlash={Boolean(schedulingCalendar && calendars && !calendars.calendars[schedulingCalendar])}
          type="danger"
        />
        <Flash
          message="You don't have access to the currently set scheduling calendar for candidate events. Please select a different one or ask someone who does have access to it to share it with you."
          showFlash={Boolean(candidateSchedulingCalendar && calendars && !calendars.calendars[candidateSchedulingCalendar])}
          type="danger"
        />
        <Flash
          message={
            <>
              <div>
                Fill out the calendar event and email that we will {schedule.hold ? 'eventually send to the candidate when you confirm the schedule. You can always edit this later.' : 'send to the candidate.'}
              </div>
              {isMultiBlock && (
                <>
                  <br />
                  <div>
                    We will send a calendar event for each schedule block. You can preview token values for the first block.
                  </div>
                </>
              )}
            </>
          }
          showFlash
          type="info"
        />
        <ErrorTokenFlash errorTokens={errorTokens} />
        <SchedulingCalendarSelectInput
          helperText={<><FontAwesomeIcon className="helper-text-warning-icon" icon={faExclamationTriangle} /> This cannot be changed after you create the schedule.</>}
          isDisabled={isConfirmingHeldSchedule}
          isRequired
          onChange={handleSchedulingCalendarChange}
          schedulingCalendar={schedulingCalendar}
        />
        <SchedulingCalendarSelectInput
          helperText={<><FontAwesomeIcon className="helper-text-warning-icon" icon={faExclamationTriangle} /> This cannot be changed after you create the schedule.</>}
          isClearable
          isDisabled={isConfirmingHeldSchedule}
          label="Scheduling Calendar for Candidate Events"
          onChange={handleCandidateSchedulingCalendarChange}
          placeholder="Leave blank to use the primary scheduling calendar"
          schedulingCalendar={candidateSchedulingCalendar}
        />
        {account?.directory_type === Directory.Google && (
          <>
            <CheckboxInput
              helperText="Recommended when scheduling a sensitive candidate, e.g. an executive or existing employee."
              isChecked={markInterviewerEventsAsPrivate}
              label="Make interviewer calendar events private."
              onChange={handleMarkInterviewerEventsAsPrivateChange}
            />
            <CheckboxInput
              helperText="Recommended when scheduling a sensitive candidate or when sending to their work email."
              isChecked={markCandidateEventsAsPrivate}
              label={`Make candidate calendar ${pluralize('event', numberOfBlocks)} private.`}
              onChange={handleMarkCandidateEventsAsPrivateChange}
            />
          </>
        )}
        <CalendarEventTemplateForm
          additionalAttendees={candidateCalendarEventTemplateAdditionalAttendees}
          additionalOptionalAttendees={candidateCalendarEventTemplateAdditionalOptionalAttendees}
          description={candidateCalendarEventTemplateDescription}
          id={candidateCalendarEventTemplateId}
          isVideoConferencingEnabled={isVideoConferencingEnabled}
          location={candidateCalendarEventTemplateLocation}
          locationHelperText={isVideoConferencingEnabled && !(isConfirmingHeldSchedule && selectedScheduleOptions[0].blocks[0].selected_zoom_host && selectedScheduleOptions[0].blocks[0].current_zoom_host_id === selectedScheduleOptions[0].blocks[0].selected_zoom_host.id) ? 'We will add a video conferencing link when the schedule is created.' : undefined}
          pendingPreviewMessage="This token will be filled in when we create the schedule."
          setAdditionalAttendees={setCandidateCalendarEventTemplateAdditionalAttendees}
          setAdditionalOptionalAttendees={setCandidateCalendarEventTemplateAdditionalOptionalAttendees}
          setDescription={setCandidateCalendarEventTemplateDescription}
          setErrorTokens={setCalendarErrorTokens}
          setId={setCandidateCalendarEventTemplateId}
          setIncludedTokens={setCalendarIncludedTokens}
          setLocation={setCandidateCalendarEventTemplateLocation}
          setTitle={setCandidateCalendarEventTemplateTitle}
          title={candidateCalendarEventTemplateTitle}
          tokens={calendarTokens}
          type="candidate_calendar_event"
        />
        <EmailTemplateForm
          additionalRequiredTokenPrefixes={descriptionHasAgenda ? [] : ['Schedule.Agenda']}
          emailTemplate={emailTemplate}
          enabledCheckboxLabel="Send confirmation email to candidate."
          isDisabled={!canSendEmail}
          isEnabled={isConfirmationEmailEnabled}
          pendingPreviewMessage="This token will be filled in when we create the schedule."
          setEmailTemplate={setEmailTemplate}
          setErrorTokens={setEmailErrorTokens}
          setHasRequiredTokens={setEmailHasRequiredTokens}
          setIsEnabled={setIsConfirmationEmailEnabled}
          tokens={emailTokens}
          type={isMultiBlock ? 'multi_block_confirmation_email' : 'confirmation_email'}
        />
        {account?.chat_type && (
          <CheckboxInput
            helperText="We will create a private hiring channel for the candidate and invite all current and past interviewers."
            isChecked={createHiringChannel}
            label="Create a hiring channel."
            onChange={handleCreateHiringChannelChange}
          />
        )}
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default EventsAndEmailsStep;
