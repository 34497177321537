import { Link } from 'react-router-dom';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import CheckboxInput from '../../../library/inputs/CheckboxInput';
import Flash from 'components/library/utils/Flash';
import InterviewPlanner from 'libraries/interviewplanner';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import OutboundLink from 'components/library/navigation/OutboundLink';
import Section from 'components/library/layout/Section';
import {
  StyledChatTypeContainer,
  StyledChatTypeSelectInput,
  StyledCheckboxContainer,
  StyledConnectButton,
  StyledContainer,
  StyledForm,
} from './styles';
import TokenInput from '../../../library/inputs/TokenInput';
import useSyncStateWithQuery from 'hooks/use-sync-state-with-query';
import { ChatTool, chatLabels } from 'types';
import { signUpParams } from 'hooks/queries/auth';
import { slateValueToText } from '../../../../libraries/editor/slate-value-to-text';
import { useAccount, useUpdateAccount } from 'hooks/queries/accounts';
import { useRender, useTokens } from '../../../../hooks/queries/tokens';
import { useSlateEditor } from '../../../../hooks/use-slate-editor';

import type { ChangeEvent } from 'react';
import type { ErrorLike } from '../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { UpdateAccountPayload } from 'hooks/queries/accounts';

const DEFAULT_HIRING_CHANNEL_NAME_TEMPLATE = 'hiring-{{ Candidate.FullName }}';

const ERROR_CODES: Record<string, JSX.Element> = {
  no_code: <span>There was an error when redirecting you. Try connecting again, and if it continues to happen, <Link to="/contact">let us know</Link>.</span>,
};

const chatOptions: Option<`${ChatTool}`>[] = [
  {
    value: ChatTool.Slack,
    label: chatLabels[ChatTool.Slack],
  },
];

const chatHelpLinks: Record<ChatTool, string> = {
  [ChatTool.Slack]: 'https://support.gem.com/hc/en-us/articles/23491755189911-How-do-I-connect-my-Slack-account',
};

const candidateNameRegex = /{{\s*Candidate\.(Full|First|Last)Name\s*}}/;

const IntegrationsChatSection = () => {
  const queryClient = useQueryClient();

  const { data } = useAccount();
  const account = data!;

  const [, queryError] = useSyncStateWithQuery<string>('chat_error', '');
  const [, querySuccess] = useSyncStateWithQuery<string>('chat_success', '');

  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState<ErrorLike | null>(ERROR_CODES[queryError] ? { message: ERROR_CODES[queryError] } : null);
  const [isFetching, setIsFetching] = useState(false);
  const [chatType, setChatType] = useState<`${ChatTool}` | ''>(account.chat_type || '');
  const [hiringChannelNameTemplateSlateEditor, hiringChannelNameTemplateSlateValue, setHiringChannelNameTemplateSlateValue, setHiringChannelNameTemplate] = useSlateEditor(account?.hiring_channel_name_template || '', true);
  const [preventRemovingUsers, setPreventRemovingUsers] = useState(account.chat_prevent_removing_users);
  const [preventArchivingChannels, setPreventArchivingChannels] = useState(account.chat_prevent_archiving_channels);
  const [hiringChannelNamePreview, setHiringChannelNamePreview] = useState('hiring-jane-doe');

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({
    type: 'hiring_channel_name',
  });

  const {
    data: renderedHiringChannelName,
    error: hiringChannelNameError,
  } = useRender({
    type: 'hiring_channel_name',
    plain_text: true,
    application: {
      ats_id: 'id', // This isn't used.
      candidate: {
        name: 'Jane Doe',
        ats_id: 'id', // This isn't used.
      },
    },
    stage: {
      name: 'Fake Stage', // This isn't used.
      job: {
        name: 'Fake Job',
      },
    },
    text: slateValueToText(hiringChannelNameTemplateSlateValue),
  }, {
    enabled: Boolean(slateValueToText(hiringChannelNameTemplateSlateValue)),
  });

  const updateAccountMutation = useUpdateAccount();

  useEffect(() => {
    setChatType(account.chat_type || '');
  }, [account.chat_type]);

  useEffect(() => {
    setHiringChannelNameTemplate(account.hiring_channel_name_template || DEFAULT_HIRING_CHANNEL_NAME_TEMPLATE);
  }, [account.hiring_channel_name_template]);

  useEffect(() => {
    setPreventRemovingUsers(account.chat_prevent_removing_users);
  }, [account.chat_prevent_removing_users]);

  useEffect(() => {
    setPreventArchivingChannels(account.chat_prevent_archiving_channels);
  }, [account.chat_prevent_archiving_channels]);

  useEffect(() => {
    if (renderedHiringChannelName?.rendered_text) {
      setHiringChannelNamePreview(renderedHiringChannelName.rendered_text);
    }
  }, [renderedHiringChannelName]);

  const handleChatTypeChange = (option: OnChangeValue<Option<`${ChatTool}`>, false>) => setChatType(option?.value || '');
  const handlePreventRemovingUsersChange = (e: ChangeEvent<HTMLInputElement>) => setPreventRemovingUsers(!e.target.checked);
  const handlePreventArchivingChannelsChange = (e: ChangeEvent<HTMLInputElement>) => setPreventArchivingChannels(!e.target.checked);

  const handleConnectChatAccount = async (cType: `${ChatTool.Slack}`) => {
    setIsFetching(true);

    // Save hiring channel name template before connecting chat account if there's a valid name template.
    if (candidateNameRegex.test(slateValueToText(hiringChannelNameTemplateSlateValue)) && !hiringChannelNameError) {
      try {
        await InterviewPlanner.request('POST', `/accounts/${account.id}`, { hiring_channel_name_template: slateValueToText(hiringChannelNameTemplateSlateValue) });
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        }
      }
    }

    try {
      const { redirect_url } = await queryClient.fetchQuery(signUpParams(cType));
      window.location.href = redirect_url;
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      setIsFetching(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setError(null);
    setChatType(account.chat_type || '');
    setHiringChannelNameTemplate(account.hiring_channel_name_template || DEFAULT_HIRING_CHANNEL_NAME_TEMPLATE);
    setPreventRemovingUsers(account.chat_prevent_removing_users);
    setPreventArchivingChannels(account.chat_prevent_archiving_channels);
    setIsEditing(false);
    setError(null);
    updateAccountMutation.reset();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();
    setError(null);

    const payload: UpdateAccountPayload = {
      hiring_channel_name_template: slateValueToText(hiringChannelNameTemplateSlateValue),
      chat_prevent_removing_users: preventRemovingUsers,
      chat_prevent_archiving_channels: preventArchivingChannels,
    };
    if (chatType !== 'slack') {
      payload.chat_type = chatType || '';
    }

    if (!candidateNameRegex.test(payload.hiring_channel_name_template || '')) {
      setError(new Error('The template must include a Candidate name token.'));
      return;
    }

    try {
      await updateAccountMutation.mutateAsync({
        id: account.id,
        payload,
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const hasChatIntegration = Boolean(account.chat_type);
  const isSaving = isFetching || updateAccountMutation.isLoading;
  const isSuccess = updateAccountMutation.isSuccess || Boolean(querySuccess);

  return (
    <Section
      isEditable
      isEditing={isEditing}
      isNew={!hasChatIntegration}
      isSaving={isSaving}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      showSaveButton={(
        chatType !== 'slack' ||
        (Boolean(account.chat_type) && slateValueToText(hiringChannelNameTemplateSlateValue) !== account.hiring_channel_name_template) ||
        (Boolean(account.chat_type) && preventRemovingUsers !== account.chat_prevent_removing_users) ||
        (Boolean(account.chat_type) && preventArchivingChannels !== account.chat_prevent_archiving_channels)
      )}
      title="Chat"
    >
      <StyledForm>
        <Flash
          message="Connect your company's chat tool to automatically create and manage hiring channels for candidates."
          showFlash={!Boolean(error) && !hasChatIntegration}
          type="info"
        />
        <Flash
          isDismissible
          message="Successfully updated!"
          showFlash={isSuccess}
          type="success"
        />
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message={tokensError?.message}
          showFlash={Boolean(tokensError)}
          type="danger"
        />
        <Flash
          message={updateAccountMutation.error?.message}
          showFlash={updateAccountMutation.isError}
          type="danger"
        />
        <StyledContainer>
          <StyledChatTypeContainer>
            <StyledChatTypeSelectInput
              helperText={isEditing && chatType ?
                <OutboundLink
                  href={chatHelpLinks[chatType]}
                  label={`${chatLabels[chatType]} Chat Helper Text`}
                >
                  Learn more about the {chatLabels[chatType]} integration.
                </OutboundLink> :
                null
              }
              isClearable
              isDisabled={!isEditing || isFetching}
              label="Chat Tool"
              onChange={handleChatTypeChange}
              options={chatOptions}
              placeholder="Select your chat tool..."
              value={find(chatOptions, ['value', chatType])}
            />
            {isEditing && chatType === 'slack' &&
              <StyledConnectButton
                color="gem-blue"
                iconRight={isFetching ? <LoadingSpinner /> : undefined}
                isDisabled={isFetching}
                onClick={() => handleConnectChatAccount(chatType)}
                value={isFetching ? 'Connecting...' : `${hasChatIntegration && account.chat_type === 'slack' ? 'Re-' : ''}Connect your account`}
              />
            }
          </StyledChatTypeContainer>
          {chatType && tokens && (
            <TokenInput
              editor={hiringChannelNameTemplateSlateEditor}
              helperText={`Enter a template to use when creating the hiring channel, e.g. #${hiringChannelNamePreview}. This must contain part of the candidate's name.`}
              isDisabled={!isEditing || isFetching}
              isRequired
              label="Hiring Channel Name"
              pendingPreviewMessage="You can preview this token when you are scheduling a candidate."
              setValue={setHiringChannelNameTemplateSlateValue}
              tokens={tokens}
              type="hiring_channel_name"
              value={hiringChannelNameTemplateSlateValue}
            />
          )}
          <StyledCheckboxContainer>
            {chatType && (
              <CheckboxInput
                isChecked={!preventRemovingUsers}
                isDisabled={!isEditing || isFetching}
                label="Remove users from hiring channels if they're removed from the interview panel."
                onChange={handlePreventRemovingUsersChange}
              />
            )}
            {chatType && (
              <CheckboxInput
                isChecked={!preventArchivingChannels}
                isDisabled={!isEditing || isFetching}
                label="Archive hiring channels if the candidate is rejected or hired."
                onChange={handlePreventArchivingChannelsChange}
              />
            )}
          </StyledCheckboxContainer>
        </StyledContainer>
      </StyledForm>
    </Section>
  );
};

export default IntegrationsChatSection;
