import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { kebabCase } from 'lodash';
import { useParams } from 'react-router-dom';

import Avatar from '../../../library/data-display/Avatar';
import JobStatusIndicator from '../../../library/utils/JobStatusIndicator';
import ListItem from '../../../library/data-display/ListItem';
import LoadingSpinner from '../../../library/utils/LoadingSpinner';
import Section from '../../../library/layout/Section';
import Table from '../../../library/data-display/Table';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useJob } from '../../../../hooks/queries/jobs';
import { useUsersMap } from '../../../../hooks/queries/users';
import { correctPath } from 'libraries/gem';

const JobDetails = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: job,
    isLoading,
  } = useJob(id);
  const users = useUsersMap({ archived: true });

  if (isLoading || !job) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Details',
        pathname: correctPath(`/app/jobs/${id}/details`),
      }}
    >
      <div className="job-details-container">
        <Helmet>
          <title>Details | {job.name} | Gem Scheduling</title>
        </Helmet>
        <Section title="Summary">
          <Table
            data={[job]}
            layout="horizontal"
            schema={[{
              header: 'Job Name',
              displayValue: ({ name }) => name,
            }, {
              header: 'Job Post Name',
              displayValue: ({ post_name }) => post_name || <i>No job post name</i>,
            }, {
              header: 'Status',
              displayValue: ({ status }) => <JobStatusIndicator status={status} />,
            }, {
              header: 'Office',
              displayValue: ({ offices }) => (
                <span className={offices ? '' : 'unset'}>
                  {offices ?
                    offices.map(({ name }) => name).join(', ') :
                    'Anywhere'
                  }
                </span>
              ),
            }, {
              header: 'Date Created',
              displayValue: ({ ats_created_at }) => formatMoment(Moment(ats_created_at), TimeFormat.LongMonthDayYear),
            }, {
              header: 'Last Modified',
              displayValue: ({ ats_updated_at }) => formatMoment(Moment(ats_updated_at), TimeFormat.LongMonthDayYear),
            }, {
              header: 'Hiring Team',
              displayValue: (job) => (
                <div className="hiring-panel-container">
                  {[
                    { role: 'Sourcer', userID: job.sourcer_id },
                    { role: 'Recruiter', userID: job.recruiter_id },
                    { role: 'Coordinator', userID: job.coordinator_id },
                    { role: 'Hiring Manager', userID: job.hiring_manager_id },
                  ].map((member) => {
                    const user = member.userID ? users[member.userID] : undefined;
                    return (
                      <ListItem
                        key={`${kebabCase(member.role)}`}
                        label={user ? user.name || user.email : <span className="unassigned">Unassigned</span>}
                        leftIcon={<Avatar
                          alt={user ? undefined : 'Unassigned'}
                          showUserTooltip={false}
                          size="small"
                          userId={user?.id}
                        />}
                        secondaryText={member.role}
                      />
                    );
                  })}
                </div>
              ),
            }]}
          />
        </Section>
      </div>
    </Breadcrumb>
  );
};

export default JobDetails;
