import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

const ApplicationNotFound = () => {
  const { pathname } = useLocation();

  return (
    <div className="not-found-container">
      <Helmet>
        <title>Not Found | InterviewPlanner</title>
      </Helmet>
      <Breadcrumb
        data={{
          title: <><FontAwesomeIcon icon={faQuestion} />Not Found</>,
          pathname,
        }}
      />
      <h1>
        Oh no! We can&apos;t find that page.
      </h1>
    </div>
  );
};

export default ApplicationNotFound;
