import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, useParams } from 'react-router-dom';
import { capitalize, find, flatten } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import Flash from '../../../../library/utils/Flash';
import InterviewerTemplatesForm from '../../../../library/inputs/InterviewerTemplatesForm';
import MultiStepFormStep from '../../../../library/inputs/MultiStepFormStep';
import PreFillInterviewerTemplatesFlash from '../../../../library/utils/PreFillInterviewerTemplatesFlash';
import Table from '../../../../library/data-display/Table';
import { Step, useNewSchedule } from './use-new-schedule';
import { formatList } from '../../../../../libraries/formatters';
import { isTraineeSlot } from '../../../../../libraries/training';
import { resolveUsersParams } from '../../../../../hooks/queries/users';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useSession } from '../../../../../hooks/use-session';

import type { CreateInterviewTemplatePayload } from '../../../../../hooks/queries/interview-templates';
import type { StageInterview } from './types';
import type { TableSchema } from '../../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const InterviewersStep = () => {
  const queryClient = useQueryClient();

  const { id: applicationId } = useParams<{ id: string }>();

  const { account } = useSession();
  const application = useApplication(applicationId).data!;

  const {
    completedStep,
    schedule,
    setCompletedStep,
    setSchedule,
  } = useNewSchedule();

  const [error, setError] = useState<Error | null>(null);
  const [stageInterviews, setStageInterviews] = useState(schedule.stage_interviews);

  const handleInterviewerTemplatesChange = (id: string, interviewerTemplates: CreateInterviewTemplatePayload['interviewer_templates']) => {
    setStageInterviews((stageInterviews) => stageInterviews.map((stageInterview) => ({
      ...stageInterview,
      interview_template: {
        ...stageInterview.interview_template,
        interviewer_templates: (
          stageInterview.id === id ? (interviewerTemplates || []).map((interviewerTemplate) => ({
            description: interviewerTemplate.description,
            optional: interviewerTemplate.optional,
            include_past_interviewers: interviewerTemplate.include_past_interviewers,
            interviewer_filters: interviewerTemplate.interviewer_filters.map((filter, i) => ({
              position: i,
              interviewer_filter_expressions: filter.interviewer_filter_expressions,
            })),
          })) : stageInterview.interview_template.interviewer_templates
        ) || [],
      },
    })));
  };

  const handleNext = async () => {
    try {
      const resolvePayloads = flatten(stageInterviews.filter(({ in_schedule }) => in_schedule).map(({ interview_template, name }) => {
        return interview_template.interviewer_templates.map(({ interviewer_filters, include_past_interviewers }) => {
          return {
            name,
            filters: interviewer_filters,
            includePastInterviewers: include_past_interviewers,
          };
        });
      }));
      const pools = await Promise.all(resolvePayloads.map(({ filters, includePastInterviewers }) => queryClient.fetchQuery(resolveUsersParams({
        applicationId,
        scheduleId: schedule.id,
        includePastInterviewers,
        interviewerFilters: filters,
      }))));
      const emptyPoolInterviews = resolvePayloads
      // We filter out any empty trainee pools.
      .map(({ name, filters }, i) => !isTraineeSlot(filters) && pools[i].length === 0 ? name : null)
      .filter((name): name is string => Boolean(name));

      if (emptyPoolInterviews.length > 0) {
        setError(new Error(`${formatList(emptyPoolInterviews)} ${emptyPoolInterviews.length === 1 ? 'has' : 'have'} an empty interviewer pool. This might be because all of the potential interviewers don't have a login in ${capitalize(account?.ats_type)}. Make sure there are valid interviewers for these interviews or remove the interviewer.`));
        return false;
      }
    } catch {
      // If it errs out for any reason, just let them continue. If there any
      // empty pools, the Schedule step will catch it.
      setError(null);
    }

    setSchedule((prev) => ({
      ...prev,
      stage_interviews: stageInterviews,
    }));
    setCompletedStep(Step.Interviewers + 1);
  };

  useEffect(() => {
    if (error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [error]);

  const interviewsWithPreFilledAtsInterviewers = stageInterviews.filter(({ ats_interviewer_ids, interview_template, in_schedule }) => in_schedule && !Boolean(interview_template.id) && ats_interviewer_ids && ats_interviewer_ids.length > 0);

  const isConfirmingHeldSchedule = Boolean(schedule.id);

  const columns = useMemo<TableSchema<StageInterview>>(() => [{
    header: 'Interview',
    displayEditValue: ({ name }) => name,
  }, {
    header: 'Interviewers',
    displayEditValue: ({ id, interview_template }) => {
      const interviewWithPreFilledAtsInterviewers = find(interviewsWithPreFilledAtsInterviewers, { id });
      return (
        <>
          <PreFillInterviewerTemplatesFlash
            handleInterviewerTemplatesChange={(interviewerTemplates) => handleInterviewerTemplatesChange(id, interviewerTemplates)}
            interviewerIds={interviewWithPreFilledAtsInterviewers?.ats_interviewer_ids || []}
            showFlash={Boolean(interviewWithPreFilledAtsInterviewers)}
          />
          <InterviewerTemplatesForm
            applicationId={applicationId}
            interviewerTemplates={interview_template && interview_template.interviewer_templates || []}
            isDisabled={isConfirmingHeldSchedule}
            scheduleId={schedule.id}
            setInterviewerTemplates={(interviewerTemplates) => handleInterviewerTemplatesChange(id, interviewerTemplates)}
          />
        </>
      );
    },
  }], [handleInterviewerTemplatesChange, interviewsWithPreFilledAtsInterviewers]);

  if (completedStep < Step.Interviewers) {
    return <Redirect to={correctPath(`/app/candidates/${applicationId}/schedule/interview-plan`)} />;
  }

  return (
    <Breadcrumb
      data={{
        title: '2. Interviewers',
        pathname: correctPath(`/app/candidates/${applicationId}/schedule/interviewers`),
      }}
    >
      <MultiStepFormStep
        backLocation={correctPath(`/app/candidates/${applicationId}/schedule/interview-plan${schedule.id ? `?schedule=${schedule.id}` : ''}`)}
        className="form-step-interviewers"
        nextButtonValue="Availability"
        nextLocation={correctPath(`/app/candidates/${applicationId}/schedule/availability${schedule.id ? `?schedule=${schedule.id}` : ''}`)}
        onNext={handleNext}
      >
        <Helmet>
          <title>2. Interviewers | Schedule {application.candidate.name || 'Unknown'} for {application.current_stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message="Review the interviewer pools for each interview and modify if needed."
          showFlash={!isConfirmingHeldSchedule}
          type="info"
        />
        <Flash
          message={<span>These are settings for generating new schedules. Since you&apos;re confirming a held schedule, generating new schedules is disabled. However, you can still make changes to the interviewers on <Link to={correctPath(`/app/candidates/${application.id}/schedule/schedule${schedule.id ? `?schedule=${schedule.id}` : ''}`)}>the Schedule step</Link>.</span>}
          showFlash={isConfirmingHeldSchedule}
          type="warning"
        />
        <Table
          data={stageInterviews.filter(({ in_schedule }) => in_schedule)}
          isEditing
          layout="vertical"
          schema={columns}
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default InterviewersStep;
