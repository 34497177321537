import Creatable from 'react-select/creatable';
import Select, { createFilter } from 'react-select';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { uniqueId } from 'lodash';

import SelectInputMenuList from './SelectInputMenuList';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import type { Group, Option, Value } from './types';
import type { InputActionMeta } from 'react-select/dist/declarations/src/types';
import type { ReactElement, ReactNode, FocusEventHandler, Ref } from 'react';
import type { SelectComponentsConfig, StylesConfig, SelectInstance } from 'react-select';

interface Props<V extends Value, O extends Option<V>, M extends boolean, G extends Group<V, O>> {
  brandColor?: string;
  className?: string;
  closeMenuOnSelect?: boolean;
  components?: SelectComponentsConfig<O, M, G>;
  formatCreateLabel?: (inputValue: string) => ReactNode;
  formatOptionLabel?: (option: O, meta: FormatOptionLabelMeta<O>) => ReactNode;
  icon?: ReactElement;
  id?: string;
  inputValue?: string;
  isAutofocus?: boolean;
  isClearable?: boolean;
  isCreatable?: boolean;
  isDisabled?: boolean;
  isMenuOpen?: boolean;
  isMulti?: M;
  isRequired?: boolean;
  isSearchable?: boolean;
  helperText?: ReactNode;
  label?: ReactNode;
  maxMenuHeight?: number;
  menuPortalTarget?: HTMLElement;
  name?: string;
  noOptionsMessage?: (obj: { inputValue: string }) => ReactNode;
  onChange?: (newValue: OnChangeValue<O, M>, actionMeta: ActionMeta<O>) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  options: O[] | G[];
  placeholder?: string;
  value?: OnChangeValue<O, M>;
}

const SelectInputInner = <V extends Value, O extends Option<V> = Option<V>, M extends boolean = false, G extends Group<V, O> = Group<V, O>>({
  brandColor,
  className,
  closeMenuOnSelect,
  components = {},
  formatCreateLabel,
  formatOptionLabel,
  helperText,
  icon,
  id,
  inputValue,
  isAutofocus = false,
  isClearable = false,
  isCreatable = false,
  isDisabled = false,
  isMenuOpen,
  isMulti,
  isRequired = false,
  isSearchable = true,
  label,
  maxMenuHeight = 250,
  menuPortalTarget,
  name,
  noOptionsMessage,
  onChange,
  onFocus,
  onInputChange,
  options,
  placeholder,
  value,
}: Props<V, O, M, G>, ref: Ref<SelectInstance<O, M, G>>) => {
  const [selectedOption, setSelectedOption] = useState<OnChangeValue<O, M> | undefined>(value);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  id = useMemo(() => id || uniqueId('select-input-'), [id]);

  const handleChange = (option: OnChangeValue<O, M>) => {
    setSelectedOption(option || undefined);
  };

  const SelectComponent = isCreatable ? Creatable : Select;

  const customStyles = useMemo<StylesConfig<O, boolean, G>>(() => ({
    control: (provided, state) => ({
      ...provided,
      borderColor: (state.isFocused && brandColor) || '#CECECE',
      '&:hover': {
        borderColor: (state.isFocused && brandColor) || '#CECECE',
      },
    }),
    menuPortal: (provided) => ({
      ...provided,
      ...(Boolean(menuPortalTarget) ? { zIndex: 9999 } : {}),
    }),
  }), [brandColor]);

  return (
    <div className={`input select-input${className ? ` ${className}` : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="select-input-container">
        {icon && <div className="icon">{icon}</div>}
        <SelectComponent
          autoFocus={isAutofocus}
          className={`select-component-container ${isMulti ? ' select-multi' : ''}${brandColor ? ' select-branded' : ''}`}
          classNamePrefix="select-input"
          closeMenuOnSelect={closeMenuOnSelect}
          components={{
            MenuList: SelectInputMenuList,
            ...components,
          }}
          filterOption={createFilter({ ignoreAccents: false })}
          formatCreateLabel={formatCreateLabel}
          formatOptionLabel={formatOptionLabel}
          inputId={id}
          // inputValue behaves weirdly when it's disabled. If it's set and it's
          // disabled, the value doesn't show up in the select input.
          inputValue={isDisabled ? undefined : inputValue}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isSearchable={isSearchable}
          maxMenuHeight={maxMenuHeight}
          menuIsOpen={isMenuOpen}
          menuPortalTarget={menuPortalTarget}
          name={name}
          noOptionsMessage={noOptionsMessage}
          onChange={onChange || handleChange}
          onFocus={onFocus}
          onInputChange={onInputChange}
          options={options}
          placeholder={placeholder}
          ref={ref}
          styles={customStyles}
          // The null is to be able to clear out the select input if value was
          // changed to the empty string from the outside.
          value={selectedOption || null}
        />
      </div>
      {isRequired && /* hack because react-select does not support required validation: https://github.com/JedWatson/react-select/issues/4327 */
        <input
          className="select-input-hidden-validation"
          onChange={() => {}}
          required
          value={(Boolean(isMulti && Array.isArray(selectedOption) ? selectedOption.join('') : selectedOption) || '').toString()}
        />
      }
      {helperText && <div className="helper-text">{helperText}</div>}
    </div>
  );
};

const SelectInput = forwardRef(SelectInputInner);

export default SelectInput;
