import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { find } from 'lodash';
import { useCallback, useMemo } from 'react';

import Flash from '../../../library/utils/Flash';
import JobListTable from './JobListTable';
import LoadingSpinner from '../../../library/utils/LoadingSpinner';
import Section from '../../../library/layout/Section';
import SelectInput from '../../../library/inputs/SelectInput';
import TextInput from '../../../library/inputs/TextInput';
import UserSelectInput from '../../../library/inputs/UserSelectInput';
import useSyncStateWithQuery from '../../../../hooks/use-sync-state-with-query';
import { useJobs } from '../../../../hooks/queries/jobs';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../library/inputs/SelectInput/types';

const statusOptions: Option<string>[] = [
  { value: 'open', label: 'Open' },
  { value: 'closed', label: 'Closed' },
  { value: 'draft', label: 'Draft' },
];

const JobList = () => {
  const [search, querySearch, setSearch] = useSyncStateWithQuery<string>('q', '', { delay: 200 });
  const [showArchived, , setShowArchived] = useSyncStateWithQuery<string>('show_archived', 'false');
  const [status, queryStatus, setStatus] = useSyncStateWithQuery<string[]>('status', [statusOptions[0].value], { array: true, stripDefault: false });
  const [including, queryIncluding, setIncluding] = useSyncStateWithQuery<string>('including', '');
  const [pageNumber, queryPageNumber, setPageNumber] = useSyncStateWithQuery<string>('page', '1');

  const {
    data: jobs,
    error,
    isFetching,
  } = useJobs({
    archived: showArchived === 'true',
    name: querySearch, // This has to be querySearch so it doesn't make a lot of requests when you type fast.
    limit: 10,
    offset: 10 * (parseInt(queryPageNumber, 10) - 1),
    status: queryStatus,
    including_user: queryIncluding !== '' ? queryIncluding : undefined,
  });

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handleShowArchivedChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setShowArchived(JSON.stringify(e.target.checked));
  }, []);

  const handleIncludingChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setIncluding(option?.value || '');
  }, []);

  const handleStatusChange = useCallback((option: OnChangeValue<Option<string>, true>) => {
    setStatus(option && option.length > 0 ? option.map((status) => status.value) : []);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value.toString());
  }, []);

  const selectedStatuses = useMemo(() => {
    if (!status) {
      return [];
    }
    return status
    .map((status) => find(statusOptions, ['value', status]))
    .filter((s): s is Option<string> => Boolean(s));
  }, [status]);

  return (
    <div className="job-list-container">
      <Helmet>
        <title>Jobs | Gem Scheduling</title>
      </Helmet>
      <Section title="Jobs">
        <div className="filters-container">
          <TextInput
            id="text-input-job-filter"
            isAutofocus
            label="Search"
            leftIcon={useMemo(() => isFetching ? <LoadingSpinner /> : <FontAwesomeIcon icon={faSearch} />, [isFetching])}
            onChange={handleSearchChange}
            placeholder="Job name"
            value={search}
          />
          <SelectInput
            className="status-select-input"
            isClearable
            isMulti
            label="Status"
            onChange={handleStatusChange}
            options={statusOptions}
            value={selectedStatuses}
          />
          <UserSelectInput
            annotateCurrentUser
            currentUserFirst
            isClearable
            label="Including"
            name="including-select-input"
            onChange={handleIncludingChange}
            value={including}
          />
        </div>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        {jobs &&
          <JobListTable
            jobs={jobs.jobs}
            onPageNumberChange={handlePageNumberChange}
            onShowArchivedChange={handleShowArchivedChange}
            pageNumber={parseInt(pageNumber, 10)}
            showArchived={showArchived === 'true'}
            totalCount={jobs.total}
          />
        }
      </Section>
    </div>
  );
};

export default JobList;
