import { find, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';

import Avatar from '../../../../../../library/data-display/Avatar';
import DurationInput from '../../../../../../library/inputs/DurationInput';
import FeedbackFormSelectInput from '../../../../../../library/inputs/FeedbackFormSelectInput';
import ListItem from '../../../../../../library/data-display/ListItem';
import LoadingSpinner from '../../../../../../library/utils/LoadingSpinner';
import SelectInput from '../../../../../../library/inputs/SelectInput';
import Table from '../../../../../../library/data-display/Table';
import UserSelectInput from '../../../../../../library/inputs/UserSelectInput';
import { InterviewerFilterableType } from '../../../../../../../types';
import { formatDuration } from '../../../../../../../libraries/formatters';
import { useResolvePools, useUsersMap } from '../../../../../../../hooks/queries/users';
import { useSession } from '../../../../../../../hooks/use-session';

import type { Dispatch, SetStateAction } from 'react';
import type { EditableSelfSchedulingLink } from '../../../../../../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../../../library/inputs/SelectInput/types';
import type { TableSchema } from '../../../../../../library/data-display/Table';

interface Props {
  isEditing: boolean;
  selfSchedulingLink: EditableSelfSchedulingLink;
  setSelfSchedulingLink: Dispatch<SetStateAction<EditableSelfSchedulingLink>>;
}

const ScheduleTable = ({ isEditing, selfSchedulingLink, setSelfSchedulingLink }: Props) => {
  const { account } = useSession();
  const users = useUsersMap({ archived: true });

  const poolsQueries = useResolvePools({
    applicationId: selfSchedulingLink.application_id,
    scheduleId: selfSchedulingLink.schedule?.id,
    includePastInterviewers: selfSchedulingLink.interview_template.interviewer_templates?.[0].include_past_interviewers ?? true,
    pools: (selfSchedulingLink.interview_template.interviewer_templates || []).map(({ interviewer_filters }) => interviewer_filters || []),
  });
  const poolsAreLoading = useMemo(() => poolsQueries.some(({ isLoading }) => isLoading), [poolsQueries]);

  const stageInterviewOptions = useMemo<Option<string>[]>(() => {
    return (selfSchedulingLink.stage.stage_interviews || []).map((stageInterview) => ({
      value: stageInterview.id,
      label: stageInterview.name,
    }));
  }, [selfSchedulingLink.stage.stage_interviews]);

  const handleDurationChange = useCallback((duration: number) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      interview_template: {
        ...prevState.interview_template,
        duration_minutes: duration,
      },
    }));
  }, []);

  const handleStageInterviewChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      // This isn't clearable, so option should always be defined.
      stage_interview_id: option!.value,
    }));
  }, []);

  const handleFeedbackFormChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      feedback_form_id: option ? option.value : '',
    }));
  }, []);

  const handleInterviewerChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      interview_template: {
        ...prevState.interview_template,
        interviewer_templates: [{
          optional: false,
          include_past_interviewers: true,
          interviewer_filters: [{
            interviewer_filter_expressions: [{
              negated: false,
              // This isn't clearable, so option should always be defined.
              filterable_id: option!.value,
              filterable_type: InterviewerFilterableType.User,
            }],
          }],
        }],
      },
    }));
  }, []);

  const schema = useMemo<TableSchema<EditableSelfSchedulingLink>>(() => [{
    header: 'Duration',
    displayValue: ({ interview_template: { duration_minutes } }) => formatDuration(duration_minutes),
    displayEditValue: ({ interview_template: { duration_minutes } }) => (
      <DurationInput
        isAbbreviatedUnits
        isRequired
        onChange={handleDurationChange}
        value={duration_minutes}
      />
    ),
  }, {
    header: 'Interview',
    displayValue: ({ stage_interview: { name } }) => name,
    displayEditValue: ({ stage_interview_id }) => (
      <SelectInput
        isRequired
        onChange={handleStageInterviewChange}
        options={stageInterviewOptions}
        value={find(stageInterviewOptions, ['value', stage_interview_id])}
      />
    ),
  }, account?.ats_type === 'lever' && {
    header: 'Feedback Form',
    displayValue: ({ feedback_form }) => {
      if (!feedback_form) {
        return <span className="no-template">No feedback form</span>;
      }
      return feedback_form.name;
    },
    displayEditValue: ({ id, feedback_form_id }) => (
      <FeedbackFormSelectInput
        name={id}
        onChange={handleFeedbackFormChange}
        selectedFeedbackFormId={feedback_form_id}
      />
    ),
  }, {
    header: 'Interview Panel',
    displayValue: ({ interview_template: { interviewer_templates } }) => (
      poolsAreLoading ? (
        <LoadingSpinner />
      ) : (
        // We just take the first interviewer in the pool, so whenever we
        // support an actual pool for self-scheduling links, this will need to
        // be updated.
        <div className="interview-panel">
          {isEmpty(interviewer_templates) ?
            <ListItem label={<i>No interviewers</i>} /> :
            interviewer_templates!.map((interviewer_template, i) => (
              <ListItem
                key={`interviewer-${i}`}
                label={
                  <span>
                    {users[poolsQueries[i].data?.[0]?.id!]?.name || users[poolsQueries[i].data?.[0]?.id!]?.email}
                    {interviewer_template.optional && <span className="optional-interviewer"> (Optional)</span>}
                  </span>
                }
                leftIcon={
                  <Avatar
                    size="small"
                    userId={poolsQueries[i].data?.[0]?.id}
                  />
                }
              />
            ))
          }
        </div>
      )
    ),
    displayEditValue: ({ interview_template: { interviewer_templates } }) => (
      <UserSelectInput
        isRequired
        onChange={handleInterviewerChange}
        // This only works for single interviewers.
        value={interviewer_templates?.[0].interviewer_filters?.[0].interviewer_filter_expressions?.[0].filterable_id}
      />
    ),
  }], [
    account?.ats_type,
    handleFeedbackFormChange,
    handleStageInterviewChange,
    poolsAreLoading,
    poolsQueries,
    users,
  ]);

  return (
    <Table
      className="self-scheduling-link-request-table"
      data={[selfSchedulingLink]}
      isEditing={isEditing}
      layout="vertical"
      schema={schema}
    />
  );
};

export default ScheduleTable;
