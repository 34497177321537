import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';

import Flash from './library/utils/Flash';
import LoadingSpinner from './library/utils/LoadingSpinner';
import useSyncStateWithQuery from '../hooks/use-sync-state-with-query';
import { useDemo } from '../hooks/queries/auth';
import { useSession } from '../hooks/use-session';
import { correctPath } from '../libraries/gem';

const UNAUTHORIZED_STATUS_CODE = 401;

const Demo = () => {
  const {
    session,
    sessionError,
    isSessionLoading,
    refetchSession,
  } = useSession();

  const [, email] = useSyncStateWithQuery<string>('email', '');
  const [, name] = useSyncStateWithQuery<string>('name', '');
  const [, company] = useSyncStateWithQuery<string>('company', '');
  const [, ats] = useSyncStateWithQuery<string>('ats', 'greenhouse');

  const demoMutation = useDemo();

  useEffect(() => {
    (async () => {
      if (!isSessionLoading && !session) {
        try {
          const payload = { email, name, company, ats };
          const { success, creating } = await demoMutation.mutateAsync({ payload });
          if (success) {
            // If the account is being created for the first time, wait 2 second
            // to allow the worker to complete.
            if (creating) {
              setTimeout(() => refetchSession?.(), 2000);
            } else {
              refetchSession?.();
            }
          }
        } catch (_) {
          // Since React Query catches the error and attaches it to the mutation, we
          // don't need to do anything with this error besides prevent it from
          // bubbling up.
        }
      }
    })();
  }, [isSessionLoading, session]);

  if (isSessionLoading) {
    return null;
  }

  if (session) {
    return <Redirect to={correctPath('/app')} />;
  }

  return (
    <div className="sign-in-container">
      <Helmet>
        <title>Demo Sign In | Gem Scheduling</title>
      </Helmet>
      <div className="form-container">
        <h1>Signing you in...</h1>
        {demoMutation.isLoading && <LoadingSpinner className="loading-spinner" />}
        <Flash
          message={sessionError?.message}
          showFlash={Boolean(sessionError && sessionError.status !== UNAUTHORIZED_STATUS_CODE)}
          type="danger"
        />
        <Flash
          message={demoMutation.error?.message}
          showFlash={demoMutation.isError}
          type="danger"
        />
        <img
          alt="Three ducks swimming in a row"
          src="/scheduling/src/assets/images/ducks-in-a-row.svg"
        />
      </div>
    </div>
  );
};

export default Demo;
