import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import Button from '../../../../../../library/inputs/Button';
import EditorInput from '../../../../../../library/inputs/EditorInput';
import EmailTemplateAdvancedSettings from '../../../../../../library/inputs/EmailTemplateAdvancedSettings';
import EmailTemplateSelectInput from '../../../../../../library/inputs/EmailTemplateSelectInput';
import EmailTemplateSummary from '../../../../../../library/data-display/EmailTemplateSummary';
import Flash from '../../../../../../library/utils/Flash';
import Section from '../../../../../../library/layout/Section';
import TemplateNamePromptModal from '../../../../../../library/data-display/TemplateNamePromptModal';
import TextInput from '../../../../../../library/inputs/TextInput';
import ToggleInput from '../../../../../../library/inputs/ToggleInput';
import TokenInput from '../../../../../../library/inputs/TokenInput';
import Tooltip from '../../../../../../library/utils/Tooltip';
import { DEFAULT_EMAIL_CONTENT } from '../../../../../EmailTemplates/EmailTemplateCreate/helpers';
import { EditorType } from '../../../../../../../types';
import { areAttachmentsDifferent } from '../../../../../../../libraries/editor';
import { differingValues } from '../../../../../../../libraries/differing-values';
import { downloadFileFromUrl, isFile } from '../../../../../../../libraries/form-data';
import {
  emailTemplateParams,
  useCreateEmailTemplate,
  useEmailTemplate,
  useEmailTemplatesMap,
  useUpdateEmailTemplate,
} from '../../../../../../../hooks/queries/email-templates';
import { htmlToSlateValue } from '../../../../../../../libraries/editor/html-to-slate-value';
import { slateValueToHtml } from '../../../../../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../../../../../libraries/editor/slate-value-to-text';
import { textToSlateValue } from '../../../../../../../libraries/editor/text-to-slate-value';
import { translateSlateValueBetweenEditorTypes } from '../../../../../../../libraries/editor/translate-slate-value-between-editor-types';
import { useJob } from '../../../../../../../hooks/queries/jobs';
import { useSession } from '../../../../../../../hooks/use-session';
import { useSlateEditor } from '../../../../../../../hooks/use-slate-editor';
import { useStage, useUpdateStage } from '../../../../../../../hooks/queries/stages';
import { useTokens } from '../../../../../../../hooks/queries/tokens';
import { useUsersMap } from '../../../../../../../hooks/queries/users';

import type { AdvancedSettings } from '../../../../../../library/inputs/EmailTemplateAdvancedSettings';
import type { ChangeEvent } from 'react';
import type { CreatableEmailTemplate, EditableAttachment } from '../../../../../../../types';
import type { CreateEmailTemplatePayload, UpdateEmailTemplatePayload } from '../../../../../../../hooks/queries/email-templates';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../../../library/inputs/SelectInput/types';
import type { TokensPayload } from '../../../../../../../hooks/queries/tokens';
import { correctPath } from 'libraries/gem';

const constructEmptyEmailTemplate = (jobName: string, accountName?: string): CreatableEmailTemplate => ({
  name: `[${jobName}] Confirmation Email`,
  subject: `${accountName ? `${accountName} ` : ''}${DEFAULT_EMAIL_CONTENT.confirmation_email.subject}`,
  body: '',
  sender_name: 'scheduler',
  sender_email: 'scheduler',
  cc_emails: [],
  bcc_emails: [],
});

const CandidateConfirmationEmailSection = () => {
  const queryClient = useQueryClient();

  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { account } = useSession();
  const { data: job } = useJob(jobId);
  const { data: stage } = useStage(jobId, stageId);
  const emailTemplates = useEmailTemplatesMap();
  const users = useUsersMap({ archived: true });

  const [error, setError] = useState<Error | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // confirmation email template
  const [confirmationEmailTemplateId, setConfirmationEmailTemplateId] = useState(stage?.schedule_template?.confirmation_email_template_id);
  const [confirmationEmailTemplateName, setConfirmationEmailTemplateName] = useState(stage?.schedule_template?.confirmation_email_template?.name || '');
  const [confirmationEmailTemplateSubjectSlateEditor, confirmationEmailTemplateSubjectSlateValue, setConfirmationEmailTemplateSubjectSlateValue] = useSlateEditor(stage?.schedule_template?.confirmation_email_template?.subject || '', true);
  const [confirmationEmailTemplateBodySlateEditor, confirmationEmailTemplateBodySlateValue, setConfirmationEmailTemplateBodySlateValue] = useSlateEditor(stage?.schedule_template?.confirmation_email_template?.body || '');
  const [confirmationEmailTemplateAttachments, setConfirmationEmailTemplateAttachments] = useState<EditableAttachment[]>(stage?.schedule_template?.confirmation_email_template?.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
  const [emailTemplateTypeForPreview, setEmailTemplateTypeForPreview] = useState<EditorType.ConfirmationEmail | EditorType.MultiBlockConfirmationEmail>(EditorType.ConfirmationEmail);

  const [isTemplateNamePromptModalOpen, setIsTemplateNamePromptModalOpen] = useState(false);

  const updateStageMutation = useUpdateStage();
  const createEmailTemplateMutation = useCreateEmailTemplate();
  const updateEmailTemplateMutation = useUpdateEmailTemplate();

  const [confirmationEmailTemplateAdvancedSettings, setConfirmationEmailTemplateAdvancedSettings] = useState<AdvancedSettings | undefined>({
    sender_email: stage?.schedule_template?.confirmation_email_template?.sender_email || '',
    sender_name: stage?.schedule_template?.confirmation_email_template?.sender_name || '',
    cc_emails: stage?.schedule_template?.confirmation_email_template?.cc_emails,
    bcc_emails: stage?.schedule_template?.confirmation_email_template?.bcc_emails,
  });

  const { data: existingEmailTemplate } = useEmailTemplate(confirmationEmailTemplateId);

  const hasEmailTemplateBeenModified = useMemo<boolean>(() => {
    return confirmationEmailTemplateName !== (existingEmailTemplate?.name || '') ||
      slateValueToText(confirmationEmailTemplateSubjectSlateValue) !== (existingEmailTemplate?.subject || '') ||
      slateValueToHtml(confirmationEmailTemplateBodySlateValue) !== (existingEmailTemplate?.body || '') ||
      areAttachmentsDifferent(confirmationEmailTemplateAttachments, (existingEmailTemplate?.attachments || [])) ||
      confirmationEmailTemplateAdvancedSettings?.sender_name !== (existingEmailTemplate?.sender_name || '') ||
      confirmationEmailTemplateAdvancedSettings?.sender_email !== (existingEmailTemplate?.sender_email || '') ||
      (confirmationEmailTemplateAdvancedSettings?.cc_emails || []).length !== (existingEmailTemplate?.cc_emails || []).length ||
      (confirmationEmailTemplateAdvancedSettings?.cc_emails || []).some((email, i) => email !== existingEmailTemplate?.cc_emails?.[i]) ||
      (confirmationEmailTemplateAdvancedSettings?.bcc_emails || []).length !== (existingEmailTemplate?.bcc_emails || []).length ||
      (confirmationEmailTemplateAdvancedSettings?.bcc_emails || []).some((email, i) => email !== existingEmailTemplate?.bcc_emails?.[i]);
  }, [
    confirmationEmailTemplateAdvancedSettings,
    confirmationEmailTemplateAttachments,
    confirmationEmailTemplateBodySlateValue,
    confirmationEmailTemplateName,
    confirmationEmailTemplateSubjectSlateValue,
    existingEmailTemplate,
  ]);

  const tokensPayload = useMemo<TokensPayload['schedule']>(() => ({
    application: {
      coordinator: job?.coordinator_id && users[job.coordinator_id] ? {
        email: users[job.coordinator_id].email,
        name: users[job.coordinator_id].name,
        phone_number: users[job.coordinator_id].phone_number,
      } : undefined,
      hiring_manager: job?.hiring_manager_id && users[job.hiring_manager_id] ? {
        email: users[job.hiring_manager_id].email,
        name: users[job.hiring_manager_id].name,
        phone_number: users[job.hiring_manager_id].phone_number,
      } : undefined,
      recruiter: job?.recruiter_id && users[job.recruiter_id] ? {
        email: users[job.recruiter_id].email,
        name: users[job.recruiter_id].name,
        phone_number: users[job.recruiter_id].phone_number,
      } : undefined,
      sourcer: job?.sourcer_id && users[job.sourcer_id] ? {
        email: users[job.sourcer_id].email,
        name: users[job.sourcer_id].name,
        phone_number: users[job.sourcer_id].phone_number,
      } : undefined,
      current_stage: {
        job: {
          name: job?.name || '',
          post_name: job?.post_name,
        },
      },
      office: job?.offices && job.offices.length > 0 ? {
        name: job.offices[0].name,
      } : undefined,
    },
    stage: {
      name: stage?.name || '',
      stage_interviews: stage?.stage_interviews?.filter((stageInterview) => !stageInterview.inline && !stageInterview.deleted).map((stageInterview) => ({
        name: stageInterview.name,
        position: stageInterview.position,
        interview_template: stageInterview.interview_template ? {
          duration_minutes: stageInterview.interview_template.duration_minutes,
          candidate_facing_name: stageInterview.interview_template.candidate_facing_name,
          candidate_facing_details: stageInterview.interview_template.candidate_facing_details,
        } : undefined,
      })),
    },
    timezone: stage?.schedule_template?.business_hours?.[0]?.timezone || undefined,
    schedule_template: {
      candidate_event_location: stage?.schedule_template?.candidate_calendar_event_template?.location || undefined,
      video_conferencing_enabled: stage?.schedule_template?.video_conferencing_enabled || false,
      onsite: stage?.schedule_template?.onsite || false,
    },
  }), [
    job,
    stage,
    users,
  ]);

  const {
    data: confirmationEmailTokens,
    error: confirmationEmailTokensError,
  } = useTokens({
    type: emailTemplateTypeForPreview,
    schedule: tokensPayload,
  }, {
    enabled: Boolean(stage?.schedule_template),
  });

  useEffect(() => {
    if (stage?.schedule_template) {
      const confirmationEmailTemplate = stage.schedule_template.confirmation_email_template;
      setConfirmationEmailTemplateId(confirmationEmailTemplate?.id || '');
      setConfirmationEmailTemplateName(confirmationEmailTemplate?.name || '');
      setConfirmationEmailTemplateSubjectSlateValue(
        translateSlateValueBetweenEditorTypes(
          textToSlateValue(confirmationEmailTemplate?.subject || ''),
          EditorType.ConfirmationEmail,
          emailTemplateTypeForPreview,
        )
      );
      setConfirmationEmailTemplateBodySlateValue(
        translateSlateValueBetweenEditorTypes(
          htmlToSlateValue(confirmationEmailTemplate?.body || ''),
          EditorType.ConfirmationEmail,
          emailTemplateTypeForPreview,
        )
      );
      setConfirmationEmailTemplateAttachments(confirmationEmailTemplate?.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
      setConfirmationEmailTemplateAdvancedSettings({
        sender_email: confirmationEmailTemplate?.sender_email || '',
        sender_name: confirmationEmailTemplate?.sender_name || '',
        cc_emails: confirmationEmailTemplate?.cc_emails,
        bcc_emails: confirmationEmailTemplate?.bcc_emails,
      });
    }
  }, [stage?.schedule_template]);

  const handleTogglePreviewForMultiBlock = () => {
    const currentEditorType = emailTemplateTypeForPreview;
    const newEditorType = currentEditorType === EditorType.ConfirmationEmail ? EditorType.MultiBlockConfirmationEmail : EditorType.ConfirmationEmail;
    setEmailTemplateTypeForPreview(newEditorType);
    setConfirmationEmailTemplateSubjectSlateValue(translateSlateValueBetweenEditorTypes(confirmationEmailTemplateSubjectSlateValue, currentEditorType, newEditorType));
    setConfirmationEmailTemplateBodySlateValue(translateSlateValueBetweenEditorTypes(confirmationEmailTemplateBodySlateValue, currentEditorType, newEditorType));
  };

  const handleConfirmationEmailTemplateChange = async (option: OnChangeValue<Option<string>, false>) => {
    setConfirmationEmailTemplateId(option?.value);

    if (!option) {
      return;
    }

    let newEmailTemplate;
    if (option.value === 'new') {
      newEmailTemplate = constructEmptyEmailTemplate(job!.name, account!.name);
    } else {
      try {
        newEmailTemplate = await queryClient.fetchQuery(emailTemplateParams(option.value));
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        }
        return;
      }
    }

    setConfirmationEmailTemplateName(newEmailTemplate.name);
    setConfirmationEmailTemplateSubjectSlateValue(
      translateSlateValueBetweenEditorTypes(
        textToSlateValue(newEmailTemplate.subject),
        EditorType.ConfirmationEmail,
        emailTemplateTypeForPreview,
      )
    );
    setConfirmationEmailTemplateBodySlateValue(
      translateSlateValueBetweenEditorTypes(
        htmlToSlateValue(newEmailTemplate.body || ''),
        EditorType.ConfirmationEmail,
        emailTemplateTypeForPreview,
      )
    );
    setConfirmationEmailTemplateAttachments(newEmailTemplate.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
    setConfirmationEmailTemplateAdvancedSettings({
      sender_email: newEmailTemplate.sender_email,
      sender_name: newEmailTemplate.sender_name,
      cc_emails: newEmailTemplate.cc_emails,
      bcc_emails: newEmailTemplate.bcc_emails,
    });
  };

  const handleConfirmationEmailTemplateNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmationEmailTemplateName(e.target.value);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    updateStageMutation.reset();
    createEmailTemplateMutation.reset();
    updateEmailTemplateMutation.reset();
    setError(null);

    const confirmationEmailTemplate = stage?.schedule_template?.confirmation_email_template;
    setConfirmationEmailTemplateId(confirmationEmailTemplate?.id || '');
    setConfirmationEmailTemplateName(confirmationEmailTemplate?.name || '');
    setConfirmationEmailTemplateSubjectSlateValue(
      translateSlateValueBetweenEditorTypes(
        textToSlateValue(confirmationEmailTemplate?.subject || ''),
        EditorType.ConfirmationEmail,
        emailTemplateTypeForPreview,
      )
    );
    setConfirmationEmailTemplateBodySlateValue(
      translateSlateValueBetweenEditorTypes(
        htmlToSlateValue(confirmationEmailTemplate?.body || ''),
        EditorType.ConfirmationEmail,
        emailTemplateTypeForPreview,
      )
    );
    setConfirmationEmailTemplateAttachments(confirmationEmailTemplate?.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
    setConfirmationEmailTemplateAdvancedSettings({
      sender_email: confirmationEmailTemplate?.sender_email || '',
      sender_name: confirmationEmailTemplate?.sender_name || '',
      cc_emails: confirmationEmailTemplate?.cc_emails,
      bcc_emails: confirmationEmailTemplate?.bcc_emails,
    });

    setIsEditing(false);
  };

  const handleSave = async () => {
    updateStageMutation.reset();
    createEmailTemplateMutation.reset();
    updateEmailTemplateMutation.reset();
    setError(null);
    setIsEditing(false);
    // We're using an isFetching state param instead of relying on react-query's
    // isLoading properties because we don't want it to flash as the multiple
    // mutations run. We want it to stay in a loading state for the entirety of
    // this function.
    setIsFetching(true);

    const confirmationEmailTemplatePayload: CreateEmailTemplatePayload | UpdateEmailTemplatePayload = {
      name: confirmationEmailTemplateName,
      attachments: confirmationEmailTemplateAttachments,
      sender_name: confirmationEmailTemplateAdvancedSettings?.sender_name || confirmationEmailTemplateAdvancedSettings?.sender_email || 'scheduler',
      sender_email: confirmationEmailTemplateAdvancedSettings?.sender_email || 'scheduler',
      cc_emails: confirmationEmailTemplateAdvancedSettings?.cc_emails,
      bcc_emails: confirmationEmailTemplateAdvancedSettings?.bcc_emails,
      type: 'confirmation_email',
    };

    // Convert multi-block confirmation email tokens back to single-block before saving
    if (emailTemplateTypeForPreview === EditorType.MultiBlockConfirmationEmail) {
      confirmationEmailTemplatePayload.subject = slateValueToText(
        translateSlateValueBetweenEditorTypes(
          confirmationEmailTemplateSubjectSlateValue, EditorType.MultiBlockConfirmationEmail, EditorType.ConfirmationEmail
        )
      );
      confirmationEmailTemplatePayload.body = slateValueToHtml(
        translateSlateValueBetweenEditorTypes(
          confirmationEmailTemplateBodySlateValue, EditorType.MultiBlockConfirmationEmail, EditorType.ConfirmationEmail
        )
      );
    } else {
      confirmationEmailTemplatePayload.subject = slateValueToText(confirmationEmailTemplateSubjectSlateValue);
      confirmationEmailTemplatePayload.body = slateValueToHtml(confirmationEmailTemplateBodySlateValue);
    }

    // Update email template if it has been edited
    if (confirmationEmailTemplateId && confirmationEmailTemplateId !== 'new') {
      const existingEmailTemplate = await queryClient.fetchQuery(emailTemplateParams(confirmationEmailTemplateId));
      const diff = differingValues(confirmationEmailTemplatePayload as UpdateEmailTemplatePayload, existingEmailTemplate);
      if (Object.keys(diff).length > 0) {
        try {
          const payload: UpdateEmailTemplatePayload = {
            ...diff,
            cc_emails: confirmationEmailTemplatePayload.cc_emails,
            bcc_emails: confirmationEmailTemplatePayload.bcc_emails,
          };
          const data = await updateEmailTemplateMutation.mutateAsync({ id: confirmationEmailTemplateId, payload });
          setConfirmationEmailTemplateAttachments(data.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
        } catch (err) {
          setIsEditing(true);
          setIsFetching(false);
          if (err instanceof Error) {
            setError(err);
          }
          return;
        }
      }
    }

    // Create new email template
    let newConfirmationEmailTemplateId;
    if (confirmationEmailTemplateId === 'new') {
      try {
        const payload: CreateEmailTemplatePayload = {
          ...confirmationEmailTemplatePayload as CreateEmailTemplatePayload,
          attachments: (confirmationEmailTemplatePayload.attachments || []).map((file) => ({ file: file as File })),
        };
        const data = await createEmailTemplateMutation.mutateAsync({ payload });
        setConfirmationEmailTemplateAttachments(data.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
        newConfirmationEmailTemplateId = data.id;
        setConfirmationEmailTemplateId(newConfirmationEmailTemplateId);
      } catch (err) {
        setIsEditing(true);
        setIsFetching(false);
        if (err instanceof Error) {
          setError(err);
        }
        return;
      }
    }

    // Update stage with email template id
    try {
      const payload = {
        confirmation_email_template_id: newConfirmationEmailTemplateId || confirmationEmailTemplateId || '',
      };
      await updateStageMutation.mutateAsync({ jobId, id: stageId, payload });
    } catch (err) {
      setIsEditing(true);
      setIsFetching(false);
      if (err instanceof Error) {
        setError(err);
      }
      return;
    }

    setIsFetching(false);
  };

  const createTemplateForSaveAs = async (templateName: string) => {
    setConfirmationEmailTemplateName(templateName);

    updateStageMutation.reset();
    createEmailTemplateMutation.reset();
    updateEmailTemplateMutation.reset();
    setError(null);
    setIsEditing(false);
    // We're using an isFetching state param instead of relying on react-query's
    // isLoading properties because we don't want it to flash as the multiple
    // mutations run. We want it to stay in a loading state for the entirety of
    // this function.
    setIsFetching(true);

    let newConfirmationEmailTemplateId;
    if (confirmationEmailTemplateId) {
      // Convert multi-block confirmation email tokens back to single-block before saving
      let subject: string;
      let body: string;
      if (emailTemplateTypeForPreview === EditorType.MultiBlockConfirmationEmail) {
        subject = slateValueToText(
          translateSlateValueBetweenEditorTypes(
            confirmationEmailTemplateSubjectSlateValue, EditorType.MultiBlockConfirmationEmail, EditorType.ConfirmationEmail
          )
        );
        body = slateValueToHtml(
          translateSlateValueBetweenEditorTypes(
            confirmationEmailTemplateBodySlateValue, EditorType.MultiBlockConfirmationEmail, EditorType.ConfirmationEmail
          )
        );
      } else {
        subject = slateValueToText(confirmationEmailTemplateSubjectSlateValue);
        body = slateValueToHtml(confirmationEmailTemplateBodySlateValue);
      }

      const attachments = await Promise.all((confirmationEmailTemplateAttachments || []).map(async (file) => {
        if (isFile(file)) {
          // This attachment is a newly added attachment.
          return { file };
        }
        // This attachment is an attachment on the existing template.
        const downloadedFile = await downloadFileFromUrl(file.link!, file.name);
        return { file: downloadedFile };
      }));

      const confirmationEmailTemplatePayload: CreateEmailTemplatePayload = {
        name: templateName,
        subject,
        body,
        attachments,
        sender_name: confirmationEmailTemplateAdvancedSettings?.sender_name || confirmationEmailTemplateAdvancedSettings?.sender_email || 'scheduler',
        sender_email: confirmationEmailTemplateAdvancedSettings?.sender_email || 'scheduler',
        cc_emails: confirmationEmailTemplateAdvancedSettings?.cc_emails,
        bcc_emails: confirmationEmailTemplateAdvancedSettings?.bcc_emails,
        type: 'confirmation_email',
      };

      // Create new email template
      try {
        const data = await createEmailTemplateMutation.mutateAsync({ payload: confirmationEmailTemplatePayload });
        setConfirmationEmailTemplateAttachments(data.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
        newConfirmationEmailTemplateId = data.id;
        setConfirmationEmailTemplateId(newConfirmationEmailTemplateId);
      } catch (err) {
        setIsEditing(true);
        setIsFetching(false);
        if (err instanceof Error) {
          setError(err);
        }
        return;
      }
    }

    // Update stage with email template id
    try {
      const payload = {
        confirmation_email_template_id: newConfirmationEmailTemplateId || '',
      };
      await updateStageMutation.mutateAsync({ jobId, id: stageId, payload });
    } catch (err) {
      setIsEditing(true);
      setIsFetching(false);
      if (err instanceof Error) {
        setError(err);
      }
      return;
    }

    setIsFetching(false);
  };

  const handleSaveAs = async () => {
    if (confirmationEmailTemplateId && confirmationEmailTemplateId !== 'new') {
      // This template is based on another one, so we want to compare the names
      // to see if they're different. If they aren't, open a modal recommending
      // them to update it.
      const existingEmailTemplate = await queryClient.fetchQuery(emailTemplateParams(confirmationEmailTemplateId));
      if (confirmationEmailTemplateName === existingEmailTemplate.name) {
        setIsTemplateNamePromptModalOpen(true);
        return;
      }
    }

    await createTemplateForSaveAs(confirmationEmailTemplateName);
  };

  const isConfirmationEmailTemplateUpdated = (stage?.schedule_template?.confirmation_email_template_id || '') !== (confirmationEmailTemplateId || '');
  const confirmationEmailTemplateLinkedStages = (confirmationEmailTemplateId && emailTemplates[confirmationEmailTemplateId]?.linked_resources) ?? null;
  const selectedConfirmationEmailTemplateIsEditableInline = !confirmationEmailTemplateId || (!isConfirmationEmailTemplateUpdated && confirmationEmailTemplateLinkedStages === 1) || (isConfirmationEmailTemplateUpdated && !confirmationEmailTemplateLinkedStages);

  return (
    <Section
      additionalHeaderActions={isEditing && confirmationEmailTemplateId && confirmationEmailTemplateId !== 'new' && (
        <Button
          color="gem-outline"
          iconRight={<FontAwesomeIcon icon={faSave} />}
          isDisabled={isFetching}
          onClick={handleSaveAs}
          size="small"
          value="Save as new template"
        />
      )}
      className="job-stage-confirmation-email"
      isEditable
      isEditing={isEditing}
      isSaveButtonDisabled={!selectedConfirmationEmailTemplateIsEditableInline && hasEmailTemplateBeenModified}
      isSaving={isFetching}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      saveButtonTooltip={!selectedConfirmationEmailTemplateIsEditableInline && hasEmailTemplateBeenModified ? (
        <Tooltip
          id="job-stage-confirmation-email-disabled-save"
          position="top"
          value="You cannot edit this template from this page because it is linked to other stages."
        />
      ) : undefined}
      title="Confirmation email"
    >
      <Flash
        isDismissible
        message="Successfully updated!"
        onDismiss={updateStageMutation.reset}
        showFlash={updateStageMutation.isSuccess}
        type="success"
      />
      <Flash
        message={error?.message}
        showFlash={Boolean(error)}
        type="danger"
      />
      <Flash
        message={confirmationEmailTokensError?.message}
        showFlash={Boolean(confirmationEmailTokensError)}
        type="danger"
      />
      <div className="form-container">
        <EmailTemplateSelectInput
          isDisabled={!isEditing || isFetching}
          isInlineCreatable
          label="Confirmation Email Template"
          onChange={handleConfirmationEmailTemplateChange}
          type="confirmation_email"
          value={confirmationEmailTemplateId}
        />
      </div>
      {confirmationEmailTemplateId && (
        ((isEditing || isFetching) ?
          <div className="email-template-inline-form">
            <Flash
              message={<span>This template is linked to other stages. You can either create a new template here with any changes you make, or go to the <Link to={correctPath(`/app/email-templates/${confirmationEmailTemplateId}`)}>email template page</Link> to edit the original template.</span>}
              showFlash={!selectedConfirmationEmailTemplateIsEditableInline}
              type="info"
            />
            <div className="form-container">
              <TextInput
                isRequired
                label="Template Name"
                onChange={handleConfirmationEmailTemplateNameChange}
                value={confirmationEmailTemplateName}
              />
            </div>
            <div className="form-container">
              <TokenInput
                editor={confirmationEmailTemplateSubjectSlateEditor}
                isRequired
                label="Subject"
                pendingPreviewMessage="You can preview this token when you are scheduling a candidate."
                setValue={setConfirmationEmailTemplateSubjectSlateValue}
                tokens={confirmationEmailTokens}
                type={emailTemplateTypeForPreview}
                value={confirmationEmailTemplateSubjectSlateValue}
              />
            </div>
            <EditorInput
              allowImages
              attachments={confirmationEmailTemplateAttachments}
              editor={confirmationEmailTemplateBodySlateEditor}
              exampleHtmlContent={DEFAULT_EMAIL_CONTENT[emailTemplateTypeForPreview]?.body}
              label="Body"
              pendingPreviewMessage="You can preview this token when you are scheduling a candidate."
              setAttachments={setConfirmationEmailTemplateAttachments}
              setValue={setConfirmationEmailTemplateBodySlateValue}
              tokens={confirmationEmailTokens}
              type={emailTemplateTypeForPreview}
              value={confirmationEmailTemplateBodySlateValue}
            />
            <EmailTemplateAdvancedSettings
              emailTemplate={confirmationEmailTemplateAdvancedSettings}
              setEmailTemplate={setConfirmationEmailTemplateAdvancedSettings}
            />
            <TemplateNamePromptModal
              isOpen={isTemplateNamePromptModalOpen}
              onSubmit={createTemplateForSaveAs}
              onToggle={() => setIsTemplateNamePromptModalOpen((prev) => !prev)}
              templateName={confirmationEmailTemplateName}
            />
          </div> :
          <EmailTemplateSummary
            id={confirmationEmailTemplateId}
            jobId={job?.id}
            pendingPreviewMessage="You can preview this token when you are scheduling a candidate."
            showFlash={isEditing || isFetching}
            tokens={confirmationEmailTokens}
            type={emailTemplateTypeForPreview}
          />
        )
      )}
      <ToggleInput
        isOn={emailTemplateTypeForPreview === EditorType.MultiBlockConfirmationEmail}
        onChange={handleTogglePreviewForMultiBlock}
        onLabel="Preview for multi-block schedule."
      />
    </Section>
  );
};

export default CandidateConfirmationEmailSection;
