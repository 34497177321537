import type { Account, EditableSelfSchedulingLink } from 'types';
import type { UpdateSelfSchedulingLinkPayload } from 'hooks/queries/self-scheduling-links';

export const constructPayload = (selfSchedulingLink: EditableSelfSchedulingLink, account?: Account): UpdateSelfSchedulingLinkPayload => ({
  schedule_template: {
    allowed_times: selfSchedulingLink.schedule_template.allowed_times ?
      selfSchedulingLink.schedule_template.allowed_times.map(({ start_time, end_time }) => ({ start_time, end_time })) :
      undefined,
    video_conferencing_enabled: selfSchedulingLink.schedule_template.video_conferencing_enabled,
    zoom_host_filters: selfSchedulingLink.schedule_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom' ?
      (selfSchedulingLink.schedule_template.zoom_host_filters || []).map(({ zoom_host_filter_expressions }) => ({
        zoom_host_filter_expressions: zoom_host_filter_expressions.map(({ filterable_id, filterable_type, negated }) => ({
          negated,
          filterable_id,
          filterable_type,
        })),
      })) : undefined,
  },
  stage_interview: {
    id: selfSchedulingLink.stage_interview_id,
    feedback_form_id: selfSchedulingLink.feedback_form_id,
    interview_template: {
      duration_minutes: selfSchedulingLink.interview_template.duration_minutes,
      interviewer_templates: (selfSchedulingLink.interview_template.interviewer_templates || []).map((interviewerTemplate) => ({
        optional: interviewerTemplate.optional,
        interviewer_filters: (interviewerTemplate.interviewer_filters || []).map((filters) => ({
          interviewer_filter_expressions: (filters.interviewer_filter_expressions || []).map((exp) => ({
            negated: exp.negated,
            filterable_id: exp.filterable_id,
            filterable_type: exp.filterable_type,
          })),
        })),
      })),
    },
  },
});
