import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import UserEmailNotificationsSection from './UserEmailNotificationsSection';
import { StyledContainer, StyledInfoFlash } from './styles';
import { useSession } from 'hooks/use-session';
import { useUser } from 'hooks/queries/users';
import { correctPath } from 'libraries/gem';

const User = () => {
  const currentUser = useSession().currentUser!;
  const { isLoading } = useUser(currentUser.id);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: <><FontAwesomeIcon icon={faUser} />User</>,
        pathname: correctPath('/app/user'),
      }}
    >
      <StyledContainer>
        <Helmet>
          <title>User | Gem Scheduling</title>
        </Helmet>
        <StyledInfoFlash
          message={<span>You can update your interviewing preferences, like working hours and interview limits, on <Link to={correctPath(`/app/interviewers/${currentUser.id}`)}>your interviewer profile</Link>.</span>}
          showFlash
          type="info"
        />
        <UserEmailNotificationsSection />
      </StyledContainer>
    </Breadcrumb>
  );
};

export default User;
