import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import Flash from '../../../library/utils/Flash';
import JobStatusIndicator from '../../../library/utils/JobStatusIndicator';
import LoadingSpinner from '../../../library/utils/LoadingSpinner';
import Section from '../../../library/layout/Section';
import Table from '../../../library/data-display/Table';
import { useCalendarEventTemplate } from '../../../../hooks/queries/calendar-event-templates';
import { useStages } from '../../../../hooks/queries/stages';

import type { Stage } from '../../../../types';
import type { TableSchema } from '../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const columns: TableSchema<Stage> = [{
  header: 'Stage',
  displayValue: ({ name }) => name,
  hasLinkStyleOnHover: true,
}, {
  header: 'Job',
  displayValue: ({ job }) => job.name,
}, {
  header: 'Job Status',
  displayValue: ({ job }) => <JobStatusIndicator status={job.status} />,
}];

const getRowLink = (stage: Stage) => correctPath(`/app/jobs/${stage.job_id}/stages/${stage.id}`);

const CalendarEventTemplateLinkedStages = () => {
  const { id } = useParams<{ id: string }>();

  const calendarEventTemplate = useCalendarEventTemplate(id).data;

  const {
    data: stages,
    isLoading: isStageLoading,
    error,
  } = useStages({ calendar_event_template: id });

  if (isStageLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Linked Stages',
        pathname: correctPath(`/app/calendar-event-templates/${id}/linked-stages`),
      }}
    >
      <Section
        className="calendar-event-template-linked-stages"
        title="Linked stages"
      >
        <Helmet>
          <title>Linked Stages | {calendarEventTemplate?.name} | InterviewPlanner</title>
        </Helmet>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        {stages &&
          <Table
            data={stages.stages}
            dataDescriptor="stages"
            getRowLink={getRowLink}
            layout="vertical"
            schema={columns}
            showResultsCount
            totalCount={stages.total}
          />
        }
      </Section>
    </Breadcrumb>
  );
};

export default CalendarEventTemplateLinkedStages;
