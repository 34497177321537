export enum AllowedTimeReferenceType {
  AvailabilityTemplate = 'availability_template',
  ScheduleTemplate = 'schedule_template',
}

export interface AllowedTime {
  id: string;
  account_id: string;
  reference_id: string;
  reference_type: AllowedTimeReferenceType;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
}

export interface EditableAllowedTime {
  start_time: string;
  end_time: string;
}
