import Moment from 'moment-timezone';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';

import Tooltip from 'components/library/utils/Tooltip';
import { useSession } from 'hooks/use-session';
import { getCalendarEventColor, getCalendarEventTextColor } from 'libraries/color';
import { formatMoment, TimeFormat } from 'libraries/time';
import { directoryCalendarLabels } from 'types';
import CalendarEventPreviewPopover from '../CalendarEventPreviewPopover';

import type { EventProps } from 'libraries/react-big-calendar';
import type { CSSProperties } from 'react';
import type { ResourceCalendarEvent } from './types';

const ResourceCalendarScheduleEvent = ({ event }: EventProps<ResourceCalendarEvent>) => {
  const { account } = useSession();

  const isMultiDayEvent = event.duration >= 24 * 60;

  const customStyles = useMemo(() => {
    const calendarEvent: CSSProperties = {};
    const calendarEventContent: CSSProperties = {};

    if (event.userAvatarColor && !event.isConflict) {
      const calendarEventColor = getCalendarEventColor(event.userAvatarColor);
      calendarEvent.boxShadow = `inset 0 0 0 1px ${calendarEventColor}`;
      calendarEvent.background = event.rsvp === 'accepted' ? calendarEventColor : 'white';
      calendarEventContent.color = event.rsvp === 'accepted' ? undefined : getCalendarEventTextColor(event.userAvatarColor);
      if (event.rsvp === 'declined') {
        calendarEventContent.textDecoration = 'line-through';
      }
    }
    if (event.color) {
      calendarEvent.borderLeft = `5px solid ${event.color}`;
    }
    return {
      calendarEvent,
      calendarEventContent,
    };
  }, [event.color, event.userAvatarColor, event.isConflict]);

  // Microsoft 365 calendar event IDs contain the '=' sign, which are not allowed in CSS selectors
  const eventContentDivId = `event-${event.id}-content`.replace(/=/g, '');

  return (
    <div className={classNames('calendar-event', event.isConflict && 'conflict', event.isInterview && 'interview')} style={customStyles.calendarEvent}>
      <div className="calendar-event-content" id={eventContentDivId} style={customStyles.calendarEventContent}>
        <div className="calendar-event-title" style={event.rsvp === 'declined' ? { fontWeight: 400 } : undefined}>
          {event.private ? (
            <span
              className="calendar-event-title-private"
              data-for={`${event.id}-private-tooltip`}
              data-tip
            >
              busy
              <FontAwesomeIcon icon={faLock} />
              <Tooltip
                id={`${event.id}-private-tooltip`}
                value={`You don't have access to view this event in ${directoryCalendarLabels[account?.directory_type!]}.`}
              />
            </span>
          ) : `${event.buffer_time ? 'Buffer window for ' : ''}${event.title}`}
        </div>
        {event.duration > 20 &&
          <div className="calendar-event-time">
            {formatMoment(Moment.tz(event.start, event.timezone), isMultiDayEvent ? TimeFormat.ShortMonthDayWithTimeAndComma : TimeFormat.Time)} - {formatMoment(Moment.tz(event.end, event.timezone), isMultiDayEvent ? TimeFormat.ShortMonthDayWithTimeAndTimezoneAndComma : TimeFormat.TimeWithTimezone)}
          </div>
        }
      </div>
      <CalendarEventPreviewPopover
        event={event}
        target={eventContentDivId}
      />
    </div>
  );
};

export default ResourceCalendarScheduleEvent;
