import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ExpandableCheckboxInput from '../../../../../../library/inputs/ExpandableCheckboxInput';
import Flash from '../../../../../../library/utils/Flash';
import ScheduleTable from './ScheduleTable';
import Section from '../../../../../../library/layout/Section';
import UpdateWorkingHoursModal from './UpdateWorkingHoursModal';
import ZoomHostFiltersBuilder from '../../../../../../library/inputs/ZoomHostFiltersBuilder';
import { BusinessHourReferenceType } from '../../../../../../../types/business-hours';
import { constructPayload } from './helpers';
import { createEmptyZoomHostFilter } from '../../../../../../../libraries/zoom-hosts';
import { defaultBusinessHoursWithStartAndEnd, isSameBusinessHours } from 'libraries/business-hours';
import { useSelfSchedulingLink, useUpdateSelfSchedulingLink } from '../../../../../../../hooks/queries/self-scheduling-links';
import { useSession } from '../../../../../../../hooks/use-session';
import { useUsersMap } from 'hooks/queries/users';

import type { ChangeEvent } from 'react';
import type { EditableSelfSchedulingLink, EditableZoomHostFilter, SelfSchedulingLink } from '../../../../../../../types';

const SelfSchedulingLinkScheduleSection = () => {
  const { scheduleId } = useParams<{ id: string; scheduleId: string }>();

  const { account } = useSession();
  const { data: originalSelfSchedulingLink } = useSelfSchedulingLink(scheduleId);
  const users = useUsersMap({ archived: true });

  const updateSelfSchedulingLinkMutation = useUpdateSelfSchedulingLink();

  const [selfSchedulingLink, setSelfSchedulingLink] = useState<EditableSelfSchedulingLink>(originalSelfSchedulingLink!);
  const [showUpdateWorkingHoursModal, setShowUpdateWorkingHoursModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const isEditable = selfSchedulingLink?.status === 'requested';

  useEffect(() => {
    setSelfSchedulingLink(originalSelfSchedulingLink!);
  }, [originalSelfSchedulingLink?.id]);

  useEffect(() => {
    updateSelfSchedulingLinkMutation.reset();
    setIsEditing(false);
  }, [scheduleId]);

  const handleVideoConferencingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      schedule_template: {
        ...prevState.schedule_template,
        video_conferencing_enabled: e.target.checked,
      },
    }));
  };
  const handleZoomHostFiltersChange = (filters: EditableZoomHostFilter[]) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      schedule_template: {
        ...prevState.schedule_template,
        zoom_host_filters: filters,
      },
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setSelfSchedulingLink(originalSelfSchedulingLink!);
    setIsEditing(false);
  };

  const newInterviewerId = selfSchedulingLink.interview_template?.interviewer_templates?.[0]?.interviewer_filters?.[0].interviewer_filter_expressions?.[0]?.filterable_id;

  const handleUpdateSuccess = (newlyUpdatedSelfSchedulingLink: SelfSchedulingLink) => {
    setIsSuccess(true);
    setSelfSchedulingLink(newlyUpdatedSelfSchedulingLink);
    setIsEditing(false);
  };

  const handleSave = async () => {
    // Check if working hours should be updated
    const newInterviewer = users[newInterviewerId || ''];
    const newInterviewerWorkingHours = (newInterviewer?.business_hours || newInterviewer?.default_business_hours || defaultBusinessHoursWithStartAndEnd(BusinessHourReferenceType.User, '09:00', '17:00') || []).map((bh) => ({ ...bh, timezone: bh.timezone || newInterviewer.default_timezone }));

    if (newInterviewerWorkingHours && newInterviewerWorkingHours.every((bh) => Boolean(bh.timezone)) && !isSameBusinessHours(selfSchedulingLink.schedule_template.business_hours || [], newInterviewerWorkingHours)) {
      setShowUpdateWorkingHoursModal(true);
      return;
    }

    updateSelfSchedulingLinkMutation.reset();

    try {
      const data = await updateSelfSchedulingLinkMutation.mutateAsync({
        id: selfSchedulingLink.id,
        payload: constructPayload(selfSchedulingLink, account),
      });
      setSelfSchedulingLink(data);
      setIsEditing(false);
      setIsSuccess(true);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const toggleUpdateWorkingHoursModal = () => {
    setShowUpdateWorkingHoursModal(!showUpdateWorkingHoursModal);
  };

  return (
    <Section
      className="schedule-section"
      isEditable={isEditable}
      isEditing={isEditing}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Schedule"
    >
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={isSuccess}
        type="success"
      />
      <Flash
        message={updateSelfSchedulingLinkMutation.error?.message}
        showFlash={updateSelfSchedulingLinkMutation.isError}
        type="danger"
      />
      <ExpandableCheckboxInput
        isChecked={selfSchedulingLink.schedule_template.video_conferencing_enabled}
        isDisabled={!isEditing}
        label="Include video conferencing links."
        onChange={handleVideoConferencingEnabledChange}
      >
        {selfSchedulingLink.schedule_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom' && (
          <ZoomHostFiltersBuilder
            filters={selfSchedulingLink.schedule_template.zoom_host_filters || createEmptyZoomHostFilter()}
            isDisabled={!isEditing}
            label="Zoom Meeting Host"
            onChange={handleZoomHostFiltersChange}
          />
        )}
      </ExpandableCheckboxInput>
      <ScheduleTable
        isEditing={isEditing}
        selfSchedulingLink={selfSchedulingLink}
        setSelfSchedulingLink={setSelfSchedulingLink}
      />
      {isEditing && newInterviewerId && (
        <UpdateWorkingHoursModal
          isOpen={showUpdateWorkingHoursModal}
          newInterviewerId={newInterviewerId}
          onSuccess={handleUpdateSuccess}
          onToggle={toggleUpdateWorkingHoursModal}
          selfSchedulingLink={selfSchedulingLink}
        />
      )}
    </Section>
  );
};

export default SelfSchedulingLinkScheduleSection;
