import { Helmet } from 'react-helmet-async';

import FeaturePage from 'components/library/layout/FeaturePage';
import RequestADemoButton from 'components/Website/RequestADemoButton';
import { StyledDivider, StyledPageContainer, StyledRequestDemoSection, StyledTitleSection } from './styles';

const InterviewerTraining = () => {
  return (
    <StyledPageContainer>
      <Helmet>
        <title>Interviewer Training | Gem Scheduling</title>
      </Helmet>
      <StyledTitleSection>
        <h1>
          Goodbye, interviewer training spreadsheet.
        </h1>
        <p>
          Calibrated interviewers create a fair hiring process and a consistent candidate experience. We automate interviewer training in a scalable way.
        </p>
      </StyledTitleSection>
      <StyledDivider />
      <FeaturePage
        features={[{
          title: 'Train interviewers quickly.',
          description: <>We automatically move trainees through training programs as they conduct interviews. You can schedule multiple upcoming training sessions to build up interviewer pools faster. If interviews are cancelled or rescheduled, we update trainees&apos; roles in future interviews accordingly.</>,
          figure: <img
            alt="Three interviewer training programs"
            src="/scheduling/src/assets/images/duck-training-programs.svg"
          />,
        }, {
          title: 'Automatically schedule trainees.',
          description: <>Adding a training program to an interview is dead simple. Then, whenever you schedule that interview, we&apos;ll always find a trainee, taking into account their availability, working hours, and interview load. You can even use the same training program across multiple roles.</>,
          figure: <img
            alt="A video conference call window with a candidate, interviewer, and shadow in a pair programming interview"
            src="/scheduling/src/assets/images/duck-virtual-shadow.svg"
          />,
        }, {
          title: 'Accurately track training progress.',
          description: <>Stay up-to-date with a real-time view of training progress. You can also manually update a trainee&apos;s progress without losing any history. Add a trainee to an upcoming interview, record a trainee in a past interview, and manually mark training sessions as completed or ignored.</>,
          figure: <img
            alt="Coordinator manually editing training progress"
            src="/scheduling/src/assets/images/duck-editing-training.svg"
          />,
        }, {
          title: 'Manage and customize programs.',
          description: <>At a glance, see active trainees and graduates in each training program. When creating a new program, you can fully customize the phase names and interview requirements. You can then modify these requirements for any individual interviewer&apos;s program to fit their needs.</>,
          figure: <img
            alt="Trainee with a custom training program"
            src="/scheduling/src/assets/images/duck-custom-training-program.svg"
          />,
        }]}
        isAlternatingLayout
      />
      <StyledRequestDemoSection>
        <h1>
          Interested?
        </h1>
        <p>
          Let&apos;s chat about how you handle interviewer training.
        </p>
        <RequestADemoButton label="Interviewer Training Feature Page" />
      </StyledRequestDemoSection>
    </StyledPageContainer>
  );
};

export default InterviewerTraining;
