import { components } from 'react-select';
import { formatISO, parseISO } from 'date-fns';
import { useMemo } from 'react';

import DatePicker from '../DatePicker';
import { useDateRange } from './use-date-range';

import type { Group } from '../SelectInput/types';
import type { MenuListProps } from 'react-select/dist/declarations/src/components/Menu';
import type { Option } from './types';
import type { RangeValue } from '../DatePicker';

const DateRangeInputMenuList = ({ children, ...props }: MenuListProps<Option, boolean, Group<string, Option>>) => {
  const { dateRange, setDateRange } = useDateRange();

  const handleDatePickerChange = (range: RangeValue) => {
    setDateRange({
      startDate: range.startDate ? formatISO(range.startDate) : undefined,
      endDate: range.endDate ? formatISO(range.endDate) : undefined,
    });
  };

  const value = useMemo(() => ({
    startDate: dateRange?.startDate ? parseISO(dateRange.startDate) : undefined,
    endDate: dateRange?.endDate ? parseISO(dateRange.endDate) : undefined,
  }), [dateRange?.endDate, dateRange?.startDate]);

  return (
    <div className="date-range-input-menu-list">
      <div className="options-container">
        <components.MenuList {...props}>
          {children}
        </components.MenuList>
      </div>
      <div className="calendar-container">
        <DatePicker
          isRange={true}
          onChange={handleDatePickerChange}
          value={value}
        />
      </div>
    </div>
  );
};

export default DateRangeInputMenuList;
