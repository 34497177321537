import Moment from 'moment-timezone';
import { Helmet } from 'react-helmet-async';
import { formatISO } from 'date-fns';
import { uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import AvailabilityErrorFlash from './AvailabilityErrorFlash';
import AvailabilityInstructions from './AvailabilityInstructions';
import AvailabilityNotFound from './AvailabilityNotFound';
import AvailabilityPicker from '../library/inputs/AvailabilityPicker';
import AvailabilityAdditionalInformationSection from './AvailabilityAdditionalInformationSection';
import Banner from '../library/utils/Banner';
import Button from '../library/inputs/Button';
import CompanyBrandedPageHeader from '../library/utils/CompanyBrandedPageHeader';
import CompanyBrandingPreviewFlash from '../library/utils/CompanyBrandingPreviewFlash';
import Flash from '../library/utils/Flash';
import LoadingSpinner from '../library/utils/LoadingSpinner';
import PoweredByGemFooter from '../library/utils/PoweredByGemFooter';
import useHideZendeskWidget from '../../hooks/use-hide-zendesk-widget';
import { addAdvancedNotice } from '../../libraries/dates';
import { businessHourDayOrdering } from '../../types/business-hours';
import { decodeSearchParamsForPreview } from '../../libraries/query-string';
import { downloadFileFromUrl } from '../../libraries/form-data';
import { formatMoment, TimeFormat } from '../../libraries/time';
import { useAvailability, useUpdateAvailabilityTimeSlots } from '../../hooks/queries/availabilities';

import type { Availability as AvailabilityType, Pronouns } from '../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../library/inputs/SelectInput/types';
import type { TimeSlot } from '../library/inputs/AvailabilityPicker/types';

const HIGHTOUCH_ACCOUNT_ID = '71638996-30a4-4c61-922a-084b2ccb0568';
const STANDARD_METRICS_ACCOUNT_ID = '0efc9ee8-0413-4064-935e-b63ab9398e5a';

const Availability = () => {
  const location = useLocation();

  const { id } = useParams<{ id: string }>();

  const availabilityPreview = useMemo(() => id === 'preview' ? decodeSearchParamsForPreview(location.search) as AvailabilityType : null, [location.search]);
  const isPreview = Boolean(availabilityPreview);

  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [suggestedTimes, setSuggestedTimes] = useState<TimeSlot[]>([]);
  const [timezone, setTimezone] = useState<string>(Moment.tz.guess());
  const [pronouns, setPronouns] = useState<Pronouns | null>(null);
  const [pronunciationExists, setPronunciationExists] = useState<boolean | undefined>();
  const [pronunciation, setPronunciation] = useState<File | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    data: availabilityReal,
    error: errorRetrievingAvailability,
    isLoading: isAvailabilityLoading,
  } = useAvailability(id, {
    enabled: !isPreview,
    onSuccess: (availability) => {
      // This is so that we start on the success page if we come back to an
      // availability where we already submitted times. This could cause
      // problems if we make the availability refresh frequently.
      if (availability.availability_time_slots && availability.availability_time_slots.length > 0) {
        setIsSuccess(true);
      }
    },
  });

  const availability = availabilityReal || availabilityPreview;

  useHideZendeskWidget();

  const updateAvailabilityTimeSlotsMutation = useUpdateAvailabilityTimeSlots();

  const minDate = useMemo<Date>(() => {
    // We add the advanced notice to now so that we provide a buffer.
    let min = addAdvancedNotice(new Date(), availability?.availability_template?.advanced_notice_hours || 0);
    const currentWeekday = Moment(min).weekday();
    const businessHours = (availability?.availability_template?.business_hours || []);
    const relevantBusinessHours = businessHours.filter((bh) => {
      return businessHourDayOrdering[bh.day] === currentWeekday;
    });
    const lastBusinessHours = relevantBusinessHours[relevantBusinessHours.length - 1];
    const allowedDaysOfWeek = uniq(businessHours.map((bh) => bh.day)).map((day) => businessHourDayOrdering[day]);

    // If business hours for this day are set, we check to see if there's enough time left in the day for a single time
    // slot of availability (based on the minimum duration and the end time of the business hour). If not, we push the
    // minimum date to the next day since there's no usable time left in the day.
    if (lastBusinessHours && lastBusinessHours.timezone && availability?.availability_template?.minimum_duration_minutes) {
      const endOfAvailability = Moment(min).tz(lastBusinessHours.timezone).add(availability?.availability_template.minimum_duration_minutes, 'minutes');
      const businessHoursEndString = lastBusinessHours.end_time === '24:00' ? '23:59' : lastBusinessHours.end_time;
      // We need to be careful when setting times. If we didn't set the year,
      // month, and date for this Moment, but instead, we took min and just
      // set the time, because of timezone conversions, it's possible that it will
      // be on a completely different day. For example, if min was
      // 2022-10-10T00:00:00-05:00, and the business timezone was
      // America/Los_Angeles, converting min into that timezone will make it
      // 2022-10-09T22:00:00-07:00. So if we then set just the time, we will
      // always have a Moment that is earlier than the endOfAvailability.
      const endOfBusinessDay = Moment().tz(lastBusinessHours.timezone).set({
        year: min.getFullYear(),
        month: min.getMonth(),
        date: min.getDate(),
        hours: Moment(businessHoursEndString, 'HH:mm').get('hours'),
        minutes: Moment(businessHoursEndString, 'HH:mm').get('minutes'),
        seconds: 0,
      });
      if (endOfAvailability.isAfter(endOfBusinessDay)) {
        min = Moment(min).add(1, 'day').startOf('day').toDate();
      }
    }

    // If, after all our previous logic, we end up on a disabled day of the
    // week, we keep adding a day until we get an allowed day.
    while (allowedDaysOfWeek.length > 0 && !allowedDaysOfWeek.includes(min.getDay())) {
      min = Moment(min).add(1, 'day').startOf('day').toDate();
    }

    return min;
  }, [
    availability?.availability_template?.advanced_notice_hours,
    availability?.availability_template?.minimum_duration_minutes,
    availability?.availability_template?.business_hours,
  ]);

  const maxDate = useMemo(() => {
    return availability?.availability_template?.rolling_window_days ? Moment().add(availability.availability_template.rolling_window_days, 'days').endOf('day').toDate() : undefined;
  }, [availability?.availability_template?.rolling_window_days]);

  useEffect(() => {
    setTimeSlots(availability?.availability_time_slots || []);
  }, [availability?.availability_time_slots]);

  useEffect(() => {
    setSuggestedTimes(availability?.availability_template?.suggested_times || []);
  }, [availability?.availability_template?.suggested_times]);

  useEffect(() => {
    setPronouns(availability?.application?.candidate.pronouns || null);
  }, [availability?.application?.candidate.pronouns]);

  useEffect(() => {
    (async () => {
      if (availability?.application?.candidate.pronunciation_url) {
        const file = await downloadFileFromUrl(availability.application.candidate.pronunciation_url, 'recording.mp3');
        setPronunciation(file);
      }
    })();
    // This needs to depend on candidate and not pronunciation_url because the
    // URL doesn't change if the recording is changed, so this will never be
    // called again unless we depend on the object itself, which will change.
  }, [availability?.application?.candidate]);

  const handleTimezoneChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setTimezone(option ? option.value : '');
  }, []);

  const handleSubmitAvailability = async () => {
    updateAvailabilityTimeSlotsMutation.reset();

    if (isPreview) {
      // If it's a preview, just set it to success and exit early.
      setIsSuccess(true);
      return;
    }

    try {
      await updateAvailabilityTimeSlotsMutation.mutateAsync({
        id,
        payload: {
          timezone,
          time_slots: timeSlots.map((slot) => ({
            id: slot.id,
            start_time: slot.start_time instanceof Date ? formatISO(slot.start_time) : slot.start_time,
            end_time: slot.end_time instanceof Date ? formatISO(slot.end_time) : slot.end_time,
          })),
          pronouns: pronouns || undefined,
          pronunciation_exists: pronunciationExists,
          pronunciation: pronunciation || undefined,
        },
      });
      setIsSuccess(true);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const brandColor = availability?.account.color || undefined;

  if (isAvailabilityLoading) {
    return (
      <div className="availability-container loading">
        <LoadingSpinner />
      </div>
    );
  }

  if ((!availability && errorRetrievingAvailability) || availability?.status === 'cancelled' || availability?.ats_id || !availability?.application || (availability?.stage_id && availability.stage_id !== availability.application.current_stage_id)) {
    return (
      <AvailabilityNotFound
        isCancelled={(availability?.status === 'cancelled') || (availability?.stage_id !== availability?.application?.current_stage_id) || Boolean(availability?.stage_id && availability.stage_id !== availability.application?.current_stage_id)}
        isNotFound={(errorRetrievingAvailability?.status === 404) || Boolean(availability?.ats_id)}
      />
    );
  }

  if (!availability) {
    // We're guaranteed to have the availability at this point, so this should
    // never happen, but it's necessary so that the types are happy.
    return null;
  }

  const buttons = {
    submit: <Button
      brandColor={brandColor}
      className="btn-cta"
      color="gem-blue"
      iconRight={updateAvailabilityTimeSlotsMutation.isLoading ? <LoadingSpinner /> : undefined}
      isDisabled={updateAvailabilityTimeSlotsMutation.isLoading}
      onClick={handleSubmitAvailability}
      size="large"
      value={updateAvailabilityTimeSlotsMutation.isLoading ? 'Submitting...' : 'Submit Availability'}
    />,
    update: <Button
      brandColor={brandColor}
      className="btn-cta"
      color="gem-outline"
      onClick={() => setIsSuccess(false)}
      size="large"
      value="Update Availability"
    />,
  };

  const isVercel = availability.account.name?.includes('Vercel');

  return (
    <div className="availability-container">
      <Helmet>
        <title>{availability.account.name ? `${availability.account.name} | ` : ''}Submit Availability</title>
      </Helmet>
      {isPreview &&
        <Banner>
          This is a preview of <b>{availability.application.candidate.name}</b>&apos;s availability link for <b>{availability.stage.name}</b>. Availabilities submitted here will not be saved.
        </Banner>
      }
      <CompanyBrandedPageHeader
        logoUrl={availability.account.logo_url}
        message={availability.account.availability_message}
      />
      <div className="flash-container" id="flash">
        {isPreview &&
          <CompanyBrandingPreviewFlash
            account={availability.account}
            page="availability"
          />
        }
        <Flash
          brandColor={brandColor}
          message={
            <AvailabilityInstructions
              businessHours={availability.availability_template?.business_hours || []}
              candidateName={availability.application.candidate.name || 'Candidate'}
              minimumDurationMinutes={availability.availability_template?.minimum_duration_minutes}
              rollingWindowDays={availability.availability_template?.rolling_window_days}
              stageName={isVercel ? undefined : availability.stage.name}
              suggestedTimes={availability.availability_template?.suggested_times}
              totalDurationMinutes={availability.availability_template?.total_duration_minutes}
            />
          }
          showFlash={!isSuccess}
          type="info"
        />
        <Flash
          message={
            <div>
              <p>
                You have successfully submitted the following availability{!isVercel && <span> for <b>{availability.stage.name}</b></span>}:
              </p>
              <ul>
                {timeSlots.map(({ start_time, end_time }) => (
                  <li key={`${start_time.toString()}:${end_time.toString()}`}>
                    <b>{formatMoment(Moment(start_time).tz(timezone), TimeFormat.LongDayOfWeekMonthDay)}</b>, {formatMoment(Moment(start_time).tz(timezone), TimeFormat.Time)}&ndash;{formatMoment(Moment(end_time).tz(timezone), TimeFormat.TimeWithTimezone)}
                  </li>
                ))}
              </ul>
            </div>
          }
          showFlash={isSuccess}
          type="success"
        />
        <Flash
          message="This is a preview. We don't save these availabilities anywhere or check that they meet your requirements. In a real availability link, we prevent the candidate from submitting times that are too short or outside your preferred hours."
          showFlash={isSuccess && isPreview}
          type="info"
        />
        <AvailabilityErrorFlash
          error={updateAvailabilityTimeSlotsMutation.error}
          timeSlots={timeSlots}
          totalDurationMinutes={availability.availability_template?.total_duration_minutes}
        />
      </div>
      {!isSuccess &&
        <div className="availabilities-container">
          <AvailabilityPicker
            availabilities={timeSlots}
            backgroundEventTimeSlots={suggestedTimes}
            brandColor={brandColor}
            businessHours={availability.availability_template?.business_hours || []}
            controlledTimezone={timezone}
            defaultScrollToHour={8}
            enforceConstraintsOnSelection
            maxDate={maxDate}
            minDate={minDate}
            minDuration={availability.availability_template ? availability.availability_template.minimum_duration_minutes : undefined}
            onTimezoneChange={handleTimezoneChange}
            setAvailabilities={setTimeSlots}
            showAvailabilitiesSummary={false}
            showFullDay={false}
            showQuickSelectOptions={false}
          />
        </div>
      }
      {!isSuccess && (availability.account.enable_pronouns || availability.account.enable_pronunciation) && (
        <AvailabilityAdditionalInformationSection
          brandColor={brandColor}
          candidateName={availability.application.candidate.name}
          isPronounsInputFreeText={availability.account.id === HIGHTOUCH_ACCOUNT_ID || availability.account.id === STANDARD_METRICS_ACCOUNT_ID}
          originalSelfDescribe={availability.application.candidate.pronouns?.self_describe}
          pronouns={pronouns}
          pronunciation={pronunciation}
          setPronouns={setPronouns}
          setPronunciation={setPronunciation}
          setPronunciationExists={setPronunciationExists}
          showPronouns={availability.account.enable_pronouns}
          showPronunciation={availability.account.enable_pronunciation}
        />
      )}
      {isSuccess ? buttons.update : buttons.submit}
      <PoweredByGemFooter />
    </div>
  );
};

export default Availability;
