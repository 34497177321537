import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { some, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import Button, { ButtonColor } from 'components/library/inputs/Button';
import JobSelectInput from 'components/library/inputs/JobSelectInput';
import SelectInput from 'components/library/inputs/SelectInput';
import StatusIndicator, { StatusIndicatorColor } from 'components/library/utils/StatusIndicator';
import Tooltip from 'components/library/utils/Tooltip';
import { useStages } from 'hooks/queries/stages';
import { StyledContainer, StyledStageOptionLabel } from './styles';

import type { Stage } from 'types';
import { JobStatus } from 'types';
import { correctPath } from 'libraries/gem';

interface Props {
  currentStage: Stage;
  onChange: (stage?: Stage) => void;
  value?: Stage;
}

const StageSelectInput = ({ currentStage, onChange, value }: Props) => {
  const [jobId, setJobId] = useState<string | undefined>();

  const { data: stagesData } = useStages(
    { job: jobId, job_status: [JobStatus.Open, JobStatus.Draft, JobStatus.Closed] },
    { enabled: Boolean(jobId) }
  );
  const stages = jobId && stagesData?.stages || [];

  // When a job is selected, auto-select a template stage if there is one with a matching name and it is enabled for scheduling.
  useEffect(() => {
    const matchingStage = stages?.find(({ name, schedule_template_id }) => name === currentStage.name && Boolean(schedule_template_id));
    if (matchingStage) {
      onChange(matchingStage);
    }
  }, [stages]);

  return (
    <StyledContainer>
      <JobSelectInput
        filterJob={(job) => some(job.stages, 'schedule_template_id')}
        helperText="These are all the jobs that have scheduling enabled."
        label="Copy from Stage"
        onChange={(option) => {
          setJobId(option?.value);
          onChange(undefined);
        }}
        value={jobId}
      />
      <SelectInput
        formatOptionLabel={(option, { context }) => (
          <StyledStageOptionLabel>
            {context === 'menu' ?
              <StatusIndicator
                color={option.isDisabled ? StatusIndicatorColor.Gray : StatusIndicatorColor.Green}
                tooltipId={`template-stage-schedulable-status-indicator-${option.value}`}
                tooltipText={`Scheduling is ${option.isDisabled ? 'not' : ''} enabled`}
              >
                {option.label}
              </StatusIndicator> :
              option.label
            }
            {!option.isDisabled && (
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  analytics.track('Template Stage Opened', {
                    stage_id: currentStage.id,
                    stage_name: currentStage.name,
                    template_stage_id: option.value,
                    template_stage_name: option.label,
                  });
                }}
                target="_blank"
                to={correctPath(`/app/jobs/${jobId}/stages/${option.value}`)}
              >
                <Button
                  color={ButtonColor.NoOutline}
                  iconRight={<FontAwesomeIcon icon={faExternalLinkAlt} />}
                  size="small"
                  tooltip={
                    <Tooltip
                      id={`${option.value}-view-stage-button`}
                      position="top"
                      value="Open stage in new tab"
                    />
                  }
                />
              </Link>
            )}
          </StyledStageOptionLabel>
        )}
        isClearable
        isDisabled={isEmpty(stages)}
        onChange={(option) => {
          onChange(stages.find(({ id }) => id === option?.value));
        }}
        options={stages.map((stage: Stage) => ({
          label: stage.name,
          value: stage.id,
          isDisabled: !Boolean(stage.schedule_template_id),
        }))}
        placeholder="Select a stage"
        value={value ? {
          label: value.name,
          value: value.id,
          isDisabled: false,
        } : null}
      />
    </StyledContainer>
  );
};

export default StageSelectInput;
