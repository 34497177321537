import pluralize from '../../../../../libraries/pluralize';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

import BusinessHoursSummary from '../../../../library/data-display/BusinessHoursSummary';
import EmailPreview from '../../../../library/data-display/EmailPreview';
import Flash from '../../../../library/utils/Flash';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import MultiStepFormStep from '../../../../library/inputs/MultiStepFormStep';
import Table from '../../../../library/data-display/Table';
import { Step, useNewAvailability } from './use-new-availability';
import { constructAvailabilityPreview, constructAvailabilityTokens } from './helpers';
import { encodeObjectForPreview } from '../../../../../libraries/query-string';
import { formatDuration } from '../../../../../libraries/formatters';
import { resolveHiringRole } from '../../../../../libraries/email';
import { useAccount } from '../../../../../hooks/queries/accounts';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useCreateAvailability } from '../../../../../hooks/queries/availabilities';
import { useRender } from '../../../../../hooks/queries/tokens';
import { useSession } from '../../../../../hooks/use-session';
import { useUsersMap } from '../../../../../hooks/queries/users';

import type { CreateAvailabilityPayload } from '../../../../../hooks/queries/availabilities';
import type { TableSchema } from '../../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const ReviewStep = () => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const { currentUser } = useSession();
  const account = useAccount().data!;
  const application = useApplication(id).data!;
  const users = useUsersMap({ archived: true });

  const {
    availability,
    completedStep,
    setAvailabilityId,
    setCompletedStep,
  } = useNewAvailability();

  const createAvailabilityMutation = useCreateAvailability();

  const {
    data: renderedAvailabilityMessage,
    error: availabilityMessageError,
  } = useRender({
    type: 'availability_message',
    plain_text: false,
    availability: constructAvailabilityTokens(application, users),
    text: account?.availability_message,
  }, {
    enabled: Boolean(completedStep >= Step.Review && account?.availability_message),
  });

  const {
    data: renderedEmailSubject,
    error: emailSubjectError,
  } = useRender({
    type: 'availability_request_email',
    plain_text: true,
    availability: constructAvailabilityTokens(application, users),
    text: availability.availability_template?.availability_request_email_template?.subject,
  }, {
    enabled: Boolean(completedStep >= Step.Review && availability.availability_template?.availability_request_email_template),
  });

  const {
    data: renderedEmailBody,
    error: emailBodyError,
  } = useRender({
    type: 'availability_request_email',
    plain_text: false,
    availability: constructAvailabilityTokens(application, users),
    text: availability.availability_template?.availability_request_email_template?.body,
  }, {
    enabled: Boolean(completedStep >= Step.Review && availability.availability_template?.availability_request_email_template),
  });

  useEffect(() => {
    if (createAvailabilityMutation.error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [createAvailabilityMutation.error]);

  if (completedStep < Step.Review) {
    return <Redirect to={correctPath(`/app/candidates/${id}/request-availability/preferences`)} />;
  }

  const handleNext = async () => {
    createAvailabilityMutation.reset();

    try {
      const data = await createAvailabilityMutation.mutateAsync({ payload: availability });
      if (availability.availability_template?.availability_request_email_template) {
        history.push(correctPath(`/app/candidates/${id}/availabilities/${data.id}`));
      } else {
        setAvailabilityId(data.id);
        setCompletedStep(Step.Review + 1);
        history.push(correctPath(`/app/candidates/${id}/request-availability/send`));
      }
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const schema = useMemo<TableSchema<Required<CreateAvailabilityPayload>['availability_template']>>(() => [{
    header: 'Preferred Date Window',
    displayValue: ({ rolling_window_days }) => `Next ${rolling_window_days} ${pluralize('day', rolling_window_days)}`,
  }, {
    header: 'Allowed Times',
    displayValue: ({ business_hours }) => <BusinessHoursSummary businessHours={business_hours} />,
  }, Boolean(availability.availability_template?.advanced_notice_hours) && {
    header: 'Advanced Notice',
    displayValue: ({ advanced_notice_hours }) => `${advanced_notice_hours} ${pluralize('hour', advanced_notice_hours)}`,
  }, Boolean(availability.availability_template?.minimum_duration_minutes) && {
    header: 'Minimum Duration',
    displayValue: ({ minimum_duration_minutes }) => `${formatDuration(minimum_duration_minutes)}`,
  }, Boolean(availability.availability_template?.total_duration_minutes) && {
    header: 'Total Availability',
    displayValue: ({ total_duration_minutes }) => `At least ${formatDuration(total_duration_minutes)}`,
  }, availability.availability_template?.suggest_times && {
    header: 'Suggested Times',
    displayValue: ({ suggested_times }) => `${suggested_times?.length || 0} suggested time ${pluralize('slot', suggested_times?.length || 0)}`,
  }, availability.availability_template?.availability_request_email_template && {
    header: 'Email',
    displayValue: ({ availability_request_email_template }) => {
      return (
        <>
          <Flash
            message={emailSubjectError?.message}
            showFlash={Boolean(emailSubjectError)}
            type="danger"
          />
          <Flash
            message={emailBodyError?.message}
            showFlash={Boolean(emailBodyError)}
            type="danger"
          />
          <EmailPreview
            attachments={availability_request_email_template!.attachments}
            bccEmails={availability_request_email_template!.bcc_emails?.map((email) => resolveHiringRole(email, 'email', currentUser, application.job, application, users))}
            body={renderedEmailBody?.rendered_text || <LoadingSpinner />}
            ccEmails={availability_request_email_template!.cc_emails?.map((email) => resolveHiringRole(email, 'email', currentUser, application.job, application, users))}
            senderEmail={resolveHiringRole(availability_request_email_template!.sender_email, 'email', currentUser, application.job, application, users)}
            senderName={resolveHiringRole(availability_request_email_template!.sender_name, 'name', currentUser, application.job, application, users)}
            subject={renderedEmailSubject?.rendered_text || <LoadingSpinner />}
            to={application.candidate.email}
          />
        </>
      );
    },
  }], [
    availability,
    emailBodyError,
    emailSubjectError,
    renderedEmailBody,
    renderedEmailSubject,
  ]);

  const availabilityPreview = useMemo(() => {
    return constructAvailabilityPreview(
      { ...account, availability_message: renderedAvailabilityMessage?.rendered_text },
      availability,
      application.current_stage!,
      application.candidate
    );
  }, [
    account,
    application,
    availability,
    renderedAvailabilityMessage?.rendered_text,
  ]);

  return (
    <Breadcrumb
      data={{
        title: '3. Review',
        pathname: correctPath(`/app/candidates/${id}/request-availability/review`),
      }}
    >
      <MultiStepFormStep
        backLocation={correctPath(`/app/candidates/${id}/request-availability/email`)}
        className="form-step-review"
        isLastStep
        isSubmitting={createAvailabilityMutation.isLoading}
        nextButtonIsDisabled={!availabilityMessageError}
        nextButtonValue={availability.availability_template?.availability_request_email_template ? 'Send Availability Request' : 'Create Availability Link'}
        onNext={handleNext}
      >
        <Helmet>
          <title>3. Review | Request availability from {application.candidate.name || 'Unknown'} for {application.current_stage?.name} | InterviewPlanner</title>
        </Helmet>
        <Flash
          message={createAvailabilityMutation.error?.message}
          showFlash={createAvailabilityMutation.isError}
          type="danger"
        />
        <Table
          data={[availability.availability_template!]}
          layout="horizontal"
          schema={schema}
        />
        <Flash
          message={(
            <span>
              <Link
                target="_blank"
                to={{
                  pathname: correctPath('/availability/preview'),
                  search: encodeObjectForPreview(availabilityPreview),
                }}
              >
                See a preview
              </Link>
              &nbsp;of how the link will look to the candidate.
            </span>
          )}
          showFlash
          type="info"
        />
        <Flash
          message={`Please contact support. Something went wrong when validating your availability message: ${availabilityMessageError?.message}`}
          showFlash={Boolean(availabilityMessageError)}
          type="danger"
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default ReviewStep;
