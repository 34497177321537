import { useEffect, useState } from 'react';

import CheckboxInput from 'components/library/inputs/CheckboxInput';
import Flash from 'components/library/utils/Flash';
import Modal from 'components/library/layout/Modal';
import { BusinessHourReferenceType } from 'types/business-hours';
import { StyledBusinessHoursSummary } from './styles';
import { constructPayload } from '../helpers';
import { defaultBusinessHoursWithStartAndEnd } from 'libraries/business-hours';
import { useSession } from 'hooks/use-session';
import { useUpdateSelfSchedulingLink } from 'hooks/queries/self-scheduling-links';
import { useUsersMap } from 'hooks/queries/users';

import type { ChangeEvent } from 'react';
import type { EditableSelfSchedulingLink, SelfSchedulingLink } from 'types';

interface Props {
  isOpen: boolean;
  newInterviewerId: string;
  onSuccess: (newlyUpdatedSelfSchedulingLink: SelfSchedulingLink) => void;
  onToggle: () => void;
  selfSchedulingLink: EditableSelfSchedulingLink;
}

const UpdateWorkingHoursModal = ({ isOpen, newInterviewerId, onSuccess, onToggle, selfSchedulingLink }: Props) => {
  const { account } = useSession();
  const users = useUsersMap({ archived: true });

  const updateSelfSchedulingLinkMutation = useUpdateSelfSchedulingLink();

  const interviewer = users[newInterviewerId];
  const interviewerWorkingHours = (interviewer.business_hours || interviewer.default_business_hours || defaultBusinessHoursWithStartAndEnd(BusinessHourReferenceType.User, '09:00', '17:00')).map((businessHour) => ({
    day: businessHour.day,
    start_time: businessHour.start_time,
    end_time: businessHour.end_time,
    timezone: businessHour.timezone || interviewer.default_timezone,
  }));

  const [shouldUpdateWorkingHours, setShouldUpdateWorkingHours] = useState(true);

  useEffect(() => {
    setShouldUpdateWorkingHours(true);
  }, [newInterviewerId]);

  const handleUpdateWorkingHoursChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShouldUpdateWorkingHours(e.target.checked);
  };

  const handleToggle = () => {
    onToggle();
  };

  const handleUpdateSelfSchedulingLink = async () => {
    updateSelfSchedulingLinkMutation.reset();

    const payload = constructPayload(selfSchedulingLink, account);
    if (shouldUpdateWorkingHours) {
      payload.schedule_template!.business_hours = interviewerWorkingHours;
    }

    try {
      const newlyUpdatedSelfSchedulingLink = await updateSelfSchedulingLinkMutation.mutateAsync({ id: selfSchedulingLink.id, payload });
      onSuccess(newlyUpdatedSelfSchedulingLink);
      onToggle();
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Modal
      cancelButtonValue="Continue editing"
      isOpen={isOpen}
      isSubmitting={updateSelfSchedulingLinkMutation.isLoading}
      onSubmit={handleUpdateSelfSchedulingLink}
      onToggle={handleToggle}
      submitButtonValue={shouldUpdateWorkingHours ? 'Yes, update' : 'No, update interviewer only'}
      title="Update working hours?"
    >
      <Flash
        message={updateSelfSchedulingLinkMutation.error?.message}
        showFlash={updateSelfSchedulingLinkMutation.isError}
        type="danger"
      />
      <CheckboxInput
        isChecked={shouldUpdateWorkingHours}
        label={<>Update the self-scheduling link to use <b>{interviewer.name}</b>&apos;s working hours:</>}
        onChange={handleUpdateWorkingHoursChange}
      />
      <StyledBusinessHoursSummary businessHours={interviewerWorkingHours} />
    </Modal>
  );
};

export default UpdateWorkingHoursModal;
