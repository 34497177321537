import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';

import HiringMeetingTemplateCreateForm from './HiringMeetingTemplateCreateForm';
import LoadingSpinner from '../../../library/utils/LoadingSpinner';
import Section from '../../../library/layout/Section';
import useQueryState from '../../../../hooks/use-query-state';
import { StyledFlash } from '../styles';
import { useHiringMeetingTemplate } from '../../../../hooks/queries/hiring-meeting-templates';
import { correctPath } from 'libraries/gem';

const HiringMeetingTemplateCreate = () => {
  const [baseId] = useQueryState<string>('base', '');
  const {
    error: baseTemplateError,
    isLoading: isBaseTemplateLoading,
  } = useHiringMeetingTemplate(baseId);

  if (baseId && isBaseTemplateLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Create',
        pathname: correctPath('/app/hiring-meeting-templates/create'),
      }}
    >
      <Section
        className="hiring-meeting-template-create-container"
        title="Create a new hiring meeting template"
      >
        <Helmet>
          <title>Create Hiring Meeting Template | Gem Scheduling</title>
        </Helmet>
        <div className="hiring-meeting-template-create-steps-container">
          {baseTemplateError ? (
            <StyledFlash
              message={baseTemplateError?.message}
              showFlash={Boolean(baseTemplateError)}
              type="danger"
            />
          ) : (
            <HiringMeetingTemplateCreateForm />
          )}
        </div>
      </Section>
    </Breadcrumb>
  );
};

export default HiringMeetingTemplateCreate;
