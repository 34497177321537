import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';

import Button from '../../../library/inputs/Button';
import Flash from '../../../library/utils/Flash';
import LoadingSpinner from '../../../library/utils/LoadingSpinner';
import Section from '../../../library/layout/Section';
import TextInput from '../../../library/inputs/TextInput';
import TrainingProgramListTable from './TrainingProgramListTable';
import useSyncStateWithQuery from '../../../../hooks/use-sync-state-with-query';
import { StyledContainer, StyledFiltersContainer, StyledHeaderActions } from './styles';
import { useTrainingPrograms } from '../../../../hooks/queries/training-programs';

import type { ChangeEvent } from 'react';
import { correctPath } from 'libraries/gem';

const TrainingProgramList = () => {
  const [search, querySearch, setSearch] = useSyncStateWithQuery<string>('q', '', { delay: 200 });
  const [showArchived, , setShowArchived] = useSyncStateWithQuery<string>('show_archived', 'false');
  const [pageNumber, queryPageNumber, setPageNumber] = useSyncStateWithQuery<string>('page', '1');

  const {
    data: trainingPrograms,
    error,
    isFetching,
  } = useTrainingPrograms({
    archived: showArchived === 'true',
    eligibility: querySearch,
    limit: 10,
    offset: 10 * (parseInt(queryPageNumber, 10) - 1),
  });

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handleShowArchivedChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setShowArchived(JSON.stringify(e.target.checked));
  }, []);

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value.toString());
  }, []);

  return (
    <StyledContainer>
      <Helmet>
        <title>Training Programs | InterviewPlanner</title>
      </Helmet>
      <Section
        headerActions={
          <StyledHeaderActions>
            <Link to={correctPath('/app/training-programs/create')}>
              <Button
                color="gem-blue"
                iconLeft={<FontAwesomeIcon icon={faPlus} />}
                value="New program"
              />
            </Link>
          </StyledHeaderActions>
        }
        title="Training programs"
      >
        <StyledFiltersContainer>
          <TextInput
            isAutofocus
            label="Search"
            leftIcon={isFetching ? <LoadingSpinner /> : <FontAwesomeIcon icon={faSearch} />}
            onChange={handleSearchChange}
            placeholder="Interview eligibility"
            value={search}
          />
        </StyledFiltersContainer>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        {trainingPrograms && (
          <TrainingProgramListTable
            onPageNumberChange={handlePageNumberChange}
            onShowArchivedChange={handleShowArchivedChange}
            pageNumber={parseInt(pageNumber, 10)}
            showArchived={showArchived === 'true'}
            totalCount={trainingPrograms.total}
            trainingPrograms={trainingPrograms.training_programs}
          />
        )}
      </Section>
    </StyledContainer>
  );
};

export default TrainingProgramList;
