import Moment from 'moment-timezone';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTimes } from '@fortawesome/free-solid-svg-icons';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useRef } from 'react';

import Button from '../Button';
import CalendarEventPreviewPopover from '../../data-display/CalendarEventPreviewPopover';
import Flash from '../../utils/Flash';
import Tooltip from '../../utils/Tooltip';
import { directoryCalendarLabels } from 'types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { getAccessibleBackgroundColor } from '../../../../libraries/color';
import { useSession } from '../../../../hooks/use-session';
import { useAvailabilityPickerContext } from './use-context';

import type { Event, TimeSlot } from './types';
import type { EventProps } from 'libraries/react-big-calendar';

interface Props extends EventProps<Event> {
  isDraggable?: boolean;
  isValid: (start: Date, end: Date) => boolean;
  brandColor?: string;
  minDuration?: number;
  setAvailabilities?: Dispatch<SetStateAction<TimeSlot[]>>;
  showEventWarnings?: boolean;
  timezone: string;
}

const AvailabilityPickerEvent = ({
  brandColor,
  event,
  isDraggable,
  isValid,
  minDuration,
  setAvailabilities,
  showEventWarnings = false,
  timezone,
}: Props) => {
  const { account } = useSession();
  const { mousePosition } = useAvailabilityPickerContext();

  const simplifiedEventRef = useRef<HTMLDivElement>(null);
  const domRectRef = useRef<DOMRect>();

  const isInBounds = useMemo(() => {
    if (simplifiedEventRef.current) {
      domRectRef.current = simplifiedEventRef.current.getBoundingClientRect();
    }
    const boundingClientRect = simplifiedEventRef.current?.getBoundingClientRect() || domRectRef.current;

    return mousePosition?.x && mousePosition?.y && boundingClientRect &&
      mousePosition.x >= boundingClientRect.left &&
      mousePosition.x <= boundingClientRect.right &&
      mousePosition.y >= boundingClientRect.top &&
      mousePosition.y <= boundingClientRect.bottom;
  }, [mousePosition]);

  const handleAvailabilityDelete = ({ start, end }: Event) => {
    const convertedStart = Moment.tz(Moment(start).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', timezone);
    const convertedEnd = Moment.tz(Moment(end).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', timezone);

    setAvailabilities?.((availabilities) => availabilities.filter((availability) => {
      const sameStartTime = Moment(availability.start_time).isSame(convertedStart);
      const sameEndTime = Moment(availability.end_time).isSame(convertedEnd);
      return !(sameStartTime && sameEndTime);
    }));
  };

  const isDurationTooShort = useMemo(() => minDuration && Moment.duration(Moment(event.end).diff(Moment(event.start))).asMinutes() < minDuration, [minDuration, event.start, event.end]);

  const isValidTime = useMemo(() => Boolean(event.start && event.end && isValid(event.start, event.end)), [isValid, event.start, event.end]);

  // Microsoft 365 calendar event IDs contain the '=' sign, which are not allowed in CSS selectors
  const eventContentDivId = `event-${event.id}-content`.replace(/=/g, '');

  const styles = useMemo(() => {
    // Right now, brandColor and event.color aren't compatible.
    if (!brandColor) {
      if (event.color) {
        return {
          borderLeft: `5px solid ${event.color}`,
        };
      }
      return undefined;
    }
    return {
      background: getAccessibleBackgroundColor(brandColor, '#666666'),
      boxShadow: `inset 0 0 0 1px ${brandColor}`,
    };
  }, [brandColor, event.color]);

  if (event.showSimplified && (!event.showPreviews || !isInBounds)) {
    return (
      <div
        className="calendar-event simplified-event"
        ref={simplifiedEventRef}
      />
    );
  }

  return (
    <div
      className={classnames([
        'calendar-event',
        !event.background && !event.interviewerEvent && (isDurationTooShort || !isValidTime) && 'warning',
        event.interviewerEvent && 'interviewer-event',
        event.isSingleSelect && 'single-select-event',
      ])}
      onTouchEnd={(e) => {
        e.preventDefault();
      }}
      style={styles}
    >
      <div
        className="calendar-event-content"
        id={eventContentDivId}
      >
        <div className="calendar-event-time">
          {formatMoment(Moment(event.start), TimeFormat.Time)}&ndash;{formatMoment(Moment(event.end), TimeFormat.Time)} {Moment.tz(timezone).format('z')}
        </div>
        {/* This hides the title for events that are too short,
            and for single-select events like when scheduling a hiring meeting,
            but for interviewer events, we always want to show the title. */}
        {(Moment(event.end).diff(Moment(event.start), 'minutes') > 60 || event.interviewerEvent) && !event.isSingleSelect &&
          <div className="calendar-event-title">
            {event.private ? (
              <span
                className="calendar-event-title-private"
                data-for={`${event.id}-private-tooltip`}
                data-tip
              >
                busy
                <FontAwesomeIcon icon={faLock} />
                <Tooltip
                  id={`${event.id}-private-tooltip`}
                  value={`You don't have access to view this event in ${directoryCalendarLabels[account?.directory_type!]}.`}
                />
              </span>
            ) : (event.buffer_time ? 'Buffer window for ' : '') + event.title}
          </div>
        }
      </div>
      {isDraggable && !event.background && !event.interviewerEvent && !event.isSingleSelect &&
        <Button
          className="btn-delete"
          color="gray"
          iconRight={<FontAwesomeIcon icon={faTimes} />}
          onClick={() => handleAvailabilityDelete(event)}
          onTouchEnd={(e) => {
            e.preventDefault();
            handleAvailabilityDelete(event);
          }}
        />
      }
      {event.interviewerEvent && (
        <CalendarEventPreviewPopover
          event={event}
          target={eventContentDivId}
        />
      )}
      <Flash
        message={isDurationTooShort ? 'Too short' : 'Outside allowed times'}
        showFlash={showEventWarnings && !event.background && !event.interviewerEvent && !event.existing && (isDurationTooShort || !isValidTime)}
        type="warning"
      />
    </div>
  );
};

export default AvailabilityPickerEvent;
