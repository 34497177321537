import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo } from 'react';

import Avatar from 'components/library/data-display/Avatar';
import CalendarEventTemplateSummary from '../../../../library/data-display/CalendarEventTemplateSummary';
import Flash from 'components/library/utils/Flash';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import MultiStepFormStep from 'components/library/inputs/MultiStepFormStep';
import Table from 'components/library/data-display/Table';
import Tag from 'components/library/data-display/Tag';
import { Directory } from 'types';
import { Step, useNewHiringMeeting } from './use-new-hiring-meeting';
import { StyledCheckIcon, StyledListItem } from './styles';
import { constructHiringMeetingPayload, constructTokensPayload } from './helpers';
import { formatDateTimeRange } from 'libraries/formatters';
import { useApplication } from 'hooks/queries/applications';
import { useCalendars } from 'hooks/queries/calendars';
import { useCreateHiringMeeting } from 'hooks/queries/hiring-meetings';
import { useRender } from 'hooks/queries/tokens';
import { useSession } from 'hooks/use-session';
import { useUsersMap } from 'hooks/queries/users';

import type { HiringMeeting } from './use-new-hiring-meeting';
import type { TableSchema } from 'components/library/data-display/Table';
import { correctPath } from 'libraries/gem';

const ReviewStep = () => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const { account } = useSession();
  const application = useApplication(id).data!;
  const { data: calendars } = useCalendars();
  const users = useUsersMap({ archived: true });

  const {
    completedStep,
    hiringMeeting,
  } = useNewHiringMeeting();

  const createHiringMeetingMutation = useCreateHiringMeeting();

  const {
    data: renderedTitle,
    isLoading: titleIsRendering,
    error: titleError,
  } = useRender({
    plain_text: true,
    ...constructTokensPayload(application, users),
    text: hiringMeeting.hiring_meeting_template.calendar_event_template?.title,
  }, { enabled: completedStep >= Step.Review });

  const {
    data: renderedDescription,
    isLoading: descriptionIsRendering,
    error: descriptionError,
  } = useRender({
    ...constructTokensPayload(application, users),
    text: hiringMeeting.hiring_meeting_template.calendar_event_template?.description,
  }, { enabled: completedStep >= Step.Review });

  useEffect(() => {
    if (createHiringMeetingMutation.error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [createHiringMeetingMutation.error]);

  if (completedStep < Step.Review) {
    return <Redirect to={correctPath(`/app/candidates/${id}/schedule-hiring-meeting/preferences`)} />;
  }

  const handleNext = async () => {
    createHiringMeetingMutation.reset();

    try {
      const data = await createHiringMeetingMutation.mutateAsync({ payload: constructHiringMeetingPayload(hiringMeeting) });
      history.push(correctPath(`/app/candidates/${id}/hiring-meetings/${data.id}`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const schema = useMemo<TableSchema<HiringMeeting>>(() => [{
    header: 'Meeting Time',
    displayValue: ({ hiring_meeting_template, start_time, timezone }) => {
      const startTime = Moment.tz(start_time, timezone);
      const endTime = Moment.tz(start_time, timezone).add(hiring_meeting_template.duration_minutes, 'minutes');
      return formatDateTimeRange(startTime, endTime, timezone);
    },
  }, hiringMeeting.hiring_meeting_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom' && Boolean(hiringMeeting.zoom_host_id) && {
    header: 'Zoom Host',
    displayValue: ({ zoom_host_id, zoom_host_type }) => {
      if (zoom_host_type === 'user') {
        return (
          <StyledListItem
            label={users[zoom_host_id!]?.name || users[zoom_host_id!]?.email}
            leftIcon={(
              <Avatar
                showUserTooltip={false}
                size="small"
                userId={zoom_host_id}
              />
            )}
          />
        );
      }
      if (zoom_host_type === 'room') {
        return (
          <Tag
            type="room"
            value={zoom_host_id!}
          />
        );
      }
      return <i>No host</i>;
    },
  }, {
    header: 'Scheduling Calendar',
    displayValue: ({ scheduling_calendar_email }) => calendars?.calendars[scheduling_calendar_email]?.name,
  }, account?.directory_type === Directory.Google && {
    header: 'Private Calendar Event',
    displayValue: ({ mark_event_as_private }) => (
      mark_event_as_private ?
        <StyledCheckIcon icon={faCheck} /> :
        <span>&mdash;</span>
    ),
  }, {
    header: 'Calendar Event',
    displayValue: ({ hiring_meeting_attendee_ids, hiring_meeting_template, start_time, timezone }) => {
      if (titleIsRendering || descriptionIsRendering) {
        return <LoadingSpinner />;
      }
      return (
        <>
          <Flash
            message={titleError?.message}
            showFlash={Boolean(titleError)}
            type="danger"
          />
          <Flash
            message={descriptionError?.message}
            showFlash={Boolean(descriptionError)}
            type="danger"
          />
          <CalendarEventTemplateSummary
            applicationId={application?.id}
            calendarEventTemplate={{
              title: renderedTitle?.rendered_text || '',
              description: renderedDescription?.rendered_text || '',
              location: hiring_meeting_template.video_conferencing_enabled ? undefined : hiring_meeting_template.calendar_event_template?.location,
              additional_attendees: [
                // Hack to display attendees in this component
                ...(hiring_meeting_attendee_ids || []).map((id) => users[id]?.email),
                ...(hiring_meeting_template.calendar_event_template?.additional_attendees || []),
              ],
              additional_optional_attendees: hiring_meeting_template.calendar_event_template?.additional_optional_attendees,
              type: 'hiring_meeting_calendar_event',
            }}
            endTime={Moment.tz(start_time, timezone).add(hiring_meeting_template.duration_minutes, 'minutes').toDate()}
            isVideoConferencingEnabled={hiring_meeting_template.video_conferencing_enabled}
            startTime={Moment.tz(start_time, timezone).toDate()}
            timezone={timezone}
          />
        </>
      );
    },
  }], [hiringMeeting, application, renderedTitle, renderedDescription, titleError, descriptionError, titleIsRendering, descriptionIsRendering, users]);

  return (
    <Breadcrumb
      data={{
        title: '3. Review',
        pathname: correctPath(`/app/candidates/${id}/schedule-hiring-meeting/review`),
      }}
    >
      <MultiStepFormStep
        backLocation={correctPath(`/app/candidates/${id}/schedule-hiring-meeting/event`)}
        isLastStep
        isSubmitting={createHiringMeetingMutation.isLoading}
        nextButtonValue="Create Hiring Meeting"
        onNext={handleNext}
      >
        <Helmet>
          <title>3. Review | Schedule hiring meeting for {application.candidate.name || 'Unknown'} | InterviewPlanner</title>
        </Helmet>
        <Flash
          message={createHiringMeetingMutation.error?.message}
          showFlash={createHiringMeetingMutation.isError}
          type="danger"
        />
        <Table
          data={[hiringMeeting]}
          layout="horizontal"
          schema={schema}
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default ReviewStep;
