import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import Button from '../../../../../../library/inputs/Button';
import EditorInput from '../../../../../../library/inputs/EditorInput';
import EmailTemplateAdvancedSettings from '../../../../../../library/inputs/EmailTemplateAdvancedSettings';
import EmailTemplateSelectInput from '../../../../../../library/inputs/EmailTemplateSelectInput';
import EmailTemplateSummary from '../../../../../../library/data-display/EmailTemplateSummary';
import Flash from '../../../../../../library/utils/Flash';
import Section from '../../../../../../library/layout/Section';
import TemplateNamePromptModal from '../../../../../../library/data-display/TemplateNamePromptModal';
import TextInput from '../../../../../../library/inputs/TextInput';
import TokenInput from '../../../../../../library/inputs/TokenInput';
import Tooltip from '../../../../../../library/utils/Tooltip';
import { DEFAULT_EMAIL_CONTENT } from '../../../../../EmailTemplates/EmailTemplateCreate/helpers';
import { areAttachmentsDifferent } from '../../../../../../../libraries/editor';
import { differingValues } from '../../../../../../../libraries/differing-values';
import { downloadFileFromUrl, isFile } from '../../../../../../../libraries/form-data';
import {
  emailTemplateParams,
  useCreateEmailTemplate,
  useEmailTemplate,
  useEmailTemplatesMap,
  useUpdateEmailTemplate,
} from '../../../../../../../hooks/queries/email-templates';
import { slateValueToHtml } from '../../../../../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../../../../../libraries/editor/slate-value-to-text';
import { useJob } from '../../../../../../../hooks/queries/jobs';
import { useSession } from '../../../../../../../hooks/use-session';
import { useSlateEditor } from '../../../../../../../hooks/use-slate-editor';
import { useStage, useUpdateStage } from '../../../../../../../hooks/queries/stages';
import { useTokens } from '../../../../../../../hooks/queries/tokens';
import { useUsersMap } from '../../../../../../../hooks/queries/users';

import type { AdvancedSettings } from '../../../../../../library/inputs/EmailTemplateAdvancedSettings';
import type { ChangeEvent } from 'react';
import type { CreatableEmailTemplate, EditableAttachment } from '../../../../../../../types';
import type { CreateEmailTemplatePayload, UpdateEmailTemplatePayload } from '../../../../../../../hooks/queries/email-templates';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../../../library/inputs/SelectInput/types';
import { correctPath } from 'libraries/gem';

const constructEmptyEmailTemplate = (jobName: string, accountName?: string): CreatableEmailTemplate => ({
  name: `[${jobName}] Availability Request Email`,
  subject: `${accountName ? `${accountName} ` : ''}${DEFAULT_EMAIL_CONTENT.availability_request_email.subject}`,
  body: '',
  sender_name: 'scheduler',
  sender_email: '',
  cc_emails: [],
  bcc_emails: [],
});

const AvailabilityRequestEmailSection = () => {
  const queryClient = useQueryClient();

  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { account } = useSession();
  const { data: job } = useJob(jobId);
  const { data: stage } = useStage(jobId, stageId);
  const emailTemplates = useEmailTemplatesMap();
  const users = useUsersMap({ archived: true });

  const [error, setError] = useState<Error | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEmailTemplateFetching, setIsEmailTemplateFetching] = useState(false);

  const [emailTemplateId, setEmailTemplateId] = useState(stage?.availability_template?.availability_request_email_template_id);
  const [name, setName] = useState(stage?.availability_template?.availability_request_email_template?.name || '');
  const [subjectSlateEditor, subjectSlateValue, setSubjectSlateValue, setSubject] = useSlateEditor(stage?.availability_template?.availability_request_email_template?.subject || '', true);
  const [bodySlateEditor, bodySlateValue, setBodySlateValue, setBody] = useSlateEditor(stage?.availability_template?.availability_request_email_template?.body || '');
  const [attachments, setAttachments] = useState<EditableAttachment[]>(stage?.availability_template?.availability_request_email_template?.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);

  const [isTemplateNamePromptModalOpen, setIsTemplateNamePromptModalOpen] = useState(false);

  const updateStageMutation = useUpdateStage();
  const createEmailTemplateMutation = useCreateEmailTemplate();
  const updateEmailTemplateMutation = useUpdateEmailTemplate();

  const [emailTemplateAdvancedSettings, setEmailTemplateAdvancedSettings] = useState<AdvancedSettings | undefined>({
    sender_email: stage?.availability_template?.availability_request_email_template?.sender_email || '',
    sender_name: stage?.availability_template?.availability_request_email_template?.sender_name || '',
    cc_emails: stage?.availability_template?.availability_request_email_template?.cc_emails,
    bcc_emails: stage?.availability_template?.availability_request_email_template?.bcc_emails,
  });

  const { data: existingEmailTemplate } = useEmailTemplate(emailTemplateId);

  const hasEmailTemplateBeenModified = useMemo<boolean>(() => {
    return name !== (existingEmailTemplate?.name || '') ||
      slateValueToText(subjectSlateValue) !== (existingEmailTemplate?.subject || '') ||
      slateValueToHtml(bodySlateValue) !== (existingEmailTemplate?.body || '') ||
      areAttachmentsDifferent(attachments, (existingEmailTemplate?.attachments || [])) ||
      emailTemplateAdvancedSettings?.sender_name !== (existingEmailTemplate?.sender_name || '') ||
      emailTemplateAdvancedSettings?.sender_email !== (existingEmailTemplate?.sender_email || '') ||
      (emailTemplateAdvancedSettings?.cc_emails || []).length !== (existingEmailTemplate?.cc_emails || []).length ||
      (emailTemplateAdvancedSettings?.cc_emails || []).some((email, i) => email !== existingEmailTemplate?.cc_emails?.[i]) ||
      (emailTemplateAdvancedSettings?.bcc_emails || []).length !== (existingEmailTemplate?.bcc_emails || []).length ||
      (emailTemplateAdvancedSettings?.bcc_emails || []).some((email, i) => email !== existingEmailTemplate?.bcc_emails?.[i]);
  }, [
    attachments,
    bodySlateValue,
    emailTemplateAdvancedSettings,
    existingEmailTemplate,
    name,
    subjectSlateValue,
  ]);

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({
    type: 'availability_request_email',
    availability: {
      application: {
        coordinator: job?.coordinator_id && users[job.coordinator_id] ? {
          email: users[job.coordinator_id].email,
          name: users[job.coordinator_id].name,
          phone_number: users[job.coordinator_id].phone_number,
        } : undefined,
        hiring_manager: job?.hiring_manager_id && users[job.hiring_manager_id] ? {
          email: users[job.hiring_manager_id].email,
          name: users[job.hiring_manager_id].name,
          phone_number: users[job.hiring_manager_id].phone_number,
        } : undefined,
        recruiter: job?.recruiter_id && users[job.recruiter_id] ? {
          email: users[job.recruiter_id].email,
          name: users[job.recruiter_id].name,
          phone_number: users[job.recruiter_id].phone_number,
        } : undefined,
        sourcer: job?.sourcer_id && users[job.sourcer_id] ? {
          email: users[job.sourcer_id].email,
          name: users[job.sourcer_id].name,
          phone_number: users[job.sourcer_id].phone_number,
        } : undefined,
        office: job?.offices && job.offices.length > 0 ? {
          name: job.offices[0].name,
        } : undefined,
      },
      stage: {
        name: stage?.name || '',
        job: {
          name: job?.name || '',
          post_name: job?.post_name,
        },
        stage_interviews: stage?.stage_interviews?.filter((stageInterview) => !stageInterview.inline && !stageInterview.deleted).map((stageInterview) => ({
          name: stageInterview.name,
          position: stageInterview.position,
          interview_template: stageInterview.interview_template ? {
            duration_minutes: stageInterview.interview_template.duration_minutes,
            candidate_facing_name: stageInterview.interview_template.candidate_facing_name,
            candidate_facing_details: stageInterview.interview_template.candidate_facing_details,
          } : undefined,
        })),
      },
    },
  }, {
    enabled: Boolean(stage?.availability_template),
  });

  useEffect(() => {
    if (stage?.availability_template) {
      const emailTemplate = stage?.availability_template.availability_request_email_template;
      setEmailTemplateId(emailTemplate?.id);
      setName(emailTemplate?.name || '');
      setSubject(emailTemplate?.subject || '');
      setBody(emailTemplate?.body || '');
      setAttachments(emailTemplate?.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
      setEmailTemplateAdvancedSettings({
        sender_email: emailTemplate?.sender_email || '',
        sender_name: emailTemplate?.sender_name || '',
        cc_emails: emailTemplate?.cc_emails,
        bcc_emails: emailTemplate?.bcc_emails,
      });
    }
  }, [stage?.availability_template]);

  const handleEmailTemplateChange = async (option: OnChangeValue<Option<string>, false>) => {
    setEmailTemplateId(option?.value);

    if (!option) {
      return;
    }

    setIsEmailTemplateFetching(true);
    let newEmailTemplate;
    if (option.value === 'new') {
      newEmailTemplate = constructEmptyEmailTemplate(job!.name, account!.name);
    } else {
      try {
        newEmailTemplate = await queryClient.fetchQuery(emailTemplateParams(option.value));
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        }
        setIsEmailTemplateFetching(false);
        return;
      }
    }

    setName(newEmailTemplate.name);
    setSubject(newEmailTemplate.subject);
    setBody(newEmailTemplate.body);
    setAttachments(newEmailTemplate.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
    setEmailTemplateAdvancedSettings({
      sender_email: newEmailTemplate.sender_email,
      sender_name: newEmailTemplate.sender_name,
      cc_emails: newEmailTemplate.cc_emails,
      bcc_emails: newEmailTemplate.bcc_emails,
    });
    setIsEmailTemplateFetching(false);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    updateStageMutation.reset();
    createEmailTemplateMutation.reset();
    updateEmailTemplateMutation.reset();
    setError(null);

    const emailTemplate = stage?.availability_template?.availability_request_email_template;
    setEmailTemplateId(emailTemplate?.id || '');
    setName(emailTemplate?.name || '');
    setSubject(emailTemplate?.subject || '');
    setBody(emailTemplate?.body || '');
    setAttachments(emailTemplate?.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
    setEmailTemplateAdvancedSettings({
      sender_email: emailTemplate?.sender_email || '',
      sender_name: emailTemplate?.sender_name || '',
      cc_emails: emailTemplate?.cc_emails,
      bcc_emails: emailTemplate?.bcc_emails,
    });
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateStageMutation.reset();
    createEmailTemplateMutation.reset();
    updateEmailTemplateMutation.reset();
    setError(null);

    const emailTemplatePayload: CreateEmailTemplatePayload | UpdateEmailTemplatePayload = {
      name,
      subject: slateValueToText(subjectSlateValue),
      body: slateValueToHtml(bodySlateValue),
      attachments,
      sender_name: emailTemplateAdvancedSettings?.sender_name || emailTemplateAdvancedSettings?.sender_email || 'scheduler',
      sender_email: emailTemplateAdvancedSettings?.sender_email || 'scheduler',
      cc_emails: emailTemplateAdvancedSettings?.cc_emails,
      bcc_emails: emailTemplateAdvancedSettings?.bcc_emails,
      type: 'availability_request_email',
    };

    if (emailTemplateId) {
      // Check that availability request link is included in availability request email
      if (!/{{\s*AvailabilityRequest\.Link\s*}}/.test(emailTemplatePayload.body!)) {
        setError(new Error('The template must include the AvailabilityRequest.Link token.'));
        return;
      }
    }

    setIsEditing(false);
    // We're using an isFetching state param instead of relying on react-query's
    // isLoading properties because we don't want it to flash as the multiple
    // mutations run. We want it to stay in a loading state for the entirety of
    // this function.
    setIsFetching(true);

    // Update email template if it has been edited
    if (emailTemplateId && emailTemplateId !== 'new') {
      const existingEmailTemplate = await queryClient.fetchQuery(emailTemplateParams(emailTemplateId));
      const diff = differingValues(emailTemplatePayload as UpdateEmailTemplatePayload, existingEmailTemplate);
      if (Object.keys(diff).length > 0) {
        try {
          const payload: UpdateEmailTemplatePayload = {
            ...diff,
            cc_emails: emailTemplatePayload.cc_emails,
            bcc_emails: emailTemplatePayload.bcc_emails,
          };
          const data = await updateEmailTemplateMutation.mutateAsync({ id: emailTemplateId, payload });
          setAttachments(data.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
        } catch (err) {
          setIsEditing(true);
          setIsFetching(false);
          if (err instanceof Error) {
            setError(err);
          }
          return;
        }
      }
    }

    // Create new email template
    let newEmailTemplateId;
    if (emailTemplateId === 'new') {
      try {
        const payload: CreateEmailTemplatePayload = {
          ...emailTemplatePayload as CreateEmailTemplatePayload,
          attachments: (emailTemplatePayload.attachments || []).map((file) => ({ file: file as File })),
        };
        const data = await createEmailTemplateMutation.mutateAsync({ payload });
        setAttachments(data.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
        newEmailTemplateId = data.id;
      } catch (err) {
        setIsEditing(true);
        setIsFetching(false);
        if (err instanceof Error) {
          setError(err);
        }
        return;
      }
    }

    // Update stage with email template id
    try {
      const payload = {
        availability_request_email_template_id: newEmailTemplateId || emailTemplateId || '',
      };
      await updateStageMutation.mutateAsync({ jobId, id: stageId, payload });
    } catch (err) {
      setIsEditing(true);
      setIsFetching(false);
      if (err instanceof Error) {
        setError(err);
      }
      return;
    }

    setIsFetching(false);
  };

  const createTemplateForSaveAs = async (templateName: string) => {
    setName(templateName);

    updateStageMutation.reset();
    createEmailTemplateMutation.reset();
    updateEmailTemplateMutation.reset();
    setError(null);

    if (emailTemplateId) {
      // Check that availability request link is included in availability request email
      const body = slateValueToHtml(bodySlateValue);
      if (!/{{\s*AvailabilityRequest\.Link\s*}}/.test(body)) {
        setError(new Error('The template must include the AvailabilityRequest.Link token.'));
        return;
      }
    }

    setIsEditing(false);
    // We're using an isFetching state param instead of relying on react-query's
    // isLoading properties because we don't want it to flash as the multiple
    // mutations run. We want it to stay in a loading state for the entirety of
    // this function.
    setIsFetching(true);

    let newEmailTemplateId;
    if (emailTemplateId) {
      const convertedAttachments = await Promise.all((attachments || []).map(async (file) => {
        if (isFile(file)) {
          // This attachment is a newly added attachment.
          return { file };
        }
        // This attachment is an attachment on the existing template.
        const downloadedFile = await downloadFileFromUrl(file.link!, file.name);
        return { file: downloadedFile };
      }));

      const emailTemplatePayload: CreateEmailTemplatePayload = {
        name: templateName,
        subject: slateValueToText(subjectSlateValue),
        body: slateValueToHtml(bodySlateValue),
        attachments: convertedAttachments,
        sender_name: emailTemplateAdvancedSettings?.sender_name || emailTemplateAdvancedSettings?.sender_email || 'scheduler',
        sender_email: emailTemplateAdvancedSettings?.sender_email || 'scheduler',
        cc_emails: emailTemplateAdvancedSettings?.cc_emails,
        bcc_emails: emailTemplateAdvancedSettings?.bcc_emails,
        type: 'availability_request_email',
      };

      // Create new email template
      try {
        const data = await createEmailTemplateMutation.mutateAsync({ payload: emailTemplatePayload });
        setAttachments(data.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || []);
        newEmailTemplateId = data.id;
      } catch (err) {
        setIsEditing(true);
        setIsFetching(false);
        if (err instanceof Error) {
          setError(err);
        }
        return;
      }
    }

    // Update stage with email template id
    try {
      const payload = {
        availability_request_email_template_id: newEmailTemplateId || '',
      };
      await updateStageMutation.mutateAsync({ jobId, id: stageId, payload });
    } catch (err) {
      setIsEditing(true);
      setIsFetching(false);
      if (err instanceof Error) {
        setError(err);
      }
      return;
    }

    setIsFetching(false);
  };

  const handleSaveAs = async () => {
    if (emailTemplateId && emailTemplateId !== 'new') {
      // This template is based on another one, so we want to compare the names
      // to see if they're different. If they aren't, open a modal recommending
      // them to update it.
      const existingEmailTemplate = await queryClient.fetchQuery(emailTemplateParams(emailTemplateId));
      if (name === existingEmailTemplate.name) {
        setIsTemplateNamePromptModalOpen(true);
        return;
      }
    }

    await createTemplateForSaveAs(name);
  };

  const isUpdated = (stage?.availability_template?.availability_request_email_template_id || '') !== (emailTemplateId || '');
  const linkedStages = emailTemplateId && emailTemplates[emailTemplateId]?.linked_resources || null;
  const selectedTemplateIsEditableInline = !emailTemplateId || (!isUpdated && linkedStages === 1) || (isUpdated && !linkedStages);

  return (
    <Section
      additionalHeaderActions={isEditing && emailTemplateId && emailTemplateId !== 'new' && (
        <Button
          color="gem-outline"
          iconRight={<FontAwesomeIcon icon={faSave} />}
          isDisabled={isFetching}
          onClick={handleSaveAs}
          size="small"
          value="Save as new template"
        />
      )}
      className="job-stage-availability-request-email"
      isEditable
      isEditing={isEditing}
      isSaveButtonDisabled={isEmailTemplateFetching || (!selectedTemplateIsEditableInline && hasEmailTemplateBeenModified)}
      isSaving={isFetching}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      saveButtonTooltip={!selectedTemplateIsEditableInline && hasEmailTemplateBeenModified ? (
        <Tooltip
          id="job-stage-availability-request-email-disabled-save"
          position="top"
          value="You cannot edit this template from this page because it is linked to other stages."
        />
      ) : undefined}
      title="Availability request email"
    >
      <Flash
        isDismissible
        message="Successfully updated!"
        onDismiss={updateStageMutation.reset}
        showFlash={updateStageMutation.isSuccess}
        type="success"
      />
      <Flash
        message={error?.message}
        showFlash={Boolean(error)}
        type="danger"
      />
      <Flash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <div className="form-container">
        <EmailTemplateSelectInput
          isDisabled={!isEditing || isFetching}
          isInlineCreatable
          onChange={handleEmailTemplateChange}
          type="availability_request_email"
          value={emailTemplateId}
        />
      </div>
      {emailTemplateId &&
        ((isEditing || isFetching) ?
          <div className="email-template-inline-form">
            <Flash
              message={<span>This template is linked to other stages. You can either create a new template here with any changes you make, or go to the <Link to={correctPath(`/app/email-templates/${emailTemplateId}`)}>email template page</Link> to edit the original template.</span>}
              showFlash={!selectedTemplateIsEditableInline}
              type="info"
            />
            <div className="form-container">
              <TextInput
                isRequired
                label="Template Name"
                onChange={handleNameChange}
                value={name}
              />
            </div>
            <div className="form-container">
              <TokenInput
                editor={subjectSlateEditor}
                isRequired
                label="Subject"
                pendingPreviewMessage="You can preview this token when you are requesting availability from a candidate."
                setValue={setSubjectSlateValue}
                tokens={tokens}
                type="availability_request_email"
                value={subjectSlateValue}
              />
            </div>
            <EditorInput
              allowImages
              attachments={attachments}
              editor={bodySlateEditor}
              exampleHtmlContent={DEFAULT_EMAIL_CONTENT.availability_request_email.body}
              label="Body"
              pendingPreviewMessage="You can preview this token when you are requesting availability from a candidate."
              setAttachments={setAttachments}
              setValue={setBodySlateValue}
              tokens={tokens}
              type="availability_request_email"
              value={bodySlateValue}
            />
            <EmailTemplateAdvancedSettings
              emailTemplate={emailTemplateAdvancedSettings}
              setEmailTemplate={setEmailTemplateAdvancedSettings}
            />
            <TemplateNamePromptModal
              isOpen={isTemplateNamePromptModalOpen}
              onSubmit={createTemplateForSaveAs}
              onToggle={() => setIsTemplateNamePromptModalOpen((prev) => !prev)}
              templateName={name}
            />
          </div> :
          <EmailTemplateSummary
            id={emailTemplateId}
            jobId={job?.id}
            pendingPreviewMessage="You can preview this token when you are requesting availability from a candidate."
            tokens={tokens}
          />
        )
      }
    </Section>
  );
};

export default AvailabilityRequestEmailSection;
