import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';

import CompanyBrandingSection from './CompanyBrandingSection';
import CompanyConflictSettingsSection from './CompanyConflictSettingsSection';
import CompanyInclusionSection from './CompanyInclusionSection';
import CompanyInternalCalendarEventsSection from './CompanyInternalCalendarEventsSection';
import CompanyInterviewLimitsSection from './CompanyInterviewLimitsSection';
import CompanyMeetingBuffersSection from './CompanyMeetingBuffersSection';
import CompanyOfficesSection from './CompanyOfficesSection';
import LoadingSpinner from '../../library/utils/LoadingSpinner';
import { useAccount } from '../../../hooks/queries/accounts';
import { correctPath } from 'libraries/gem';

const Company = () => {
  const { isLoading } = useAccount();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: <><FontAwesomeIcon icon={faBuilding} />Company</>,
        pathname: correctPath('/app/company'),
      }}
    >
      <div className="company-container">
        <Helmet>
          <title>Company | Gem Scheduling</title>
        </Helmet>
        <CompanyBrandingSection />
        <CompanyInternalCalendarEventsSection />
        <CompanyConflictSettingsSection />
        <CompanyInterviewLimitsSection />
        <CompanyMeetingBuffersSection />
        <CompanyInclusionSection />
        <CompanyOfficesSection />
      </div>
    </Breadcrumb>
  );
};

export default Company;
