import TinyColor from 'tinycolor2';
import { avatarColorToHex } from 'types';

import type { UserAvatarColor } from 'types';

export function getAccessibleBackgroundColor (backgroundColor: string, textColor: string): string {
  let background = TinyColor(backgroundColor);

  while (!TinyColor.isReadable(textColor, background) && !TinyColor.equals(background, 'white')) {
    if (background.isDark()) {
      background = TinyColor(background.lighten(10).toHexString());
    } else {
      background = TinyColor(background.lighten(5).toHexString());
    }
  }

  return background.toHexString();
}

export function getAccessibleTextColor (backgroundColor: string, textColors: string[]): string {
  return TinyColor.mostReadable(TinyColor(backgroundColor).darken(15), textColors).toHexString();
}

export function getAccessibleContrastColors (color: string): { background: string; text: string } {
  const background = TinyColor(getAccessibleBackgroundColor(color, '#666666')).lighten(2).toHexString();

  let text = color;
  while (!TinyColor.equals(TinyColor(text).darken(10), 'black')) {
    text = TinyColor(text).darken(10).toHexString();
  }

  return { background, text };
}

export function getCalendarEventColor (avatarColor: UserAvatarColor): string {
  const background = TinyColor(avatarColorToHex[avatarColor]);
  if (avatarColor === 'orchid-pink') {
    return background.lighten(15).toHexString();
  }
  if (avatarColor === 'java-blue') {
    return background.lighten(30).desaturate(5).toHexString();
  }
  return background.lighten(20).brighten(10).toHexString();
}

export function getCalendarEventTextColor (avatarColor: UserAvatarColor): string {
  let textColor = TinyColor(avatarColorToHex[avatarColor]);
  while (!TinyColor.isReadable(textColor, 'white', { size: 'large' }) && !TinyColor.equals(textColor, 'white')) {
    textColor = TinyColor(textColor.darken(5).toHexString());
  }
  return textColor.toHexString();
}
