import { FullStory } from '@fullstory/browser';
import { setUser } from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { ATS, ChatTool, Directory, Session, VideoConferencingTool } from '../../types';
import type { InterviewPlannerError } from '../../libraries/interviewplanner';
import type { UseQueryOptions } from 'react-query';
import { isRunningUnderGem } from '../../libraries/gem';

function generateRedirectHost (): string {
  return isRunningUnderGem() ? `${window.location.origin}/scheduling` : 'DEFAULT';
}

export enum QueryKey {
  AdminConsent = 'AdminConsent',
  RetrieveAuth = 'RetrieveAuth',
  SignIn = 'SignIn',
  SignUp = 'SignUp',
}

export const useAuth = (options: UseQueryOptions<Session, InterviewPlannerError> = {}) => {
  return useQuery<Session, InterviewPlannerError>([QueryKey.RetrieveAuth], () => {
    return InterviewPlanner.request('GET', '/auth');
  }, {
    ...options,
    onSuccess: ({ user }) => {
      if (user) {
        analytics.identify(user.id, {
          language: window.navigator.language,
        });
        analytics.alias(user.id);
        setUser({ id: user.id, email: user.email });
        if (user.gem_internal_id) {
          try {
            FullStory('setIdentity', {
              uid: user.gem_internal_id,
              properties: {
                displayName: user.name,
                email: user.email,
              },
            });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
        }
      } else {
        setUser(null);
      }
    },
  });
};

export const useSignOut = () => {
  const queryClient = useQueryClient();

  return useMutation<null, InterviewPlannerError>(() => {
    return InterviewPlanner.request('DELETE', '/auth');
  }, {
    onSuccess: () => {
      queryClient.resetQueries([QueryKey.RetrieveAuth]);
      setUser(null);
    },
  });
};

export interface CreateDemoAccountPayload {
  email?: string;
  name?: string;
  company?: string;
  ats?: string;
}

interface CreateDemoAccountMutationVariables {
  payload: CreateDemoAccountPayload;
}

interface CreateDemoAccountData {
  success: boolean;
  creating: boolean;
}

export const useDemo = () => {
  return useMutation<CreateDemoAccountData, InterviewPlannerError, CreateDemoAccountMutationVariables>(({ payload }) => {
    return InterviewPlanner.request('POST', '/auth/demo', payload);
  });
};

interface AdminConsentData {
  redirect_url: string;
}

export const adminConsentParams = (isGem: boolean = false, options: UseQueryOptions<AdminConsentData> = {}): UseQueryOptions<AdminConsentData> => {
  return {
    queryKey: [QueryKey.AdminConsent],
    queryFn: () => {
      return InterviewPlanner.request('GET', '/auth/microsoft365/adminconsent', null, { gem: isGem || undefined });
    },
    staleTime: Infinity, // This should never become stale.
    ...options,
  };
};

interface SignUpData {
  redirect_url: string;
}

export const signInParams = (type: Directory, redirectUri: string = '', isGem: boolean = false, options: UseQueryOptions<SignUpData> = {}): UseQueryOptions<SignUpData> => {
  const redirectHost = generateRedirectHost();
  return {
    queryKey: [QueryKey.SignIn, type],
    queryFn: () => {
      return InterviewPlanner.request('GET', `/auth/${type}/signin`, null, { redirect_host: redirectHost, redirect_uri: redirectUri, gem: isGem || undefined });
    },
    staleTime: Infinity, // This should never become stale.
    ...options,
  };
};

interface SignUpData {
  redirect_url: string;
}

type IntegrationWithSignUpRoute = `${Directory | VideoConferencingTool.Zoom | ATS.Lever | ChatTool.Slack}`;

export const signUpParams = (type: IntegrationWithSignUpRoute, isGem: boolean = false, options: UseQueryOptions<SignUpData> = {}): UseQueryOptions<SignUpData> => {
  const redirectHost = generateRedirectHost();
  return {
    queryKey: [QueryKey.SignUp, type],
    queryFn: () => {
      return InterviewPlanner.request('GET', `/auth/${type}/signup`, null, { redirect_host: redirectHost, gem: isGem || undefined });
    },
    staleTime: Infinity, // This should never become stale.
    ...options,
  };
};
