import { Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Application from './Application';
import Availability from './Availability';
import Demo from './Demo';
import GemGlobalStyles from 'theme/gem/styles';
import gemTheme from 'theme/gem';
import Go from './Go';
import Pilot from './Pilot';
import SecureRoute from './library/navigation/SecureRoute';
import SelfSchedule from './SelfSchedule';
import SignIn from './SignIn';
import Website from './Website';
import { correctPath } from '../libraries/gem';

const App = () => {
  return (
    <ThemeProvider theme={gemTheme}>
      <GemGlobalStyles />
      <Switch>
        <Route component={Availability} path={correctPath('/availability/:id')} />
        <Route component={SelfSchedule} path={correctPath('/schedule/:id')} />
        {/* These 2 routes are special and aren't meant for real users. They use Gem Google credentials so that we can
        record a video showing the functionality we've built which is needed for getting the OAuth2 App verified. */}
        <Route component={SignIn} exact path={correctPath('/app/signin')} />
        <Route component={Pilot} exact path={correctPath('/app/pilot')} />
        <SecureRoute component={Application} path={correctPath('/app')} />
        <Redirect from={correctPath('/login')} to={correctPath('/signin')} />
        <Route component={SignIn} path={correctPath('/signin')} />
        <Route component={Pilot} path={correctPath('/pilot')} />
        <Route component={Demo} path={correctPath('/demo')} />
        <Route component={Go} path={correctPath('/go/:slug')} />
        {/* The website has InterviewPlanner styles overriding it. */}
        <Route component={Website} path={correctPath('/')} />
      </Switch>
    </ThemeProvider>
  );
};

export default App;
