import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { difference, differenceBy, find, isEmpty, orderBy, toPairs } from 'lodash';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Flash from '../../../library/utils/Flash';
import InterviewerListTable from './InterviewerListTable';
import LoadingSpinner from '../../../library/utils/LoadingSpinner';
import Section from '../../../library/layout/Section';
import SelectInput from '../../../library/inputs/SelectInput';
import Tag from '../../../library/data-display/Tag';
import TagEditDiffMessage, { DiffAction, DiffType } from '../../../library/data-display/TagEditDiffMessage';
import TextInput from '../../../library/inputs/TextInput';
import useSyncStateWithQuery from '../../../../hooks/use-sync-state-with-query';
import { useEligibilities, useUpdateUser, useUsers, useUserTags } from '../../../../hooks/queries/users';

import type { ActionMeta } from 'react-select';
import type { ChangeEvent } from 'react';
import type { EditDiff } from '../../../library/data-display/TagEditDiffMessage';
import type { EligibilityOption, UpdateUserPayload } from './types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../library/inputs/SelectInput/types';
import type { UserTag } from '../../../../types';

const InterviewerList = () => {
  const [search, querySearch, setSearch] = useSyncStateWithQuery<string>('q', '', { delay: 200 });
  const [showArchived, , setShowArchived] = useSyncStateWithQuery<string>('show_archived', 'false');
  const [eligibility, queryEligibility, setEligibility] = useSyncStateWithQuery<string[]>('eligibility', [], { array: true });
  const [tag, queryTag, setTag] = useSyncStateWithQuery<string[]>('tag', [], { array: true });
  const [pageNumber, queryPageNumber, setPageNumber] = useSyncStateWithQuery<string>('page', '1');

  const { data: eligibilities } = useEligibilities();
  const eligibilityOptionsFromServer = useMemo<EligibilityOption[]>(() => orderBy(eligibilities || [], ['id', 'trainee'], ['asc', 'desc']).map((eligibility) => ({
    label: eligibility.id,
    value: `${eligibility.id}${eligibility.trainee ? ':trainee' : ''}`,
    trainee: eligibility.trainee,
    training_program_id: eligibility.training_program_id,
  })), [eligibilities]);
  const [eligibilityOptions, setEligibilityOptions] = useState<EligibilityOption[]>(eligibilityOptionsFromServer);

  const { data: userTags } = useUserTags();
  const tagOptionsFromServer = useMemo<Option<string>[]>(() => (userTags || []).map((tag) => ({
    label: tag.id,
    value: tag.id,
  })), [userTags]);
  const [tagOptions, setTagOptions] = useState<Option<string>[]>(tagOptionsFromServer);

  const {
    data: users,
    error: usersError,
    isFetching: isUsersFetching,
  } = useUsers({
    archived: showArchived === 'true',
    name_or_email: querySearch, // This has to be querySearch so it doesn't make a lot of requests when you type fast.
    eligibility: queryEligibility,
    user_tag: queryTag,
    limit: 10,
    offset: 10 * (parseInt(queryPageNumber, 10) - 1),
  });

  const [isFetching, setIsFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userPayloads, setUserPayloads] = useState<Record<string, UpdateUserPayload>>({});
  const [editDiff, setEditDiff] = useState<EditDiff>({});
  const [errors, setErrors] = useState<string[]>([]);

  const updateUserMutation = useUpdateUser();

  useEffect(() => {
    setEligibilityOptions(eligibilityOptionsFromServer);
  }, [JSON.stringify(eligibilityOptionsFromServer)]);

  useEffect(() => {
    setTagOptions(tagOptionsFromServer);
  }, [JSON.stringify(tagOptionsFromServer)]);

  useEffect(() => {
    const newEligibilityOptions = [...eligibilityOptions];

    // We need to keep track of whether it changed, otherwise, we'd always call
    // setEligibilityOptions which would make this useEffect run again, causing
    // an infinite loop.
    let changed = false;

    eligibility.forEach((eligibility) => {
      if (find(eligibilityOptions, ['value', eligibility])) {
        return;
      }

      changed = true;
      newEligibilityOptions.push({ label: eligibility, value: eligibility, trainee: false });
    });

    if (changed) {
      setEligibilityOptions(newEligibilityOptions);
    }
  }, [JSON.stringify(eligibilityOptions), eligibility]);

  useEffect(() => {
    const newTagOptions = [...tagOptions];

    // We need to keep track of whether it changed, otherwise, we'd always call
    // setTagOptions which would make this useEffect run again, causing an
    // infinite loop.
    let changed = false;

    tag.forEach((tag) => {
      if (find(tagOptions, ['value', tag])) {
        return;
      }

      changed = true;
      newTagOptions.push({ label: tag, value: tag });
    });

    if (changed) {
      setTagOptions(newTagOptions);
    }
  }, [JSON.stringify(tagOptions), tag]);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handleShowArchivedChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setShowArchived(JSON.stringify(e.target.checked));
  }, []);

  const handleEligibilityChange = useCallback((option: OnChangeValue<EligibilityOption, true>) => {
    setEligibility(option && option.length > 0 ? option.map((eligibility) => eligibility.value) : []);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handleTagChange = useCallback((option: OnChangeValue<Option<string>, true>) => {
    setTag(option && option.length > 0 ? option.map((tag) => tag.value) : []);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value.toString());
  }, []);

  const handleEligibilitiesChange = (option: OnChangeValue<EligibilityOption, true>, actionMeta: ActionMeta<EligibilityOption>) => {
    const userId = actionMeta.name;
    const user = find(users?.users, ['id', userId]);
    if (!userId || !user) {
      return;
    }

    if (actionMeta.action === 'create-option') {
      // update our complete list of eligibilities to include this new one
      setEligibilityOptions([
        ...eligibilityOptions,
        { label: option[option.length - 1].label, value: option[option.length - 1].value, trainee: false },
      ]);
    }

    // update the edit diff appropriately
    const previousValues = (userPayloads[userId]?.eligibilities || user.eligibilities || []);
    const newValuesMap = (option || [])
    .reduce<Record<string, boolean>>((acc, eligibility) => {
      return ({ ...acc, [`${eligibility.label}:${eligibility.trainee || false}`]: true });
    }, {});
    const newValues: UpdateUserPayload['eligibilities'] = option ?
      option
      .map((eligibility) => ({ id: eligibility.label, trainee: eligibility.trainee, training_program_id: eligibility.training_program_id }))
      .filter((({ id, trainee }) => {
        if (!trainee) {
          return true;
        }
        return !newValuesMap[`${id}:false`];
      })) :
      [];
    const newEditDiff: EditDiff = { ...editDiff };

    differenceBy(previousValues, newValues, ({ id, trainee }) => `${id}:${trainee}`).map((removed) => {
      if (newEditDiff[`eligibility:added:${removed.id}:${removed.trainee}`]?.items[userId]) {
        // this eligibility that was just removed was recently added, so instead
        // of saying it was added and removed, just delete the addition
        delete newEditDiff[`eligibility:added:${removed.id}:${removed.trainee}`].items[userId];
        // if this was the last user to have this eligibility added, delete the
        // entry in the diff for it so it doesn't show an empty block in the
        // diff flash message
        if (isEmpty(newEditDiff[`eligibility:added:${removed.id}:${removed.trainee}`].items)) {
          delete newEditDiff[`eligibility:added:${removed.id}:${removed.trainee}`];
        }
      } else {
        // record that this eligibility is being removed from this user
        newEditDiff[`eligibility:removed:${removed.id}:${removed.trainee}`] = {
          ...newEditDiff[`eligibility:removed:${removed.id}:${removed.trainee}`] || {
            type: DiffType.Eligibility,
            action: DiffAction.Removed,
            value: removed,
          },
          items: {
            ...newEditDiff[`eligibility:removed:${removed.id}:${removed.trainee}`]?.items || {},
            [userId]: user.name || user.email,
          },
        };
      }
    });
    differenceBy(newValues, previousValues, ({ id, trainee }) => `${id}:${trainee}`).map((added) => {
      if (newEditDiff[`eligibility:removed:${added.id}:${added.trainee}`]?.items[userId]) {
        // this eligibility that was just added was recently removed, so instead
        // of saying it was removed and added, just delete the addition
        delete newEditDiff[`eligibility:removed:${added.id}:${added.trainee}`].items[userId];
        // if this was the last user to have this eligibility removed, delete
        // the entry in the diff for it so it doesn't show an empty block in the
        // diff flash message
        if (isEmpty(newEditDiff[`eligibility:removed:${added.id}:${added.trainee}`].items)) {
          delete newEditDiff[`eligibility:removed:${added.id}:${added.trainee}`];
        }
      } else {
        // record that this eligibility is being added to this user
        newEditDiff[`eligibility:added:${added.id}:${added.trainee}`] = {
          ...newEditDiff[`eligibility:added:${added.id}:${added.trainee}`] || {
            type: DiffType.Eligibility,
            action: DiffAction.Added,
            value: added,
          },
          items: {
            ...newEditDiff[`eligibility:added:${added.id}:${added.trainee}`]?.items || {},
            [userId]: user.name || user.email,
          },
        };
      }
    });

    setEditDiff(newEditDiff);
    setUserPayloads((prevState) => ({
      ...prevState,
      [userId]: {
        ...prevState[userId] || {},
        // we're saving the name here so we can use it for any potential error
        // messages
        name: user.name || user.email,
        eligibilities: newValues,
      },
    }));
  };

  const handleTagsChange = (option: OnChangeValue<Option<string>, true>, actionMeta: ActionMeta<Option<string>>) => {
    const userId = actionMeta.name;
    const user = find(users?.users, ['id', userId]);
    if (!userId || !user) {
      return;
    }

    if (actionMeta.action === 'create-option') {
      // update our complete list of tags to include this new one
      setTagOptions([
        ...tagOptions,
        { label: option[option.length - 1].label, value: option[option.length - 1].value },
      ]);
    }

    // update the edit diff appropriately
    const previousValues = (userPayloads[userId]?.user_tags || user.user_tags || []).map((tag) => (tag as UserTag).id || tag as string);
    const newValues = option ? option.map((tag) => tag.value) : [];
    const newEditDiff: EditDiff = { ...editDiff };

    difference(previousValues, newValues).map((removed) => {
      if (newEditDiff[`tag:added:${removed}`]?.items[userId]) {
        // this tag that was just removed was recently added, so instead
        // of saying it was added and removed, just delete the addition
        delete newEditDiff[`tag:added:${removed}`].items[userId];
        // if this was the last user to have this tag added, delete the
        // entry in the diff for it so it doesn't show an empty block in the
        // diff flash message
        if (isEmpty(newEditDiff[`tag:added:${removed}`].items)) {
          delete newEditDiff[`tag:added:${removed}`];
        }
      } else {
        // record that this tag is being removed from this user
        newEditDiff[`tag:removed:${removed}`] = {
          ...newEditDiff[`tag:removed:${removed}`] || {
            type: DiffType.Tag,
            action: DiffAction.Removed,
            value: { id: removed },
          },
          items: {
            ...newEditDiff[`tag:removed:${removed}`]?.items || {},
            [userId]: user.name || user.email,
          },
        };
      }
    });
    difference(newValues, previousValues).map((added) => {
      if (newEditDiff[`tag:removed:${added}`]?.items[userId]) {
        // this tag that was just added was recently removed, so instead
        // of saying it was removed and added, just delete the addition
        delete newEditDiff[`tag:removed:${added}`].items[userId];
        // if this was the last user to have this tag removed, delete
        // the entry in the diff for it so it doesn't show an empty block in the
        // diff flash message
        if (isEmpty(newEditDiff[`tag:removed:${added}`].items)) {
          delete newEditDiff[`tag:removed:${added}`];
        }
      } else {
        // record that this tag is being added to this user
        newEditDiff[`tag:added:${added}`] = {
          ...newEditDiff[`tag:added:${added}`] || {
            type: DiffType.Tag,
            action: DiffAction.Added,
            value: { id: added },
          },
          items: {
            ...newEditDiff[`tag:added:${added}`]?.items || {},
            [userId]: user.name || user.email,
          },
        };
      }
    });

    setEditDiff(newEditDiff);
    setUserPayloads((prevState) => ({
      ...prevState,
      [userId]: {
        ...prevState[userId] || {},
        // we're saving the name here so we can use it for any potential error
        // messages
        name: user.name || user.email,
        user_tags: newValues,
      },
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
    setIsSuccess(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsSuccess(false);
    setEligibilityOptions(eligibilityOptionsFromServer);
    setTagOptions(tagOptionsFromServer);
    setEditDiff({});
    setUserPayloads({});
  };

  const handleSave = async () => {
    setIsFetching(true);
    setIsSuccess(false);

    const responses = await Promise.all(
      toPairs(userPayloads).map(
        async ([userId, payload]) => {
          try {
            return await updateUserMutation.mutateAsync({
              id: userId,
              payload: {
                eligibilities_v2: payload.eligibilities?.map((eligibility) => ({
                  id: eligibility.id,
                  trainee: eligibility.trainee || false,
                })),
                user_tags: payload.user_tags,
              },
            });
          } catch (err) {
            return { id: userId, error: err as Error };
          }
        }
      )
    );

    const errors = responses
    .filter((resp: any): resp is { id: string; error: Error } => Boolean(resp.error))
    .map(({ id, error }) => `${userPayloads[id].name}: ${error.message}`);

    if (errors.length > 0) {
      setErrors(errors);
      setIsFetching(false);
      return;
    }

    // if there were errors, we don't want to clear out the payloads
    setEditDiff({});
    setUserPayloads({});

    setIsFetching(false);
    setIsSuccess(true);
    setIsEditing(false);
  };

  const selectedEligibilities = useMemo<EligibilityOption[]>(() => {
    if (!eligibility) {
      return [];
    }
    return eligibility
    .map((eligibility) => find(eligibilityOptions, ['value', eligibility]))
    .filter((e): e is EligibilityOption => Boolean(e));
  }, [eligibility, eligibilityOptions]);

  const selectedTags = useMemo<Option<string>[]>(() => {
    if (!tag) {
      return [];
    }
    return tag
    .map((tag) => find(tagOptions, ['value', tag]))
    .filter((t): t is Option<string> => Boolean(t));
  }, [tag, tagOptions]);

  return (
    <div className="interviewers-container">
      <Helmet>
        <title>Interviewers | InterviewPlanner</title>
      </Helmet>
      <Section
        isEditable
        isEditing={isEditing}
        isSaving={isFetching}
        onCancel={handleCancel}
        onEdit={handleEdit}
        onSave={handleSave}
        title="Interviewers"
      >
        <Flash
          isDismissible
          message="Successfully updated!"
          showFlash={isSuccess}
          type="success"
        />
        <Flash
          message={<TagEditDiffMessage editDiff={editDiff} />}
          showFlash={!isEmpty(editDiff)}
          type="info"
        />
        <Flash
          message={JSON.stringify(errors)}
          showFlash={!isEmpty(errors) && isEditing}
          type="danger"
        />
        <div className="filters-container">
          <TextInput
            className="text-input-interviewers-filter"
            isAutofocus
            label="Search"
            leftIcon={isUsersFetching ? <LoadingSpinner /> : <FontAwesomeIcon icon={faSearch} />}
            onChange={handleSearchChange}
            placeholder="Interviewer name or email"
            value={search}
          />
        </div>
        <div className="filters-container">
          <SelectInput
            className="select-input-interviewers-eligibility"
            formatOptionLabel={(option) => (
              <Tag
                hasTrainingProgram={Boolean(option.training_program_id)}
                isNegated={false}
                isTrainee={option.trainee}
                type="eligibility"
                value={option.label}
              />
            )}
            isClearable
            isMulti
            label="Interview Eligibility"
            onChange={handleEligibilityChange}
            options={eligibilityOptions}
            value={selectedEligibilities}
          />
          <SelectInput
            className="select-input-interviewers-tag"
            formatOptionLabel={(option) => (
              <Tag
                isNegated={false}
                type="tag"
                value={option.value}
              />
            )}
            isClearable
            isMulti
            label="Tag"
            onChange={handleTagChange}
            options={tagOptions}
            value={selectedTags}
          />
        </div>
        <Flash
          message={usersError?.message}
          showFlash={Boolean(usersError)}
          type="danger"
        />
        {users &&
          <InterviewerListTable
            eligibilityOptions={eligibilityOptions}
            isEditing={isEditing || isFetching}
            onEligibilitiesChange={handleEligibilitiesChange}
            onPageNumberChange={handlePageNumberChange}
            onShowArchivedChange={handleShowArchivedChange}
            onTagsChange={handleTagsChange}
            pageNumber={parseInt(pageNumber, 10)}
            showArchived={showArchived === 'true'}
            tagOptions={tagOptions}
            totalCount={users.total}
            userPayloads={userPayloads}
            users={users.users}
          />
        }
      </Section>
    </div>
  );
};

export default InterviewerList;
