import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import BusinessHoursSummary from '../../../../../library/data-display/BusinessHoursSummary';
import Section from '../../../../../library/layout/Section';
import Table from '../../../../../library/data-display/Table';
import { formatDuration } from '../../../../../../libraries/formatters';
import { useSelfSchedulingLink } from '../../../../../../hooks/queries/self-scheduling-links';

import type { SelfSchedulingLink } from '../../../../../../types';
import type { TableSchema } from '../../../../../library/data-display/Table';

const SelfSchedulingLinkPreferencesSection = () => {
  const { scheduleId } = useParams<{ id: string; scheduleId: string }>();

  const { data: selfSchedulingLink } = useSelfSchedulingLink(scheduleId);

  const schema = useMemo<TableSchema<SelfSchedulingLink>>(() => [{
    header: 'Working Hours',
    displayValue: ({ schedule_template }) => <BusinessHoursSummary businessHours={schedule_template.business_hours || []} />,
  }, Boolean(selfSchedulingLink?.schedule_template.self_scheduling_advanced_notice_hours) && {
    header: 'Advanced Notice',
    displayValue: ({ schedule_template }) => formatDuration(schedule_template.self_scheduling_advanced_notice_hours * 60),
  }, {
    header: 'Rescheduling',
    displayValue: ({ schedule_template }) => (
      schedule_template.self_scheduling_reschedule_enabled ?
        `Allowed${schedule_template.self_scheduling_reschedule_notice_hours ? ` unless it's ${formatDuration(schedule_template.self_scheduling_reschedule_notice_hours * 60)} before scheduled time` : ''}` :
        'Not allowed'
    ),
  }, {
    header: 'Cancellations',
    displayValue: ({ schedule_template }) => (
      schedule_template.self_scheduling_cancel_enabled ?
        `Allowed${schedule_template.self_scheduling_cancel_notice_hours ? ` unless it's ${formatDuration(schedule_template.self_scheduling_cancel_notice_hours * 60)} before scheduled time` : ''}` :
        'Not allowed'
    ),
  }], [selfSchedulingLink]);

  if (!selfSchedulingLink) {
    return null;
  }

  return (
    <Section className="summary-section" title="Preferences">
      <Table
        data={[selfSchedulingLink]}
        layout="horizontal"
        schema={schema.filter(Boolean)}
      />
    </Section>
  );
};

export default SelfSchedulingLinkPreferencesSection;
