import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { HiringTeamRole } from '../../types';
import type { Job } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListJobs = 'ListJobs',
  RetrieveJob = 'RetrieveJob',
}

export const useJob = (id?: string, options: UseQueryOptions<Job, Error> = {}) => {
  return useQuery<Job, Error>([QueryKey.RetrieveJob, id], () => {
    return InterviewPlanner.request('GET', `/jobs/${id}`);
  }, {
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
  });
};

interface ListJobsQuery {
  limit?: number;
  offset?: number;
  name?: string;
  archived?: boolean;
  status?: string[];
  including_user?: string;
  including_role?: HiringTeamRole[];
}

interface ListJobsData {
  jobs: Job[];
  total: number;
}

export const useJobs = (query: ListJobsQuery = {}, options: UseQueryOptions<ListJobsData, Error> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return useQuery<ListJobsData, Error>([QueryKey.ListJobs, query], async () => {
    const pageSize = 100;
    const paginatedQuery = {
      limit: pageSize,
      offset: 0,
      ...query,
    };

    const jobs = [];
    let total = 0;
    let totalLimit;

    while (totalLimit === undefined || jobs.length < totalLimit) {
      const response = await InterviewPlanner.request<ListJobsData>('GET', '/jobs', null, paginatedQuery);
      total = response.total;
      if (response.jobs.length === 0) {
        break;
      }

      jobs.push(...response.jobs);
      totalLimit = query.limit! < total ? query.limit : total;
      paginatedQuery.offset = paginatedQuery.offset + paginatedQuery.limit;
    }

    return { jobs, total };
  }, options);
};

interface UpdateJobPayload {
  archived?: boolean;
}

interface UpdateJobMutationVariables {
  id: string;
  payload?: UpdateJobPayload;
}

export const useUpdateJob = () => {
  const queryClient = useQueryClient();

  return useMutation<Job, Error, UpdateJobMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/jobs/${id}`, payload);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.ListJobs]);
      queryClient.setQueryData([QueryKey.RetrieveJob, data.id], data);
    },
  });
};
