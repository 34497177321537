import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Redirect, useHistory } from 'react-router-dom';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

import Flash from '../../../library/utils/Flash';
import InterviewerTemplatesSummary from '../../../library/data-display/InterviewerTemplatesSummary';
import MultiStepFormStep from '../../../library/inputs/MultiStepFormStep';
import Table from '../../../library/data-display/Table';
import { formatDuration, formatPositions, formatTimeRange } from '../../../../libraries/formatters';
import { liveCodingLabels } from '../../../../types';
import { useCreateInterviewTemplate } from '../../../../hooks/queries/interview-templates';
import { useNewInterviewTemplate } from './use-new-interview-template';
import { useSession } from '../../../../hooks/use-session';

import type { CreateInterviewTemplatePayload } from '../../../../hooks/queries/interview-templates';
import type { TableSchema } from '../../../library/data-display/Table';
import type { WithId } from '../../../library/data-display/Table/types';
import { useLDFlags } from '../../../../hooks/use-ld-flags';
import { correctPath } from 'libraries/gem';

const ReviewStep = () => {
  const history = useHistory();

  const { account } = useSession();

  const { candidateFacingInterviewDetails } = useLDFlags();

  const {
    completedStep,
    interviewTemplate,
  } = useNewInterviewTemplate();

  const createInterviewTemplateMutation = useCreateInterviewTemplate();

  const schema = useMemo<TableSchema<CreateInterviewTemplatePayload & WithId>>(() => [{
    header: 'Name',
    displayValue: ({ name }) => name,
  }, {
    header: 'Duration',
    displayValue: ({ duration_minutes }) => formatDuration(duration_minutes),
  }, account?.live_coding_type && {
    header: `${liveCodingLabels[account.live_coding_type]} Link`,
    displayValue: ({ live_coding_enabled }) => (
      live_coding_enabled ?
        <FontAwesomeIcon className="check" icon={faCheck} /> :
        <span className="empty">&mdash;</span>
    ),
  }, {
    header: 'Candidate-Facing Name',
    displayValue: ({ candidate_facing_name }) => (
      candidate_facing_name ?
        candidate_facing_name :
        <span className="empty">Default (the name of the interview)</span>
    ),
  }, candidateFacingInterviewDetails && {
    header: 'Candidate-Facing Details',
    displayValue: ({ candidate_facing_details }) => (
      candidate_facing_details ?
        <div dangerouslySetInnerHTML={{ __html: candidate_facing_details }} /> :
        <span className="empty">Not set</span>
    ),
  }, {
    header: 'Positions',
    displayValue: ({ positions }) => formatPositions(positions),
  }, {
    header: 'Time Window',
    displayValue: ({ time_window_start, time_window_end }) => formatTimeRange(time_window_start, time_window_end),
  }, {
    header: 'Interview Panel',
    displayValue: ({ interviewer_templates }) => (
      !interviewer_templates || isEmpty(interviewer_templates) ?
        <i>No interviewers</i> :
        <InterviewerTemplatesSummary interviewerTemplates={interviewer_templates} />
    ),
  }], [Boolean(account?.live_coding_type)]);

  if (completedStep < 2) {
    return <Redirect to={correctPath('/app/interview-templates/create/details')} />;
  }

  const handleNext = async () => {
    createInterviewTemplateMutation.reset();

    try {
      const data = await createInterviewTemplateMutation.mutateAsync({ payload: interviewTemplate });
      history.push(correctPath(`/app/interview-templates/${data.id}`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Breadcrumb
      data={{
        title: '3. Review',
        pathname: correctPath('/app/interview-templates/create/review'),
      }}
    >
      <MultiStepFormStep
        backLocation={correctPath('/app/interview-templates/create/interviewers')}
        isLastStep
        isSubmitting={createInterviewTemplateMutation.isLoading}
        nextButtonValue="Create Interview Template"
        onNext={handleNext}
      >
        <Helmet>
          <title>3. Review | Create Interview Template | InterviewPlanner</title>
        </Helmet>
        <Flash
          message={createInterviewTemplateMutation.error?.message}
          showFlash={createInterviewTemplateMutation.isError}
          type="danger"
        />
        <Table
          data={[interviewTemplate]}
          layout="horizontal"
          schema={schema}
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default ReviewStep;
