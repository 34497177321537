import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { capitalize, every, find, flatten, max, some, sumBy } from 'lodash';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import ATSScorecardIcon from './ATSScorecardIcon';
import CheckboxInput from 'components/library/inputs/CheckboxInput';
import DurationInput from 'components/library/inputs/DurationInput';
import FeedbackFormSelectInput from 'components/library/inputs/FeedbackFormSelectInput';
import Flash from 'components/library/utils/Flash';
import InterviewTemplateAdvancedSettings from 'components/library/inputs/InterviewTemplateAdvancedSettings';
import InterviewTemplateSelectInput from 'components/library/inputs/InterviewTemplateSelectInput';
import Label from 'components/library/utils/Label';
import MultiStepFormStep from 'components/library/inputs/MultiStepFormStep';
import OutboundLink from 'components/library/navigation/OutboundLink';
import StatusIndicator from 'components/library/utils/StatusIndicator';
import Table from 'components/library/data-display/Table';
import Tooltip from 'components/library/utils/Tooltip';
import { ATS, liveCodingLabels } from 'types';
import { AtsHrefType, constructAtsHref } from 'libraries/candidates';
import { MAX_ALLOWED_INTERVIEWS_FOR_MULTI_BLOCK_SCHEDULING } from '../types';
import { Step, useNewSchedule } from '../use-new-schedule';
import { formatDuration, formatList } from 'libraries/formatters';
import { formatMoment, TimeFormat } from 'libraries/time';
import { interviewTemplateParams } from 'hooks/queries/interview-templates';
import { useApplication } from 'hooks/queries/applications';
import { useSession } from 'hooks/use-session';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { ChangeEvent } from 'react';
import type { InterviewTemplate } from 'types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { StageInterview } from '../types';
import type { TableSchema } from 'components/library/data-display/Table';
import { correctPath } from 'libraries/gem';

const DURATION_SELECT_INPUT_NAME_PREFIX = 'duration-select-input-stage-interview-';
const FEEDBACK_FORM_SELECT_INPUT_NAME_PREFIX = 'feedback-form-select-input-stage-interview-';
const INTERVIEW_TEMPLATE_SELECT_INPUT_NAME_PREFIX = 'interview-template-select-input-stage-interview-';
const IN_SCHEDULE_CHECKBOX_INPUT_NAME_PREFIX = 'in-schedule-checkbox-input-stage-interview-';
const LIVE_CODING_ENABLED_CHECKBOX_INPUT_NAME_PREFIX = 'live-coding-enabled-checkbox-input-stage-interview-';
const CANDIDATE_FACING_NAME_SELECT_INPUT_NAME_PREFIX = 'candidate-facing-name-select-input-stage-interview-';
const POSITIONS_SELECT_INPUT_NAME_PREFIX = 'positions-select-input-stage-interview-';
const TIME_WINDOW_SELECT_INPUT_NAME_PREFIX = 'time-window-select-input-stage-interview-';

const InterviewPlanStep = () => {
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();

  const { account } = useSession();
  const application = useApplication(id).data!;

  const {
    schedule,
    setCompletedStep,
    setSchedule,
  } = useNewSchedule();

  const [error, setError] = useState<Error | null>(null);
  const [stageInterviews, setStageInterviews] = useState(schedule.stage_interviews);

  const handleHeaderInScheduleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStageInterviews((prev) => prev.map((stageInterview) => ({
      ...stageInterview,
      in_schedule: (stageInterview.ats_schedulable === null || stageInterview.ats_schedulable) && e.target.checked,
    })));
  };

  const handleIsInScheduleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const stageInterviewId = e.target.name.replace(IN_SCHEDULE_CHECKBOX_INPUT_NAME_PREFIX, '');

    setStageInterviews((prev) => prev.map((stageInterview) => ({
      ...stageInterview,
      in_schedule: stageInterview.id === stageInterviewId ? e.target.checked : stageInterview.in_schedule,
    })));
  };

  const handleInterviewTemplateChange = async (option: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => {
    setError(null);
    const stageInterviewId = actionMeta.name?.replace(INTERVIEW_TEMPLATE_SELECT_INPUT_NAME_PREFIX, '');
    let interviewTemplate: InterviewTemplate;

    if (option) {
      try {
        interviewTemplate = await queryClient.fetchQuery(interviewTemplateParams(option.value));
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        }
        return;
      }
    }

    setStageInterviews((prev) => prev.map((stageInterview) => {
      return {
        ...stageInterview,
        interview_template: (stageInterview.id === stageInterviewId ?
          (interviewTemplate ? {
            id: interviewTemplate.id,
            name: interviewTemplate.name,
            duration_minutes: interviewTemplate.duration_minutes,
            live_coding_enabled: interviewTemplate.live_coding_enabled,
            positions: interviewTemplate.positions,
            time_window_start: interviewTemplate.time_window_start,
            time_window_end: interviewTemplate.time_window_end,
            candidate_facing_name: interviewTemplate.candidate_facing_name,
            candidate_facing_details: interviewTemplate.candidate_facing_details,
            interviewer_templates: (interviewTemplate.interviewer_templates || []).map((interviewerTemplate) => ({
              description: interviewerTemplate.description,
              optional: interviewerTemplate.optional,
              include_past_interviewers: interviewerTemplate.include_past_interviewers,
              interviewer_filters: (interviewerTemplate.interviewer_filters || []).map((filter) => ({
                position: filter.position,
                interviewer_filter_expressions: (filter.interviewer_filter_expressions || []).map((exp) => ({
                  negated: exp.negated,
                  filterable_id: exp.filterable_id,
                  filterable_type: exp.filterable_type,
                })),
              })),
            })),
          } : {
            name: '',
            duration_minutes: stageInterview.ats_duration_minutes || 0,
            live_coding_enabled: false,
            positions: undefined,
            time_window_start: undefined,
            time_window_end: undefined,
            candidate_facing_name: undefined,
            candidate_facing_details: undefined,
            interviewer_templates: (stageInterview.ats_interviewer_ids || []).map((interviewerId) => ({
              optional: false,
              include_past_interviewers: true,
              interviewer_filters: [{
                position: 0,
                interviewer_filter_expressions: [{
                  negated: false,
                  filterable_id: interviewerId,
                  filterable_type: 'user',
                }],
              }],
            })),
          }) :
          stageInterview.interview_template
        ),
      };
    }));
  };

  const handleFeedbackFormChange = (option: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => {
    const stageInterviewId = actionMeta.name?.replace(FEEDBACK_FORM_SELECT_INPUT_NAME_PREFIX, '');
    const feedbackFormId = option?.value;

    setStageInterviews((prev) => prev.map((stageInterview) => ({
      ...stageInterview,
      feedback_form_id: stageInterview.id === stageInterviewId ? feedbackFormId : stageInterview.feedback_form_id,
    })));
  };

  const handleDurationChange = (duration: number, name?: string) => {
    const stageInterviewId = name?.replace(DURATION_SELECT_INPUT_NAME_PREFIX, '');

    setStageInterviews((prev) => prev.map((stageInterview) => ({
      ...stageInterview,
      interview_template: {
        ...stageInterview.interview_template,
        duration_minutes: stageInterview.id === stageInterviewId ? duration : stageInterview.interview_template.duration_minutes,
      },
    })));
  };

  const handleLiveCodingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    const stageInterviewId = e.target.name.replace(LIVE_CODING_ENABLED_CHECKBOX_INPUT_NAME_PREFIX, '');

    setStageInterviews((prev) => prev.map((stageInterview) => ({
      ...stageInterview,
      interview_template: {
        ...stageInterview.interview_template,
        live_coding_enabled: stageInterview.id === stageInterviewId ? e.target.checked : stageInterview.interview_template.live_coding_enabled,
      },
    })));
  };

  const handleCandidateFacingNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const stageInterviewId = e.target.name.replace(CANDIDATE_FACING_NAME_SELECT_INPUT_NAME_PREFIX, '');
    const newName = e.target.value;

    setStageInterviews((prev) => prev.map((stageInterview) => ({
      ...stageInterview,
      interview_template: {
        ...stageInterview.interview_template,
        candidate_facing_name: stageInterview.id === stageInterviewId ? newName : stageInterview.interview_template.candidate_facing_name,
      },
    })));
  };

  const handlePositionsChange = (option: OnChangeValue<Option<number>, true>, actionMeta: ActionMeta<Option<number>>) => {
    const stageInterviewId = actionMeta.name?.replace(POSITIONS_SELECT_INPUT_NAME_PREFIX, '');
    const newPositions = option.length === 0 ? undefined : option.map((option) => option.value);

    setStageInterviews((prev) => prev.map((stageInterview) => ({
      ...stageInterview,
      interview_template: {
        ...stageInterview.interview_template,
        positions: stageInterview.id === stageInterviewId ? newPositions : stageInterview.interview_template.positions,
      },
    })));
  };

  const handleWindowStartChange = (option: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => {
    const stageInterviewId = actionMeta.name?.replace(`start-${TIME_WINDOW_SELECT_INPUT_NAME_PREFIX}`, '');

    setStageInterviews((prev) => prev.map((stageInterview) => ({
      ...stageInterview,
      interview_template: {
        ...stageInterview.interview_template,
        time_window_start: stageInterview.id === stageInterviewId ? option?.value : stageInterview.interview_template.time_window_start,
      },
    })));
  };

  const handleWindowEndChange = (option: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => {
    const stageInterviewId = actionMeta.name?.replace(`end-${TIME_WINDOW_SELECT_INPUT_NAME_PREFIX}`, '');

    setStageInterviews((prev) => prev.map((stageInterview) => ({
      ...stageInterview,
      interview_template: {
        ...stageInterview.interview_template,
        time_window_end: stageInterview.id === stageInterviewId ? option?.value : stageInterview.interview_template.time_window_end,
      },
    })));
  };

  const setCandidateFacingDetails = (details: string, stageInterviewId: string) => {
    setStageInterviews((prev) => prev.map((stageInterview) => ({
      ...stageInterview,
      interview_template: {
        ...stageInterview.interview_template,
        candidate_facing_details: stageInterview.id === stageInterviewId ? details : stageInterview.interview_template.candidate_facing_details,
      },
    })));
  };

  const handleNext = () => {
    setSchedule((prev) => ({
      ...prev,
      stage_interviews: stageInterviews.map((stageInterview) => ({
        ...stageInterview,
        interview_template: stageInterview.interview_template ? {
          ...stageInterview.interview_template,
          candidate_facing_details: stageInterview.interview_template.candidate_facing_details && stageInterview.interview_template.candidate_facing_details !== '<br>' ? stageInterview.interview_template.candidate_facing_details : undefined,
        } : stageInterview.interview_template,
      })),
    }));
    setCompletedStep(Step.InterviewPlan + 1);
  };

  const previouslyScheduledInterviews = useMemo(() => application.active_schedules ? flatten(
    application.active_schedules.map(({ interviews, timezone }) => interviews.map(({ stage_interview_id, start_time }) => ({ stage_interview_id, date: formatMoment(Moment.tz(start_time, timezone), TimeFormat.LongDayOfWeekMonthDay) })))
  ) : [], [application.active_schedules]);

  const isConfirmingHeldSchedule = Boolean(schedule.id);

  const columns = useMemo<TableSchema<StageInterview>>(() => [{
    header: (
      <div data-for="schedule-all-tooltip" data-tip>
        <CheckboxInput
          isChecked={every(stageInterviews.filter((stageInterview) => stageInterview.ats_schedulable === null || stageInterview.ats_schedulable), 'in_schedule')}
          isDisabled={isConfirmingHeldSchedule}
          onChange={handleHeaderInScheduleChange}
        />
        <Tooltip
          id="schedule-all-tooltip"
          position="top"
          value={every(stageInterviews, 'in_schedule') ? 'Un-select all' : 'Select all'}
        />
      </div>
    ),
    displayEditValue: ({ ats_schedulable, id, in_schedule }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <CheckboxInput
          isChecked={in_schedule}
          isDisabled={isConfirmingHeldSchedule || (ats_schedulable !== null && !ats_schedulable)}
          name={`${IN_SCHEDULE_CHECKBOX_INPUT_NAME_PREFIX}${id}`}
          onChange={handleIsInScheduleChange}
        />
      </div>
    ),
  }, previouslyScheduledInterviews.length > 0 && {
    header: '',
    displayEditValue: ({ id }) => {
      const previousInterviews = previouslyScheduledInterviews.filter((interview) => id === interview.stage_interview_id);

      return (
        <div className={`previously-scheduled ${previousInterviews.length ? 'blue' : 'gray'}`}>
          <FontAwesomeIcon
            data-for={`${id}-scheduled-tooltip`}
            data-tip
            icon={faCalendarDay}
          />
          <Tooltip
            id={`${id}-scheduled-tooltip`}
            position="top"
            value={previousInterviews.length ? `Scheduled for ${formatList(previousInterviews.map(({ date }) => date))}` : 'Not scheduled'}
          />
        </div>
      );
    },
  }, {
    header: 'Interview',
    displayEditValue: ({ ats_schedulable, name }) => (
      <>
        {name}
        {ats_schedulable !== null && !ats_schedulable ?
          <Label
            color="gray"
            tooltip={
              <Tooltip
                id={`ats-unschedulable-label-${id}`}
                position="top"
                value={`You have not marked this interview as schedulable in ${capitalize(account?.ats_type)}.`}
              />
            }
          >
            Unschedulable
          </Label> :
          ''
        }
      </>
    ),
  }, {
    header: 'Interview Template',
    displayEditValue: ({ id, in_schedule }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <InterviewTemplateSelectInput
          isDisabled={!in_schedule || isConfirmingHeldSchedule}
          name={`${INTERVIEW_TEMPLATE_SELECT_INPUT_NAME_PREFIX}${id}`}
          onChange={handleInterviewTemplateChange}
          value={find(stageInterviews, ['id', id])?.interview_template.id}
        />
      </div>
    ),
  }, account?.ats_type === 'lever' && {
    header: 'Feedback Form',
    displayEditValue: ({ id, feedback_form_id }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <FeedbackFormSelectInput
          isDisabled={isConfirmingHeldSchedule}
          name={`${FEEDBACK_FORM_SELECT_INPUT_NAME_PREFIX}${id}`}
          onChange={handleFeedbackFormChange}
          selectedFeedbackFormId={feedback_form_id}
        />
      </div>
    ),
  }, {
    header: 'Duration',
    displayEditValue: ({ id, in_schedule, interview_template }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <DurationInput
          id={`${DURATION_SELECT_INPUT_NAME_PREFIX}${id}`}
          isAbbreviatedUnits
          isDisabled={!in_schedule || isConfirmingHeldSchedule}
          isRequired
          name={`${DURATION_SELECT_INPUT_NAME_PREFIX}${id}`}
          onChange={handleDurationChange}
          value={interview_template.duration_minutes}
        />
      </div>
    ),
  }, account?.live_coding_type && {
    header: `${liveCodingLabels[account.live_coding_type]} Link`,
    displayEditValue: ({ id, in_schedule, interview_template }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <CheckboxInput
          isChecked={interview_template.live_coding_enabled}
          isDisabled={!in_schedule || isConfirmingHeldSchedule}
          name={`${LIVE_CODING_ENABLED_CHECKBOX_INPUT_NAME_PREFIX}${id}`}
          onChange={handleLiveCodingEnabledChange}
        />
      </div>
    ),
  }, (account?.ats_type === ATS.Greenhouse || account?.ats_type === ATS.Gem) && {
    header: '',
    displayEditValue: ({ ats_id, ats_schedulable }) => (
      ats_schedulable && Boolean(ats_id) && <ATSScorecardIcon />
    ),
  }], [stageInterviews, previouslyScheduledInterviews, account?.ats_type, account?.live_coding_type]);

  const longestAvailability = max(schedule.availabilities.filter(({ end_time }) => Moment(end_time).isAfter(Moment())).map(({ start_time, end_time }) => Moment.duration(Moment(end_time).diff(Moment.max(Moment(start_time), Moment()))).asMinutes()));

  const hasAtsUnschedulableInterviews = useMemo(() => stageInterviews.some(({ ats_schedulable }) => ats_schedulable !== null && !ats_schedulable), [stageInterviews]);
  const sumOfInterviewDurations = sumBy(stageInterviews.filter(({ in_schedule }) => in_schedule), ({ interview_template }) => interview_template ? interview_template.duration_minutes : 0);

  const interviewsWithPreFilledAtsDurations = (stageInterviews || []).filter(({ ats_duration_minutes, ats_schedulable, interview_template }) => (
    !Boolean(interview_template.id) &&
    Boolean(ats_duration_minutes) &&
    (ats_schedulable === null || ats_schedulable) &&
    ats_duration_minutes === interview_template.duration_minutes)
  );

  const isSingleBlockRequired = stageInterviews.filter(({ in_schedule }) => in_schedule).length > MAX_ALLOWED_INTERVIEWS_FOR_MULTI_BLOCK_SCHEDULING;

  return (
    <Breadcrumb
      data={{
        title: '1. Interview Plan',
        pathname: correctPath(`/app/candidates/${id}/schedule/interview-plan`),
      }}
    >
      <MultiStepFormStep
        className="form-step-interview-plan"
        isFirstStep
        nextButtonIsDisabled={!application.candidate.email}
        nextButtonValue="Interviewers"
        nextLocation={correctPath(`/app/candidates/${id}/schedule/interviewers`)}
        onNext={handleNext}
      >
        <Helmet>
          <title>1. Interview Plan | Schedule {application.candidate.name || 'Unknown'} for {application.current_stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message={
            <span>
              This candidate doesn&apos;t have an email address associated to them, so we can&apos;t send calendar invitations or emails. You can add an email in <OutboundLink externalLinkIcon={false} href={constructAtsHref(account!, AtsHrefType.Candidate, { applicationAtsId: application.id, candidateAtsId: application.candidate.ats_id })} label="Add Email Warning">{capitalize(account?.ats_type)}</OutboundLink>.
            </span>
          }
          showFlash={!application.candidate.email}
          type="danger"
        />
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message={
            <span>
              This stage doesn&apos;t have any interviews configured. You can add interviews <Link to={constructAtsHref(account!, AtsHrefType.InterviewPlan, { jobAtsId: application.job.ats_id }) || correctPath(`/app/jobs/${application.job_id}/stages/${application.current_stage_id}/interviews`)}>here</Link>.
            </span>
          }
          showFlash={stageInterviews.length === 0}
          type="warning"
        />
        <Flash
          message="You have already scheduled all interviews for this candidate. You may schedule repeat interviews if you wish."
          showFlash={stageInterviews.length > 0 && every(stageInterviews, ({ id }) => previouslyScheduledInterviews.map(({ stage_interview_id }) => stage_interview_id).includes(id))}
          type="warning"
        />
        <Flash
          message={<span>The candidate is not available for {formatDuration(sumOfInterviewDurations)} straight. <Link to={correctPath(`/app/candidates/${application.id}/availabilities`)}>View their submitted availability here</Link>. Continue {isSingleBlockRequired ? 'if' : ' to proceed with multi-block scheduling or if'} you know other times they are available.</span>}
          showFlash={longestAvailability !== undefined && longestAvailability < sumOfInterviewDurations}
          type="warning"
        />
        <Flash
          message={
            <>
              <span>
                Select the interviews you would like to schedule. For each interview, modify the duration, positions, and time window if needed.
              </span>
              {interviewsWithPreFilledAtsDurations.length > 0 && (
                <span>
                  &nbsp;We have pre-filled durations from {capitalize(account?.ats_type)} for {interviewsWithPreFilledAtsDurations.length === 1 ? 'one interview without a template' : 'interviews without templates'}.
                </span>
              )}
              {previouslyScheduledInterviews.length > 0 &&
                <span>
                  &nbsp;<b>Interviews with a blue calendar icon have already been scheduled.</b>
                </span>
              }
            </>}
          showFlash={!isConfirmingHeldSchedule}
          type="info"
        />
        <Flash
          message={<span>These are settings for generating new schedules. Since you&apos;re confirming a held schedule, generating new schedules is disabled. However, you can still make changes to the duration and ordering of interviews on <Link to={correctPath(`/app/candidates/${application.id}/schedule/schedule${schedule.id ? `?schedule=${schedule.id}` : ''}`)}>the Schedule step</Link>.</span>}
          showFlash={isConfirmingHeldSchedule}
          type="warning"
        />
        <Table
          data={stageInterviews}
          displayExpandedContent={({ id, in_schedule, interview_template, name }) => (
            in_schedule ?
              <InterviewTemplateAdvancedSettings
                candidateFacingDetails={interview_template.candidate_facing_details}
                candidateFacingName={interview_template.candidate_facing_name}
                candidateFacingNameInputName={`${CANDIDATE_FACING_NAME_SELECT_INPUT_NAME_PREFIX}${id}`}
                duration={interview_template.duration_minutes}
                handleCandidateFacingNameChange={handleCandidateFacingNameChange}
                handlePositionsChange={handlePositionsChange}
                handleTimeWindowEndChange={handleWindowEndChange}
                handleTimeWindowStartChange={handleWindowStartChange}
                isDisabled={isConfirmingHeldSchedule}
                isExpanding={false}
                positionInputName={`${POSITIONS_SELECT_INPUT_NAME_PREFIX}${id}`}
                positions={interview_template.positions}
                setCandidateFacingDetails={(details: string) => {
                  setCandidateFacingDetails(details, id);
                }}
                stageInterviewName={name}
                timeWindowEnd={interview_template.time_window_end}
                timeWindowInputName={`${TIME_WINDOW_SELECT_INPUT_NAME_PREFIX}${id}`}
                timeWindowStart={interview_template.time_window_start}
              /> :
              null
          )}
          displayRowArrowLabel={({ interview_template }) => (
            <StatusIndicator
              color={Boolean((interview_template.positions && interview_template.positions.length > 0) || interview_template.time_window_start || interview_template.time_window_end || interview_template.candidate_facing_name || (interview_template.candidate_facing_details && interview_template.candidate_facing_details !== '<br>')) ? 'green' : 'gray'}
            >
              <span className="btn btn-small btn-no-outline">
                Advanced settings
              </span>
            </StatusIndicator>
          )}
          isEditing
          layout="vertical"
          schema={columns}
        />
        {hasAtsUnschedulableInterviews && account?.ats_type === ATS.Greenhouse &&
          <div className="helper-text">
            <OutboundLink
              href="https://support.gem.com/hc/en-us/articles/23491571522839-What-is-an-unschedulable-interview-in-Greenhouse"
              label="ATS Unschedulable Helper Text"
            >
              What is an unschedulable interview?
            </OutboundLink>
          </div>
        }
        <input
          checked={some(stageInterviews, 'in_schedule')}
          className="interview-plan-step-hidden-validation"
          name="interview"
          onChange={() => {}}
          required
          type="radio"
        />
        <Flash
          message={<span>We will only calculate single-block options for this schedule. Automated multi-block schedule generation is supported for up to {MAX_ALLOWED_INTERVIEWS_FOR_MULTI_BLOCK_SCHEDULING} interviews at a time.</span>}
          showFlash={isSingleBlockRequired}
          type="warning"
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default InterviewPlanStep;
