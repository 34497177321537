import Moment from 'moment-timezone';
import { isEmpty, keyBy, pick, sortBy } from 'lodash';

import { sortPositions } from '../../../../../libraries/interview-templates';

import type { Account, Application } from '../../../../../types';
import type { Block, Schedule, SelectedSchedule } from './types';
import type { CreateSchedulePayload, UpdateSchedulePayload } from '../../../../../hooks/queries/schedules';
import type { RoomMap } from '../../../../../hooks/queries/rooms';
import type { TokensPayloadSchedule } from '../../../../../hooks/queries/tokens';
import type { UserMap } from '../../../../../hooks/queries/users';

export const constructScheduleTokens = (application: Application, schedule: Schedule, blocks: Block[], rooms: RoomMap, users: UserMap): TokensPayloadSchedule[] | undefined => {
  if (isEmpty(blocks) || !application.current_stage) {
    // This shouldn't ever really happen, it's just if the user refreshes the
    // page while on the step that calls this function.
    return undefined;
  }
  const stageInterviews = keyBy(application.current_stage.stage_interviews, 'id');

  return blocks.map((block): TokensPayloadSchedule => ({
    application: {
      ats_id: application.ats_id,
      candidate: {
        ats_id: application.candidate.ats_id,
        email: application.candidate.email,
        name: application.candidate.name,
        pronouns: application.candidate.pronouns || undefined,
        pronunciation_url: application.candidate.pronunciation_url || undefined,
        resume_url: application.candidate.resume_url || undefined,
        phone_number: application.candidate.phone_number,
        linkedin_url: application.candidate.linkedin_url,
      },
      coordinator: application.coordinator_id && users[application.coordinator_id] ? {
        email: users[application.coordinator_id].email,
        name: users[application.coordinator_id].name,
        phone_number: users[application.coordinator_id].phone_number,
      } : undefined,
      hiring_manager: application.hiring_manager_id && users[application.hiring_manager_id] ? {
        email: users[application.hiring_manager_id].email,
        name: users[application.hiring_manager_id].name,
        phone_number: users[application.hiring_manager_id].phone_number,
      } : undefined,
      recruiter: application.recruiter_id && users[application.recruiter_id] ? {
        email: users[application.recruiter_id].email,
        name: users[application.recruiter_id].name,
        phone_number: users[application.recruiter_id].phone_number,
      } : undefined,
      sourcer: application.sourcer_id && users[application.sourcer_id] ? {
        email: users[application.sourcer_id].email,
        name: users[application.sourcer_id].name,
        phone_number: users[application.sourcer_id].phone_number,
      } : undefined,
      current_stage: {
        job: {
          name: application.current_stage!.job.name,
          post_name: application.current_stage!.job.post_name,
        },
      },
      office: application.office ? {
        name: application.office.name,
      } : undefined,
    },
    stage: {
      name: application.current_stage!.name,
      stage_interviews: application.current_stage!.stage_interviews?.filter((stageInterview) => !stageInterview.inline && !stageInterview.deleted).map((stageInterview) => ({
        name: stageInterview.name,
        position: stageInterview.position,
        interview_template: stageInterview.interview_template ? {
          duration_minutes: stageInterview.interview_template.duration_minutes,
          candidate_facing_name: stageInterview.interview_template.candidate_facing_name,
          candidate_facing_details: stageInterview.interview_template.candidate_facing_details,
        } : undefined,
      })),
    },
    timezone: schedule.timezone,
    candidate_timezone: schedule.candidate_timezone,
    candidate_event_location: schedule.schedule_template.video_conferencing_enabled ? (block.video_conferencing_link && (!block.selected_zoom_host || block.current_zoom_host_id === block.selected_zoom_host.id) ? block.video_conferencing_link : undefined) : schedule.schedule_template.candidate_calendar_event_template?.location,
    // The interviews need to be sorted by the start time after they've been
    // converted to Moment and normalized into ISO8601 because if an interview
    // is manually added on the schedule step, it might be in a different
    // timezone from the rest of interviews, so just sorting them as strings
    // won't always work correctly.
    interviews: sortBy(block.interviews, [({ start_time }) => Moment(start_time).format()]).map((interview) => ({
      start_time: interview.start_time,
      feedback_form_id: interview.feedback_form_id,
      video_conferencing_passcode: interview.video_conferencing_passcode,
      interview_template: {
        duration_minutes: interview.interview_template.duration_minutes,
        live_coding_enabled: interview.interview_template.live_coding_enabled,
        candidate_facing_name: interview.interview_template.candidate_facing_name,
        candidate_facing_details: interview.interview_template.candidate_facing_details,
      },
      room: block.selected_room?.room_id && rooms[block.selected_room.room_id] ? {
        name: rooms[block.selected_room.room_id].name,
      } : undefined,
      stage_interview: {
        name: stageInterviews[interview.stage_interview_id].name,
        ats_id: stageInterviews[interview.stage_interview_id].ats_id,
        greenhouse_interview_kit_id: stageInterviews[interview.stage_interview_id].greenhouse_interview_kit_id,
      },
      interviewers: interview.interviewers.map((interviewer) => ({
        user: {
          id: interviewer.selected_user.user_id!,
          email: users[interviewer.selected_user.user_id!].email,
          name: users[interviewer.selected_user.user_id!].name,
          linkedin_url: users[interviewer.selected_user.user_id!].linkedin_url,
          phone_number: users[interviewer.selected_user.user_id!].phone_number,
          title: users[interviewer.selected_user.user_id!].title,
        },
        interviewer_template: {
          optional: interviewer.interviewer_template.optional,
          interviewer_filters: interviewer.interviewer_template.interviewer_filters.map((filter) => ({
            interviewer_filter_expressions: filter.interviewer_filter_expressions.map((exp) => ({
              negated: exp.negated,
              filterable_id: exp.filterable_id,
              filterable_type: exp.filterable_type,
            })),
          })),
        },
      })),
    })),
    schedule_template: {
      candidate_event_location: schedule.schedule_template.candidate_calendar_event_template?.location,
      video_conferencing_enabled: schedule.schedule_template.video_conferencing_enabled,
      onsite: schedule.schedule_template.onsite,
    },
  }));
};

export const constructScheduleUpdatePayloads = ({ blocks, schedule_template }: SelectedSchedule): UpdateSchedulePayload[] => {
  const emailTemplateType = blocks.length > 1 ? 'multi_block_confirmation_email_template' : 'confirmation_email_template';
  const emailTemplate = schedule_template[emailTemplateType];
  return blocks.map((block) => ({
    confirming_held_schedule: true,
    schedule_template: {
      candidate_event_title: schedule_template.candidate_calendar_event_template?.title,
      candidate_event_location: schedule_template.video_conferencing_enabled ? undefined : schedule_template.candidate_calendar_event_template?.location,
      candidate_event_description: schedule_template.candidate_calendar_event_template?.description,
      candidate_event_additional_attendees: schedule_template.candidate_calendar_event_template?.additional_attendees,
      candidate_event_additional_optional_attendees: schedule_template.candidate_calendar_event_template?.additional_optional_attendees,
      video_conferencing_enabled: schedule_template.video_conferencing_enabled,
      [emailTemplateType]: {
        enabled: Boolean(emailTemplate),
        ...(emailTemplate ? {
          id: emailTemplate.id,
          subject: emailTemplate.subject,
          body: emailTemplate.body,
          attachments: emailTemplate.attachments || [],
          cc_emails: emailTemplate.cc_emails,
          bcc_emails: emailTemplate.bcc_emails,
          sender_email: emailTemplate.sender_email,
          sender_name: emailTemplate.sender_name || emailTemplate.sender_email,
        } : {}),
      },
    },
    interviews: block.interviews.map((interview) => ({
      id: interview.id?.startsWith('new-interview') ? undefined : interview.id,
      interview_template: {
        name: interview.interview_template.name || 'Interview',
        duration_minutes: interview.interview_template.duration_minutes,
        live_coding_enabled: interview.interview_template.live_coding_enabled,
        candidate_facing_name: interview.interview_template.candidate_facing_name,
        candidate_facing_details: interview.interview_template.candidate_facing_details,
      },
      room_id: block.selected_room?.room_id!,
      zoom_host_id: block.selected_zoom_host?.id!,
      zoom_host_type: block.selected_zoom_host?.type!,
      stage_interview_id: interview.stage_interview_id,
      feedback_form_id: interview.feedback_form_id,
      start_time: interview.start_time,
      interviewers: interview.interviewers.map((interviewer) => ({
        id: interviewer.id,
        interviewer_template: {
          description: interviewer.interviewer_template.description,
          optional: interviewer.interviewer_template.optional,
          interviewer_filters: (interviewer.interviewer_template.interviewer_filters || []).map(({ interviewer_filter_expressions }) => ({
            interviewer_filter_expressions: (interviewer_filter_expressions || []).map((expression) => pick(expression, ['negated', 'filterable_id', 'filterable_type'])),
          })),
        },
        user_id: interviewer.selected_user.user_id!,
      })),
    })),
  }));
};

export const constructSchedulePayload = (schedules: SelectedSchedule[], hold: boolean, { video_conferencing_type }: Account, deleteHeldSchedules: boolean = false): CreateSchedulePayload => {
  // If you expect more values here, make sure you update selectedSchedules in
  // ReviewStep.
  const { application_id, timezone, candidate_timezone, schedule_template, scheduling_calendar_email, candidate_scheduling_calendar_email } = schedules[0];
  return {
    application_id,
    send_interviewer_notifications: !hold,
    timezone,
    candidate_timezone,
    scheduling_calendar_email,
    candidate_scheduling_calendar_email: candidate_scheduling_calendar_email || scheduling_calendar_email,
    hold,
    delete_held_schedules: deleteHeldSchedules,
    schedule_template: {
      business_hours: schedule_template.business_hours.map((bh) => ({
        day: bh.day,
        start_time: bh.start_time || '00:00',
        end_time: bh.end_time || '24:00',
        // All business hours should have a timezone by this point.
        timezone: bh.timezone!,
      })),
      candidate_event_title: schedule_template.candidate_calendar_event_template!.title,
      candidate_event_location: schedule_template.video_conferencing_enabled ? undefined : schedule_template.candidate_calendar_event_template!.location,
      candidate_event_description: schedule_template.candidate_calendar_event_template!.description,
      candidate_event_additional_attendees: schedule_template.candidate_calendar_event_template!.additional_attendees,
      candidate_event_additional_optional_attendees: schedule_template.candidate_calendar_event_template!.additional_optional_attendees,
      confirmation_email_template: (schedule_template.confirmation_email_template ? {
        id: schedule_template.confirmation_email_template.id,
        name: schedule_template.confirmation_email_template.name,
        subject: schedule_template.confirmation_email_template.subject,
        body: schedule_template.confirmation_email_template.body,
        attachments: schedule_template.confirmation_email_template.attachments || [],
        cc_emails: schedule_template.confirmation_email_template.cc_emails,
        bcc_emails: schedule_template.confirmation_email_template.bcc_emails,
        sender_email: schedule_template.confirmation_email_template.sender_email,
        sender_name: schedule_template.confirmation_email_template.sender_name || schedule_template.confirmation_email_template.sender_email,
      } : undefined),
      multi_block_confirmation_email_template: (schedule_template.multi_block_confirmation_email_template ? {
        id: schedule_template.multi_block_confirmation_email_template.id,
        name: schedule_template.multi_block_confirmation_email_template.name,
        subject: schedule_template.multi_block_confirmation_email_template.subject,
        body: schedule_template.multi_block_confirmation_email_template.body,
        attachments: schedule_template.multi_block_confirmation_email_template.attachments || [],
        cc_emails: schedule_template.multi_block_confirmation_email_template.cc_emails,
        bcc_emails: schedule_template.multi_block_confirmation_email_template.bcc_emails,
        sender_email: schedule_template.multi_block_confirmation_email_template.sender_email,
        sender_name: schedule_template.multi_block_confirmation_email_template.sender_name || schedule_template.multi_block_confirmation_email_template.sender_email,
      } : undefined),
      onsite: schedule_template.onsite,
      mark_interviewer_events_as_private: schedule_template.mark_interviewer_events_as_private,
      mark_candidate_events_as_private: schedule_template.mark_candidate_events_as_private,
      room_filters: isEmpty(schedule_template.room_filters) ? undefined : schedule_template.room_filters?.map(({ room_filter_expressions }) => ({
        room_filter_expressions: room_filter_expressions.map(({ filterable_id, filterable_type, negated }) => ({
          negated,
          filterable_id,
          filterable_type,
        })),
      })),
      video_conferencing_enabled: schedule_template.video_conferencing_enabled,
      create_hiring_channel: schedule_template.create_hiring_channel,
      zoom_host_filters: schedule_template.video_conferencing_enabled && video_conferencing_type === 'zoom' ? (schedule_template.zoom_host_filters || []).map(({ zoom_host_filter_expressions }) => ({
        zoom_host_filter_expressions: zoom_host_filter_expressions.map(({ filterable_id, filterable_type, negated }) => ({
          negated,
          filterable_id,
          filterable_type,
        })),
      })) : undefined,
    },
    schedules: schedules[0].id ? (
      // For confirming a schedule on hold
      schedules[0].blocks.map((block) => ({
        id: block.schedule_id,
      }))
    ) :
      // For creating new schedules
      schedules.map(({ id, blocks }) => ({
        id,
        blocks: blocks.map((block) => ({
          room_id: block.selected_room ? block.selected_room.room_id : null,
          zoom_host_id: block.selected_zoom_host ? block.selected_zoom_host.id : null,
          zoom_host_type: block.selected_zoom_host ? block.selected_zoom_host.type : null,
          interviews: block.interviews.map((interview) => ({
            interview_template: {
              name: interview.interview_template.name,
              duration_minutes: interview.interview_template.duration_minutes,
              live_coding_enabled: interview.interview_template.live_coding_enabled,
              positions: (interview.interview_template.positions || []).length > 0 ? sortPositions(interview.interview_template.positions) : undefined,
              time_window_start: interview.interview_template.time_window_start,
              time_window_end: interview.interview_template.time_window_end,
              candidate_facing_name: interview.interview_template.candidate_facing_name,
              candidate_facing_details: interview.interview_template.candidate_facing_details,
            },
            stage_interview_id: interview.stage_interview_id,
            feedback_form_id: interview.feedback_form_id || null,
            start_time: interview.start_time,
            interviewers: interview.interviewers.map((interviewer) => ({
              interviewer_template: {
                ...pick(interviewer.interviewer_template, ['description', 'optional']),
                interviewer_filters: (interviewer.interviewer_template.interviewer_filters || []).map(({ interviewer_filter_expressions }) => ({
                  interviewer_filter_expressions: (interviewer_filter_expressions || []).map((expression) => pick(expression, ['negated', 'filterable_id', 'filterable_type'])),
                })),
              },
              user_id: interviewer.selected_user.user_id,
            })),
          })),
        })),
      })),
  };
};
