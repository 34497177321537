import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { pullAt } from 'lodash';

import Button from '../Button';
import Tooltip from '../../utils/Tooltip';
import { StyledDateRangeContainer, StyledDateRangeInput } from './styles';

import type { Dispatch, SetStateAction } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as DateRangeOption } from 'components/library/inputs/DateRangeInput/types';
import type { EditableAllowedTime } from 'types';

interface Props {
  allowedTimes: EditableAllowedTime[];
  helperText?: string;
  label?: string;
  setAllowedTimes: Dispatch<SetStateAction<EditableAllowedTime[]>>;
}

const AllowedTimesInput = ({
  allowedTimes,
  helperText,
  label,
  setAllowedTimes,
}: Props) => {
  const handleAddDateRange = () => {
    setAllowedTimes([...allowedTimes, {
      start_time: '',
      end_time: '',
    }]);
  };

  const handleRemoveDateRange = (idx: number) => {
    const allowedTimesCopy = allowedTimes!.slice();
    pullAt(allowedTimesCopy, [idx]);
    setAllowedTimes(allowedTimesCopy);
  };

  const handleDateRangeChange = (option: OnChangeValue<DateRangeOption, false>, idx: number) => {
    setAllowedTimes((prev) => prev.map((allowedTime, i) => (
      i === idx ? {
        start_time: option?.startDate || '',
        end_time: option?.endDate || '',
      } : allowedTime
    )));
  };

  return (
    <div className="input">
      {label && <label>{label}</label>}
      {allowedTimes.map(({ start_time, end_time }, i) => (
        <StyledDateRangeContainer key={`allowed-times-${i}`}>
          <StyledDateRangeInput
            allowEmptyEndDate={false}
            isAlwaysCustom
            onChange={(option) => handleDateRangeChange(option, i)}
            placeholder={i === 0 && allowedTimes.length === 1 ? 'Any dates' : ''}
            selectedDateRange={{
              value: 'custom',
              startDate: start_time,
              endDate: end_time,
            }}
          />
          {allowedTimes.length > 1 && (
            <Button
              className="btn-delete"
              color="gray"
              iconRight={<FontAwesomeIcon icon={faTrashCan} />}
              onClick={() => handleRemoveDateRange(i)}
              tooltip={
                <Tooltip
                  id={`${i}-remove-date-range`}
                  position="top"
                  value="Remove dates"
                />
              }
            />
          )}
        </StyledDateRangeContainer>
      ))}
      {allowedTimes?.[0]?.start_time && (
        <div>
          <Button
            color="no-outline"
            iconLeft={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => handleAddDateRange()}
            value="Add more dates"
          />
        </div>
      )}
      {helperText && <div className="helper-text">{helperText}</div>}
    </div>
  );
};

export default AllowedTimesInput;
