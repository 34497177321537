import { orderBy } from 'lodash';

import { formatDateRange } from 'libraries/formatters';

import type { EditableAllowedTime } from 'types';

interface Props {
  allowedTimes: EditableAllowedTime[];
  timezone?: string;
}

const AllowedTimesSummary = ({ allowedTimes, timezone }: Props) => {
  if (allowedTimes.length === 0) {
    return <>Any dates</>;
  }

  return (
    <div>
      {orderBy(allowedTimes, ['start_time', 'end_time']).map(({ start_time, end_time }, i) =>
        <div key={`allowed-time-${i}`}>{formatDateRange(start_time, end_time, timezone)}</div>
      )}
    </div>
  );
};

export default AllowedTimesSummary;
