import Moment from 'moment-timezone';
import pluralize from 'pluralize';
import { capitalize, groupBy, isEqual, join, trim, uniq } from 'lodash';

import { businessHourDayOrdering, defaultDays } from '../types/business-hours';
import { formatMoment, TimeFormat } from './time';
import { sortBusinessHours } from './business-hours';

import type { EditableBusinessHour } from '../types/business-hours';
import type { Pronouns } from '../types';

const NUMBERS_TO_WORDS_TRANSLATION: { [key: number]: string } = {
  0: 'no',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
};

// Source: https://gist.github.com/lanqy/5193417
export function formatBytes (bytes: number): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Bytes';
  if (bytes === 1) return '1 Byte';
  const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`, 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
}

export function formatDateRange (start?: string, end?: string, timezone?: string): string {
  const momentStart = Moment(start).tz(timezone || Moment.tz.guess());
  const momentEnd = Moment(end).tz(timezone || Moment.tz.guess());
  if (momentStart.isSame(momentEnd, 'day')) {
    return formatMoment(momentStart, TimeFormat.LongMonthDayYear);
  }
  const isSameYear = momentStart.isSame(momentEnd, 'year');
  const startDate = start ? formatMoment(momentStart, isSameYear ? TimeFormat.LongMonthDay : TimeFormat.LongMonthDayYear) : ' ';
  const endDate = end ? formatMoment(momentEnd, TimeFormat.LongMonthDayYear) : ' ';
  return `${startDate}–${endDate}`;
}

export function formatDate (date: string, timezone: string): string {
  const localizedDate = Moment.tz(date, timezone);
  const now = Moment();

  const isThisYear = localizedDate.isSame(now, 'year');
  return formatMoment(localizedDate, isThisYear ? TimeFormat.LongDayOfWeekMonthDay : TimeFormat.LongDayOfWeekMonthDayYear);
}

export function formatDateTimeRange (start: string | Moment.Moment | Date, end: string | Moment.Moment | Date, timezone: string): string {
  const localizedStart = Moment.tz(start, timezone);
  const localizedEnd = Moment.tz(end, timezone);
  const now = Moment();

  const isThisYear = localizedStart.isSame(now, 'year') && localizedEnd.isSame(now, 'year');

  const formattedStart = formatMoment(localizedStart, isThisYear ? TimeFormat.LongDayOfWeekMonthDayWithTimeAndComma : TimeFormat.LongDayOfWeekMonthDayYearWithTimeAndComma);
  let formattedEnd;
  if (localizedStart.isSame(localizedEnd, 'day')) {
    formattedEnd = formatMoment(localizedEnd, TimeFormat.TimeWithTimezone);
  } else {
    formattedEnd = formatMoment(localizedEnd, isThisYear ? TimeFormat.LongDayOfWeekMonthDayWithTimeAndTimezoneAndComma : TimeFormat.LongDayOfWeekMonthDayYearWithTimeAndTimezoneAndComma);
  }

  return `${formattedStart} \u2013 ${formattedEnd}`;
}

export function formatDuration (durationMinutes: number | string, useAbbreviatedUnits: boolean = false): string {
  const duration = Moment.duration(durationMinutes, 'minutes');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  const hourUnits = useAbbreviatedUnits ? 'h' : `hour${hours !== 1 ? 's' : ''}`;
  const minuteUnits = useAbbreviatedUnits ? 'min' : `minute${minutes !== 1 ? 's' : ''}`;

  const formattedHours = hours ? `${hours} ${hourUnits}` : '';
  const formattedMinutes = minutes ? `${minutes} ${minuteUnits}` : (hours ? '' : `0 ${minuteUnits}`);

  return trim(join([formattedHours, formattedMinutes], ' '));
}

export function formatInterviewLimit (limit: number = -1, suffix: string = ''): string {
  if (suffix && suffix[0] !== ' ') {
    suffix = ` ${suffix}`;
  }

  if (limit === -1) {
    return `Unlimited interviews${suffix}`;
  }

  return pluralize('interview', limit, true) + suffix;
}

// formatList takes in an array of strings and returns the list of items using
// proper grammar.
export function formatList (list: (string | number)[], conjunction: string = 'and'): string {
  switch (list.length) {
    case 0:
      return '';
    case 1:
      return `${list[0]}`;
    case 2:
      return list.join(` ${conjunction} `);
    default:
      return `${list.slice(0, -1).join(', ')}, ${conjunction} ${list[list.length - 1]}`;
  }
}

export function formatNumberAsWord (number: number): string {
  return NUMBERS_TO_WORDS_TRANSLATION[number] || `${number}`;
}

export function formatPosition (positionNumber: number | null | undefined): string {
  switch (positionNumber) {
    case null:
    case undefined:
    case 0:
      return 'Any';
    case 1:
      return 'First';
    case 2:
      return 'Second';
    case 3:
      return 'Third';
    case 4:
      return 'Fourth';
    case 5:
      return 'Fifth';
    case 6:
      return 'Sixth';
    case -6:
      return 'Sixth to last';
    case -5:
      return 'Fifth to last';
    case -4:
      return 'Fourth to last';
    case -3:
      return 'Third to last';
    case -2:
      return 'Second to last';
    case -1:
      return 'Last';
    default:
      return '';
  }
}

export function formatPositions (positions: number[] | undefined): string {
  if (!positions || positions.length === 0) {
    return 'Any';
  }
  return capitalize(formatList(positions.map((position) => formatPosition(position).toLowerCase()), 'or'));
}

export function formatPronouns (pronouns: Pronouns | null): string {
  if (!pronouns) {
    return '';
  }

  const parts: string[] = [];
  if (pronouns.he_him) {
    parts.push('he/him');
  }
  if (pronouns.she_her) {
    parts.push('she/her');
  }
  if (pronouns.they_them) {
    parts.push('they/them');
  }
  if (pronouns.xe_xem) {
    parts.push('xe/xem');
  }
  if (pronouns.ze_hir) {
    parts.push('ze/hir');
  }
  if (pronouns.ey_em) {
    parts.push('ey/em');
  }
  if (pronouns.hir_hir) {
    parts.push('hir/hir');
  }
  if (pronouns.fae_faer) {
    parts.push('fae/faer');
  }
  if (pronouns.hu_hu) {
    parts.push('hu/hu');
  }
  if (pronouns.self_describe) {
    parts.push(pronouns.self_describe);
  }

  return parts.join(', ');
}

export function formatTime (time?: string | Moment.Moment, inputFormat: string = 'HH:mm', outputFormat: TimeFormat = TimeFormat.Time, timezone?: string): string {
  if (!time) {
    return '';
  }
  if (timezone) {
    // The types for Moment.tz thinks that it can't handle passing in a Moment
    // object with an input format, but it seems to be able to handle it just
    // fine (see tests). So we just cast it as a string, so it won't complain
    // anymore.
    return formatMoment(Moment.tz(time as string, inputFormat, timezone), outputFormat);
  }
  return formatMoment(Moment(time, inputFormat), outputFormat);
}

export function formatTimeRange (start?: string | Moment.Moment, end?: string | Moment.Moment, timezone?: string, capitalize: boolean = true): string {
  if ((!start || start === '00:00') && (!end || end === '24:00')) {
    if (capitalize) {
      return 'Any';
    }
    return 'any';
  }
  if (start && (!end || end === '24:00')) {
    return `${capitalize ? 'After' : 'after'} ${formatTime(start, 'HH:mm', timezone ? TimeFormat.TimeWithTimezone : TimeFormat.Time, timezone)}`;
  }
  if ((!start || start === '00:00') && end) {
    return `${capitalize ? 'Before' : 'before'} ${formatTime(end, 'HH:mm', timezone ? TimeFormat.TimeWithTimezone : TimeFormat.Time, timezone)}`;
  }

  return `${formatTime(start)} \u2013 ${formatTime(end, 'HH:mm', timezone ? TimeFormat.TimeWithTimezone : TimeFormat.Time, timezone)}`;
}

export function formatBusinessHoursForWeekday (allBusinessHours: EditableBusinessHour[], defaultTimezone: string | undefined, weekday: number): string {
  // This could definitely be better e.g. not repeating the timezone if it's the same for every window.
  const businessHoursForDay = allBusinessHours.filter((bh) => businessHourDayOrdering[bh.day] === weekday && (bh.timezone || defaultTimezone));
  if (businessHoursForDay.length === 0) {
    // This day doesn't have any valid windows.
    return uniq(allBusinessHours.map((bh) => capitalize(bh.day))).join(', ');
  }
  return uniq(businessHoursForDay
  .map((bh) => {
    const start = bh.start_time === '00:00' ? undefined : bh.start_time;
    const end = bh.end_time === '24:00' ? undefined : bh.end_time;
    return formatTimeRange(start, end, bh.timezone || defaultTimezone);
  }))
  .join(', ');
}

export function formatBusinessHours (businessHours: EditableBusinessHour[]): string {
  // Sort the business hours so that the ordering will be as expected.
  businessHours = sortBusinessHours(businessHours);

  const groupedHours = groupBy(businessHours, (bh) => `${bh.start_time}-${bh.end_time}-${bh.timezone}`);
  const keys = Object.keys(groupedHours);
  const days = uniq(businessHours.map((bh) => bh.day));

  if (keys.length === 1) {
    // All the business hours are the same.
    if (keys[0].startsWith('00:00-24:00-')) {
      // All the business hours are entire day windows.
      if (isEqual(days, defaultDays)) {
        return 'all weekdays';
      }
      return formatList(days.map((day) => capitalize(day)));
    }
    let daysString: string;
    if (isEqual(days, defaultDays)) {
      daysString = 'all weekdays';
    } else {
      daysString = formatList(days.map((day) => capitalize(day)));
    }
    return `${daysString} (${formatTimeRange(businessHours[0].start_time, businessHours[0].end_time, businessHours[0].timezone || undefined, false)})`;
  }
  // We're currently not handling how it should look if it's not a single consistent window. Instead, we will check if
  // there is a "special" formatting determined by this function, and if not, we'll just create a bulleted list using
  // formatBusinessHoursForWeekday.
  return '';
}
