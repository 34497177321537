import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import InterviewersSection from './InterviewersSection';
import SummarySection from './SummarySection';
import { useInterviewTemplate } from '../../../../../hooks/queries/interview-templates';
import { correctPath } from 'libraries/gem';

const InterviewTemplateDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { data: interviewTemplate } = useInterviewTemplate(id);

  return (
    <Breadcrumb
      data={{
        title: 'Details',
        pathname: correctPath(`/app/interview-templates/${id}/details`),
      }}
    >
      <div className="interview-template-details-container">
        <Helmet>
          <title>Details | {interviewTemplate?.name} | InterviewPlanner</title>
        </Helmet>
        <SummarySection />
        <InterviewersSection />
      </div>
    </Breadcrumb>
  );
};

export default InterviewTemplateDetails;
