import { Helmet } from 'react-helmet-async';

import OutboundLink from 'components/library/navigation/OutboundLink';

const Privacy = () => {
  return (
    <div className="privacy-container">
      <Helmet>
        <title>Privacy Policy | InterviewPlanner</title>
      </Helmet>
      <h1>
        Privacy Policy
      </h1>
      <p>
        <b>Effective:</b> May 31, 2024
      </p>
      <p>
        InterviewPlanner, LLC (&quot;<b>InterviewPlanner</b>&quot;, &quot;<b>we</b>&quot;, or &quot;<b>us</b>&quot;) understands how important privacy is. We want to make sure that we&apos;re being mindful of both our customers&apos; data and their candidates&apos; data. We want you to feel confident in how we manage your data, so this Privacy Policy (&quot;<b>Policy</b>&quot;) outlines how we collect, use, and share your information as you use our website and our service (&quot;<b>Platform</b>&quot;).
      </p>
      <h2 id="information-we-collect">
        Information We Collect
      </h2>
      <p>
        When you interact with our Platform, we may collect data relating to an identified or identifiable individual (&quot;<b>Personal Information</b>&quot;), such as a name, address, email address, or phone number.
      </p>
      <h3 id="information-you-provide-voluntarily">
        Information You Provide Voluntarily
      </h3>
      <ul>
        <li>
          Your organization&apos;s members&apos; Personal Information
          <ul>
            <li>
              When you sign up for InterviewPlanner, it connects your directory/calendar systems (e.g., GSuite) so that we can continuously sync and store the names and email addresses of members of your organization.
            </li>
            <li>
              OAuth2 tokens are encrypted and stored for users that directly log into the Platform i.e., if an employee never logs in, we will not have their OAuth2 token.
            </li>
            <li>
              Calendar event and availability information is pulled from your calendar system at the time of scheduling an interview and is not stored by InterviewPlanner.
            </li>
            <li>
              Email communication with candidates is pulled from your email system when viewing candidate history and is not stored by InterviewPlanner.
            </li>
            <li>
              If connected, Zoom meeting information is pulled from your account at the time of scheduling an interview and is not stored by InterviewPlanner.
            </li>
          </ul>
        </li>
        <li>
          Your candidates&apos; Personal Information
          <ul>
            <li>
              When you connect your Applicant Tracking System (&quot;<b>ATS</b>&quot;) by entering an ATS API key that we encrypt and store, we continuously sync and store names, links to personal websites, resumes, email addresses, and phone numbers of your candidates. Candidates may additionally voluntarily provide their gender pronouns and audio pronunciation of their name.
            </li>
          </ul>
        </li>
      </ul>
      <h3 id="information-we-collect-automatically">
        Information We Collect Automatically
      </h3>
      <ul>
        <li>
          Log information
          <ul>
            <li>
              As you use the Platform, we collect information about how you access it. This can include (but is not limited to) IP addresses, browser information, device information, network information, webpages you were on before visiting the Platform, and pages you viewed while using the Platform.
            </li>
          </ul>
        </li>
      </ul>
      <h2 id="how-we-use-your-information">
        How We Use Your Information
      </h2>
      <ul>
        <li>
          Provide the value of the Platform
          <ul>
            <li>
              Most of the information that we collect is for the sole purpose of performing the services that you expect from us.
            </li>
            <li>
              Your organization&apos;s members&apos; Personal Information is used to create interview pools and manage how interviews are scheduled.
            </li>
            <li>
              OAuth2 tokens are encrypted and stored so that we can query your calendar system, email system, and video conferencing system to determine availability and potential conflicts at the time of scheduling.
            </li>
            <li>
              Candidate information is stored so that we can offer our services in a more reliable and performant way without having to be dependent on the uptime of your ATS.
            </li>
          </ul>
        </li>
        <li>
          Improve the Platform
          <ul>
            <li>
              Log information is used to debug issues and improve the services that we offer on the Platform.
            </li>
          </ul>
        </li>
        <li>
          Communicate with users and candidates
          <ul>
            <li>
              We use names and email addresses to be able to communicate with you and other members of your organization. This can be about changes to the Platform, issues with your account, or notifications of actions to be taken on the Platform.
            </li>
            <li>
              We use names and email addresses to be able to communicate with your candidates and keep them informed about calendar invites and any communication you want to relay to them.
            </li>
            <li>
              We use permissions granted from your email system to be able to send emails to your candidates and keep all communication with them in a centralized location.
            </li>
          </ul>
        </li>
      </ul>
      <h2 id="how-we-share-and-disclose-your-information">
        How We Share and Disclose Your Information
      </h2>
      <p>
        We do not sell or share your data to anyone outside of those listed here. We understand your information is valuable, and we don&apos;t want to use it without informing you.
      </p>
      <ul>
        <li>
          With other members of your organization
          <ul>
            <li>
              Depending on the level of access within the Platform, your Personal Information could be shared with other members of your organization. This is to facilitate the management of interviews and their scheduling preferences.
            </li>
            <li>
              If a member of the organization is placed on an interview for a candidate, they will be able to see limited Personal Information of that candidate.
            </li>
          </ul>
        </li>
        <li>
          With third parties
          <ul>
            <li>
              We store and process all of our data within a public cloud provider. This is done to make the Platform highly available and performant in a cost efficient manner.
            </li>
            <li>
              We use email platforms to send emails to members of your organization and candidates as part of the services of the Platform.
            </li>
            <li>
              When possible, we send email information back to your ATS to maintain a history and thorough log of actions that occur throughout the Platform.
            </li>
            <li>
              We use analytics platforms that collect usage information from you in order to help improve the services that we provide.
            </li>
            <li>
              We use payment platforms to process all of our payments in a PCI-compliant manner.
            </li>
          </ul>
        </li>
        <li>
          With candidates
          <ul>
            <li>
              Names and email addresses of interviewers can be shared with candidates that are scheduled using the Platform.
            </li>
          </ul>
        </li>
        <li>
          Through business transfers
          <ul>
            <li>
              In the event of a merger, acquisition, reorganization, sale of assets, financial due diligence, dissolution, bankruptcy, or similar, your information might be transferred to another entity. If this does occur, we will make a reasonable attempt at keeping you informed about how your information is handled.
            </li>
          </ul>
        </li>
      </ul>
      <h2 id="your-privacy-rights">
        Your Privacy Rights
      </h2>
      <p>
        If you are in the EEA, the UK, or Switzerland, your rights in relation to your Personal Information processed by us as a controller specifically include:
      </p>
      <ul>
        <li>
          <b>Right of access and/or portability</b>: You have the right to access any Personal Information that we hold about you and, in some circumstances, have that Information provided to you so that you can provide or &quot;port&quot; it to another provider.
        </li>
        <li>
          <b>Right of erasure</b>: In certain circumstances, you have the right to the erasure of Personal Information that we hold about you (for example, if it is no longer necessary for the purposes for which it was originally collected).
        </li>
        <li>
          <b>Right to object to processing</b>: In certain circumstances, you have the right to request that we stop processing your Personal Information.
        </li>
        <li>
          <b>Right to rectification</b>: You have the right to require us to correct any inaccurate or incomplete Personal Information.
        </li>
        <li>
          <b>Right to restrict processing</b>: You have the right to request that we restrict processing of your Personal Information in certain circumstances (for example, where you believe that the Personal Information we hold about you is not accurate or lawfully held).
        </li>
      </ul>
      <p>
        To exercise any of your rights, please send an email to <a href="mailto:privacy@interviewplanner.com">privacy@interviewplanner.com</a> detailing the request. We will process such requests in accordance with applicable laws.
      </p>
      <h2 id="international-data-transfers">
        International Data Transfers
      </h2>
      <h3 id="for-european-users">
        For European Users
      </h3>
      <p>
        If you choose to use the Platform from the EEA, the UK, Switzerland, or other regions of the world with laws governing data collection and use that may differ from US law, please note that you are transferring your Personal Information outside of those regions to the US for storage and processing. By providing any information, including Personal Information, on or to the Platform, you consent to such transfer, storage, and processing.
      </p>
      <p>
        As described in this Policy, we may share Personal Information with third parties and may be required to disclose information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
      </p>
      <p>
        The legal grounds for our processing your Personal Information for the purposes in this Policy are as follows:
      </p>
      <ul>
        <li>
          You provided your consent by agreeing to this Policy, if applicable, which you may withdraw at any time by emailing us at <a href="mailto:privacy@interviewplanner.com">privacy@interviewplanner.com</a>.
        </li>
        <li>
          It is necessary for our contractual relationship.
        </li>
        <li>
          The processing is necessary for us to comply with our legal or regulatory obligations.
        </li>
        <li>
          The processing is in our legitimate interest as a provider of the Platform (for example, to protect the security and integrity of our systems and to provide you with customer service and the core functionality of the Platform).
        </li>
      </ul>
      <h3 id="for-other-international-transfers">
        For Other International Transfers
      </h3>
      <p>
        If you are located outside the US, the EEA, the UK, and Switzerland, and you choose to provide Personal Information to us, we may transfer your Personal Information to the US and process it there. When you provide Personal Information, it may be sent to servers located in the US and other countries around the world.
      </p>
      <h2 id="cookies-and-tracking-technologies">
        Cookies and Tracking Technologies
      </h2>
      <p>
        While using the Platform, we will use &quot;<b>cookies</b>&quot; (small text files that are sent to and from your computer) and <b>local storage</b> to store information pertaining to your current session. The primary reason we use them is to identify you when you return to the Platform, but it can also be used to store preferences. You can manage and delete cookies within your browser&apos;s settings; however, this might have adverse effects with respect to your usage of the Platform, such as being required to re-authenticate to start a new session. Learn how to manage your browser cookies at <OutboundLink href="https://aboutcookies.org" label="About Cookies - Privacy Policy">aboutcookies.org</OutboundLink>.
      </p>
      <p>
        We also utilize analytics platforms such as Google Analytics to help collect information to improve the services we provide. These platforms may use cookies to identify you and your sessions. They may use &quot;<b>web beacons</b>&quot; (pieces of code embedded in the Platform that can track user activity), also known as &quot;<b>pixel tags</b>&quot;, and other tracking technologies to automatically collect information such as your IP address, geographic location, and how you interact with our Platform. For more information about how Google uses data, view <OutboundLink href="https://policies.google.com/technologies/partner-sites" label="Google Partner Privacy Policy - Privacy Policy">policies.google.com/technologies/partner-sites</OutboundLink>. To opt out of Google Analytics&apos; tracking on our website, go to <OutboundLink href="https://tools.google.com/dlpage/gaoptout" label="Google Analytics Opt Out - Privacy Policy">tools.google.com/dlpage/gaoptout</OutboundLink>.
      </p>
      <p>
        Some browsers may be configured to send a &quot;Do Not Track&quot; signal to websites to avoid third-parties from tracking information, but we currently do not respond to these signals.
      </p>
      <h2 id="data-security">
        Data Security
      </h2>
      <p>
        We maintain a variety of administrative, technical, and physical safeguards that are designed to protect the privacy and security of your Personal Information. However, the Internet is not a fully secure environment, and we cannot ensure or warranty the security of any Personal Information transmitted to or generated by InterviewPlanner in connection with the Platform. You play a critical role in protecting your Personal Information by maintaining up-to-date computer security protections. Steps you take to ensure the security of your computer, computer passwords, or other personal identifier authentication mechanisms are key components of the protection of your Personal Information. You agree that you are responsible for any additional verification procedures and security you deem necessary.
      </p>
      <h2 id="childrens-privacy">
        Children&apos;s Privacy
      </h2>
      <p>
        InterviewPlanner is not intended to be used by children under the age of 13, and we do not knowingly collect Personal Information from children under the age of 13. If we learn that we have collected Personal Information from a child under the age of 13, we will ensure that information is promptly deleted. If you become aware that we have collected Personal Information of a child under the age of 13, please let us know at <a href="mailto:privacy@interviewplanner.com">privacy@interviewplanner.com</a>.
      </p>
      <h2 id="california-privacy-rights">
        California Privacy Rights
      </h2>
      <p>
        Residents of California have the right to request a list of third parties to which we have disclosed any information about you for direct marketing purposes and the respective categories of information. If you are a California resident and would like to request this list, please send an email to <a href="mailto:privacy@interviewplanner.com">privacy@interviewplanner.com</a>.
      </p>
      <h2 id="changes-and-updates-to-this-policy">
        Changes and Updates to this Policy
      </h2>
      <p>
        We may update this Policy from time to time as our Platform and laws change. If it is updated, it will be reflected in an updated &quot;Effective&quot; date at the top of this Policy. If we make material changes to this Policy, we may either send you an email or push a notification to you within the Platform. We encourage you to review this Policy frequently to stay informed about how we are protecting your data.
      </p>
      <h2 id="contacting-us">
        Contacting Us
      </h2>
      <p>
        If you have any questions or comments about this policy, please contact us at <a href="mailto:privacy@interviewplanner.com">privacy@interviewplanner.com</a>. You can also email us if you no longer wish to use the Platform and would like to have your account deleted and data purged.
      </p>
    </div>
  );
};

export default Privacy;
