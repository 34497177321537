import type { Attendee } from '../../../../types';
import type { ReactNode } from 'react';

// export type Time = Date | Moment.Moment | string;
export type Time = Date | string;

export interface TimeSlot {
  id?: string;
  title?: string;
  start_time: Time;
  end_time: Time;
}

export enum QuickSelectValue {
  SubmittedAvailability = 'submitted_availability',
  Custom = 'custom',
  Next7Days = 'next_7_days',
  Next14Days = 'next_14_days',
}

export interface MousePosition {
  x?: number;
  y?: number;
}

export interface Event {
  id?: string;
  title: ReactNode;
  start: Date;
  end: Date;
  timezone: string;
  hide?: boolean;
  background?: boolean;
  existing?: boolean;
  buffer_time: boolean;
  isSingleSelect?: boolean;

  // Set for interviewer events.
  interviewerEvent?: boolean;
  color?: string;
  private?: boolean;
  attendees?: Attendee[];
  showSimplified?: boolean;
  showPreviews?: boolean;
}
