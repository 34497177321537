import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { QueryKey as ApplicationQueryKey } from './applications';

import type { EditableBusinessHour } from '../../types/business-hours';
import type { InterviewPlannerError } from '../../libraries/interviewplanner';
import type { ScheduleOption, SelfSchedulingLink } from '../../types';
import type { UseQueryOptions } from 'react-query';
import type { EditableAllowedTime, ZoomHostFilterableType } from '../../types';

export enum QueryKey {
  RetrieveScheduleOptions = 'RetrieveScheduleOptions',
  RetrieveSelfSchedulingLink = 'RetrieveSelfSchedulingLink',
}

export const useSelfSchedulingLink = (id?: string, options: UseQueryOptions<SelfSchedulingLink, InterviewPlannerError> = {}) => {
  return useQuery<SelfSchedulingLink, InterviewPlannerError>([QueryKey.RetrieveSelfSchedulingLink, id], () => {
    return InterviewPlanner.request('GET', `/self-scheduling-links/${id}`);
  }, {
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
  });
};

export interface ScheduleOptionsPayload {
  month: string;
  // timezone: string;
  application_id?: string;
  interview_template?: {
    duration_minutes: number;
    candidate_facing_name?: string;
    interviewer_templates?: {
      optional: boolean;
      interviewer_filters: {
        interviewer_filter_expressions: {
          negated: boolean;
          filterable_id: string;
          filterable_type: string;
        }[];
      }[];
    }[];
  };
  schedule_template?: {
    business_hours: {
      day: string;
      start_time: string;
      end_time: string;
      timezone: string;
    }[];
    allowed_times: {
      start_time: string;
      end_time: string;
    }[];
    scheduling_interval_minutes?: number;
    video_conferencing_enabled: boolean;
    zoom_host_filters?: {
      zoom_host_filter_expressions: {
        filterable_id: string;
        filterable_type: `${ZoomHostFilterableType}`;
        negated: boolean;
      }[];
    }[];
    self_scheduling_advanced_notice_hours?: number;
  };
}

export const useScheduleOptions = (id: string, payload: ScheduleOptionsPayload, options: UseQueryOptions<ScheduleOption[], InterviewPlannerError> = {}) => {
  return useQuery<ScheduleOption[], InterviewPlannerError>([QueryKey.RetrieveScheduleOptions, id, payload], () => {
    return InterviewPlanner.request('POST', `/self-scheduling-links/${id}/options`, payload);
  }, {
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
  });
};

export interface CreateSelfSchedulingLinkPayload {
  application_id: string;
  scheduling_calendar_email: string;
  candidate_scheduling_calendar_email: string;
  schedule_template: {
    candidate_event_title: string;
    candidate_event_description: string;
    candidate_event_location?: string;
    candidate_event_additional_attendees?: string[];
    candidate_event_additional_optional_attendees?: string[];
    business_hours: {
      day: string;
      start_time: string;
      end_time: string;
      timezone: string;
    }[];
    allowed_times: {
      start_time: string;
      end_time: string;
    }[];
    scheduling_interval_minutes: number;
    onsite: boolean;
    mark_interviewer_events_as_private: boolean;
    mark_candidate_events_as_private: boolean;
    video_conferencing_enabled: boolean;
    zoom_host_filters?: {
      zoom_host_filter_expressions: {
        filterable_id: string;
        filterable_type: `${ZoomHostFilterableType}`;
        negated: boolean;
      }[];
    }[];
    create_hiring_channel: boolean;
    confirmation_email_template?: {
      id?: string;
      name: string;
      subject: string;
      sender_name: string;
      sender_email: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body: string;
      attachments?: ({
        name: string;
        new?: boolean;
        index?: number;
        file?: File;
      } | File)[];
    };
    self_scheduling_advanced_notice_hours: number;
    self_scheduling_reschedule_enabled: boolean;
    self_scheduling_reschedule_notice_hours?: number;
    self_scheduling_cancel_enabled: boolean;
    self_scheduling_cancel_notice_hours?: number;
    self_scheduling_email_follow_up_enabled: boolean;
    self_scheduling_email_follow_up_delay_days?: number;
    self_scheduling_request_email_template?: {
      id?: string;
      name: string;
      subject: string;
      sender_name: string;
      sender_email: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body: string;
      attachments?: ({
        name: string;
        new?: boolean;
        index?: number;
        file?: File;
      } | File)[];
    };
    room_filters?: {
      room_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: string;
      }[];
    }[];
  };
  stage_interview: {
    id: string;
    feedback_form_id?: string;
    interview_template: {
      name: string;
      duration_minutes: number;
      live_coding_enabled?: boolean;
      position?: number;
      positions?: number[];
      time_window_start?: string;
      time_window_end?: string;
      candidate_facing_name?: string;
      candidate_facing_details?: string;
      interviewer_templates?: {
        description?: string;
        optional: boolean;
        interviewer_filters: {
          interviewer_filter_expressions: {
            negated: boolean;
            filterable_id: string;
            filterable_type: string;
          }[];
        }[];
      }[];
    };
  };
}

interface CreateSelfSchedulingLinkMutationVariables {
  payload?: CreateSelfSchedulingLinkPayload;
}

export const useCreateSelfSchedulingLink = () => {
  const queryClient = useQueryClient();

  return useMutation<SelfSchedulingLink, Error, CreateSelfSchedulingLinkMutationVariables>(({ payload }) => {
    let isMultipartForm = false;

    if (payload?.schedule_template?.confirmation_email_template?.attachments) {
      payload.schedule_template.confirmation_email_template.attachments = payload.schedule_template.confirmation_email_template.attachments.map((attachment) => {
        const isNew = attachment instanceof File;
        isMultipartForm = isMultipartForm || isNew;
        return {
          name: attachment.name,
          new: isNew,
          index: isNew ? undefined : attachment.index,
          file: isNew ? attachment : undefined,
        };
      });
    }

    if (payload?.schedule_template?.self_scheduling_request_email_template?.attachments) {
      payload.schedule_template.self_scheduling_request_email_template.attachments = payload.schedule_template.self_scheduling_request_email_template.attachments.map((attachment) => {
        const isNew = attachment instanceof File;
        isMultipartForm = isMultipartForm || isNew;
        return {
          name: attachment.name,
          new: isNew,
          index: isNew ? undefined : attachment.index,
          file: isNew ? attachment : undefined,
        };
      });
    }

    return InterviewPlanner.request('POST', '/self-scheduling-links', payload, null, isMultipartForm);
  }, {
    onSuccess: (data, { payload }) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, payload?.application_id]);
      queryClient.setQueryData([QueryKey.RetrieveSelfSchedulingLink, data.id], data);
    },
  });
};

export interface UpdateSelfSchedulingLinkPayload {
  schedule_template?: {
    business_hours?: EditableBusinessHour[];
    allowed_times?: EditableAllowedTime[];
    candidate_event_title?: string;
    candidate_event_description?: string;
    candidate_event_location?: string;
    candidate_event_additional_attendees?: string[];
    candidate_event_additional_optional_attendees?: string[];
    video_conferencing_enabled?: boolean;
    zoom_host_filters?: {
      zoom_host_filter_expressions: {
        filterable_id: string;
        filterable_type: `${ZoomHostFilterableType}`;
        negated: boolean;
      }[];
    }[];
    create_hiring_channel?: boolean;
    confirmation_email_template?: {
      id?: string;
      enabled: boolean;
      subject?: string;
      sender_name?: string;
      sender_email?: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body?: string;
      attachments?: ({
        name: string;
        new: boolean;
        index?: number;
        file?: File;
      } | File)[];
    };
  };
  stage_interview?: {
    id?: string;
    feedback_form_id?: string;
    interview_template: {
      duration_minutes: number;
      live_coding_enabled?: boolean;
      interviewer_templates?: {
        optional: boolean;
        interviewer_filters: {
          interviewer_filter_expressions: {
            negated: boolean;
            filterable_id: string;
            filterable_type: string;
          }[];
        }[];
      }[];
    };
  };
}

interface UpdateSelfSchedulingLinkMutationVariables {
  id: string;
  payload?: UpdateSelfSchedulingLinkPayload;
}

export const useUpdateSelfSchedulingLink = () => {
  const queryClient = useQueryClient();

  return useMutation<SelfSchedulingLink, Error, UpdateSelfSchedulingLinkMutationVariables>(({ id, payload }) => {
    let isMultipartForm = false;

    if (payload?.schedule_template?.confirmation_email_template?.attachments) {
      payload.schedule_template.confirmation_email_template.attachments = payload.schedule_template.confirmation_email_template.attachments.map((attachment) => {
        const isNew = attachment instanceof File;
        isMultipartForm = isMultipartForm || isNew;
        return {
          name: attachment.name,
          new: isNew,
          index: isNew ? undefined : attachment.index,
          file: isNew ? attachment : undefined,
        };
      });
    }

    return InterviewPlanner.request('POST', `/self-scheduling-links/${id}`, payload, null, isMultipartForm);
  }, {
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.RetrieveSelfSchedulingLink, data.id], data);
    },
  });
};

interface DeleteSelfSchedulingLinkMutationVariables {
  id: string;
}

export const useDeleteSelfSchedulingLink = () => {
  const queryClient = useQueryClient();

  return useMutation<SelfSchedulingLink, Error, DeleteSelfSchedulingLinkMutationVariables>(({ id }) => {
    return InterviewPlanner.request('DELETE', `/self-scheduling-links/${id}`);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, data.application_id]);
      queryClient.setQueryData([QueryKey.RetrieveSelfSchedulingLink, data.id], data);
    },
  });
};

export interface CreateSelfSchedulingLinkSchedulePayload {
  candidate_timezone: string;
  interviews: {
    interview_template_id: string;
    stage_interview_id: string;
    feedback_form_id?: string;
    room_id?: string;
    zoom_host_id?: string;
    zoom_host_type?: string;
    start_time: string;
    interviewers?: {
      user_id: string;
      interviewer_template_id: string;
    }[];
  }[];
}

interface CreateSelfSchedulingLinkScheduleMutationVariables {
  id: string;
  payload?: CreateSelfSchedulingLinkSchedulePayload;
}

export const useCreateSelfSchedulingLinkSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation<SelfSchedulingLink, InterviewPlannerError, CreateSelfSchedulingLinkScheduleMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/self-scheduling-links/${id}/schedules`, payload);
  }, {
    onError: (err, { id }) => {
      if (err.code === 'time_no_longer_available') {
        // If we hit this error, that means our current set of options is not up-to-date enough, so we invalidate the
        // data so that it will refetch it immediately.
        queryClient.invalidateQueries([QueryKey.RetrieveScheduleOptions, id]);
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.RetrieveSelfSchedulingLink, data.id], data);
    },
  });
};

export interface UpdateSelfSchedulingLinkSchedulePayload {
  candidate_timezone: string;
  interviews: {
    id?: string;
    interview_template_id: string;
    stage_interview_id: string;
    feedback_form_id?: string;
    room_id?: string;
    zoom_host_id?: string;
    zoom_host_type?: string;
    start_time: string;
    interviewers?: {
      user_id: string;
      interviewer_template_id: string;
    }[];
  }[];
}

interface UpdateSelfSchedulingLinkScheduleMutationVariables {
  id: string;
  scheduleId: string;
  payload?: UpdateSelfSchedulingLinkSchedulePayload;
}

export const useUpdateSelfSchedulingLinkSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation<SelfSchedulingLink, InterviewPlannerError, UpdateSelfSchedulingLinkScheduleMutationVariables>(({ id, payload, scheduleId }) => {
    return InterviewPlanner.request('POST', `/self-scheduling-links/${id}/schedules/${scheduleId}`, payload);
  }, {
    onError: (err, { id }) => {
      if (err.code === 'time_no_longer_available') {
        // If we hit this error, that means our current set of options is not up-to-date enough, so we invalidate the
        // data so that it will refetch it immediately.
        queryClient.invalidateQueries([QueryKey.RetrieveScheduleOptions, id]);
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.RetrieveSelfSchedulingLink, data.id], data);
    },
  });
};

interface DeleteSelfSchedulingLinkScheduleMutationVariables {
  id: string;
  scheduleId: string;
}

export const useDeleteSelfSchedulingLinkSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation<SelfSchedulingLink, Error, DeleteSelfSchedulingLinkScheduleMutationVariables>(({ id, scheduleId }) => {
    return InterviewPlanner.request('DELETE', `/self-scheduling-links/${id}/schedules/${scheduleId}`);
  }, {
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.RetrieveSelfSchedulingLink, data.id], data);
    },
  });
};
