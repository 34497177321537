import Moment from 'moment-timezone';

import { ApplicationStatus, SelfSchedulingLinkStatus } from '../../../../../types';
import { BusinessHourReferenceType } from '../../../../../types/business-hours';
import { defaultBusinessHours } from '../../../../../libraries/business-hours';
import { resolveUsersParams } from '../../../../../hooks/queries/users';
import { sortPositions } from '../../../../../libraries/interview-templates';

import type { Account, Application, Candidate, SelfSchedulingLinkPreview, Stage, StageInterview } from '../../../../../types';
import type { CreateSelfSchedulingLinkPayload } from '../../../../../hooks/queries/self-scheduling-links';
import type { QueryClient } from 'react-query';
import type { SelfSchedulingLink } from './use-new-self-scheduling-link';
import type { TokensPayload } from '../../../../../hooks/queries/tokens';
import type { UserMap } from '../../../../../hooks/queries/users';

export const defaultValuesForStageInterview = async (queryClient: QueryClient, account: Account, application: Application, users: UserMap, stageInterview?: StageInterview) => {
  let interviewerPool;
  let interviewer;
  let duration;
  if (stageInterview?.interview_template) {
    duration = stageInterview.interview_template.duration_minutes;
    interviewerPool = await queryClient.fetchQuery(resolveUsersParams({
      applicationId: application.id,
      includePastInterviewers: stageInterview.interview_template.interviewer_templates?.[0].include_past_interviewers ?? true,
      interviewerFilters: stageInterview.interview_template.interviewer_templates?.[0].interviewer_filters || [],
    }));
    interviewer = interviewerPool[0];
  } else if (stageInterview?.ats_interviewer_ids && stageInterview.ats_interviewer_ids.length > 0) {
    interviewer = { id: stageInterview.ats_interviewer_ids[0] };
    interviewerPool = [interviewer];
  }
  if (duration === undefined && stageInterview?.ats_duration_minutes && stageInterview.ats_duration_minutes > 0) {
    duration = stageInterview.ats_duration_minutes;
  }

  const interviewerValues = defaultValuesForInterviewer(application, users, interviewer?.id);

  return {
    duration: duration || 0,
    feedbackFormId: account.ats_type === 'lever' ? stageInterview?.feedback_form_id : undefined,
    interviewTimeWindowEnd: stageInterview?.interview_template?.time_window_end,
    interviewTimeWindowStart: stageInterview?.interview_template?.time_window_start,
    interviewerPoolIds: (interviewerPool || []).map(({ id }) => id),
    interviewerId: interviewer?.id,
    liveCodingEnabled: stageInterview?.interview_template?.live_coding_enabled || false,
    positions: stageInterview?.interview_template?.positions,
    candidateFacingName: stageInterview?.interview_template?.candidate_facing_name,
    candidateFacingDetails: stageInterview?.interview_template?.candidate_facing_details,
    ...interviewerValues,
  };
};

export const defaultValuesForInterviewer = (application: Application, users: UserMap, interviewerId?: string) => {
  // If the selected interviewer has working hours set, use those as the
  // business hours. Otherwise, default to the schedule template's business
  // hours.
  const user = users[interviewerId!];
  const businessHours = user?.business_hours || user?.default_business_hours || application.current_stage?.schedule_template?.business_hours || defaultBusinessHours(BusinessHourReferenceType.ScheduleTemplate, user?.default_timezone);

  return {
    businessHours: businessHours.map((businessHour) => ({
      ...businessHour,
      start_time: businessHour.start_time === '00:00' ? '' : businessHour.start_time,
      end_time: businessHour.end_time === '24:00' ? '' : businessHour.end_time,
      timezone: businessHour.timezone || user?.default_timezone || Moment.tz.guess(),
    })),
  };
};

// If selfSchedulingLink is passed in, then we're constructing a payload for the
// schedule property (so for types candidate_calendar_description and
// confirmation_email).
export const constructSelfSchedulingTokensPayload = (application: Application, stageInterviewName: string, durationMinutes: number | undefined, candidateFacingName: string | undefined, candidateFacingDetails: string | undefined, users: UserMap): TokensPayload['self_scheduling_link'] => ({
  application: {
    candidate: {
      email: application.candidate.email,
      name: application.candidate.name,
      pronouns: application.candidate.pronouns || undefined,
      pronunciation_url: application.candidate.pronunciation_url || undefined,
      resume_url: application.candidate.resume_url || undefined,
      phone_number: application.candidate.phone_number,
      linkedin_url: application.candidate.linkedin_url,
      website: application.candidate.website,
    },
    coordinator: application.coordinator_id && users[application.coordinator_id] ? {
      email: users[application.coordinator_id].email,
      name: users[application.coordinator_id].name,
      phone_number: users[application.coordinator_id].phone_number,
    } : undefined,
    hiring_manager: application.hiring_manager_id && users[application.hiring_manager_id] ? {
      email: users[application.hiring_manager_id].email,
      name: users[application.hiring_manager_id].name,
      phone_number: users[application.hiring_manager_id].phone_number,
    } : undefined,
    recruiter: application.recruiter_id && users[application.recruiter_id] ? {
      email: users[application.recruiter_id].email,
      name: users[application.recruiter_id].name,
      phone_number: users[application.recruiter_id].phone_number,
    } : undefined,
    sourcer: application.sourcer_id && users[application.sourcer_id] ? {
      email: users[application.sourcer_id].email,
      name: users[application.sourcer_id].name,
      phone_number: users[application.sourcer_id].phone_number,
    } : undefined,
    office: application.office ? {
      name: application.office.name,
    } : undefined,
  },
  stage: {
    name: application.current_stage!.name,
    job: {
      name: application.job.name,
      post_name: application.job.post_name,
    },
  },
  stage_interview: stageInterviewName ? {
    name: stageInterviewName,
  } : undefined,
  interview_template: {
    duration_minutes: durationMinutes,
    candidate_facing_name: candidateFacingName,
    candidate_facing_details: candidateFacingDetails,
  },
});

export const constructScheduleTokensPayload = (application: Application, users: UserMap, selfSchedulingLink: SelfSchedulingLink): TokensPayload['schedule'] => ({
  application: {
    ats_id: application.ats_id,
    candidate: {
      ats_id: application.candidate.ats_id,
      email: application.candidate.email,
      name: application.candidate.name,
      pronouns: application.candidate.pronouns || undefined,
      pronunciation_url: application.candidate.pronunciation_url || undefined,
      resume_url: application.candidate.resume_url || undefined,
      phone_number: application.candidate.phone_number,
      linkedin_url: application.candidate.linkedin_url,
      website: application.candidate.website,
    },
    coordinator: application.coordinator_id && users[application.coordinator_id] ? {
      email: users[application.coordinator_id].email,
      name: users[application.coordinator_id].name,
      phone_number: users[application.coordinator_id].phone_number,
    } : undefined,
    hiring_manager: application.hiring_manager_id && users[application.hiring_manager_id] ? {
      email: users[application.hiring_manager_id].email,
      name: users[application.hiring_manager_id].name,
      phone_number: users[application.hiring_manager_id].phone_number,
    } : undefined,
    recruiter: application.recruiter_id && users[application.recruiter_id] ? {
      email: users[application.recruiter_id].email,
      name: users[application.recruiter_id].name,
      phone_number: users[application.recruiter_id].phone_number,
    } : undefined,
    sourcer: application.sourcer_id && users[application.sourcer_id] ? {
      email: users[application.sourcer_id].email,
      name: users[application.sourcer_id].name,
      phone_number: users[application.sourcer_id].phone_number,
    } : undefined,
    office: application.office ? {
      name: application.office.name,
    } : undefined,
    current_stage: {
      job: {
        name: application.job.name,
        post_name: application.job.post_name,
      },
    },
  },
  stage: {
    name: application.current_stage!.name,
    stage_interviews: application.current_stage!.stage_interviews?.filter((stageInterview) => !stageInterview.inline && !stageInterview.deleted).map((stageInterview) => ({
      name: stageInterview.name,
      position: stageInterview.position,
      interview_template: stageInterview.interview_template ? {
        duration_minutes: stageInterview.interview_template.duration_minutes,
        candidate_facing_name: stageInterview.interview_template.candidate_facing_name,
        candidate_facing_details: stageInterview.interview_template.candidate_facing_details,
      } : undefined,
    })),
  },
  // All business hours should have a timezone by this point.
  timezone: selfSchedulingLink?.schedule_template.business_hours[0].timezone!,
  candidate_event_location: selfSchedulingLink?.schedule_template.video_conferencing_enabled ? undefined : selfSchedulingLink?.schedule_template.candidate_calendar_event_template.location,
  schedule_template: {
    candidate_event_location: selfSchedulingLink.schedule_template.candidate_calendar_event_template.location || undefined,
    video_conferencing_enabled: selfSchedulingLink.schedule_template.video_conferencing_enabled,
    onsite: selfSchedulingLink.schedule_template.onsite,
  },
});

export const constructSchedulePreview = (account: Account, selfSchedulingLink: SelfSchedulingLink, stage: Stage, stageInterview: StageInterview, candidate: Candidate): SelfSchedulingLinkPreview => ({
  account: {
    name: account.name,
    color: account.color,
    logo_url: account.logo_url,
    availability_message: account.availability_message,
    availability_message_in_self_scheduling: account.availability_message_in_self_scheduling,
  },
  application: {
    candidate: {
      name: candidate.name,
      email: candidate.email,
    },
    status: ApplicationStatus.Active,
  },
  interview_template: {
    duration_minutes: selfSchedulingLink.stage_interview.interview_template.duration_minutes,
    candidate_facing_name: selfSchedulingLink.stage_interview.interview_template.candidate_facing_name,
    candidate_facing_details: selfSchedulingLink.stage_interview.interview_template.candidate_facing_details,
    interviewer_templates: (selfSchedulingLink.stage_interview.interview_template.interviewer_templates || []).map((template) => ({
      optional: template.optional,
      interviewer_filters: template.interviewer_filters.map((filter) => ({
        interviewer_filter_expressions: (filter.interviewer_filter_expressions || []).map((exp) => ({
          negated: exp.negated,
          filterable_id: exp.filterable_id,
          filterable_type: exp.filterable_type,
        })),
      })),
    })),
  },
  schedule_template: {
    business_hours: selfSchedulingLink.schedule_template.business_hours.map((bh) => ({
      day: bh.day,
      start_time: bh.start_time || '00:00',
      end_time: bh.end_time || '24:00',
      // All business hours should have a timezone by this point.
      timezone: bh.timezone!,
    })),
    allowed_times: selfSchedulingLink.schedule_template.allowed_times,
    scheduling_interval_minutes: selfSchedulingLink.schedule_template.scheduling_interval_minutes,
    video_conferencing_enabled: selfSchedulingLink.schedule_template.video_conferencing_enabled,
    zoom_host_filters: selfSchedulingLink.schedule_template.zoom_host_filters,
    self_scheduling_advanced_notice_hours: selfSchedulingLink.schedule_template.self_scheduling_advanced_notice_hours,
    self_scheduling_reschedule_enabled: selfSchedulingLink.schedule_template.self_scheduling_reschedule_enabled,
    self_scheduling_reschedule_notice_hours: selfSchedulingLink.schedule_template.self_scheduling_reschedule_notice_hours,
    self_scheduling_cancel_enabled: selfSchedulingLink.schedule_template.self_scheduling_cancel_enabled,
    self_scheduling_cancel_notice_hours: selfSchedulingLink.schedule_template.self_scheduling_cancel_notice_hours,
  },
  stage: {
    name: stage.name,
  },
  stage_interview: {
    name: stageInterview.name,
  },
  status: SelfSchedulingLinkStatus.Requested,
});

export const constructSelfSchedulingLinkPayload = (selfSchedulingLink: SelfSchedulingLink): CreateSelfSchedulingLinkPayload => {
  return {
    application_id: selfSchedulingLink.application_id,
    scheduling_calendar_email: selfSchedulingLink.scheduling_calendar_email,
    candidate_scheduling_calendar_email: selfSchedulingLink.candidate_scheduling_calendar_email || selfSchedulingLink.scheduling_calendar_email,
    schedule_template: {
      // We used to make selfSchedulingLink exactly the same as the payload, so
      // we didn't have to have a helper function that converts it, but when we
      // moved to calendar event templates, we didn't want to change all of the
      // payloads yet, so for now, they still accept the flattened version. When
      // we finally do convert it, this can be removed.
      candidate_event_title: selfSchedulingLink.schedule_template.candidate_calendar_event_template.title,
      candidate_event_description: selfSchedulingLink.schedule_template.candidate_calendar_event_template.description,
      candidate_event_location: selfSchedulingLink.schedule_template.candidate_calendar_event_template.location,
      candidate_event_additional_attendees: selfSchedulingLink.schedule_template.candidate_calendar_event_template.additional_attendees,
      candidate_event_additional_optional_attendees: selfSchedulingLink.schedule_template.candidate_calendar_event_template.additional_optional_attendees,
      business_hours: selfSchedulingLink.schedule_template.business_hours.map((bh) => ({
        day: bh.day,
        start_time: bh.start_time || '00:00',
        end_time: bh.end_time || '24:00',
        // All business hours should have a timezone by this point.
        timezone: bh.timezone!,
      })),
      allowed_times: selfSchedulingLink.schedule_template.allowed_times,
      scheduling_interval_minutes: selfSchedulingLink.schedule_template.scheduling_interval_minutes,
      onsite: selfSchedulingLink.schedule_template.onsite,
      mark_interviewer_events_as_private: selfSchedulingLink.schedule_template.mark_interviewer_events_as_private,
      mark_candidate_events_as_private: selfSchedulingLink.schedule_template.mark_candidate_events_as_private,
      video_conferencing_enabled: selfSchedulingLink.schedule_template.video_conferencing_enabled,
      zoom_host_filters: selfSchedulingLink.schedule_template.zoom_host_filters,
      create_hiring_channel: selfSchedulingLink.schedule_template.create_hiring_channel,
      confirmation_email_template: selfSchedulingLink.schedule_template.confirmation_email_template ? {
        id: selfSchedulingLink.schedule_template.confirmation_email_template.id,
        name: selfSchedulingLink.schedule_template.confirmation_email_template.name,
        subject: selfSchedulingLink.schedule_template.confirmation_email_template.subject,
        sender_name: selfSchedulingLink.schedule_template.confirmation_email_template.sender_name,
        sender_email: selfSchedulingLink.schedule_template.confirmation_email_template.sender_email,
        cc_emails: selfSchedulingLink.schedule_template.confirmation_email_template.cc_emails,
        bcc_emails: selfSchedulingLink.schedule_template.confirmation_email_template.bcc_emails,
        body: selfSchedulingLink.schedule_template.confirmation_email_template.body,
        attachments: selfSchedulingLink.schedule_template.confirmation_email_template.attachments,
      } : undefined,
      self_scheduling_advanced_notice_hours: selfSchedulingLink.schedule_template.self_scheduling_advanced_notice_hours,
      self_scheduling_reschedule_enabled: selfSchedulingLink.schedule_template.self_scheduling_reschedule_enabled,
      self_scheduling_reschedule_notice_hours: selfSchedulingLink.schedule_template.self_scheduling_reschedule_notice_hours,
      self_scheduling_cancel_enabled: selfSchedulingLink.schedule_template.self_scheduling_cancel_enabled,
      self_scheduling_cancel_notice_hours: selfSchedulingLink.schedule_template.self_scheduling_cancel_notice_hours,
      self_scheduling_email_follow_up_enabled: Boolean(selfSchedulingLink.schedule_template.self_scheduling_email_follow_up_templates?.[0]),
      self_scheduling_email_follow_up_delay_days: selfSchedulingLink.schedule_template.self_scheduling_email_follow_up_templates?.[0].delay_days || undefined,
      self_scheduling_request_email_template: selfSchedulingLink.schedule_template.self_scheduling_request_email_template ? {
        id: selfSchedulingLink.schedule_template.self_scheduling_request_email_template.id,
        name: selfSchedulingLink.schedule_template.self_scheduling_request_email_template.name,
        subject: selfSchedulingLink.schedule_template.self_scheduling_request_email_template.subject,
        sender_name: selfSchedulingLink.schedule_template.self_scheduling_request_email_template.sender_name,
        sender_email: selfSchedulingLink.schedule_template.self_scheduling_request_email_template.sender_email,
        cc_emails: selfSchedulingLink.schedule_template.self_scheduling_request_email_template.cc_emails,
        bcc_emails: selfSchedulingLink.schedule_template.self_scheduling_request_email_template.bcc_emails,
        body: selfSchedulingLink.schedule_template.self_scheduling_request_email_template.body,
        attachments: selfSchedulingLink.schedule_template.self_scheduling_request_email_template.attachments,
      } : undefined,
      room_filters: selfSchedulingLink.schedule_template.room_filters?.map((filter) => ({
        room_filter_expressions: filter.room_filter_expressions.map((exp) => ({
          negated: exp.negated,
          filterable_id: exp.filterable_id,
          filterable_type: exp.filterable_type,
        })),
      })),
    },
    stage_interview: {
      id: selfSchedulingLink.stage_interview.id,
      feedback_form_id: selfSchedulingLink.stage_interview.feedback_form_id,
      interview_template: {
        name: selfSchedulingLink.stage_interview.interview_template.name,
        duration_minutes: selfSchedulingLink.stage_interview.interview_template.duration_minutes,
        live_coding_enabled: selfSchedulingLink.stage_interview.interview_template.live_coding_enabled,
        positions: (selfSchedulingLink.stage_interview.interview_template.positions || []).length > 0 ? sortPositions(selfSchedulingLink.stage_interview.interview_template.positions) : undefined,
        time_window_start: selfSchedulingLink.stage_interview.interview_template.time_window_start,
        time_window_end: selfSchedulingLink.stage_interview.interview_template.time_window_end,
        candidate_facing_name: selfSchedulingLink.stage_interview.interview_template.candidate_facing_name,
        candidate_facing_details: selfSchedulingLink.stage_interview.interview_template.candidate_facing_details,
        interviewer_templates: selfSchedulingLink.stage_interview.interview_template.interviewer_templates?.map((interviewerTemplate) => ({
          description: interviewerTemplate.description,
          optional: interviewerTemplate.optional,
          interviewer_filters: interviewerTemplate.interviewer_filters.map((filter) => ({
            interviewer_filter_expressions: filter.interviewer_filter_expressions.map((exp) => ({
              negated: exp.negated,
              filterable_id: exp.filterable_id,
              filterable_type: exp.filterable_type,
            })),
          })),
        })),
      },
    },
  };
};
