import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import ApplicationDetailsSection from './ApplicationDetailsSection';
import CandidateDetailsSection from './CandidateDetailsSection';
import { useApplication } from '../../../../../hooks/queries/applications';
import { correctPath } from 'libraries/gem';

const CandidateDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { data: application } = useApplication(id);

  if (!application) {
    return null;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Details',
        pathname: correctPath(`/app/candidates/${id}/details`),
      }}
    >
      <div className="candidate-details-container">
        <Helmet>
          <title>Details | {application?.candidate.name || 'Unknown'} ({application?.job.name}) | Gem Scheduling</title>
        </Helmet>
        <CandidateDetailsSection applicationAtsId={application.ats_id} candidate={application.candidate} />
        <ApplicationDetailsSection application={application} />
      </div>
    </Breadcrumb>
  );
};

export default CandidateDetails;
