import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import Button from '../../../../../../library/inputs/Button';
import CalendarEventTemplateAdvancedSettings from '../../../../../../library/inputs/CalendarEventTemplateAdvancedSettings';
import CalendarEventTemplateSelectInput from '../../../../../../library/inputs/CalendarEventTemplateSelectInput';
import CalendarEventTemplateSummary from '../../../../../../library/data-display/CalendarEventTemplateSummary';
import CheckboxInput from 'components/library/inputs/CheckboxInput';
import EditorInput from '../../../../../../library/inputs/EditorInput';
import Flash from '../../../../../../library/utils/Flash';
import SchedulingCalendarSelectInput from '../../../../../../library/inputs/SchedulingCalendarSelectInput';
import Section from '../../../../../../library/layout/Section';
import TemplateNamePromptModal from '../../../../../../library/data-display/TemplateNamePromptModal';
import TextInput from '../../../../../../library/inputs/TextInput';
import TokenInput from '../../../../../../library/inputs/TokenInput';
import Tooltip from '../../../../../../library/utils/Tooltip';
import { CalendarEventTemplateType, Directory, EditorType } from '../../../../../../../types';
import { DEFAULT_CALENDAR_EVENT_CONTENT } from '../../../../../CalendarEventTemplates/CalendarEventTemplateCreate/helpers';
import {
  calendarEventTemplateParams,
  useCalendarEventTemplate,
  useCalendarEventTemplatesMap,
  useCreateCalendarEventTemplate,
  useUpdateCalendarEventTemplate,
} from '../../../../../../../hooks/queries/calendar-event-templates';
import { differingValues } from '../../../../../../../libraries/differing-values';
import { slateValueToHtml } from '../../../../../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../../../../../libraries/editor/slate-value-to-text';
import { useCalendars } from '../../../../../../../hooks/queries/calendars';
import { useJob } from '../../../../../../../hooks/queries/jobs';
import { useSession } from '../../../../../../../hooks/use-session';
import { useSlateEditor } from '../../../../../../../hooks/use-slate-editor';
import { useStage, useUpdateStage } from '../../../../../../../hooks/queries/stages';
import { useTokens } from '../../../../../../../hooks/queries/tokens';
import { useUsersMap } from '../../../../../../../hooks/queries/users';

import type { ChangeEvent } from 'react';
import type { CreatableCalendarEventTemplate } from '../../../../../../../types';
import type { CreateCalendarEventTemplatePayload } from '../../../../../../../hooks/queries/calendar-event-templates';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as CalendarEventTemplateOption } from '../../../../../../library/inputs/CalendarEventTemplateSelectInput';
import type { Option } from '../../../../../../library/inputs/SelectInput/types';
import { correctPath } from 'libraries/gem';

const constructEmptyCalendarEventTemplate = (jobName: string, accountName?: string): CreatableCalendarEventTemplate => ({
  id: 'new',
  name: `[${jobName}] Candidate Calendar Event`,
  title: `${accountName ? `${accountName} ` : ''}${DEFAULT_CALENDAR_EVENT_CONTENT.candidate_calendar_event.title}`,
  description: '',
  additional_attendees: [],
  additional_optional_attendees: [],
});

const CalendarEventsSection = () => {
  const queryClient = useQueryClient();

  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { account } = useSession();
  const { data: calendars } = useCalendars();
  const { data: job } = useJob(jobId);
  const { data: stage } = useStage(jobId, stageId);
  const calendarEventTemplates = useCalendarEventTemplatesMap();
  const users = useUsersMap({ archived: true });

  const [error, setError] = useState<Error | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCalendarEventTemplateFetching, setIsCalendarEventTemplateFetching] = useState(false);

  const [schedulingCalendar, setSchedulingCalendar] = useState(stage?.schedule_template?.scheduling_calendar_email || '');
  const [candidateSchedulingCalendar, setCandidateSchedulingCalendar] = useState(stage?.schedule_template?.candidate_scheduling_calendar_email || '');
  const [calendarEventTemplateId, setCalendarEventTemplateId] = useState(stage?.schedule_template?.candidate_calendar_event_template_id);
  const [calendarEventTemplateName, setCalendarEventTemplateName] = useState(stage?.schedule_template?.candidate_calendar_event_template?.name || '');
  const [calendarEventTemplateTitleSlateEditor, calendarEventTemplateTitleSlateValue, setCalendarEventTemplateTitleSlateValue, setCalendarEventTemplateTitle] = useSlateEditor(stage?.schedule_template?.candidate_calendar_event_template?.title || '', true);
  const [calendarEventTemplateDescriptionSlateEditor, calendarEventTemplateDescriptionSlateValue, setCalendarEventTemplateDescriptionSlateValue, setCalendarEventTemplateDescription] = useSlateEditor(stage?.schedule_template?.candidate_calendar_event_template?.description || '');
  const [calendarEventTemplateLocation, setCalendarEventTemplateLocation] = useState(stage?.schedule_template?.candidate_calendar_event_template?.location || '');
  const [calendarEventTemplateAdditionalAttendees, setCalendarEventTemplateAdditionalAttendees] = useState(stage?.schedule_template?.candidate_calendar_event_template?.additional_attendees || []);
  const [calendarEventTemplateAdditionalOptionalAttendees, setCalendarEventTemplateAdditionalOptionalAttendees] = useState(stage?.schedule_template?.candidate_calendar_event_template?.additional_optional_attendees || []);
  const [markInterviewerEventsAsPrivate, setMarkInterviewerEventsAsPrivate] = useState(stage?.schedule_template?.mark_interviewer_events_as_private || false);
  const [markCandidateEventsAsPrivate, setMarkCandidateEventsAsPrivate] = useState(stage?.schedule_template?.mark_candidate_events_as_private || false);

  const [isTemplateNamePromptModalOpen, setIsTemplateNamePromptModalOpen] = useState(false);

  const updateStageMutation = useUpdateStage();
  const createCalendarEventTemplateMutation = useCreateCalendarEventTemplate();
  const updateCalendarEventTemplateMutation = useUpdateCalendarEventTemplate();

  const { data: existingCalendarEventTemplate } = useCalendarEventTemplate(calendarEventTemplateId);

  const hasCalendarEventTemplateBeenModified = useMemo<boolean>(() => {
    return calendarEventTemplateName !== (existingCalendarEventTemplate?.name || '') ||
      slateValueToText(calendarEventTemplateTitleSlateValue) !== (existingCalendarEventTemplate?.title || '') ||
      slateValueToHtml(calendarEventTemplateDescriptionSlateValue) !== (existingCalendarEventTemplate?.description || '') ||
      (calendarEventTemplateLocation || '') !== (existingCalendarEventTemplate?.location || '') ||
      calendarEventTemplateAdditionalAttendees.length !== (existingCalendarEventTemplate?.additional_attendees || []).length ||
      calendarEventTemplateAdditionalAttendees.some((attendee, i) => attendee !== existingCalendarEventTemplate?.additional_attendees?.[i]) ||
      calendarEventTemplateAdditionalOptionalAttendees.length !== (existingCalendarEventTemplate?.additional_optional_attendees || []).length ||
      calendarEventTemplateAdditionalOptionalAttendees.some((attendee, i) => attendee !== existingCalendarEventTemplate?.additional_optional_attendees?.[i]);
  }, [
    calendarEventTemplateAdditionalAttendees,
    calendarEventTemplateAdditionalOptionalAttendees,
    calendarEventTemplateDescriptionSlateValue,
    calendarEventTemplateLocation,
    calendarEventTemplateName,
    calendarEventTemplateTitleSlateValue,
    existingCalendarEventTemplate,
  ]);

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({
    type: EditorType.CandidateCalendarEvent,
    schedule: {
      application: {
        coordinator: job?.coordinator_id && users[job.coordinator_id] ? {
          email: users[job.coordinator_id].email,
          name: users[job.coordinator_id].name,
          phone_number: users[job.coordinator_id].phone_number,
        } : undefined,
        hiring_manager: job?.hiring_manager_id && users[job.hiring_manager_id] ? {
          email: users[job.hiring_manager_id].email,
          name: users[job.hiring_manager_id].name,
          phone_number: users[job.hiring_manager_id].phone_number,
        } : undefined,
        recruiter: job?.recruiter_id && users[job.recruiter_id] ? {
          email: users[job.recruiter_id].email,
          name: users[job.recruiter_id].name,
          phone_number: users[job.recruiter_id].phone_number,
        } : undefined,
        sourcer: job?.sourcer_id && users[job.sourcer_id] ? {
          email: users[job.sourcer_id].email,
          name: users[job.sourcer_id].name,
          phone_number: users[job.sourcer_id].phone_number,
        } : undefined,
        current_stage: {
          job: {
            name: job?.name || '',
            post_name: job?.post_name,
          },
        },
        office: job?.offices && job?.offices?.length > 0 ? {
          name: job.offices[0].name,
        } : undefined,
      },
      stage: {
        name: stage?.name || '',
        stage_interviews: stage?.stage_interviews?.filter((stageInterview) => !stageInterview.inline && !stageInterview.deleted).map((stageInterview) => ({
          name: stageInterview.name,
          position: stageInterview.position,
          interview_template: stageInterview.interview_template ? {
            duration_minutes: stageInterview.interview_template.duration_minutes,
            candidate_facing_name: stageInterview.interview_template.candidate_facing_name,
            candidate_facing_details: stageInterview.interview_template.candidate_facing_details,
          } : undefined,
        })),
      },
      timezone: stage?.schedule_template?.business_hours?.[0]?.timezone || undefined,
      schedule_template: {
        candidate_event_location: stage?.schedule_template?.candidate_calendar_event_template?.location || undefined,
        video_conferencing_enabled: stage?.schedule_template?.video_conferencing_enabled || false,
        onsite: stage?.schedule_template?.onsite || false,
      },
    },
  }, {
    enabled: Boolean(stage?.schedule_template),
  });

  const setCalendarEventTemplateValues = useCallback((calendarEventTemplate: CreatableCalendarEventTemplate | undefined) => {
    setCalendarEventTemplateId(calendarEventTemplate?.id);
    setCalendarEventTemplateName(calendarEventTemplate?.name || '');
    setCalendarEventTemplateTitle(calendarEventTemplate?.title || '');
    setCalendarEventTemplateDescription(calendarEventTemplate?.description || '');
    setCalendarEventTemplateLocation(calendarEventTemplate?.location || '');
    setCalendarEventTemplateAdditionalAttendees(calendarEventTemplate?.additional_attendees || []);
    setCalendarEventTemplateAdditionalOptionalAttendees(calendarEventTemplate?.additional_optional_attendees || []);
  }, []);

  useEffect(() => {
    if (stage?.schedule_template?.candidate_calendar_event_template) {
      setCalendarEventTemplateValues(stage.schedule_template.candidate_calendar_event_template);
    }
  }, [
    setCalendarEventTemplateValues,
    stage?.schedule_template?.candidate_calendar_event_template,
  ]);

  const handleCalendarEventTemplateChange = async (option: OnChangeValue<CalendarEventTemplateOption, false>) => {
    setCalendarEventTemplateId(option ? option.value : undefined);

    if (!option) {
      return;
    }

    setIsCalendarEventTemplateFetching(true);
    let newCalendarEventTemplate;
    if (option.value === 'new') {
      newCalendarEventTemplate = constructEmptyCalendarEventTemplate(job!.name, account!.name);
    } else {
      try {
        newCalendarEventTemplate = await queryClient.fetchQuery(calendarEventTemplateParams(option.value));
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        }
        setIsCalendarEventTemplateFetching(false);
        return;
      }
    }

    setCalendarEventTemplateValues(newCalendarEventTemplate);
    setIsCalendarEventTemplateFetching(false);
  };

  const handleSchedulingCalendarChange = (option: OnChangeValue<Option<string>, false>) => setSchedulingCalendar(option?.value || '');
  const handleCandidateSchedulingCalendarChange = (option: OnChangeValue<Option<string>, false>) => setCandidateSchedulingCalendar(option?.value || '');
  const handleCalendarEventTemplateNameChange = (e: ChangeEvent<HTMLInputElement>) => setCalendarEventTemplateName(e.target.value);
  const handleCalendarEventTemplateLocationChange = (e: ChangeEvent<HTMLInputElement>) => setCalendarEventTemplateLocation(e.target.value);
  const handleMarkInterviewerEventsAsPrivateChange = (e: ChangeEvent<HTMLInputElement>) => setMarkInterviewerEventsAsPrivate(e.target.checked);
  const handleMarkCandidateEventsAsPrivateChange = (e: ChangeEvent<HTMLInputElement>) => setMarkCandidateEventsAsPrivate(e.target.checked);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    updateStageMutation.reset();
    createCalendarEventTemplateMutation.reset();
    updateCalendarEventTemplateMutation.reset();
    setError(null);
    setIsEditing(false);
    setSchedulingCalendar(stage?.schedule_template?.scheduling_calendar_email || '');
    setCandidateSchedulingCalendar(stage?.schedule_template?.candidate_scheduling_calendar_email || '');
    setMarkInterviewerEventsAsPrivate(stage?.schedule_template?.mark_interviewer_events_as_private || false);
    setMarkCandidateEventsAsPrivate(stage?.schedule_template?.mark_candidate_events_as_private || false);
    setCalendarEventTemplateValues(stage?.schedule_template?.candidate_calendar_event_template);
  };

  const handleSave = async () => {
    updateStageMutation.reset();
    createCalendarEventTemplateMutation.reset();
    updateCalendarEventTemplateMutation.reset();
    setError(null);
    setIsEditing(false);
    // We're using an isFetching state param instead of relying on react-query's
    // isLoading properties because we don't want it to flash as the multiple
    // mutations run. We want it to stay in a loading state for the entirety of
    // this function.
    setIsFetching(true);

    const calendarEventTemplatePayload: CreateCalendarEventTemplatePayload = {
      name: calendarEventTemplateName,
      type: CalendarEventTemplateType.CandidateCalendarEvent,
      title: slateValueToText(calendarEventTemplateTitleSlateValue),
      description: slateValueToHtml(calendarEventTemplateDescriptionSlateValue),
      location: calendarEventTemplateLocation,
      additional_attendees: calendarEventTemplateAdditionalAttendees,
      additional_optional_attendees: calendarEventTemplateAdditionalOptionalAttendees,
    };

    // Update calendar event template if it has been edited.
    if (calendarEventTemplateId && calendarEventTemplateId !== 'new') {
      const existingCalendarEventTemplate = await queryClient.fetchQuery(calendarEventTemplateParams(calendarEventTemplateId));
      const diff = differingValues(calendarEventTemplatePayload, existingCalendarEventTemplate);
      if (Object.keys(diff).length > 0) {
        try {
          await updateCalendarEventTemplateMutation.mutateAsync({ id: calendarEventTemplateId, payload: diff });
        } catch (err) {
          setIsEditing(true);
          setIsFetching(false);
          if (err instanceof Error) {
            setError(err);
          }
          return;
        }
      }
    }

    // Create new calendar event template.
    let newCalendarEventTemplateId;
    if (calendarEventTemplateId === 'new') {
      try {
        const data = await createCalendarEventTemplateMutation.mutateAsync({ payload: calendarEventTemplatePayload });
        newCalendarEventTemplateId = data.id;
        setCalendarEventTemplateId(newCalendarEventTemplateId);
      } catch (err) {
        setIsEditing(true);
        setIsFetching(false);
        if (err instanceof Error) {
          setError(err);
        }
        return;
      }
    }

    // Update stage with calendar event template ID.
    try {
      await updateStageMutation.mutateAsync({
        id: stageId,
        jobId,
        payload: {
          scheduling_calendar_email: schedulingCalendar || '',
          candidate_scheduling_calendar_email: candidateSchedulingCalendar || '',
          candidate_calendar_event_template_id: newCalendarEventTemplateId || calendarEventTemplateId || '',
          mark_interviewer_events_as_private: markInterviewerEventsAsPrivate,
          mark_candidate_events_as_private: markCandidateEventsAsPrivate,
        },
      });
    } catch (err) {
      setIsEditing(true);
      setIsFetching(false);
      if (err instanceof Error) {
        setError(err);
      }
      return;
    }

    setIsFetching(false);
  };

  const createTemplateForSaveAs = async (templateName: string) => {
    setCalendarEventTemplateName(templateName);

    updateStageMutation.reset();
    createCalendarEventTemplateMutation.reset();
    setError(null);
    setIsEditing(false);
    // We're using an isFetching state param instead of relying on react-query's
    // isLoading properties because we don't want it to flash as the multiple
    // mutations run. We want it to stay in a loading state for the entirety of
    // this function.
    setIsFetching(true);

    // Create new calendar event template.
    let newCalendarEventTemplateId;
    if (calendarEventTemplateId) {
      try {
        const data = await createCalendarEventTemplateMutation.mutateAsync({
          payload: {
            name: templateName,
            type: CalendarEventTemplateType.CandidateCalendarEvent,
            title: slateValueToText(calendarEventTemplateTitleSlateValue),
            description: slateValueToHtml(calendarEventTemplateDescriptionSlateValue),
            location: calendarEventTemplateLocation,
            additional_attendees: calendarEventTemplateAdditionalAttendees,
            additional_optional_attendees: calendarEventTemplateAdditionalOptionalAttendees,
          },
        });
        newCalendarEventTemplateId = data.id;
        setCalendarEventTemplateId(newCalendarEventTemplateId);
      } catch (err) {
        setIsEditing(true);
        setIsFetching(false);
        if (err instanceof Error) {
          setError(err);
        }
        return;
      }
    }

    // Update stage with calendar event template ID.
    try {
      await updateStageMutation.mutateAsync({
        id: stageId,
        jobId,
        payload: {
          scheduling_calendar_email: schedulingCalendar || '',
          candidate_scheduling_calendar_email: candidateSchedulingCalendar || '',
          candidate_calendar_event_template_id: newCalendarEventTemplateId || '',
          mark_interviewer_events_as_private: markInterviewerEventsAsPrivate,
          mark_candidate_events_as_private: markCandidateEventsAsPrivate,
        },
      });
    } catch (err) {
      setIsEditing(true);
      setIsFetching(false);
      if (err instanceof Error) {
        setError(err);
      }
      return;
    }

    setIsFetching(false);
  };

  const handleSaveAs = async () => {
    if (calendarEventTemplateId && calendarEventTemplateId !== 'new') {
      // This template is based on another one, so we want to compare the names
      // to see if they're different. If they aren't, open a modal recommending
      // them to update it.
      const existingCalendarEventTemplate = await queryClient.fetchQuery(calendarEventTemplateParams(calendarEventTemplateId));
      if (calendarEventTemplateName === existingCalendarEventTemplate.name) {
        setIsTemplateNamePromptModalOpen(true);
        return;
      }
    }

    await createTemplateForSaveAs(calendarEventTemplateName);
  };

  const isCalendarEventTemplateUpdated = (stage?.schedule_template?.candidate_calendar_event_template_id || '') !== (calendarEventTemplateId || '');
  const calendarEventTemplateLinkedStages = (calendarEventTemplateId && calendarEventTemplates[calendarEventTemplateId]?.linked_resources) ?? null;
  const selectedCalendarEventTemplateIsEditableInline = !calendarEventTemplateId || (!isCalendarEventTemplateUpdated && calendarEventTemplateLinkedStages === 1) || (isCalendarEventTemplateUpdated && !calendarEventTemplateLinkedStages);

  return (
    <Section
      additionalHeaderActions={isEditing && calendarEventTemplateId && calendarEventTemplateId !== 'new' && (
        <Button
          color="gem-outline"
          iconRight={<FontAwesomeIcon icon={faSave} />}
          isDisabled={isFetching}
          onClick={handleSaveAs}
          size="small"
          value="Save as new template"
        />
      )}
      className="job-stage-calendar-events"
      isEditable
      isEditing={isEditing}
      isSaveButtonDisabled={isCalendarEventTemplateFetching || (!selectedCalendarEventTemplateIsEditableInline && hasCalendarEventTemplateBeenModified)}
      isSaving={isFetching}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      saveButtonTooltip={!selectedCalendarEventTemplateIsEditableInline && hasCalendarEventTemplateBeenModified ? (
        <Tooltip
          id="job-stage-calendar-events-disabled-save"
          position="top"
          value="You cannot edit this template from this page because it is linked to other stages."
        />
      ) : undefined}
      title="Calendar events"
    >
      <Flash
        isDismissible
        message="Successfully updated!"
        onDismiss={updateStageMutation.reset}
        showFlash={updateStageMutation.isSuccess}
        type="success"
      />
      <Flash
        message={error?.message}
        showFlash={Boolean(error)}
        type="danger"
      />
      <Flash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <div className="scheduling-calendar-form-container">
        <SchedulingCalendarSelectInput
          helperText={isEditing ? 'This calendar should be accessible to all team members who schedule interviews for this role.' : null}
          isClearable
          isDisabled={!isEditing || isFetching}
          onChange={handleSchedulingCalendarChange}
          placeholder={`Use the account default (${!calendars || !account ? 'Loading...' : !account.scheduling_calendar_email ? 'Currently unset' : !calendars.calendars[account.scheduling_calendar_email] ? 'You don\'t have permissions for that calendar' : calendars.calendars[account.scheduling_calendar_email].name})`}
          schedulingCalendar={schedulingCalendar}
        />
        <SchedulingCalendarSelectInput
          helperText={isEditing ? 'This calendar should be accessible to all team members who schedule interviews for this role.' : null}
          isClearable
          isDisabled={!isEditing || isFetching}
          label="Scheduling Calendar for Candidate Events"
          onChange={handleCandidateSchedulingCalendarChange}
          placeholder={!calendars || !account ? 'Loading...' : `Use the ${account.candidate_scheduling_calendar_email ? 'account default candidate' : 'primary'} scheduling calendar${account.candidate_scheduling_calendar_email || !schedulingCalendar ? '' : ' for this role'} (${!(account.candidate_scheduling_calendar_email || schedulingCalendar || account.scheduling_calendar_email) ? 'Currently unset' : !calendars.calendars[account.candidate_scheduling_calendar_email || schedulingCalendar || account.scheduling_calendar_email || ''] ? 'You don\'t have permissions for that calendar' : calendars.calendars[account.candidate_scheduling_calendar_email || schedulingCalendar || account.scheduling_calendar_email || ''].name})`}
          schedulingCalendar={candidateSchedulingCalendar}
        />
      </div>
      {account?.directory_type === Directory.Google && (
        <>
          <CheckboxInput
            helperText="Recommended when scheduling a sensitive candidate, e.g. an executive or existing employee."
            isChecked={markInterviewerEventsAsPrivate}
            isDisabled={!isEditing || isFetching}
            label="Make interviewer calendar events private."
            onChange={handleMarkInterviewerEventsAsPrivateChange}
          />
          <CheckboxInput
            helperText="Recommended when scheduling a sensitive candidate or when sending to their work email."
            isChecked={markCandidateEventsAsPrivate}
            isDisabled={!isEditing || isFetching}
            label="Make candidate calendar event private."
            onChange={handleMarkCandidateEventsAsPrivateChange}
          />
        </>
      )}
      <div className="form-container">
        <CalendarEventTemplateSelectInput
          isClearable
          isDisabled={!isEditing || isFetching}
          isInlineCreatable
          label="Candidate Calendar Event Template"
          onChange={handleCalendarEventTemplateChange}
          type="candidate_calendar_event"
          value={calendarEventTemplateId}
        />
      </div>
      {calendarEventTemplateId && (
        (isEditing || isFetching) ? (
          <div className="calendar-event-template-inline-form">
            <Flash
              message={<span>This template is linked to other stages. You can either create a new template here with any changes you make, or go to the <Link to={correctPath(`/app/calendar-event-templates/${calendarEventTemplateId}`)}>calendar event template page</Link> to edit the original template.</span>}
              showFlash={!selectedCalendarEventTemplateIsEditableInline}
              type="info"
            />
            <div className="form-container">
              <TextInput
                isRequired
                label="Template Name"
                onChange={handleCalendarEventTemplateNameChange}
                value={calendarEventTemplateName}
              />
            </div>
            <div className="form-container">
              <TokenInput
                editor={calendarEventTemplateTitleSlateEditor}
                isDisabled={!isEditing || isFetching}
                isRequired
                label="Title"
                pendingPreviewMessage="You can preview this token when you are scheduling a candidate."
                setValue={setCalendarEventTemplateTitleSlateValue}
                tokens={tokens}
                type="candidate_calendar_event"
                value={calendarEventTemplateTitleSlateValue}
              />
              <TextInput
                helperText="We will replace this with the video conferencing link for remote interviews."
                isDisabled={!isEditing || isFetching}
                label="Location"
                onChange={handleCalendarEventTemplateLocationChange}
                value={calendarEventTemplateLocation}
              />
            </div>
            <EditorInput
              editor={calendarEventTemplateDescriptionSlateEditor}
              exampleHtmlContent={DEFAULT_CALENDAR_EVENT_CONTENT.candidate_calendar_event.description}
              isDisabled={!isEditing || isFetching}
              label="Description"
              pendingPreviewMessage="You can preview this token when you are scheduling a candidate."
              setValue={setCalendarEventTemplateDescriptionSlateValue}
              tokens={tokens}
              type="candidate_calendar_event"
              value={calendarEventTemplateDescriptionSlateValue}
            />
            <CalendarEventTemplateAdvancedSettings
              additionalAttendees={calendarEventTemplateAdditionalAttendees}
              additionalOptionalAttendees={calendarEventTemplateAdditionalOptionalAttendees}
              isDisabled={!isEditing || isFetching}
              setAdditionalAttendees={setCalendarEventTemplateAdditionalAttendees}
              setAdditionalOptionalAttendees={setCalendarEventTemplateAdditionalOptionalAttendees}
            />
            <TemplateNamePromptModal
              isOpen={isTemplateNamePromptModalOpen}
              onSubmit={createTemplateForSaveAs}
              onToggle={() => setIsTemplateNamePromptModalOpen((prev) => !prev)}
              templateName={calendarEventTemplateName}
            />
          </div>
        ) : (
          <CalendarEventTemplateSummary
            id={calendarEventTemplateId}
            isVideoConferencingEnabled={stage?.schedule_template?.video_conferencing_enabled}
            jobId={jobId}
            pendingPreviewMessage="You can preview this token when you are scheduling a candidate."
            tokens={tokens}
          />
        )
      )}
    </Section>
  );
};

export default CalendarEventsSection;
