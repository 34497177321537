import GoogleButton from 'react-google-button';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

import Flash from './library/utils/Flash';
import MicrosoftButton from './library/inputs/MicrosoftButton';
import OutboundLink from './library/navigation/OutboundLink';
import useSyncStateWithQuery from '../hooks/use-sync-state-with-query';
import { Directory } from '../types';
import { signUpParams } from '../hooks/queries/auth';
import { useSession } from '../hooks/use-session';

import type { ReactNode, MouseEvent } from 'react';
import { correctPath } from 'libraries/gem';

const UNAUTHORIZED_STATUS_CODE = 401;

const ERROR_CODES: { [error: string]: ReactNode } = {
  // this happens when they don't select all of the necessary permissions
  insufficient_permissions: 'Not enough permissions were granted. Make sure you select all of the checkboxes when authorizing the InterviewPlanner application.',
  // this happens if the state params aren't of the correct format, which is rare
  invalid_state_param: <>Looks like something went wrong. Try signing in again, and if it continues to happen, <Link to="/contact">let us know</Link>.</>,
  // this happens if they don't have admin consent
  ms_cancel: <>Looks like you need admin approval before being able to sign up. Read more about admin consent <OutboundLink href="https://support.gem.com/hc/en-us/articles/23488253797655-What-is-admin-consent" label="Microsoft Cancel Error Message">here</OutboundLink>.</>,
  // this happens if this user doesn't have a valid Microsoft license
  ms_no_license: <>Only users with a valid Microsoft 365 license can use InterviewPlanner.</>,
  // this happens if a user tries to sign in but Microsoft admin consent has been revoked
  ms_revoked: <>Looks like admin consent was revoked for your Microsoft 365 account. Read more about admin consent and how to fix this <OutboundLink href="https://support.gem.com/hc/en-us/articles/23488253797655-What-is-admin-consent" label="Microsoft Revoked Error Message">here</OutboundLink>.</>,
  // this happens when the user clicks cancel on the Google auth page
  no_code: <>The sign in process was interrupted. Try signing in again, and if it continues to happen, <Link to="/contact">let us know</Link>.</>,
  // this should never really happen, but it's if this is the first time signing in for an account but they're already authorized for our Google app
  no_refresh_token: <>Looks like something went wrong. Try signing in again, and if it continues to happen, <Link to="/contact">let us know</Link>.</>,
  // this happens when the user's account isn't part of an organization
  non_organization: 'It doesn\'t look like that Google account is tied to an organization. Please use your company\'s Google account.',
  // any kind of 500
  server_error: <>Looks like something went wrong. Try signing in again, and if it continues to happen, <Link to="/contact">let us know</Link>.</>,
};

const Pilot = () => {
  const queryClient = useQueryClient();

  const {
    session,
    sessionError,
    isSessionLoading,
  } = useSession();

  const [, queryError] = useSyncStateWithQuery('error', '');

  const [error, setError] = useState(ERROR_CODES[queryError] ? { message: ERROR_CODES[queryError] } : null);
  const [isFetching, setIsFetching] = useState(false);

  const handleGoogleSignUp = async (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    setIsFetching(true);

    try {
      const { redirect_url } = await queryClient.fetchQuery(signUpParams(Directory.Google, window.location.pathname === '/scheduling/app/pilot'));
      window.location.href = redirect_url;
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      setIsFetching(false);
    }
  };

  const handleMicrosoft365SignUp = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setIsFetching(true);

    try {
      const { redirect_url } = await queryClient.fetchQuery(signUpParams(Directory.Microsoft));
      window.location.href = redirect_url;
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      setIsFetching(false);
    }
  };

  if (isSessionLoading) {
    return null;
  }

  if (session) {
    return <Redirect to={correctPath('/app')} />;
  }

  return (
    <div className="pilot-container">
      <Helmet>
        <title>Sign Up | Gem Scheduling</title>
      </Helmet>
      <div className="form-container">
        <h1>Welcome!</h1>
        <Flash
          message={sessionError?.message}
          showFlash={Boolean(sessionError && sessionError.status !== UNAUTHORIZED_STATUS_CODE)}
          type="danger"
        />
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <div className="auth-btn-container">
          <GoogleButton
            className="btn-google"
            disabled={isFetching}
            label="Sign up with Google"
            onClick={handleGoogleSignUp}
            style={{
              border: 'default',
              borderRadius: 'default',
              boxShadow: 'none',
              color: 'default',
              fontFamily: 'inherit',
            }}
            type="light"
          />
          <MicrosoftButton
            isDisabled={isFetching}
            onClick={handleMicrosoft365SignUp}
            value="Sign up with Microsoft"
          />
        </div>
        <p>
          By signing up, you agree to Gem&apos;s <OutboundLink externalLinkIcon={false} href="https://www.gem.com/compliance/terms" label="Gem Terms of Service">Terms of Service</OutboundLink> and <OutboundLink externalLinkIcon={false} href="https://www.gem.com/compliance/privacy" label="Gem Privacy Policy">Privacy Policy</OutboundLink>.
        </p>
        <p>
          Already have an account? <Link to={correctPath('/signin')}>Sign in here.</Link>
        </p>
      </div>
    </div>
  );
};

export default Pilot;
