import Moment from 'moment-timezone';
import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { find, isEmpty, orderBy, sortBy, uniq, uniqBy } from 'lodash';
import { useMediaQuery } from 'react-responsive';

import AvailabilityPickerEvent from './AvailabilityPickerEvent';
import AvailabilityPickerToolbar from './AvailabilityPickerToolbar';
import CheckboxInput from '../CheckboxInput';
import LoadingSpinner from '../../utils/LoadingSpinner';
import SelectInput from '../SelectInput';
import Tooltip from '../../utils/Tooltip';
import pluralize from '../../../../libraries/pluralize';
import withDragAndDrop from '../../../../libraries/react-big-calendar/addons/dragAndDrop';
import { AvailabilityPickerProvider } from './use-context';
import { Calendar, momentLocalizer } from '../../../../libraries/react-big-calendar';
import { QuickSelectValue } from './types';
import { areAvailabilitiesEqual, generateAvailabilitiesForDays } from './helpers';
import { directoryCalendarLabels, RSVP } from 'types';
import { businessHourDayOrdering } from 'types/business-hours';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { isWindowValid } from '../../../../libraries/business-hours';
import { useEvents } from '../../../../hooks/use-events';
import { useUsersMap } from '../../../../hooks/queries/users';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Directory } from 'types';
import type { CalendarProps, EventPropGetter, EventProps, SlotInfo } from 'libraries/react-big-calendar';
import type { ChangeEvent, ComponentType, Dispatch, PropsWithChildren, SetStateAction } from 'react';
import type { EditableBusinessHour } from '../../../../types/business-hours';
import type { Event, MousePosition, Time, TimeSlot } from './types';
import type { Option } from '../SelectInput/types';
import type { withDragAndDropProps } from 'libraries/react-big-calendar/addons/dragAndDrop';

const localizer = momentLocalizer(Moment);

const DragAndDropCalendar = withDragAndDrop<Event>(Calendar);

interface Props {
  allowPastTimes?: boolean;
  availabilities: TimeSlot[];
  backgroundEventTimeSlots?: TimeSlot[];
  backgroundEventTitle?: string;
  brandColor?: string;
  businessHours: EditableBusinessHour[];
  controlledTimezone?: string;
  dataDescriptor?: string;
  defaultScrollToHour?: number;
  defaultTimezone?: string;
  directory?: Directory;
  enforceConstraintsOnSelection?: boolean;
  eventTitle?: string;
  interviewerEventsAreLoading?: boolean;
  interviewerIds?: string[];
  isDraggable?: boolean;
  isMulti?: boolean;
  isRequired?: boolean;
  isSimplifiedViewByDefault?: boolean;
  showPreviewsInSimplifiedView?: boolean;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  minDuration?: number;
  onDateChange?: (date: Date) => void;
  onTimezoneChange?: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  setAvailabilities?: Dispatch<SetStateAction<TimeSlot[]>>;
  showAvailabilitiesSummary?: boolean;
  showEventWarnings?: boolean;
  showFullDay?: boolean;
  showInterviewerEventFilters?: boolean;
  showQuickSelectOptions?: boolean;
  submittedAvailabilities?: TimeSlot[];
}

const AvailabilityPicker = memo(({
  allowPastTimes = false,
  availabilities,
  backgroundEventTimeSlots,
  backgroundEventTitle = 'Suggested',
  brandColor,
  businessHours,
  controlledTimezone,
  dataDescriptor = 'availability',
  defaultScrollToHour,
  defaultTimezone,
  directory,
  enforceConstraintsOnSelection = false,
  eventTitle = 'Available',
  interviewerEventsAreLoading = false,
  interviewerIds,
  isDraggable = true,
  isMulti = true,
  isRequired = true,
  isSimplifiedViewByDefault = false,
  label,
  maxDate,
  minDate,
  minDuration,
  onDateChange,
  onTimezoneChange,
  setAvailabilities,
  showAvailabilitiesSummary = true,
  showEventWarnings = true,
  showFullDay = true,
  showInterviewerEventFilters = false,
  showPreviewsInSimplifiedView = false,
  showQuickSelectOptions = true,
  submittedAvailabilities,
}: Props) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 750px)' });

  const [mousePosition, setMousePosition] = useState<MousePosition>();

  const { events: allEvents } = useEvents();
  const users = useUsersMap();

  const CalendarComponent: ComponentType<CalendarProps<Event> & withDragAndDropProps<Event>> = useMemo(() => isDraggable && !isSmallScreen ? DragAndDropCalendar : Calendar, [isDraggable, isSmallScreen]);

  const [calendarDate, setCalendarDate] = useState(availabilities.length ? Moment(sortBy(availabilities, 'start_time')[0].start_time).toDate() : minDate);

  const [timezone, setTimezone] = useState(defaultTimezone || controlledTimezone || Moment.tz.guess());
  useEffect(() => {
    controlledTimezone && setTimezone(controlledTimezone);
  }, [controlledTimezone]);

  const [showSimplifiedInterviewerEventsView, setShowSimplifiedInterviewerEventsView] = useState<boolean>(isSimplifiedViewByDefault);
  const [showDeclinedInterviewerEvents, setShowDeclinedInterviewerEvents] = useState<boolean>(false);
  const [showIgnoredInterviewerEvents, setShowIgnoredInterviewerEvents] = useState<boolean>(false);

  const quickSelectOptions = useMemo<Option<QuickSelectValue>[]>(() => {
    const opts: (Option<QuickSelectValue> | false)[] = [!isEmpty(submittedAvailabilities) && {
      label: `Submitted ${dataDescriptor}`,
      value: QuickSelectValue.SubmittedAvailability,
    }, {
      label: `Custom ${dataDescriptor} slots`,
      value: QuickSelectValue.Custom,
    }, {
      label: 'Next 7 days',
      value: QuickSelectValue.Next7Days,
    }, {
      label: 'Next 14 days',
      value: QuickSelectValue.Next14Days,
    }];
    return opts.filter((obj): obj is Option<QuickSelectValue> => Boolean(obj));
  }, [submittedAvailabilities]);

  const handleTimezoneChange = (option: OnChangeValue<Option<string>, false>) => {
    // This shouldn't be clearable, so option should always be defined.
    setTimezone(option ? option.value : '');
  };

  const handleShowSimplifiedInterviewerEventsViewChange = (e: ChangeEvent<HTMLInputElement>) => setShowSimplifiedInterviewerEventsView(e.target.checked);
  const handleShowDeclinedInterviewerEventsChange = (e: ChangeEvent<HTMLInputElement>) => setShowDeclinedInterviewerEvents(e.target.checked);
  const handleShowIgnoredInterviewerEventsChange = (e: ChangeEvent<HTMLInputElement>) => setShowIgnoredInterviewerEvents(e.target.checked);

  const earliestBusinessHour = orderBy(businessHours, [(bh) => bh.start_time], ['asc'])[0] as EditableBusinessHour | undefined;
  const latestBusinessHour = orderBy(businessHours, [(bh) => bh.end_time], ['desc'])[0] as EditableBusinessHour | undefined;
  const allowedDaysOfWeek = uniq(businessHours.map((bh) => bh.day)).map((day) => businessHourDayOrdering[day]);
  // A lot of this code relies on a concept of business hour timezone, but we don't have a single one anymore. I'm not
  // sure if just picking the first one is really the best option, but it's better than nothing.
  const businessHoursTimezone = businessHours[0]?.timezone;

  // The way that min and max are calculated is confusing and I don't know if
  // it's good. The idea is that we take the business hour value and cast it in
  // the scheduling timezone. Once we have that, we convert it to the
  // picker's timezone, so we know the boundary in relation to the
  // picker's availability. Then we take that hour and minute value (i.e.
  // HH:mm string) and create a new Date object with those values but in the
  // local timezone. We need to do this because `min` and `max` only accept
  // native Date objects, but native Date objects don't contain timezone
  // information. And since we only need to extract the hour and minute values
  // anyway, the timezone data isn't too important. All that matters is that
  // it's a native Date object with correct hour and minute values. In addition
  // to this logic, we also need to do bounds checking in case the time has
  // rolled over or under (e.g. 00:00 as turned into 22:00). We do this just by
  // doing a lexicographical string comparison of the HH:mm string with 20:00
  // and 04:00 (+/- 4 hours from the boundary) to determine whether we should
  // snap back to 00:00 or 23:59. Here's a full example: Let's say we have a
  // start time of 10:00, a scheduling timezone of CST, a picker timezone of
  // EST, and a local timezone of PST. So first, we take 10:00 CST and convert
  // it to EST, which gives us 11:00 EST. We then take 11:00 and make a new Date
  // object at 11:00 PST. And for an example of the rollover: Let's say we have
  // a start time of 00:00, a scheduling timezone of EST, a candidate timezone
  // of CST, and a local timezone of PST. When we take 00:00 EST and convert it
  // to CST, we get 23:00 CST. Since we see that it's > '20:00', we use 00:00
  // instead and return 00:00 PST. I wrote this all out to explain my madness
  // and to justify the 2 hours I spent on these 5 lines of code.
  const min = useMemo(() => {
    const newStart = Moment
    .tz(earliestBusinessHour?.start_time || '00:00', 'HH:mm', earliestBusinessHour?.timezone || timezone)
    .tz(timezone)
    .format('HH:mm');
    return Moment(newStart, 'HH:mm').toDate();
  }, [earliestBusinessHour, timezone]);
  const max = useMemo(() => {
    const newEnd = Moment
    .tz(!latestBusinessHour?.end_time || latestBusinessHour.end_time === '24:00' ? '23:59' : latestBusinessHour.end_time, 'HH:mm', latestBusinessHour?.timezone || timezone)
    .tz(timezone)
    .format('HH:mm');
    return Moment(newEnd, 'HH:mm').toDate();
  }, [latestBusinessHour, timezone]);

  const [quickSelectValue, setQuickSelectValue] = useState(() => {
    if (submittedAvailabilities && submittedAvailabilities.length > 0 && areAvailabilitiesEqual(availabilities, submittedAvailabilities)) {
      return 'submitted_availability';
    }

    // To avoid generating the availabilities for next 7 & 14 days, we check if
    // the count of availabilities is correct. We subtract 2 days per week
    // because of weekends.
    if (availabilities.length === 5 && areAvailabilitiesEqual(availabilities, generateAvailabilitiesForDays(7, timezone, min, max))) {
      return 'next_7_days';
    }
    if (availabilities.length === 10 && areAvailabilitiesEqual(availabilities, generateAvailabilitiesForDays(14, timezone, min, max))) {
      return 'next_14_days';
    }

    return 'custom';
  });

  const isValidAvailability = useCallback((start: Date, end: Date): boolean => {
    if (!enforceConstraintsOnSelection) {
      return true;
    }
    const convertedStart = Moment.tz(Moment(start).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', timezone);
    const convertedEnd = Moment.tz(Moment(end).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', timezone);

    // Check if before minDate (used for advanced notice)
    if (minDate && convertedStart.isBefore(Moment.tz(Moment(minDate).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', businessHoursTimezone || timezone))) {
      return false;
    }
    // Check if after maxDate (used for rolling window)
    if (maxDate && convertedEnd.isAfter(Moment.tz(Moment(maxDate).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', businessHoursTimezone || timezone))) {
      return false;
    }
    // Check if it falls on a disabled day of the week
    if (
      !allowedDaysOfWeek.includes(convertedStart.tz(businessHoursTimezone || timezone).day()) ||
      !allowedDaysOfWeek.includes(convertedEnd.tz(businessHoursTimezone || timezone).day())
    ) {
      return false;
    }
    // Check if outside business hours
    return isWindowValid(convertedStart, convertedEnd, businessHours);
  }, [enforceConstraintsOnSelection, businessHoursTimezone, timezone, allowedDaysOfWeek, max, min]);

  const events = useMemo<Event[]>(() => {
    const eventsToDisplay: Event[] = [];

    // The actual availabilities to display.
    availabilities.forEach(({ id, title, start_time, end_time }, i) => {
      const start = Moment(Moment.tz(start_time, timezone).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm').toDate();
      const end = Moment(Moment.tz(end_time, timezone).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm').toDate();

      eventsToDisplay.push({
        id: id || `availability-${i}`,
        title: title || eventTitle,
        start,
        end,
        timezone,
        existing: Boolean(id),
        buffer_time: false,
        isSingleSelect: !isMulti,
      });
    });

    interviewerIds?.forEach((interviewerId) => {
      Object.values(allEvents).forEach((eventsByTimezone) => {
        eventsByTimezone[timezone]?.users?.[interviewerId]?.events?.forEach((userEvent) => {
          if (userEvent.title === 'Outside Working Hours' ||
              userEvent.title === 'Daily Interview Limit Reached' ||
              userEvent.title === 'Weekly Interview Limit Reached') {
            // We filter these out because they just cause clutter and aren't
            // "real" events.
            return;
          }

          if (!showDeclinedInterviewerEvents) {
            const interviewerAttendee = (userEvent.attendees || []).find(({ email }) => email === users[interviewerId]?.email);
            if (interviewerAttendee?.rsvp === 'declined') {
              return;
            }
          }

          if (!showIgnoredInterviewerEvents && userEvent.ignored) {
            return;
          }

          const start = Moment(Moment.tz(userEvent.start_time, businessHoursTimezone || timezone).tz(timezone).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm');
          const end = Moment(Moment.tz(userEvent.end_time, businessHoursTimezone || timezone).tz(timezone).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm');
          const duration = Moment.duration(Moment.utc(userEvent.end_time).diff(Moment.utc(userEvent.start_time))).asMinutes();
          const isMultiDayEvent = duration >= 24 * 60;
          const user = users[interviewerId];

          eventsToDisplay.push({
            id: `${userEvent.id}-${interviewerId}`,
            showSimplified: showSimplifiedInterviewerEventsView,
            showPreviews: showPreviewsInSimplifiedView,
            title: userEvent.title,
            start: start.add(isMultiDayEvent ? 1 : 0, 'seconds').toDate(),
            end: end.toDate(),
            timezone,
            interviewerEvent: true,
            color: user?.color,
            private: userEvent.private,
            attendees: userEvent.attendees || [],
            buffer_time: userEvent.buffer_time,
            rsvp: userEvent.attendees?.length === 0 ? RSVP.Accepted : (userEvent.attendees || []).find(({ email }) => email === user?.email)?.rsvp,
          });
        });
      });
    });

    return uniqBy(eventsToDisplay, 'id');
  }, [allEvents, availabilities, interviewerIds, showDeclinedInterviewerEvents, showIgnoredInterviewerEvents, showSimplifiedInterviewerEventsView, timezone, users]);

  const backgroundEvent = useMemo<Event[]>(() => {
    if (!backgroundEventTimeSlots) {
      return [];
    }

    return backgroundEventTimeSlots.map(({ title, start_time, end_time }, i) => {
      const start = Moment(Moment.tz(start_time, businessHoursTimezone || timezone).tz(timezone).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm').toDate();
      const end = Moment(Moment.tz(end_time, businessHoursTimezone || timezone).tz(timezone).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm').toDate();

      return {
        id: `suggested-time-${i}`,
        title: title || backgroundEventTitle,
        start,
        end,
        timezone,
        background: true,
        buffer_time: false,
      };
    });
  }, [businessHoursTimezone, backgroundEventTimeSlots, backgroundEventTitle, timezone]);

  const handleAvailabilitySelect = ({ action, start, end }: SlotInfo) => {
    const startTime = Moment.tz(Moment(start).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', timezone);
    let endTime = Moment.tz(Moment(end).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', timezone);
    if (minDuration && action === 'click') {
      // Extend end time to meet minimum duration
      const duration = Moment.duration(endTime.diff(startTime)).asMinutes();
      if (duration < minDuration) {
        endTime = Moment(startTime).add(minDuration, 'minutes');
      }
    }
    setQuickSelectValue(QuickSelectValue.Custom);
    setAvailabilities?.([
      ...(isMulti ? availabilities : []),
      {
        start_time: startTime.toDate(),
        end_time: endTime.toDate(),
      },
    ]);
  };

  const handleAvailabilityChange = ({ event, start, end }: { event: Event; start: Time; end: Time }) => {
    // There are some times where the end date passed in is an Invalid Date. If
    // this is added to availabilities, it gets into a weird state because many
    // actions (such as deleting and saving availabilities) relies on the times
    // being correct. So it's safer just to always check that start and end are
    // valid.
    if (typeof start !== 'string' && !isNaN(start.valueOf()) && typeof end !== 'string' && !isNaN(end.valueOf())) {
      const newEvents: Event[] = events.filter((event) => !event.interviewerEvent).map((oldEvent) => (
        oldEvent.id === event.id ?
          { ...oldEvent, start, end } :
          oldEvent
      ));
      setQuickSelectValue(QuickSelectValue.Custom);
      setAvailabilities?.(newEvents.map((e) => ({
        // If the event is for an existing availability time slot, then we want
        // to bubble up the ID.
        id: e.existing ? e.id : undefined,
        start_time: Moment.tz(Moment(e.start).format('YYYY-MM-DDTHH:mm'), timezone).toDate(),
        end_time: Moment.tz(Moment(e.end).format('YYYY-MM-DDTHH:mm'), timezone).toDate(),
      })));
    }
  };

  const draggableAccessor = useCallback((event: Event) => !event.background && !event.interviewerEvent, []);

  const EventContainerWrapper = (props: PropsWithChildren<{}>) => (
    <div
      onMouseMove={(e) => {
        setMousePosition({ x: e.clientX, y: e.clientY });
      }}
    >
      {props.children}
    </div>
  );

  const AvailabilityPickerEventContainer = (props: EventProps<Event>) => (
    <AvailabilityPickerEvent
      brandColor={brandColor}
      isDraggable={isDraggable}
      isValid={isValidAvailability}
      minDuration={minDuration}
      setAvailabilities={(newValue: SetStateAction<TimeSlot[]>) => {
        setQuickSelectValue(QuickSelectValue.Custom);
        setAvailabilities?.(newValue);
      }}
      showEventWarnings={showEventWarnings}
      timezone={timezone}
      {...props}
    />
  );

  // Workaround for issue where dragging does not start on first attempt when no
  // events are visible on initialization:
  // https://github.com/intljusticemission/react-big-calendar/issues/1105#issuecomment-459563886
  const hideFirstAvailability: EventPropGetter<Event> = (event: Event) => event.hide ? { style: { display: 'none' } } : {};
  const eventsToDisplay: Event[] = events.length ? events : [{
    start: new Date(),
    end: new Date(),
    timezone,
    title: '',
    hide: true,
    buffer_time: false,
  }];

  const getSlotClassName = (date: Time): { className?: string } => {
    const convertedDate = Moment.tz(Moment(date).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', timezone);

    // Check if before minDate (used for advanced notice)
    if (minDate && convertedDate.isBefore(Moment.tz(Moment(minDate).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', businessHoursTimezone || timezone))) {
      return { className: 'disabled out-of-range' };
    }
    // Check if after maxDate (used for rolling window)
    if (maxDate && convertedDate.isAfter(Moment.tz(Moment(maxDate).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', businessHoursTimezone || timezone))) {
      return { className: 'disabled out-of-range' };
    }
    // Check if it falls on a disabled day of the week
    if (!allowedDaysOfWeek.includes(convertedDate.tz(businessHoursTimezone || timezone).day())) {
      return { className: 'disabled' };
    }
    // Check if outside business hours
    if (!isWindowValid(convertedDate, convertedDate.clone().add(15, 'minutes'), businessHours)) {
      // We add 15m for the end time since the step of the availability picker is 15m.
      return { className: 'disabled' };
    }
    return {};
  };

  const handleAvailabilityClick = (availability: TimeSlot) => {
    setCalendarDate(Moment(availability.start_time).toDate());
  };

  const handleQuickSelectChange = (option: OnChangeValue<Option<QuickSelectValue>, false>) => {
    if (!option) {
      // option should never be null because the SelectInput isn't clearable.
      return;
    }

    setQuickSelectValue(option.value);

    let newAvailabilities;

    switch (option.value) {
      case QuickSelectValue.SubmittedAvailability:
        newAvailabilities = submittedAvailabilities || [];
        break;
      case QuickSelectValue.Next7Days:
        newAvailabilities = generateAvailabilitiesForDays(7, timezone, min, max);
        break;
      case QuickSelectValue.Next14Days:
        newAvailabilities = generateAvailabilitiesForDays(14, timezone, min, max);
        break;
    }

    if (newAvailabilities) {
      setAvailabilities?.(newAvailabilities);
      if (newAvailabilities.length > 0) {
        // All of the above ways of setting the availabilities should always be
        // sorting by start time ascending, so this should always move the
        // window to the first time slot.
        setCalendarDate(Moment(newAvailabilities[0].start_time).toDate());
      }
    }
  };

  const isTimezoneWithRollover = useMemo<boolean>(() => {
    const startTime = Moment.tz(earliestBusinessHour?.start_time || '00:00', 'HH:mm', earliestBusinessHour?.timezone || timezone);
    const endTime = Moment.tz(!latestBusinessHour?.end_time || latestBusinessHour.end_time === '24:00' ? '23:59' : latestBusinessHour.end_time, 'HH:mm', latestBusinessHour?.timezone || timezone);
    const convertedToTimezoneStartTime = Moment(startTime).tz(timezone).format('YYYY-MM-DD');
    const convertedToTimezoneEndTime = Moment(endTime).tz(timezone).format('YYYY-MM-DD');
    return convertedToTimezoneStartTime !== convertedToTimezoneEndTime;
  }, [earliestBusinessHour, latestBusinessHour, timezone, businessHoursTimezone]);

  return (
    <AvailabilityPickerProvider
      brandColor={brandColor}
      mousePosition={mousePosition}
      onTimezoneChange={onTimezoneChange || handleTimezoneChange}
      timezone={timezone}
    >
      <div className="input calendar-container availability-picker">
        {label && <label>{label}</label>}
        {showQuickSelectOptions && isDraggable && (
          <SelectInput
            className="availability-picker-quick-select"
            onChange={handleQuickSelectChange}
            options={quickSelectOptions}
            value={quickSelectValue ? find(quickSelectOptions, ['value', quickSelectValue]) : null}
          />
        )}
        {showInterviewerEventFilters &&
          <div className="interviewer-event-filters">
            {interviewerEventsAreLoading && (
              <div className="loading-status">
                <LoadingSpinner />
                Syncing from {directory ? directoryCalendarLabels[directory] : 'team calendar'}
              </div>
            )}
            <CheckboxInput
              isChecked={showSimplifiedInterviewerEventsView}
              label="Show simplified view."
              onChange={handleShowSimplifiedInterviewerEventsViewChange}
            />
            <CheckboxInput
              isChecked={showDeclinedInterviewerEvents}
              label="Show declined events."
              onChange={handleShowDeclinedInterviewerEventsChange}
            />
            <CheckboxInput
              isChecked={showIgnoredInterviewerEvents}
              label="Show ignored conflicts."
              onChange={handleShowIgnoredInterviewerEventsChange}
            />
          </div>
        }
        <input
          className="availability-input-hidden-validation"
          min={allowPastTimes ? undefined : Moment.tz(timezone).format('YYYY-MM-DDTHH:mm')}
          onChange={() => {}}
          required={isRequired}
          type="datetime-local"
          value={availabilities.length ? Moment(sortBy(availabilities, 'start_time')[0].start_time).format('YYYY-MM-DDTHH:mm') : ''}
        />
        <CalendarComponent
          backgroundEvents={backgroundEvent}
          components={{
            event: AvailabilityPickerEventContainer,
            toolbar: AvailabilityPickerToolbar,
            eventContainerWrapper: showInterviewerEventFilters ? EventContainerWrapper : undefined,
          }}
          culture={window.navigator.language}
          date={calendarDate}
          defaultDate={availabilities.length ? Moment(sortBy(availabilities, 'start_time')[0].start_time).toDate() : undefined}
          draggableAccessor={isDraggable ? draggableAccessor : undefined}
          drilldownView={null}
          endAccessor="end"
          eventPropGetter={hideFirstAvailability}
          events={eventsToDisplay}
          formats={{
            dayFormat: 'ddd, MMM D',
            timeGutterFormat: (date) => `${formatMoment(Moment(date), TimeFormat.Time)} ${Moment.tz(timezone).format('z')}`,
            selectRangeFormat: ({ start, end }) => `${formatMoment(Moment(start), TimeFormat.Time)}–${formatMoment(Moment(end), TimeFormat.Time)} ${Moment.tz(timezone).format('z')}`,
          }}
          getNow={() => Moment(Moment.tz(timezone).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm').toDate()}
          // This is only used as the key because we want to re-render the whole
          // calendar when it changes. This is because if we don't, the businessHoursTimezones
          // won't change in the Event components.
          key={timezone}
          localizer={localizer}
          longPressThreshold={150}
          max={latestBusinessHour && latestBusinessHour.end_time !== '24:00' && !showFullDay && !isTimezoneWithRollover ? max : undefined}
          min={earliestBusinessHour && earliestBusinessHour.start_time !== '00:00' && !showFullDay && !isTimezoneWithRollover ? min : undefined}
          onEventDrop={isDraggable ? handleAvailabilityChange : undefined}
          onEventResize={isDraggable ? handleAvailabilityChange : undefined}
          onNavigate={(date) => {
            onDateChange?.(date);
            setCalendarDate(date);
          }}
          onSelectSlot={isDraggable ? handleAvailabilitySelect : undefined}
          onView={() => {}}
          resizable={isDraggable}
          scrollToTime={(
            earliestBusinessHour && earliestBusinessHour.start_time !== '00:00' ?
              Moment.tz(min, timezone).subtract(1, 'hour').toDate() :
              (defaultScrollToHour ? Moment().set('hour', defaultScrollToHour).toDate() : calendarDate)
          )}
          selectable={isDraggable ? 'ignoreEvents' : undefined}
          showMultiDayTimes
          slotPropGetter={getSlotClassName}
          startAccessor="start"
          step={15}
          timeslots={4}
          view={isSmallScreen ? 'day' : 'week'}
          views={['day', 'week']}
        />
        {showAvailabilitiesSummary &&
          <div className="availabilities">
            <h6>
              Selected {pluralize(dataDescriptor, availabilities.length)} <b>({availabilities.length})</b>
            </h6>
            <div className="availabilities-list">
              {sortBy(availabilities, 'start_time').map((availability, i) => (
                <Fragment key={`availability-${i}`}>
                  <div
                    className="availability"
                    data-for={`availability-${i}-tooltip`}
                    data-tip
                    onClick={() => handleAvailabilityClick(availability)}
                  >
                    <b>{formatMoment(Moment.tz(availability.start_time, timezone), TimeFormat.ShortDayOfWeekMonthDay)}</b>, {formatMoment(Moment.tz(availability.start_time, timezone), TimeFormat.Time)}&ndash;{formatMoment(Moment.tz(availability.end_time, timezone), TimeFormat.TimeWithTimezone)}
                  </div>
                  {!isSmallScreen &&
                    <Tooltip
                      id={`availability-${i}-tooltip`}
                      position="top"
                      value={`Jump to ${dataDescriptor}`}
                    />
                  }
                </Fragment>
              ))}
            </div>
          </div>
        }
      </div>
    </AvailabilityPickerProvider>
  );
});

export default AvailabilityPicker;
