import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { faAngleRight, faCogs, faTasks, faUserFriends } from '@fortawesome/free-solid-svg-icons';

import Card from 'components/library/layout/Card';
import RequestADemoButton from 'components/Website/RequestADemoButton';
import TestimonialSection from './TestimonialSection';

const Home = () => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Automated Interview Scheduling Platform | Gem Scheduling</title>
      </Helmet>
      <section className="intro-section">
        <div className="copy-container">
          <h1>
            Beautiful, intelligent interview scheduling.
          </h1>
          <p>
            InterviewPlanner helps you get your ducks in a row when it comes to scheduling candidates.
          </p>
          <RequestADemoButton label="Home Hero Section" />
        </div>
        <figure>
          <img
            alt="A recruiting coordinator goose getting her candidate ducks in a row"
            src="/scheduling/src/assets/images/duck-candidates.svg"
          />
        </figure>
      </section>
      <TestimonialSection />
      <section className="section-banner" id="features">
        <h2>
          The complete toolkit for recruiting coordinators.
        </h2>
        <div className="cards-container">
          <Card
            icon={<FontAwesomeIcon icon={faTasks} />}
            title="Actionable daily notifications."
          >
            <p>
              Hire candidates faster by staying ahead of your scheduling tasks.
            </p>
            <Link className="btn btn-link" to="/#reduce-time-to-hire">
              Learn more <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </Card>
          <Card
            icon={<FontAwesomeIcon icon={faUserFriends} />}
            title="Intuitive interviewer management."
          >
            <p>
              Track your team&apos;s attributes and interviewer skills in one place.
            </p>
            <Link className="btn btn-link" to="/#get-rid-of-your-complicated-interviewer-spreadsheet">
              Learn more <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </Card>
          <Card
            icon={<FontAwesomeIcon icon={faCogs} />}
            title="Automated interview scheduling."
          >
            <p>
              You have more important things to do than juggle calendars.
            </p>
            <Link className="btn btn-link" to="/#spend-less-time-juggling-calendars">
              Learn more <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </Card>
        </div>
      </section>
      <section className="feature-section" id="get-started-effortlessly">
        <div className="copy-container">
          <h2>
            Get started effortlessly.
          </h2>
          <p>
            We integrate with the existing tools in your recruiting workflow. We sync with your ATS and calendar so that you can keep up with your ever-evolving company without any additional overhead.
          </p>
          <Link className="btn btn-link" to="/features/integrations">
            Learn more <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>
        <figure>
          <img
            alt="InterviewPlanner integrates with Greenhouse, Zoom, and GSuite."
            src="/scheduling/src/assets/images/integrations.svg"
          />
        </figure>
      </section>
      <section className="feature-section" id="spend-less-time-juggling-calendars">
        <div className="copy-container">
          <h2>
            Spend less time juggling calendars.
          </h2>
          <p>
            We compare calendars to find a schedule that works for everyone. By automating this process, we eliminate scheduling errors and identify availability bottlenecks so that you can focus on strategic initiatives.
          </p>
          <Link className="btn btn-link" to="/contact">
            Questions? Ask us <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>
        <figure>
          <img
            alt="Candidate ducks and their scheduled interview events on the 21st, 22nd, and 23rd."
            src="/scheduling/src/assets/images/duck-schedules.svg"
          />
        </figure>
      </section>
      <section className="feature-section" id="set-new-interviewers-up-for-success">
        <div className="copy-container">
          <h2>
            Set new interviewers up for success.
          </h2>
          <p>
            We enable you to create interviewer training programs that integrate seamlessly into the scheduling process. Trainees learn how to evaluate candidates by shadowing and leading sessions, resulting in a more standardized interview process across your team.
          </p>
          <Link className="btn btn-link" to="/features/interviewer-training">
            Learn more <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>
        <figure>
          <img
            alt="A lead interviewer and an interviewer-in-training sit across the table from a candidate."
            src="/scheduling/src/assets/images/duck-shadowing.svg"
          />
        </figure>
      </section>
      <section className="feature-section" id="get-rid-of-your-complicated-interviewer-spreadsheet">
        <div className="copy-container">
          <h2>
            Get rid of your complicated interviewer spreadsheet.
          </h2>
          <p>
            We help you manage interviewer eligibilities and attributes in one location. We act as the source of truth for your team&apos;s capabilities, locations, departments, and more.
          </p>
          <Link className="btn btn-link" to="/contact">
            Questions? Ask us <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>
        <figure>
          <img
            alt="Spreadsheet with three interviewer ducks, tags, and checkmarks indicating whether they are eligible to conduct interviews."
            src="/scheduling/src/assets/images/duck-spreadsheet.svg"
          />
        </figure>
      </section>
      <section className="feature-section" id="reduce-time-to-hire">
        <div className="copy-container">
          <h2>
            Reduce time-to-hire with actionable notifications.
          </h2>
          <p>
            We keep track of the status of all of your candidates and notify you when you need to take action, so you can rest assured that nothing will slip through the cracks. Our candidates view and email notifications ensure that you always know what to do next.
          </p>
          <Link className="btn btn-link" to="/contact">
            Questions? Ask us <FontAwesomeIcon icon={faAngleRight} />
          </Link>
        </div>
        <figure>
          <img
            alt="Recruiting coordinator duck with three to-do list items floating around her, two of which are checked off."
            src="/scheduling/src/assets/images/duck-todo-list.svg"
          />
        </figure>
      </section>
      <section className="section-cta">
        <h1>
          Interested?
        </h1>
        <p>
          Let&apos;s chat about how InterviewPlanner can transform the way you schedule.
        </p>
        <RequestADemoButton label="Home Bottom CTA Section" />
      </section>
    </div>
  );
};

export default Home;
