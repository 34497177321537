import type { AllowedTime } from './allowed-times';
import type { BusinessHour } from './business-hours';
import type { CalendarEventTemplate } from './calendar-event-templates';
import type { EmailFollowUpTemplate } from './email-follow-ups';
import type { EmailTemplate } from './email-templates';

export enum SchedulingWorkflow {
  RequestAvailability = 'request_availability',
  ScheduleNow = 'schedule_now',
  SelfSchedule = 'self_schedule',
}

export enum RoomFilterType {
  ScheduleTemplates = 'schedule_templates',
  InterviewTemplates = 'interview_templates',
}

export enum ZoomHostFilterType {
  ScheduleTemplates = 'schedule_templates',
  InterviewTemplates = 'interview_templates',
}

export enum RoomFilterExpressionFilterableType {
  Room = 'room',
  Tag = 'tag',
}

export enum ZoomHostFilterableType {
  Role = 'role',
  Room = 'room',
  Tag = 'tag',
  User = 'user',
}

export interface ScheduleTemplate {
  id: string;
  default_workflow: SchedulingWorkflow;
  business_hours: BusinessHour[] | null;
  allowed_times: AllowedTime[] | null;
  scheduling_interval_minutes: number;
  account_id: string;
  scheduling_calendar_email?: string;
  candidate_scheduling_calendar_email?: string;
  candidate_event_title: string;
  candidate_event_description: string;
  candidate_event_location?: string;
  candidate_event_additional_attendees?: string[];
  candidate_event_additional_optional_attendees?: string[];
  candidate_calendar_event_template_id?: string;
  candidate_calendar_event_template?: CalendarEventTemplate;
  onsite: boolean;
  mark_interviewer_events_as_private: boolean;
  mark_candidate_events_as_private: boolean;
  video_conferencing_enabled: boolean;
  create_hiring_channel: boolean;
  zoom_host_filters: ZoomHostFilter[] | null;
  confirmation_email_template_id?: string;
  confirmation_email_template?: EmailTemplate;
  multi_block_confirmation_email_template_id?: string;
  multi_block_confirmation_email_template?: EmailTemplate;
  self_scheduling_automatic_sends_enabled_at?: string;
  self_scheduling_automatic_requester?: string;
  self_scheduling_advanced_notice_hours: number;
  self_scheduling_reschedule_enabled: boolean;
  self_scheduling_reschedule_notice_hours?: number;
  self_scheduling_cancel_enabled: boolean;
  self_scheduling_cancel_notice_hours?: number;
  self_scheduling_request_email_template_id?: string;
  self_scheduling_request_email_template?: EmailTemplate;
  self_scheduling_email_follow_up_templates: EmailFollowUpTemplate[] | null;
  inline: boolean;
  room_filters: RoomFilter[];
  created_at: string;
  updated_at: string;
  deleted: boolean;
}

export interface RoomFilter {
  id: string;
  template_id: string;
  template_type: RoomFilterType;
  position: number;
  room_filter_expressions: RoomFilterExpression[];
  created_at: string;
}

export interface RoomFilterExpression {
  id: string;
  room_filter_id: string;
  negated: boolean;
  filterable_id: string;
  filterable_type: RoomFilterExpressionFilterableType;
  created_at: string;
}

export interface ZoomHostFilter {
  id: string;
  template_id: string;
  template_type: ZoomHostFilterType;
  position: number;
  zoom_host_filter_expressions: ZoomHostFilterExpression[];
  created_at: string;
}

export interface ZoomHostFilterExpression {
  id: string;
  zoom_host_filter_id: string;
  negated: boolean;
  filterable_id: string;
  filterable_type: ZoomHostFilterableType;
  created_at: string;
}

export interface EditableZoomHostFilter {
  zoom_host_filter_expressions: EditableZoomHostFilterExpression[];
}

export interface EditableZoomHostFilterExpression {
  negated: boolean;
  filterable_id: string;
  filterable_type: `${ZoomHostFilterableType}`;
}

export interface EditableRoomFilter {
  room_filter_expressions: EditableRoomFilterExpression[];
}

export interface EditableRoomFilterExpression {
  negated: boolean;
  filterable_id: string;
  filterable_type: `${RoomFilterExpressionFilterableType}`;
}

export type EditableScheduleTemplate = Omit<ScheduleTemplate, 'zoom_host_filters' | 'room_filters'> & {
  zoom_host_filters?: EditableZoomHostFilter[] | null;
  room_filters?: EditableRoomFilter[] | null;
};
