import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import CalendarEventSection from './CalendarEventSection';
import Flash from 'components/library/utils/Flash';
import HiringMeetingSection from './HiringMeetingSection';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import SummarySection from './SummarySection';
import { HiringMeetingStatus } from '../../../../../../types';
import { StyledContainer, StyledFlash } from './styles';
import { formatMoment, TimeFormat } from 'libraries/time';
import { useApplication } from 'hooks/queries/applications';
import { useHiringMeeting } from 'hooks/queries/hiring-meetings';
import { correctPath } from 'libraries/gem';

const CandidateHiringMeeting = () => {
  const { id, hiringMeetingId } = useParams<{ id: string; hiringMeetingId: string }>();

  const { data: application } = useApplication(id);

  const {
    data: hiringMeeting,
    isLoading,
    isError,
    error,
  } = useHiringMeeting(hiringMeetingId, {
    // We don't want to keep the previous data, otherwise, navigating to other
    // hiring meetings produces weird results.
    keepPreviousData: false,
  });

  if (isLoading) {
    return (
      <StyledContainer>
        <LoadingSpinner />
      </StyledContainer>
    );
  }

  const meetingDate = hiringMeeting ? formatMoment(Moment.tz(hiringMeeting.start_time, hiringMeeting.timezone), TimeFormat.ShortMonthDayYear) : '';

  return (
    <Breadcrumb
      data={{
        title: isError ? hiringMeetingId : `${hiringMeeting?.stage.name} (${meetingDate})`,
        pathname: correctPath(`/app/candidates/${application?.id}/hiring-meetings/${hiringMeetingId}`),
      }}
    >
      <StyledContainer>
        {isError ? (
          <Flash
            message={error?.message}
            showFlash={isError}
            type="danger"
          />
        ) : (<>
          <Helmet>
            <title>{hiringMeeting?.stage.name} ({meetingDate}) | {application?.candidate.name || 'Unknown'} ({application?.job.name}) | Gem Scheduling</title>
          </Helmet>
          {hiringMeeting && (
            <>
              <StyledFlash
                message="This hiring meeting has been cancelled and all calendar events have been deleted."
                showFlash={hiringMeeting.status === HiringMeetingStatus.Cancelled || hiringMeeting.status === HiringMeetingStatus.Cancelling}
                type="danger"
              />
              <SummarySection hiringMeeting={hiringMeeting} />
              <HiringMeetingSection />
              <CalendarEventSection hiringMeeting={hiringMeeting} />
            </>
          )}
        </>)}
      </StyledContainer>
    </Breadcrumb>
  );
};

export default CandidateHiringMeeting;
