import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { groupBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Flash from '../../../../library/utils/Flash';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import Section from '../../../../library/layout/Section';
import TrainingProgramInterviewersTable from './TrainingProgramInterviewersTable';
import TrainingProgramPendingChangesFlash from './TrainingProgramPendingChangesFlash';
import useSyncStateWithQuery from '../../../../../hooks/use-sync-state-with-query';
import {
  PendingChangeType,
} from './types';
import { StyledTabContainer } from '../styles';
import { useTrainingProgram } from '../../../../../hooks/queries/training-programs';
import {
  useCreateTrainingProgramUser,
  useDeleteTrainingProgramUser,
  useTrainingProgramUsers,
} from '../../../../../hooks/queries/training-program-users';

import type { ChangeEvent } from 'react';
import type { PendingChange, PendingChangeUser,
  PendingChangeTrainingProgramUserCreate,
  PendingChangeTrainingProgramUserDelete,
  PendingChangeTrainingSessionDelete,
  PendingChangeTrainingSessionCreate,
  PendingChangeTrainingSessionIgnore,
  PendingChangeTrainingSessionUnignore,
  PendingChangeTrainingProgramUserGraduate,
  PendingChangeTrainingProgramUserUngraduate,
  PendingChangeTrainingOverrideCreate,
  PendingChangeTrainingOverrideUpdate,
  PendingChangeTrainingOverrideDelete } from './types';
import {
  useCreateTrainingSession,
  useDeleteTrainingSession,
  useUpdateTrainingSession,
} from '../../../../../hooks/queries/training-sessions';
import {
  useCreateTrainingOverride,
  useDeleteTrainingOverride,
  useUpdateTrainingOverride,
} from '../../../../../hooks/queries/training-overrides';
import { correctPath } from 'libraries/gem';

const TrainingProgramInterviewers = () => {
  const { id: trainingProgramId } = useParams<{ id: string }>();

  const [showGraduated, , setShowGraduated] = useSyncStateWithQuery<string>('show_graduated', 'true');

  const [trainingProgramUsers, setTrainingProgramUsers] = useState<PendingChangeUser[] | undefined>();
  const [pendingChanges, setPendingChanges] = useState<PendingChange[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const trainingProgram = useTrainingProgram(trainingProgramId).data!;

  const {
    data: originalTrainingProgramUsers,
    error,
    refetch,
  } = useTrainingProgramUsers(trainingProgramId, {
    graduated: showGraduated === 'true',
  }, {
    // We set the cache time to 0 (meaning it will not keep any of this data in the cache and will discard it as soon as
    // it becomes "inactive" i.e. when this query hook unmounts) because of the useEffect down below. To avoid
    // overwriting any potential changes, we don't every update the local state except for the very first time. But as a
    // result, our data will constantly stay stale. If I navigate away and schedule a candidate and then come back to
    // this page, that new schedule wouldn't be reflected here because it loads the data from the cache first and that's
    // outdated. This setting makes it so that it will fetch new data every time this component is mounted, so it should
    // always be as fresh as we can make it.
    cacheTime: 0,
  });

  const createTrainingProgramUserMutation = useCreateTrainingProgramUser();
  const deleteTrainingProgramUserMutation = useDeleteTrainingProgramUser();
  const createTrainingSessionMutation = useCreateTrainingSession();
  const updateTrainingSessionMutation = useUpdateTrainingSession();
  const deleteTrainingSessionMutation = useDeleteTrainingSession();
  const createTrainingOverrideMutation = useCreateTrainingOverride();
  const updateTrainingOverrideMutation = useUpdateTrainingOverride();
  const deleteTrainingOverrideMutation = useDeleteTrainingOverride();

  useEffect(() => {
    if (originalTrainingProgramUsers && (!trainingProgramUsers || !isEditing)) {
      // We only want this to run the first time. If we don't restrict that, it could run randomly while there are in
      // progress changes, clearing everything out.
      setTrainingProgramUsers(originalTrainingProgramUsers.users);
    }
  }, [originalTrainingProgramUsers, trainingProgramUsers]);

  const handleShowGraduatedChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setShowGraduated(JSON.stringify(e.target.checked));
  }, []);

  const handleEdit = () => {
    setIsEditing(true);
    setIsSuccess(false);
  };

  const handleCancel = () => {
    setTrainingProgramUsers(originalTrainingProgramUsers?.users);
    setPendingChanges([]);
    setIsEditing(false);
    setErrors([]);
  };

  const handleSave = async () => {
    setIsSaving(true);
    setErrors([]);
    setIsSuccess(false);

    // We don't need to check if all the user create changes have a user ID set because that is a required input, so we
    // can't get here if it's not selected.

    const changesByType = groupBy(pendingChanges, 'type');

    // Adding, graduating, and ungraduating users. All three of these actions uses the same endpoint.
    if (changesByType[PendingChangeType.TrainingProgramUserCreate] || changesByType[PendingChangeType.TrainingProgramUserGraduate] || changesByType[PendingChangeType.TrainingProgramUserUngraduate]) {
      const errs: string[] = [];
      const changes = [
        ...(changesByType[PendingChangeType.TrainingProgramUserCreate] || []),
        ...(changesByType[PendingChangeType.TrainingProgramUserGraduate] || []),
        ...(changesByType[PendingChangeType.TrainingProgramUserUngraduate] || []),
      ] as (PendingChangeTrainingProgramUserCreate | PendingChangeTrainingProgramUserGraduate | PendingChangeTrainingProgramUserUngraduate)[];
      await Promise.all(changes.map(async (change) => {
        try {
          await createTrainingProgramUserMutation.mutateAsync({
            trainingProgramId,
            payload: {
              // All of these users should have an ID already.
              user_id: change.user.id!,
              trainee: change.user.trainee,
            },
          });
        } catch (err) {
          if (err instanceof Error) {
            const verb = (() => {
              switch (change.type) {
                case PendingChangeType.TrainingProgramUserCreate:
                  return 'Adding';
                case PendingChangeType.TrainingProgramUserGraduate:
                  return 'Graduating';
                case PendingChangeType.TrainingProgramUserUngraduate:
                  return 'Ungraduating';
              }
            })();
            errs.push(`${verb} ${change.user.name}: ${err.message}`);
          }
        }
      }));
      if (errs.length > 0) {
        setIsSaving(false);
        setErrors(errs);
        return;
      }
    }

    // Delete manual sessions.
    if (changesByType[PendingChangeType.TrainingSessionDelete]) {
      const errs: string[] = [];
      const changes = changesByType[PendingChangeType.TrainingSessionDelete] as PendingChangeTrainingSessionDelete[];
      await Promise.all(changes.flatMap(async (change) => {
        return await Promise.all(change.sessions.map(async (session) => {
          try {
            await deleteTrainingSessionMutation.mutateAsync({
              trainingProgramId,
              id: session.id,
            });
          } catch (err) {
            if (err instanceof Error) {
              errs.push(`Removing manually completed ${session.phase_name} interview for ${change.user.name}: ${err.message}`);
            }
          }
        }));
      }));
      if (errs.length > 0) {
        setIsSaving(false);
        setErrors(errs);
        return;
      }
    }

    // Ignore sessions.
    if (changesByType[PendingChangeType.TrainingSessionIgnore]) {
      const errs: string[] = [];
      const changes = changesByType[PendingChangeType.TrainingSessionIgnore] as PendingChangeTrainingSessionIgnore[];
      await Promise.all(changes.flatMap(async (change) => {
        return await Promise.all(change.sessions.map(async (session) => {
          try {
            await updateTrainingSessionMutation.mutateAsync({
              trainingProgramId,
              id: session.id,
              payload: {
                ignored: true,
              },
            });
          } catch (err) {
            if (err instanceof Error) {
              errs.push(`Ignoring ${session.phase_name} interview for ${change.user.name}: ${err.message}`);
            }
          }
        }));
      }));
      if (errs.length > 0) {
        setIsSaving(false);
        setErrors(errs);
        return;
      }
    }

    // Unignore sessions.
    if (changesByType[PendingChangeType.TrainingSessionUnignore]) {
      const errs: string[] = [];
      const changes = changesByType[PendingChangeType.TrainingSessionUnignore] as PendingChangeTrainingSessionUnignore[];
      await Promise.all(changes.flatMap(async (change) => {
        return await Promise.all(change.sessions.map(async (session) => {
          try {
            await updateTrainingSessionMutation.mutateAsync({
              trainingProgramId,
              id: session.id,
              payload: {
                ignored: false,
              },
            });
          } catch (err) {
            if (err instanceof Error) {
              errs.push(`Unignoring ${session.phase_name} interview for ${change.user.name}: ${err.message}`);
            }
          }
        }));
      }));
      if (errs.length > 0) {
        setIsSaving(false);
        setErrors(errs);
        return;
      }
    }

    // Create sessions.
    if (changesByType[PendingChangeType.TrainingSessionCreate]) {
      const errs: string[] = [];
      const changes = changesByType[PendingChangeType.TrainingSessionCreate] as PendingChangeTrainingSessionCreate[];
      await Promise.all(changes.flatMap(async (change) => {
        return await Promise.all(change.sessions.map(async (session) => {
          try {
            await createTrainingSessionMutation.mutateAsync({
              trainingProgramId,
              payload: {
                training_phase_id: session.phase.id,
                user_id: change.user.id!,
              },
            });
          } catch (err) {
            if (err instanceof Error) {
              errs.push(`Manually completing ${session.phase.name} interview for ${change.user.name}: ${err.message}`);
            }
          }
        }));
      }));
      if (errs.length > 0) {
        setIsSaving(false);
        setErrors(errs);
        return;
      }
    }

    // Creating overrides.
    if (changesByType[PendingChangeType.TrainingOverrideCreate]) {
      const errs: string[] = [];
      const changes = changesByType[PendingChangeType.TrainingOverrideCreate] as PendingChangeTrainingOverrideCreate[];
      await Promise.all(changes.map(async (change) => {
        try {
          await createTrainingOverrideMutation.mutateAsync({
            trainingProgramId,
            payload: {
              user_id: change.user.id!,
              training_phase_id: change.phase.id,
              number_of_interviews: change.value,
            },
          });
        } catch (err) {
          if (err instanceof Error) {
            errs.push(`Customizing ${change.phase.name} phase for ${change.user.name}: ${err.message}`);
          }
        }
      }));
      if (errs.length > 0) {
        setIsSaving(false);
        setErrors(errs);
        return;
      }
    }

    // Updating overrides.
    if (changesByType[PendingChangeType.TrainingOverrideUpdate]) {
      const errs: string[] = [];
      const changes = changesByType[PendingChangeType.TrainingOverrideUpdate] as PendingChangeTrainingOverrideUpdate[];
      await Promise.all(changes.map(async (change) => {
        try {
          await updateTrainingOverrideMutation.mutateAsync({
            trainingProgramId,
            id: change.override.id,
            payload: {
              number_of_interviews: change.value,
            },
          });
        } catch (err) {
          if (err instanceof Error) {
            errs.push(`Customizing ${change.phase.name} phase for ${change.user.name}: ${err.message}`);
          }
        }
      }));
      if (errs.length > 0) {
        setIsSaving(false);
        setErrors(errs);
        return;
      }
    }

    // Deleting overrides.
    if (changesByType[PendingChangeType.TrainingOverrideDelete]) {
      const errs: string[] = [];
      const changes = changesByType[PendingChangeType.TrainingOverrideDelete] as PendingChangeTrainingOverrideDelete[];
      await Promise.all(changes.map(async (change) => {
        try {
          await deleteTrainingOverrideMutation.mutateAsync({
            trainingProgramId,
            id: change.override.id,
          });
        } catch (err) {
          if (err instanceof Error) {
            errs.push(`Reverting ${change.phase.name} phase for ${change.user.name}: ${err.message}`);
          }
        }
      }));
      if (errs.length > 0) {
        setIsSaving(false);
        setErrors(errs);
        return;
      }
    }

    // Deleting users.
    if (changesByType[PendingChangeType.TrainingProgramUserDelete]) {
      const errs: string[] = [];
      const changes = changesByType[PendingChangeType.TrainingProgramUserDelete] as PendingChangeTrainingProgramUserDelete[];
      await Promise.all(changes.map(async (change) => {
        try {
          await deleteTrainingProgramUserMutation.mutateAsync({
            trainingProgramId,
            // All of these users should have an ID already.
            userId: change.user.id!,
          });
        } catch (err) {
          if (err instanceof Error) {
            errs.push(`Removing ${change.user.name}: ${err.message}`);
          }
        }
      }));
      if (errs.length > 0) {
        setIsSaving(false);
        setErrors(errs);
        return;
      }
    }

    // Now that we've made all the changes, reload all users from the server.
    const { data: refetchedData } = await refetch();
    if (refetchedData) {
      setTrainingProgramUsers(refetchedData.users);
    }

    setIsSaving(false);
    setIsSuccess(true);
    setPendingChanges([]);
    setIsEditing(false);
  };

  return (
    <Breadcrumb
      data={{
        title: 'Interviewers',
        pathname: correctPath(`/app/training-programs/${trainingProgramId}/interviewers`),
      }}
    >
      <StyledTabContainer>
        <Helmet>
          <title>{trainingProgram.eligibility} | Interviewers | Training Programs | Gem Scheduling</title>
        </Helmet>
        <Section
          isEditable
          isEditing={isEditing}
          isSaving={isSaving}
          onCancel={handleCancel}
          onEdit={handleEdit}
          onSave={handleSave}
          title="Interviewers"
        >
          <Flash
            isDismissible
            message="Successfully updated!"
            onDismiss={() => setIsSuccess(false)}
            showFlash={isSuccess}
            type="success"
          />
          <Flash
            message={error?.message}
            showFlash={Boolean(error)}
            type="danger"
          />
          <Flash
            message={(
              <>
                We encountered some errors when saving your changes:
                <ul>
                  {errors.map((error, i) => (
                    <li key={i}>{error}</li>
                  ))}
                </ul>
              </>
            )}
            showFlash={errors.length > 0}
            type="danger"
          />
          <TrainingProgramPendingChangesFlash pendingChanges={pendingChanges} />
          {!trainingProgramUsers && <LoadingSpinner />}
          {trainingProgramUsers && (
            <TrainingProgramInterviewersTable
              isEditing={isEditing}
              onShowGraduatedChange={handleShowGraduatedChange}
              originalTrainingProgramUsers={originalTrainingProgramUsers!.users}
              pendingChanges={pendingChanges}
              setPendingChanges={setPendingChanges}
              setTrainingProgramUsers={setTrainingProgramUsers}
              showGraduated={showGraduated === 'true'}
              totalCount={trainingProgramUsers.length}
              trainingProgram={trainingProgram}
              trainingProgramUsers={trainingProgramUsers}
            />
          )}
        </Section>
      </StyledTabContainer>
    </Breadcrumb>
  );
};

export default TrainingProgramInterviewers;
