import {
  faFlag,
  faHandsHelping,
  faLightbulb,
  faPaintBrush,
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import Card from '../library/layout/Card';
import OutboundLink from '../library/navigation/OutboundLink';

const About = () => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About | InterviewPlanner</title>
      </Helmet>
      <section className="intro-section">
        <div className="copy-container">
          <h6>
            <FontAwesomeIcon icon={faFlag} /> Our Mission
          </h6>
          <h1>
            We make recruiting teams more efficient, proactive, and successful.
          </h1>
        </div>
      </section>
      <section className="goal-section">
        <div className="copy-container">
          <h2>
            We believe that recruiting workflows can be better.
          </h2>
          <div className="copy">
            <p>
              Too often, the hiring process involves inefficient back-and-forth communication and manual tasks across various tools. This gives teams less time to curate candidate experiences.
            </p>
            <p>
              <b>Our goal is</b> to take care of these logistics and ease the burden on recruiters and recruiting coordinators, so that they can focus on what matters&mdash;building relationships with their candidates.
            </p>
          </div>
        </div>
      </section>
      <section className="why-section" id="why-interview-scheduling">
        <div className="copy-container">
          <h2>
            Why interview scheduling?
          </h2>
          <p>
            In 2019, we worked on a small team that was growing rapidly. We were scheduling dozens of interviews a week, and our recruiting coordinators were swamped.  This time of growth really magnified the difficulties we were facing with our existing scheduling tool.
          </p>
          <p>
            We were spending too much time learning how to configure our scheduling tool in an effective way. Some onsites were taking over an hour to schedule, especially if there were really busy interviewers on the panel. We lost candidates who were on a tight timeline because we couldn&apos;t schedule them soon enough. We had little to no visibility into why a schedule couldn&apos;t be created or what the most common bottlenecks were. To make up for gaps in the scheduling tool, we found ourselves keeping track of interviewer load, interviewer pools, and training statuses in spreadsheets, but those became outdated quickly.
          </p>
          <p>
            What we needed was a more comprehensive platform that treated these things as first-class citizens. By collaborating with our recruiting coordinators, we started to imagine a friendlier, more streamlined system that made their jobs easier.
          </p>
        </div>
        <figure>
          <img
            alt="Recruiting coordinator duck with too many things to do."
            src="/scheduling/src/assets/images/frazzled-duck.svg"
          />
        </figure>
      </section>
      <section className="what-section" id="what-we-do">
        <div className="copy-container">
          <h2>
            What we do
          </h2>
          <p>
            We&apos;re building an interview scheduling platform that&apos;s <b>simple</b> to set up, <b>customizable</b> enough to handle any special edge cases, and actually <b>feels good</b> to use.
          </p>
          <p>
            We recognize that scheduling interviews is an extremely nuanced task that requires a lot of manual adjustments. No one knows your team&apos;s calendar like you do. Our goal is to provide recruiting teams with all of the information and structure that they need to schedule interviews, but give them the flexibility to play with the results.
          </p>
          <p>
            As we grow, our aim is to create a platform that integrates all scheduling-related needs in one place, so coordinators no longer have to jump between their calendar system, ATS, video conferencing software, and email just to schedule a candidate.
          </p>
        </div>
      </section>
      <section className="values-section" id="what-we-strive-to-be">
        <h2>
          What we strive to be
        </h2>
        <div className="cards-container">
          <div className="card-column">
            <Card
              icon={<FontAwesomeIcon icon={faSquare} />}
              title="Transparent"
            >
              <p>
                Building a relationship with the recruiting community is very important to us, and we believe the foundation for that is open, transparent communication. We want to build a platform that recruiting teams can trust.
              </p>
              <p>
                This is why we provide previews of all candidate communication before sending anything out. This is why we aim to give visibility into scheduling errors and bottlenecks.
              </p>
              <p>
                This is why we have a <Link to="/roadmap">public roadmap</Link>. We want our users to know what we&apos;re prioritizing and we want their feedback to help us build a better product together.
              </p>
            </Card>
            <Card
              icon={<FontAwesomeIcon icon={faHandsHelping} />}
              title="Empathetic"
            >
              <p>
                We know that coordinating schedules is not an easy job. We listen to our users to understand their needs, pain points, and current workflows. We want to work closely with recruiting teams throughout our development process and as they use our platform.
              </p>
              <p>
                We are kind and respectful to everyone we interact with. We are accepting of people&apos;s beliefs, values, identities, and experiences.
              </p>
            </Card>
          </div>
          <div className="card-column">
            <Card
              icon={<FontAwesomeIcon icon={faPaintBrush} />}
              title="Quality-focused"
            >
              <p>
                We prioritize user experience, beauty, and consistency. We take pride in the product we build and we want recruiting teams to genuinely enjoy using it.
              </p>
              <p>
                We value thoughtful, justifiable decision-making. We continually gather feedback from users to make customer-focused choices.
              </p>
              <p>
                We research thoroughly to come up with elegant solutions that address the root cause of problems, rather than superficially fixing the symptoms.
              </p>
            </Card>
            <Card
              icon={<FontAwesomeIcon icon={faLightbulb} />}
              title="Fresh"
            >
              <p>
                We are open-minded to new approaches and are always thinking of ways we can differentiate our product and our brand.
              </p>
              <p>
                We encourage experimentation, with a game plan for how it can translate into potential action.
              </p>
              <p>
                At the individual level, we prioritize personal well-being and mental health. We guard against burnout and encourage taking rest to stay creative and inspired.
              </p>
            </Card>
          </div>
        </div>
      </section>
      <section className="founders-section" id="meet-the-founders">
        <div className="copy-container">
          <h2>
            Meet the founders
          </h2>
          <div className="founders-container">
            <div className="founder">
              <img
                alt="Robin Joseph"
                src="/scheduling/src/assets/images/robin.jpg"
              />
              <h3>Robin Joseph</h3>
              <h6>Co-Founder</h6>
              <OutboundLink
                externalLinkIcon={false}
                href="https://linkedin.com/in/robin-joseph-0207065b"
                label="About Page"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </OutboundLink>
            </div>
            <div className="founder">
              <img
                alt="Pallavi Delgado"
                src="/scheduling/src/assets/images/pallavi.jpg"
              />
              <h3>Pallavi Delgado</h3>
              <h6>Co-Founder</h6>
              <OutboundLink
                externalLinkIcon={false}
                href="https://linkedin.com/in/pallavidelgado"
                label="About Page"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </OutboundLink>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
