import { Helmet } from 'react-helmet-async';

const WebsiteNotFound = () => {
  return (
    <div className="not-found-container">
      <Helmet>
        <title>Not Found | Gem Scheduling</title>
      </Helmet>
      <h1>
        Oh no! We can&apos;t find that page.
      </h1>
    </div>
  );
};

export default WebsiteNotFound;
