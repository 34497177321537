import { faCalendar, faEnvelope, faLaptopCode, faMessage, faUserTie, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import FeaturePage from 'components/library/layout/FeaturePage';
import IntegrationLogo from './IntegrationLogo';
import { StyledDivider, StyledIntegrationLogoContainer, StyledPageContainer, StyledQuestionsSection, StyledTitleSection } from './styles';

import {
  ATS, atsLabels, atsLogos,
  ChatTool, chatLabels, chatLogos,
  Directory, directoryCalendarLabels, directoryCalendarLogos,
  directoryEmailLabels, directoryEmailLogos,
  LiveCodingTool, liveCodingLabels, liveCodingLogos,
  VideoConferencingTool, videoConferencingLabels, videoConferencingLogos,
} from 'types';

const Integrations = () => {
  return (
    <StyledPageContainer>
      <Helmet>
        <title>Integrations | Gem Scheduling</title>
      </Helmet>
      <StyledTitleSection>
        <h1>
          Seamlessly in sync.
        </h1>
        <p>
          We integrate with your recruiting stack. No need to jump between your calendar, ATS, video conferencing, and email just to schedule an interview.
        </p>
      </StyledTitleSection>
      <StyledDivider />
      <FeaturePage
        features={[{
          kicker: 'Applicant tracking systems',
          kickerIcon: <FontAwesomeIcon icon={faUserTie} />,
          title: 'Supercharge your ATS.',
          description: <>We recognize that your ATS is your source of truth. Our scheduling functionality is built on top of it, and we sync all schedules, reschedules, emails, and candidate actions back to it.</>,
          figure: (
            <StyledIntegrationLogoContainer>
              {(Object.values(ATS) as ATS[]).map((ats) => (
                <IntegrationLogo
                  img={atsLogos[ats]}
                  key={atsLabels[ats]}
                  label={atsLabels[ats]}
                />
              ))}
            </StyledIntegrationLogoContainer>
          ),
        }, {
          kicker: 'Calendars',
          kickerIcon: <FontAwesomeIcon icon={faCalendar} />,
          title: 'Streamline scheduling.',
          description: <>We use your team&apos;s calendar to instantly build working schedules or let candidates book their own one-on-ones, based on realtime interviewer availability. We also send calendar invites to your candidate and interviewers, report back their RSVPs, and automatically update any agendas in event descriptions on reschedules and cancellations.</>,
          figure: (
            <StyledIntegrationLogoContainer>
              {(Object.values(Directory) as Directory[]).map((directory) => (
                <IntegrationLogo
                  img={directoryCalendarLogos[directory]}
                  key={directoryCalendarLabels[directory]}
                  label={directoryCalendarLabels[directory]}
                />
              ))}
            </StyledIntegrationLogoContainer>
          ),
        }, {
          kicker: 'Email',
          kickerIcon: <FontAwesomeIcon icon={faEnvelope } />,
          title: 'Have a personal touch.',
          description: 'We send emails that look like they come from you—not from a platform—while using highly customizable templates behind the scenes. Collect availability, request self-scheduling, send interview confirmations, and easily cover for your teammates by sending emails on behalf of them.',
          figure: (
            <StyledIntegrationLogoContainer>
              {(Object.values(Directory) as Directory[]).map((directory) => (
                <IntegrationLogo
                  img={directoryEmailLogos[directory]}
                  key={directoryEmailLabels[directory]}
                  label={directoryEmailLabels[directory]}
                />
              ))}
            </StyledIntegrationLogoContainer>
          ),
        }, {
          kicker: 'Video conferencing',
          kickerIcon: <FontAwesomeIcon icon={faVideo} />,
          title: 'Be remote-friendly.',
          description: 'We keep it simple with one video conferencing link for the whole interview day, so candidates don\'t need to jump between tabs.',
          figure: (
            <StyledIntegrationLogoContainer>
              {(Object.values(VideoConferencingTool) as VideoConferencingTool[]).map((tool) => (
                <IntegrationLogo
                  className={`integration-${tool}`}
                  img={videoConferencingLogos[tool]}
                  key={videoConferencingLabels[tool]}
                  label={videoConferencingLabels[tool]}
                />
              ))}
            </StyledIntegrationLogoContainer>
          ),
        }, {
          kicker: 'Live coding',
          kickerIcon: <FontAwesomeIcon icon={faLaptopCode} />,
          title: 'Assess technical skills.',
          description: 'We create a live coding session link per technical interview and automatically add them to interviewer and candidate communication.',
          figure: (
            <StyledIntegrationLogoContainer>
              {(Object.values(LiveCodingTool) as LiveCodingTool[]).map((tool) => (
                <IntegrationLogo
                  img={liveCodingLogos[tool]}
                  key={liveCodingLabels[tool]}
                  label={liveCodingLabels[tool]}
                />
              ))}
            </StyledIntegrationLogoContainer>
          ),
        }, {
          kicker: 'Chat',
          kickerIcon: <FontAwesomeIcon icon={faMessage} />,
          title: 'Discuss candidates privately.',
          description: 'We create hiring channels for each candidate and keep the members up to date when you swap out interviewers.',
          figure: (
            <StyledIntegrationLogoContainer>
              {(Object.values(ChatTool) as ChatTool[]).map((tool) => (
                <IntegrationLogo
                  img={chatLogos[tool]}
                  key={chatLabels[tool]}
                  label={chatLabels[tool]}
                />
              ))}
            </StyledIntegrationLogoContainer>
          ),
        }]}
      />
      <StyledQuestionsSection>
        <h2>
          Looking for something?
        </h2>
        <p>
          If you&apos;re interested in an integration that&apos;s not listed above, contact us <Link to="/contact">here</Link>.
        </p>

      </StyledQuestionsSection>
    </StyledPageContainer>
  );
};

export default Integrations;
