import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import EmailSection from './EmailSection';
import { useEmailTemplate } from '../../../../../hooks/queries/email-templates';
import { correctPath } from 'libraries/gem';

const EmailTemplateDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { data: emailTemplate } = useEmailTemplate(id);

  return (
    <Breadcrumb
      data={{
        title: 'Details',
        pathname: correctPath(`/app/email-templates/${id}/details`),
      }}
    >
      <div className="email-template-details-container">
        <Helmet>
          <title>Details | {emailTemplate?.name} | Gem Scheduling</title>
        </Helmet>
        <EmailSection />
      </div>
    </Breadcrumb>
  );
};

export default EmailTemplateDetails;
