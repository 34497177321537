import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import ConflictSettingsSection from './ConflictSettingsSection';
import InterviewLimitsSection from './InterviewLimitsSection';
import MeetingBuffersSection from './MeetingBuffersSection';
import TagsSection from './TagsSection';
import WorkingHoursSection from './WorkingHoursSection';
import { useUser } from '../../../../../hooks/queries/users';
import { correctPath } from 'libraries/gem';

const InterviewerSchedulingPreferences = () => {
  const { id } = useParams<{ id: string }>();

  const { data: user } = useUser(id);

  return (
    <Breadcrumb
      data={{
        title: 'Scheduling Preferences',
        pathname: correctPath(`/app/interviewers/${id}/scheduling-preferences`),
      }}
    >
      <div className="interviewer-details-container">
        <Helmet>
          <title>Scheduling Preferences | {user?.name} | InterviewPlanner</title>
        </Helmet>
        <WorkingHoursSection />
        <ConflictSettingsSection />
        <InterviewLimitsSection />
        <MeetingBuffersSection />
        <TagsSection />
      </div>
    </Breadcrumb>
  );
};

export default InterviewerSchedulingPreferences;
