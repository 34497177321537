import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';

import CheckboxInput from '../../../library/inputs/CheckboxInput';
import DurationInput from '../../../library/inputs/DurationInput';
import InterviewTemplateAdvancedSettings from '../../../library/inputs/InterviewTemplateAdvancedSettings';
import MultiStepFormStep from '../../../library/inputs/MultiStepFormStep';
import TextInput from '../../../library/inputs/TextInput';
import { Step, useNewInterviewTemplate } from './use-new-interview-template';
import { liveCodingLabels } from '../../../../types';
import { sortPositions } from '../../../../libraries/interview-templates';
import { useSession } from '../../../../hooks/use-session';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../library/inputs/SelectInput/types';
import { correctPath } from 'libraries/gem';

const DetailsStep = () => {
  const { account } = useSession();

  const {
    interviewTemplate,
    setCompletedStep,
    setInterviewTemplate,
  } = useNewInterviewTemplate();

  const [name, setName] = useState(interviewTemplate.name);
  const [duration, setDuration] = useState(interviewTemplate.duration_minutes);
  const [liveCodingEnabled, setLiveCodingEnabled] = useState(interviewTemplate.live_coding_enabled);
  const [candidateFacingName, setCandidateFacingName] = useState(interviewTemplate.candidate_facing_name || '');
  const [candidateFacingDetails, setCandidateFacingDetails] = useState(interviewTemplate.candidate_facing_details || '');
  const [positions, setPositions] = useState(interviewTemplate.positions);
  const [windowStart, setWindowStart] = useState(interviewTemplate.time_window_start);
  const [windowEnd, setWindowEnd] = useState(interviewTemplate.time_window_end);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const handleDurationChange = (duration: number) => setDuration(duration);
  const handleLiveCodingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setLiveCodingEnabled(e.target.checked);
  const handleCandidateFacingNameChange = (e: ChangeEvent<HTMLInputElement>) => setCandidateFacingName(e.target.value);
  const handlePositionsChange = (option: OnChangeValue<Option<number>, true>) => setPositions(option.map(({ value }) => value));
  const handleWindowStartChange = (option: OnChangeValue<Option<string>, false>) => setWindowStart(option ? option.value : undefined);
  const handleWindowEndChange = (option: OnChangeValue<Option<string>, false>) => setWindowEnd(option ? option.value : undefined);

  const handleNext = () => {
    setInterviewTemplate((prev) => ({
      ...prev,
      name,
      duration_minutes: duration,
      live_coding_enabled: liveCodingEnabled,
      positions: (positions || []).length > 0 ? sortPositions(positions) : undefined,
      time_window_start: windowStart,
      time_window_end: windowEnd,
      candidate_facing_name: candidateFacingName || undefined,
      candidate_facing_details: candidateFacingDetails && candidateFacingDetails !== '<br>' ? candidateFacingDetails : undefined,
    }));
    setCompletedStep(Step.Details + 1);
  };

  return (
    <Breadcrumb
      data={{
        title: '1. Details',
        pathname: correctPath('/app/interview-templates/create/details'),
      }}
    >
      <MultiStepFormStep
        isFirstStep
        nextButtonValue="Interviewers"
        nextLocation={correctPath('/app/interview-templates/create/interviewers')}
        onNext={handleNext}
      >
        <Helmet>
          <title>1. Details | Create Interview Template | Gem Scheduling</title>
        </Helmet>
        <TextInput
          className="create-name"
          id="create-name"
          isAutofocus
          isRequired
          label="Name"
          onChange={handleNameChange}
          value={name}
        />
        <DurationInput
          className="create-duration"
          id="create-duration"
          isRequired
          label="Interview Duration"
          onChange={handleDurationChange}
          value={duration}
        />
        {account?.live_coding_type && (
          <CheckboxInput
            className="create-live-coding-enabled"
            helperText="The link will be added to interviewer calendar events and the Schedule.Agenda token."
            id="create-live-coding-enabled"
            isChecked={liveCodingEnabled}
            label={`Create a ${liveCodingLabels[account.live_coding_type]} link when scheduling this interview.`}
            onChange={handleLiveCodingEnabledChange}
          />
        )}
        <InterviewTemplateAdvancedSettings
          candidateFacingDetails={candidateFacingDetails}
          candidateFacingName={candidateFacingName}
          duration={duration}
          handleCandidateFacingNameChange={handleCandidateFacingNameChange}
          handlePositionsChange={handlePositionsChange}
          handleTimeWindowEndChange={handleWindowEndChange}
          handleTimeWindowStartChange={handleWindowStartChange}
          isExpanding
          positions={positions}
          setCandidateFacingDetails={setCandidateFacingDetails}
          timeWindowEnd={windowEnd}
          timeWindowStart={windowStart}
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default DetailsStep;
