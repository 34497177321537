import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useMemo } from 'react';
import { faLock, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { isEmpty, mapValues, some } from 'lodash';

import Avatar from 'components/library/data-display/Avatar';
import Button from 'components/library/inputs/Button';
import Flash from 'components/library/utils/Flash';
import Popover from 'components/library/utils/Popover';
import Tooltip from 'components/library/utils/Tooltip';
import { useAccount } from 'hooks/queries/accounts';
import { useEvents } from 'hooks/use-events';
import { useUsersMap } from 'hooks/queries/users';
import { useWeeklyInterviewLoad } from 'hooks/queries/interviews';
import { formatMoment, TimeFormat } from 'libraries/time';
import { isTraineeSlot } from 'libraries/training';
import { directoryCalendarLabels } from 'types';
import CalendarEventPreview from '../CalendarEventPreview';
import CalendarScheduleInterviewerSelectInput from './CalendarScheduleInterviewerSelectInput';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { EventProps } from '../../../../libraries/react-big-calendar';
import type { Option as InterviewerSelectInputOption } from './CalendarScheduleInterviewerSelectInput';
import type { DetailEventInterview, Event, PotentialUser } from './types';

const CalendarScheduleEvent = ({ event }: EventProps<Event>) => {
  const timezone = event.timezone;
  const date = useMemo(() => Moment(event.start).tz(timezone).format('YYYY-MM-DD'), [event.start, timezone]);

  const { data: account } = useAccount();
  const interviews = useWeeklyInterviewLoad(date);
  const users = useUsersMap({ archived: true });

  const allUsersInterviewLoad = useMemo<{ [userId: string]: DetailEventInterview[] }>(() => mapValues(
    users,
    (user) =>
      interviews?.[user.id]?.map((interview) => {
        return {
          start_time: interview.start_time,
          end_time: Moment(interview.start_time).add(interview.interview_template.duration_minutes, 'minutes').format(),
          candidate: interview.schedule.application?.candidate.name || 'Candidate',
          job: interview.schedule.stage.job.name,
          title: interview.name,
          attendees: (interview.interviewers || []).map((interviewer) => ({
            name: interviewer.user.name,
            email: interviewer.user.email,
            rsvp: interviewer.rsvp,
          })),
        };
      }) || []
  ), [date, interviews, timezone, users]);

  const { events } = useEvents();
  const userEvents = events?.[date]?.[timezone]?.users;
  const roomEvents = events?.[date]?.[timezone]?.rooms;
  const zoomHostMeetings = events?.[date]?.[timezone]?.zoom_hosts;

  const allUsersConflicts = useMemo(() => mapValues(
    users,
    (user) => userEvents?.[user.id]?.events?.filter((userEvent) => event.isConflict(userEvent, false, user.email)) || null
  ), [userEvents, event.start.getTime(), event.end.getTime(), timezone, users]);

  const hasInterviewerConflict = some(event.interviewers.filter(({ interviewer_template }) => !interviewer_template.optional && !isTraineeSlot(interviewer_template.interviewer_filters)), ({ selected_user }) => (
    selected_user.user_id && allUsersConflicts[selected_user.user_id]?.filter(({ ignored }) => !ignored).length
  ));
  const hasRoomConflict = !isEmpty(
    event.selectedRoom.room_id && roomEvents?.[event.selectedRoom.room_id]?.events?.filter((roomEvent) => event.isConflict(roomEvent, true))
  );
  const hasZoomHostConflict = (event.selectedZoomHost?.id && zoomHostMeetings?.[event.selectedZoomHost?.id]?.meetings?.filter((zoomHostMeeting) => event.isConflict(zoomHostMeeting, true)).length || 0) >= account!.zoom_host_concurrent_meeting_limit;
  const hasConflict = hasInterviewerConflict || hasRoomConflict || hasZoomHostConflict;

  const isEditable = Boolean(event.onInterviewerChange);

  const handleInterviewerChange = (index: number, option: OnChangeValue<InterviewerSelectInputOption, false>) => {
    event.onInterviewerChange?.(index, {
      user_id: option!.value,
      conflicts: option?.conflicts && option.conflicts.length > 0 ? option.conflicts : undefined,
    });
  };

  const handleInterviewerOptionalityChange = (index: number, optional: boolean) => {
    event.onInterviewerOptionalityChange?.(index, optional);
  };

  const handleInterviewerTrainingProgramChange = (index: number, option: OnChangeValue<Option<string>, false>) => {
    event.onInterviewerTrainingProgramChange?.(index, option?.value || null);
  };

  const handleAddInterviewer = (userId: string, optional: boolean, trainingProgramEligibiliity: string | null) => {
    event.onAddInterviewer?.({
      user_id: userId,
    }, optional, trainingProgramEligibiliity, []);
  };

  const handleRemoveInterviewer = (index: number) => {
    event.onRemoveInterviewer?.(index);
  };

  const handleRemoveInterview = () => {
    if (!event.isSingleInterview) {
      event.onRemoveInterview?.();
    }
  };

  const usersInAts = useMemo(() => Object.values(users).filter(({ ats_id, directory_archived, user_archived }) => !user_archived && !directory_archived && Boolean(ats_id)), [users]) ;
  const allPotentialUsers = useMemo<PotentialUser[]>(() => usersInAts.map((user) => ({
    user_id: user.id,
    conflicts: allUsersConflicts[user.id],
    interview_load: allUsersInterviewLoad[user.id],
  })), [usersInAts, allUsersConflicts, allUsersInterviewLoad]);

  return (
    <div className={`calendar-event${hasConflict ? ' conflict' : ''}`}>
      <div className="calendar-event-content" id={`${event.id}-content`}>
        {event.duration > 20 &&
          <div className="calendar-event-time">
            {formatMoment(Moment.tz(event.start, event.timezone), TimeFormat.Time)} - {formatMoment(Moment.tz(event.end, event.timezone), TimeFormat.TimeWithTimezone)}
          </div>
        }
        <div className="calendar-event-title">
          {event.private ? (
            <span
              className="calendar-event-title-private"
              data-for={`${event.id}-private-tooltip`}
              data-tip
            >
              busy
              <FontAwesomeIcon icon={faLock} />
              <Tooltip
                id={`${event.id}-private-tooltip`}
                value={`You don't have access to view this event in ${directoryCalendarLabels[account?.directory_type!]}.`}
              />
            </span>
          ) : event.title}
        </div>
      </div>
      {event.showEventDetailsPopover &&
        <Popover
          position="auto-start"
          target={`${event.id}-content`}
        >
          <CalendarEventPreview
            endTime={Moment.tz(event.end, event.timezone).toDate()}
            guests={event.interviewers.map((interviewer) => ({
              id: interviewer.selected_user.user_id || undefined,
              name: users[interviewer.selected_user.user_id!]?.name || users[interviewer.selected_user.user_id!]?.email,
              description: interviewer.interviewer_template.description || 'Interviewer',
            }))}
            isPrivate={event.private}
            startTime={Moment.tz(event.start, event.timezone).toDate()}
            timezone={event.timezone}
            title={event.title}
          />
        </Popover>
      }
      <div className={`calendar-event-guests${event.duration > 20 ? '' : ' no-padding'}`}>
        {event.interviewers.map((interviewer, i) => {
          const avatarId = `event-${event.id}-interviewer-${i}`;

          return (
            <Fragment key={avatarId}>
              <Button
                color="no-outline"
                iconRight={
                  <>
                    <Avatar
                      alt={interviewer.selected_user?.user_id ? undefined : 'Not selected'}
                      showOptionalBadge={interviewer.interviewer_template.optional}
                      showTraineeBadge={isTraineeSlot(interviewer.interviewer_template.interviewer_filters)}
                      size="small"
                      status={Boolean(interviewer.selected_user.user_id && allUsersConflicts[interviewer.selected_user.user_id]?.filter(({ ignored }) => !ignored).length) ? 'warning' : undefined}
                      tooltipHelperText={isEditable ? `(click to ${interviewer.selected_user?.user_id ? 'swap' : 'set'} interviewer)` : undefined}
                      userId={interviewer.selected_user.user_id || undefined}
                    />
                  </>
                }
                id={avatarId}
                onClick={isEditable ? (e) => e.stopPropagation() : undefined}
              />
              {isEditable &&
                <CalendarScheduleInterviewerSelectInput
                  applicationId={event.applicationId}
                  endTime={event.end}
                  interviewerTemplate={interviewer.interviewer_template}
                  onChange={(selectedUser) => handleInterviewerChange(i, selectedUser)}
                  onOptionalityChange={(optional) => handleInterviewerOptionalityChange(i, optional)}
                  onRemove={event.onRemoveInterviewer ? () => handleRemoveInterviewer(i) : undefined}
                  onTrainingProgramChange={(option) => handleInterviewerTrainingProgramChange(i, option)}
                  potentialUsers={(
                    event.allowSelectOptionsOutsidePool ?
                      allPotentialUsers :
                      interviewer.potential_users.map((user) => ({
                        user_id: user.user_id,
                        conflicts: allUsersConflicts[user.user_id!],
                        interview_load: allUsersInterviewLoad[user.user_id!],
                      }))
                  )}
                  scheduleId={event.scheduleId}
                  selectedUserId={interviewer.selected_user.user_id}
                  startTime={event.start}
                  targetId={avatarId}
                  timezone={event.timezone}
                />
              }
            </Fragment>
          );
        })}
        {event.onAddInterviewer &&
          <>
            <Button
              className="btn-add-interviewer"
              color="gray"
              iconRight={
                <div className="avatar avatar-small avatar-user">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              }
              id={`${event.id}-button-add-interviewer`}
              onClick={(e) => e.stopPropagation()}
              tooltip={
                <Tooltip
                  id={`${event.id}-add-interviewer-tooltip`}
                  position="top"
                  value="Add interviewer"
                />
              }
            />
            <CalendarScheduleInterviewerSelectInput
              applicationId={event.applicationId}
              endTime={event.end}
              onAdd={(selectedUserId, optional, trainingProgramEligibility) => handleAddInterviewer(selectedUserId, optional, trainingProgramEligibility)}
              potentialUsers={allPotentialUsers}
              scheduleId={event.scheduleId}
              selectedUserId={null}
              startTime={event.start}
              targetId={`${event.id}-button-add-interviewer`}
              timezone={event.timezone}
            />
          </>
        }
      </div>
      {isEditable &&
        <>
          <Button
            className="btn-delete"
            color="gray"
            iconRight={<FontAwesomeIcon icon={faTimes} />}
            id={`${event.id}-button-delete-interview`}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveInterview();
            }}
            onTouchEnd={(e) => {
              e.preventDefault();
              handleRemoveInterview();
            }}
          />
          {event.isSingleInterview &&
            <Popover
              className="calendar-schedule-cannot-delete-interview"
              position="bottom-end"
              target={`${event.id}-button-delete-interview`}
            >
              <Flash
                message="You must add another interview before deleting this one."
                showFlash
                type="danger"
              />
            </Popover>
          }
        </>
      }
    </div>
  );
};

export default CalendarScheduleEvent;
