import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Flash from '../../../library/utils/Flash';
import Section from '../../../library/layout/Section';
import Table from '../../../library/data-display/Table';
import TrainingProgramSelectInput from '../../../library/inputs/TrainingProgramSelectInput';
import useSyncStateWithQuery from '../../../../hooks/use-sync-state-with-query';
import { HiringTeamRoleWithInterviewer, ScheduleStatus } from '../../../../types';
import { StyledFiltersContainer, StyledTableListItem } from './styles';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useInterviews } from '../../../../hooks/queries/interviews';
import { useLDFlags } from '../../../../hooks/use-ld-flags';
import { useUser } from '../../../../hooks/queries/users';

import type { Interview } from '../../../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../library/inputs/TrainingProgramSelectInput';
import type { TableSchema } from '../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const getLinkToSchedule = ({ schedule }: Interview) => schedule.application ? correctPath(`/app/candidates/${schedule.application.id}/schedules/${schedule.id}`) : undefined;

const InterviewerInterviewHistory = () => {
  const { id } = useParams<{ id: string }>();

  const { interviewHistoryFiltering } = useLDFlags();

  const { data: user } = useUser(id);

  const [pageNumber, queryPageNumber, setPageNumber] = useSyncStateWithQuery<string>('page', '1');
  const [trainingProgram, queryTrainingProgram, setTrainingProgram] = useSyncStateWithQuery<string[]>('training_program', [], { array: true });

  const {
    data: interviews,
    error,
  } = useInterviews({
    schedule_status: [ScheduleStatus.Confirmed],
    including_user: id,
    including_role: [HiringTeamRoleWithInterviewer.Interviewer],
    training_programs: queryTrainingProgram,
    sort_by: ['interview.start_time desc'],
    limit: 10,
    offset: 10 * (parseInt(queryPageNumber, 10) - 1),
  });

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value.toString());
  }, []);

  const handleTrainingProgramChange = useCallback((option: OnChangeValue<Option, true>) => {
    setTrainingProgram(option && option.length > 0 ? option.map((program) => program.value) : []);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const columns = useMemo<TableSchema<Interview>>(() => [{
    header: 'Interview',
    displayValue: ({ name, start_time, interviewers, schedule }) => {
      const interviewer = (interviewers || []).find((interviewer) => interviewer.user_id === id);
      const isCompleted = !Moment(start_time).isAfter(Moment());
      return (
        <StyledTableListItem
          className={schedule.application ? undefined : 'extra-padding'}
          label={name}
          secondaryText={interviewer?.training_session ? `${!isCompleted ? 'Prediction: ' : ''}${interviewer.training_session.phase_name}` : undefined}
        />
      );
    },
    hasLinkStyleOnHover: true,
  }, {
    header: 'Job',
    displayValue: ({ schedule }) => schedule.stage.job.name,
  }, {
    header: 'Candidate',
    displayValue: ({ schedule }) => schedule.application?.candidate.name || <span className="redacted">Redacted</span>,
  }, {
    header: 'Date',
    displayValue: ({ start_time }) => formatMoment(Moment(start_time), TimeFormat.LongMonthDayYear),
  }], []);

  return (
    <Breadcrumb
      data={{
        title: 'Interview History',
        pathname: correctPath(`/app/interviewers/${id}/interview-history`),
      }}
    >
      <Section
        className="interviewer-interview-history-section"
        title="Interview history"
      >
        <Helmet>
          <title>Interview History | {user?.name} | Gem Scheduling</title>
        </Helmet>
        {interviewHistoryFiltering && (
          <StyledFiltersContainer>
            <TrainingProgramSelectInput
              isMulti
              onChange={handleTrainingProgramChange}
              selectedTrainingProgram={trainingProgram}
              valueType="id"
            />
          </StyledFiltersContainer>
        )}
        <Flash
          message={<span>This includes all past and future interviews where this person is an interviewer.</span>}
          showFlash
          type="info"
        />
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        {interviews && (
          <Table
            data={interviews.interviews}
            dataDescriptor="interviews"
            getRowLink={getLinkToSchedule}
            isPaginated
            layout="vertical"
            onPageNumberChange={handlePageNumberChange}
            pageNumber={parseInt(pageNumber, 10)}
            schema={columns}
            totalCount={interviews.total}
          />
        )}
      </Section>
    </Breadcrumb>
  );
};

export default InterviewerInterviewHistory;
