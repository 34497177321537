import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import AvailabilityRequestEmailSection from './AvailabilityRequestEmailSection';
import PreferencesSection from './PreferencesSection';
import useScrollToLocation from '../../../../../../../hooks/use-scroll-to-location';
import { useJob } from '../../../../../../../hooks/queries/jobs';
import { useStage } from '../../../../../../../hooks/queries/stages';
import { correctPath } from 'libraries/gem';

const JobStageAvailabilityPreferences = () => {
  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { data: job } = useJob(jobId);
  const {
    data: stage,
    isLoading,
  } = useStage(jobId, stageId);

  useScrollToLocation();

  if (isLoading) {
    return null;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Availability Preferences',
        pathname: correctPath(`/app/jobs/${jobId}/stages/${stageId}/availability-preferences`),
      }}
    >
      <div className="job-stage-availability-preferences-container">
        <Helmet>
          <title>Availability Preferences | {stage?.name} | {job?.name} | InterviewPlanner</title>
        </Helmet>
        <PreferencesSection />
        <AvailabilityRequestEmailSection />
      </div>
    </Breadcrumb>
  );
};

export default JobStageAvailabilityPreferences;
