import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import Button from '../../../library/inputs/Button';
import Flash from '../../../library/utils/Flash';
import Label from '../../../library/utils/Label';
import PageHeader from '../../../library/layout/PageHeader';
import StatusIndicator from '../../../library/utils/StatusIndicator';
import TextInput from '../../../library/inputs/TextInput';
import TrainingProgramDetails from './TrainingProgramDetails';
import TrainingProgramInterviewHistory from './TrainingProgramInterviewHistory';
import TrainingProgramInterviewers from './TrainingProgramInterviewers';
import TrainingProgramLinkedInterviews from './TrainingProgramLinkedInterviews';
import { StyledModal, StyledSubtitle } from './styles';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useRenameEligibilities } from '../../../../hooks/queries/users';
import { useTrainingProgram, useUpdateTrainingProgram } from '../../../../hooks/queries/training-programs';

import type { ChangeEvent } from 'react';
import { correctPath } from 'libraries/gem';

const TrainingProgram = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: trainingProgram,
    error: trainingProgramError,
    isLoading: isTrainingProgramLoading,
  } = useTrainingProgram(id);

  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [renameEligibilityTo, setRenameEligibilityTo] = useState('');
  const [renameEligibilityError, setRenameEligibilityError] = useState('');
  const [renameEligibilitySuccess, setRenameEligibilitySuccess] = useState(false);

  const updateTrainingProgramMutation = useUpdateTrainingProgram();
  const renameEligibilitiesMutation = useRenameEligibilities();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  const handleArchive = () => {
    updateTrainingProgramMutation.reset();
    updateTrainingProgramMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = () => {
    updateTrainingProgramMutation.reset();
    updateTrainingProgramMutation.mutate({ id, payload: { archived: false } });
  };

  const handleRenameEligibilityChange = (e: ChangeEvent<HTMLInputElement>) => setRenameEligibilityTo(e.target.value);

  const handleRenameEligibility = async () => {
    setRenameEligibilityError('');
    renameEligibilitiesMutation.reset();

    if (!renameEligibilityTo) {
      setRenameEligibilityError('You need to provide a value to rename the eligibility to.');
      return;
    }

    try {
      await renameEligibilitiesMutation.mutateAsync({
        payload: {
          from: trainingProgram!.eligibility,
          to: renameEligibilityTo,
        },
      });
      setRenameEligibilityTo('');
      setRenameModalOpen(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  if (isTrainingProgramLoading) {
    return null;
  }

  if (trainingProgramError) {
    return (
      <Breadcrumb
        data={{
          title: id,
          pathname: correctPath(`/app/training-programs/${id}`),
        }}
      >
        <div className="training-program-container">
          <Flash
            message={trainingProgramError.message}
            showFlash
            type="danger"
          />
        </div>
      </Breadcrumb>
    );
  }

  if (!trainingProgram) {
    return null;
  }

  return (
    <Breadcrumb
      data={{
        title: trainingProgram.eligibility || id,
        pathname: correctPath(`/app/training-programs/${id}`),
      }}
    >
      <div className="training-program-container">
        <Helmet>
          <title>{trainingProgram.eligibility} | Interviewers | Training Programs | InterviewPlanner</title>
        </Helmet>
        <Flash
          isDismissible
          message={updateTrainingProgramMutation.error?.message}
          onDismiss={updateTrainingProgramMutation.reset}
          showFlash={updateTrainingProgramMutation.isError}
          type="danger"
        />
        <Flash
          isDismissible
          message={`Training program ${trainingProgram.archived_at ? 'archived' : 'unarchived'}.`}
          onDismiss={updateTrainingProgramMutation.reset}
          showFlash={updateTrainingProgramMutation.isSuccess}
          type="success"
        />
        <Flash
          isDismissible
          message="Interview eligibility successfully renamed!"
          onDismiss={() => setRenameEligibilitySuccess(false)}
          showFlash={renameEligibilitySuccess}
          type="success"
        />
        <>
          <PageHeader
            details={
              <>
                <StatusIndicator color={trainingProgram.trainee_count > 0 ? 'green' : 'gray'}>
                  <Link to={correctPath(`/app/training-programs/${trainingProgram.id}/interviewers`)}>{trainingProgram.trainee_count} trainee{trainingProgram.trainee_count !== 1 && 's'}</Link>
                </StatusIndicator>
                {!isSmallScreen && <span className="separator">|</span>}
                {!isSmallScreen && <span>Created {formatMoment(Moment(trainingProgram.created_at), TimeFormat.LongMonthDayYear)}</span>}
                {!isSmallScreen && trainingProgram.created_at !== trainingProgram.updated_at && <span className="separator">|</span>}
                {!isSmallScreen && trainingProgram.created_at !== trainingProgram.updated_at && <span>Updated {formatMoment(Moment(trainingProgram.updated_at), TimeFormat.LongMonthDayYear)}</span>}
              </>
            }
            navbarLinks={[{
              label: 'Interviewers',
              location: correctPath(`/app/training-programs/${trainingProgram.id}/interviewers`),
            }, {
              label: 'Details',
              location: correctPath(`/app/training-programs/${trainingProgram.id}/details`),
            }, {
              label: 'Linked Interviews',
              location: correctPath(`/app/training-programs/${trainingProgram.id}/linked-interviews`),
            }, {
              label: 'Interview History',
              location: correctPath(`/app/training-programs/${trainingProgram.id}/interview-history`),
            }]}
            title={(
              <>
                {trainingProgram.eligibility} <StyledSubtitle>(Training Program)</StyledSubtitle>
              </>
            )}
            titleIcon={trainingProgram.archived_at ? <Label color="gray">Archived</Label> : undefined}
          >
            <Button
              color="gem-outline"
              onClick={() => setRenameModalOpen((prev) => !prev)}
              size="small"
              value="Rename Eligibility"
            />
            <ArchiveButton
              id={id}
              isArchived={Boolean(trainingProgram.archived_at)}
              onArchive={handleArchive}
              onUnarchive={handleUnarchive}
            />
            <StyledModal
              cancelButtonValue="No, don't rename"
              isOpen={renameModalOpen}
              onSubmit={handleRenameEligibility}
              onToggle={() => {
                setRenameEligibilityTo('');
                setRenameModalOpen((prev) => !prev);
              }}
              submitButtonValue="Yes, rename"
              submittingButtonValue="Renaming..."
              title="Rename this training program's eligibility?"
            >
              <Flash
                message={renameEligibilityError}
                showFlash={Boolean(renameEligibilityError)}
                type="danger"
              />
              <Flash
                message={renameEligibilitiesMutation.error?.message}
                showFlash={Boolean(renameEligibilitiesMutation.error)}
                type="danger"
              />
              <TextInput
                helperText="Cannot be the name of an existing interview eligibility."
                isAutofocus
                isRequired
                label="New Eligibility Name"
                onChange={handleRenameEligibilityChange}
                value={renameEligibilityTo}
              />
              <p>
                This will rename the <b>{trainingProgram.eligibility}</b> eligibility in all interviewer profiles and interview templates.
              </p>
            </StyledModal>
          </PageHeader>
          <Switch>
            <Redirect exact from={correctPath('/app/training-programs/:id')} to={correctPath('/app/training-programs/:id/interviewers')} />
            <Route component={TrainingProgramInterviewers} path={correctPath('/app/training-programs/:id/interviewers')} />
            <Route component={TrainingProgramDetails} path={correctPath('/app/training-programs/:id/details')} />
            <Route component={TrainingProgramLinkedInterviews} path={correctPath('/app/training-programs/:id/linked-interviews')} />
            <Route component={TrainingProgramInterviewHistory} path={correctPath('/app/training-programs/:id/interview-history')} />
          </Switch>
        </>
      </div>
    </Breadcrumb>
  );
};

export default TrainingProgram;
