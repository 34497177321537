import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router-dom';
import { formatISO } from 'date-fns';
import { flatten, partition } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import AvailabilityPicker from 'components/library/inputs/AvailabilityPicker';
import CalendarResourceSelectInput from 'components/library/inputs/CalendarResourceSelectInput';
import MultiStepFormStep from 'components/library/inputs/MultiStepFormStep';
import Flash from 'components/library/utils/Flash';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import { useApplication } from 'hooks/queries/applications';
import { useEvents } from 'hooks/use-events';
import { useSession } from 'hooks/use-session';
import { useResolvePools } from 'hooks/queries/users';
import { Step, useNewAvailability } from './use-new-availability';

import type { Option } from 'components/library/inputs/SelectInput/types';
import type { TimeSlot } from 'components/library/inputs/AvailabilityPicker/types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import { correctPath } from 'libraries/gem';

const SuggestedTimesStep = () => {
  const { id } = useParams<{ id: string }>();
  const { account } = useSession();

  const application = useApplication(id).data!;
  const { fetchEvents } = useEvents();

  const {
    availability,
    completedStep,
    setAvailability,
    setCompletedStep,
  } = useNewAvailability();

  const [suggestedTimes, setSuggestedTimes] = useState<TimeSlot[]>(availability.availability_template!.suggested_times || []);
  const [selectedInterviewers, setSelectedInterviewers] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(() => new Date());
  const [timezone, setTimezone] = useState(Moment.tz.guess());
  const [eventsAreLoading, setEventsAreLoading] = useState(false);

  const stageInterviews = application?.current_stage?.stage_interviews || [];
  const [stageInterviewsWithTemplates, stageInterviewsWithoutTemplates] = useMemo(() => partition(stageInterviews, ({ interview_template }) => Boolean(interview_template)), [stageInterviews]);
  const poolsQueries = useResolvePools({
    applicationId: application.id,
    includePastInterviewers: true,
    pools: flatten(stageInterviewsWithTemplates.map(({ interview_template }) => (interview_template!.interviewer_templates || []).map(({ interviewer_filters }) => interviewer_filters || []))),
  });
  const poolsAreResolved = useMemo(() => poolsQueries.every(({ isSuccess }) => isSuccess), [poolsQueries]);

  const defaultSelectedInterviewers = useMemo<string[]>(() => {
    const allPotentialInterviewerIds = new Set<string>();
    if (poolsAreResolved) {
      poolsQueries.forEach(({ data }) => {
        data?.forEach(({ id }) => {
          allPotentialInterviewerIds.add(id);
        });
      });
      stageInterviewsWithoutTemplates.forEach(({ ats_interviewer_ids }) => {
        ats_interviewer_ids?.forEach((id) => {
          allPotentialInterviewerIds.add(id);
        });
      });
    }
    // The sort is just to make sure the interviewer ids are in a deterministic order.
    // It's sorting uuids.
    return Array.from(allPotentialInterviewerIds).sort();
  }, [
    stageInterviewsWithoutTemplates,
    poolsAreResolved,
  ]);

  useEffect(() => {
    setSelectedInterviewers(defaultSelectedInterviewers);
  }, [defaultSelectedInterviewers]);

  useEffect(() => {
    if (selectedInterviewers.length === 0) {
      return;
    }

    const [zoomHosts, users] = partition(selectedInterviewers, (id) => id.endsWith(':zoom'));
    const start = Moment(selectedDate).tz(timezone).startOf('week');
    // TODO: It would be nice if the backend supported a range of dates, which
    // isn't hard to do, but if it did, we'd have to update useEvents to handle
    // that.
    for (let i = 0; i < 7; i++) {
      const date = start.clone().add(i, 'day').format('YYYY-MM-DD');
      const fetchInterviewerEvents = async () => {
        setEventsAreLoading(true);
        await fetchEvents({
          date,
          timezone,
          user_ids: users,
          // While we do load Zoom meetings, we don't actually display them in the picker. Right now, we just display
          // events.
          zoom_host_ids: zoomHosts.map((id) => id.replaceAll(':zoom', '')),
        });
        setEventsAreLoading(false);
      };
      fetchInterviewerEvents();
    }
  }, [selectedDate, selectedInterviewers, timezone]);

  const handleTimezoneChange = (option: OnChangeValue<Option<string>, false>) => {
    setTimezone(option ? option.value : '');
  };

  const handleSelectedInterviewersChange = (options: OnChangeValue<Option<string>, true>) => {
    setSelectedInterviewers(options?.map((option) => option.value) || []);
  };

  if (completedStep < Step.SuggestedTimes) {
    return <Redirect to={correctPath(`/app/candidates/${id}/request-availability/preferences`)} />;
  }

  const handleNext = async () => {
    setAvailability((prev) => ({
      ...prev,
      availability_template: {
        ...prev.availability_template!,
        suggested_times: suggestedTimes.map(({ start_time, end_time }) => ({
          start_time: start_time instanceof Date ? formatISO(start_time) : start_time,
          end_time: end_time instanceof Date ? formatISO(end_time) : end_time,
        })),
      },
    }));
    setCompletedStep(Step.SuggestedTimes + 1);
  };

  return (
    <Breadcrumb
      data={{
        title: '2. Suggested Times',
        pathname: correctPath(`/app/candidates/${id}/request-availability/suggested-times`),
      }}
    >
      <MultiStepFormStep
        backLocation={correctPath(`/app/candidates/${id}/request-availability/preferences`)}
        className="form-step-suggested-times"
        nextButtonValue="Email"
        nextLocation={correctPath(`/app/candidates/${id}/request-availability/email`)}
        onNext={handleNext}
      >
        <Helmet>
          <title>2. Suggested Times | Request availability from {application.candidate.name || 'Unknown'} for {application.current_stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message="Suggest times for the candidate. They will still be able to submit availability outside the suggested times."
          showFlash
          type="info"
        />
        {poolsAreResolved ? (
          <>
            <CalendarResourceSelectInput
              helperText="View interviewers' calendars to select suggested times where they are available."
              label="Interviewers"
              onChange={handleSelectedInterviewersChange}
              selectedUserIds={selectedInterviewers}
            />
            <AvailabilityPicker
              availabilities={suggestedTimes}
              businessHours={availability.availability_template!.business_hours}
              controlledTimezone={timezone}
              dataDescriptor="suggested time"
              directory={account?.directory_type}
              eventTitle="Suggested"
              interviewerEventsAreLoading={eventsAreLoading}
              interviewerIds={selectedInterviewers}
              isRequired={false}
              isSimplifiedViewByDefault={defaultSelectedInterviewers.length > 3}
              minDuration={availability.availability_template!.minimum_duration_minutes}
              onDateChange={(date) => setSelectedDate(date)}
              onTimezoneChange={handleTimezoneChange}
              setAvailabilities={setSuggestedTimes}
              showEventWarnings={false}
              showInterviewerEventFilters
              showPreviewsInSimplifiedView
              showQuickSelectOptions={false}
            />
          </>
        ) : <LoadingSpinner />}
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default SuggestedTimesStep;
