import styled from 'styled-components';

import SelectInput from '../../../../library/inputs/SelectInput';
import Table from 'components/library/data-display/Table';
import UserSelectInput from '../../../../library/inputs/UserSelectInput';

// The typing for this is weird because of styled-components. This is inspired from
// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
export const StyledTable = styled(Table)`
  th,
  td {
    &:nth-child(3) {
      border-left: 2px solid ${({ theme }) => theme.colors.mercuryGray || theme.colors.PRODUCT.SECONDARY_BORDER};

      &:not(:last-child) {
        border-right: 2px solid ${({ theme }) => theme.colors.mercuryGray || theme.colors.PRODUCT.SECONDARY_BORDER};
      }
    }
  }

  .expand-action button {
    display: flex;
    align-items: center;
  }

  .row-expanded-content {
    td {
      border-top: 1px solid ${({ theme }) => theme.colors.mercuryGray || theme.colors.PRODUCT.SECONDARY_BORDER};
      padding: 1em;
    }
  }

  .training-program-overrides-table {
    /* Since this table is nested, we need to reset the border styles. */
    th,
    td {
      border: none;

      &:first-child {
        border-right: 1px solid ${({ theme }) => theme.colors.mercuryGray || theme.colors.PRODUCT.SECONDARY_BORDER};
      }
    }
  }

  .training-program-status-cell {
    white-space: nowrap;
  }

  .training-program-progress-bar-cell {
    max-width: 30vw;
    width: 100%;
    overflow: scroll;
  }

  tr:not(.row-expanded-content) {
    td.action-buttons-container.additional-column-after {
      /* We only want less padding if the expanding column for custom phases is present. If it's not, the padding would
       * be unbalanced, so we don't want to adjust it in that case. */
      padding-right: 0;
    }
  }
` as typeof Table;

export const StyledTableContainer = styled.div`
  .flash {
    margin-bottom: 1em;
  }
`;

export const StyledInterviewerSelectInput = styled(UserSelectInput)`
  margin-right: 1em;
  width: 250px;
`;

// The typing for this is weird because of styled-components. This is inspired from
// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
export const StyledStatusSelectInput = styled(SelectInput)`
  width: 130px;
` as typeof SelectInput;
