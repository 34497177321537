import styled from 'styled-components';

import DateRangeInput from '../DateRangeInput';

export const StyledDateRangeContainer = styled.div`
  display: flex;
  padding: 8px 0;

  .fa-trash-can {
    color: ${({ theme }) => theme.colors.ACCENT.RED};
  }
`;

export const StyledDateRangeInput = styled(DateRangeInput)`
  &.input.date-range-input {
    min-width: auto;
    max-width: 336px;
    width: 336px;
  }

  .date-picker {
    width: 100%;

    .rdp-months {
      width: 100%;

      .rdp-day {
        padding: 7px 15px;
      }
    }
  }
`;
