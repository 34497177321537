import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';

import Label from '../../library/utils/Label';
import PricingQuestion from './PricingQuestion';
import PricingTier from './PricingTier';
import ToggleInput from '../../library/inputs/ToggleInput';
import useSyncStateWithQuery from '../../../hooks/use-sync-state-with-query';

enum BillingType {
  Annually = 'annually',
  Monthly = 'monthly',
}

interface Feature {
  description: string;
  comingSoon?: boolean;
}

const features: Feature[] = [{
  description: 'Integrations with Google and Microsoft Office',
}, {
  description: 'Integrations with Greenhouse and Lever',
}, {
  description: 'Integrations with Zoom and Google Meet',
}, {
  description: 'Integrations with CoderPad and HackerRank',
}, {
  description: 'Integration with Slack',
}, {
  description: 'Intelligent schedule generation',
}, {
  description: 'Interviewer utilization reporting',
}, {
  description: 'Automated interviewer training programs',
}, {
  description: 'Interviewer pool management with tags',
}, {
  description: 'Candidate availability request workflow',
}, {
  description: 'Candidate self-scheduling links',
}, {
  description: 'Customizable email communication',
}, {
  description: 'Schedule holds for busy interviewers',
}, {
  description: 'Interview limits, conflict keywords, and more!',
}];

const pricesPerMonth = {
  startup: {
    [BillingType.Monthly]: 600,
    [BillingType.Annually]: 500,
  },
  business: {
    [BillingType.Monthly]: 1200,
    [BillingType.Annually]: 1000,
  },
  enterprise: {
    [BillingType.Monthly]: 'Custom',
    [BillingType.Annually]: 'Custom',
  },
};

const percentSavings = Math.round(100 * (pricesPerMonth.business.monthly - pricesPerMonth.business.annually) / pricesPerMonth.business.monthly);

const Pricing = () => {
  const [billingType, , setBillingType] = useSyncStateWithQuery<BillingType>('billing', BillingType.Annually, { stripDefault: false });

  const isSmallScreen = useMediaQuery({ query: '(max-width: 700px)' });

  const handleBillingTypeChange = () => {
    if (billingType === BillingType.Annually) {
      setBillingType(BillingType.Monthly, { method: 'replace' });
    } else {
      setBillingType(BillingType.Annually, { method: 'replace' });
    }
  };

  return (
    <div className="pricing-container">
      <Helmet>
        <title>Pricing | InterviewPlanner</title>
      </Helmet>
      <section className="intro-section">
        <h1>
          Simple pricing for your team.
        </h1>
        <p>
          Try us for free for 14 days. No credit card required.
        </p>
      </section>
      <section className="tiers-section">
        <div className="billing-type-toggle">
          <ToggleInput
            isOn={billingType === 'annually'}
            isSwitch={false}
            offLabel="Billed Monthly"
            onChange={handleBillingTypeChange}
            onLabel={
              <span className="annual-label">
                Billed Annually
                {!isSmallScreen && <span className="annual-savings">Save {percentSavings}%!</span>}
              </span>
            }
          />
          {isSmallScreen && <span className="annual-savings">Save {percentSavings}% by billing annually!</span>}
        </div>
        <div className="tiers-container">
          <PricingTier
            description="For companies with under 100 employees"
            name="Startup"
            price={pricesPerMonth.startup[billingType]}
          />
          <PricingTier
            description="For companies with 100 to 1000 employees"
            name="Business"
            price={pricesPerMonth.business[billingType]}
          />
          <PricingTier
            description="For companies with over 1000 employees"
            name="Enterprise"
            price={pricesPerMonth.enterprise[billingType]}
          />
        </div>
      </section>
      <section className="features-section">
        <div className="copy-container">
          <h2>
            All plans include all features.
          </h2>
          <ul className="fa-ul features-list">
            {features.map((feature, i) => (
              <li key={`feature-${i}`}>
                <span>
                  <FontAwesomeIcon className="checkmark" icon={faCheck} />
                  <span className="description">
                    {feature.description}
                    {feature.comingSoon && <Label color="blue">Coming soon!</Label>}
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className="questions-section">
        <div className="copy-container">
          <h2>
            Questions?
          </h2>
          <p>
            We have answers. If you don&apos;t see your question below, chat with us or contact us <Link to="/contact">here</Link> for more information.
          </p>
          <div className="questions-container">
            <PricingQuestion
              answer={<p>Nope! Whenever you reach out and are ready to try it out, we&apos;ll schedule an implementation session to make sure you get everything connected correctly, and we&apos;ll walk you through the common workflows that you&apos;ll be using. And we&apos;ll do all of this free of charge! We want to make sure you&apos;re set up for success.</p>}
              question="Do you charge an implementation fee?"
            />
            <PricingQuestion
              answer={<p>When you sign up, you connect your G Suite or Microsoft 365 account to the platform. This allows us to pull in all of the users to be potential interviewers, but it&apos;s also the way that we calculate employee count. We understand that there will be some &quot;users&quot; that don&apos;t actually belong to employees, and we can easily exclude those from the count if you reach out to us <Link to="/contact">here</Link>.</p>}
              question="How do you determine our employee count?"
            />
            <PricingQuestion
              answer={
                <>
                  <p>
                    Congrats on growing out your team!
                  </p>
                  <p>
                    If you originally joined while you were smaller, we will keep you on the Startup tier for the remainder of your subscription term. So if you&apos;re on the monthly plan, you&apos;ll need to switch to the Business tier at the beginning of the next billing month. But if you&apos;re on the annual plan, you&apos;ll only need to convert to Business when your existing plan expires. So it might be a good idea to lock in the Startup tier if you anticipate hypergrowth!
                  </p>
                </>
              }
              question="What happens if we get more than 100 employees while on the Startup tier?"
            />
            <PricingQuestion
              answer={<p>No matter what, we will honor the free trial of every new account. So if you already know that you want to commit to an annual plan on day 1 of your free trial, great! You can set everything up to be charged at the end of your 14 days, and that&apos;s when your year starts.</p>}
              question="If I purchase an annual plan while on the free trial, when am I charged?"
            />
            <PricingQuestion
              answer={<p>Absolutely! We offer InterviewPlanner for free to non-profits and educational institutions. <Link to="/contact">Contact us</Link> to see if you qualify. </p>}
              question="Do you offer discounts for non-profits and educational institutions?"
            />
            <PricingQuestion
              answer={
                <>
                  <p>
                    While we&apos;re sad to see you go, we respect your decision.
                  </p>
                  <p>
                    We will take care of deleting your account and all associated data. And not the kind of &quot;deleting&quot; where we keep some of it for data mining. All candidate and interviewer data will be wiped completely from our various means of storage.
                  </p>
                </>
              }
              question="What if we don't want to use InterviewPlanner anymore?"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
