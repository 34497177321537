import type Moment from 'moment-timezone';

// Technically, all locales should work, but these are the only ones that we explicitly test for right now.
export enum Locale {
  EnUS = 'en-US',
  EnGB = 'en-GB',
}

// This is an enum to represent the various formats that we use throughout the app. The format in the comment is the
// equivalent Moment format string, assuming en-US locale.
export enum TimeFormat {
  DayOfMonth = 'DayOfMonth', // D
  LongDayOfWeekMonthDay = 'LongDayOfWeekMonthDay', // dddd, MMMM D
  LongDayOfWeekMonthDayAtTimeAndTimezone = 'LongDayOfWeekMonthDayAtTimeAndTimezone', // dddd, MMMM D [at] h:mm A z
  LongDayOfWeekMonthDayWithTimeAndComma = 'LongDayOfWeekMonthDayWithTimeAndComma', // dddd, MMMM D, h:mm A
  LongDayOfWeekMonthDayWithTimeAndTimezoneAndComma = 'LongDayOfWeekMonthDayWithTimeAndTimezoneAndComma', // dddd, MMMM D, h:mm A z
  LongDayOfWeekMonthDayYear = 'LongDayOfWeekMonthDayYear', // dddd, MMMM D, YYYY
  LongDayOfWeekMonthDayYearWithTimeAndComma = 'LongDayOfWeekMonthDayYearWithTimeAndComma', // dddd, MMMM D, YYYY, h:mm A
  LongDayOfWeekMonthDayYearWithTimeAndTimezoneAndComma = 'LongDayOfWeekMonthDayYearWithTimeAndTimezoneAndComma', // dddd, MMMM D, YYYY, h:mm A z
  LongMonthDay = 'LongMonthDay', // MMMM D
  LongMonthDayYear = 'LongMonthDayYear', // MMMM D, YYYY (LL)
  LongMonthDayYearWithTime = 'LongMonthDayYearWithTime', // MMMM D, YYYY h:mm A (LLL)
  LongMonthDayYearWithTimeAndTimezone = 'LongMonthDayYearWithTimeAndTimezone', // MMMM D, YYYY h:mm A z (LLL z)
  MonthDayYearWithSlashes = 'MonthDayYearWithSlashes', // M/D/YYYY (l)
  ShortDayOfWeek = 'ShortDayOfWeek', // ddd
  ShortDayOfWeekMonthDay = 'ShortDayOfWeekMonthDay', // ddd, MMM D
  ShortDayOfWeekMonthDayWithTimeAndTimezoneAndComma = 'ShortDayOfWeekMonthDayWithTimeAndTimezoneAndComma', // ddd, MMM D, h:mm A z
  ShortMonthDay = 'ShortMonthDay', // MMM D
  ShortMonthDayWithTimeAndComma = 'ShortMonthDayWithTimeAndComma', // MMM D, h:mm A
  ShortMonthDayWithTimeAndTimezoneAndComma = 'ShortMonthDayWithTimeAndTimezoneAndComma', // MMM D, h:mm A z
  ShortMonthDayYear = 'ShortMonthDayYear', // MMM D, YYYY (ll)
  ShortMonthDayYearWithTimeAndTimezone = 'ShortMonthDayYearWithTimeAndTimezone', // MMM D, YYYY h:mm A z (lll z)
  ShortMonthDayYearWithTimeAndTimezoneAndComma = 'ShortMonthDayYearWithTimeAndTimezoneAndComma', // MMM D, YYYY, h:mm A z (ll, LT z)
  Time = 'Time', // h:mm A (LT)
  TimeWithTimezone = 'TimeWithTimezone', // h:mm A z (LT z)
  TimeHourWithTimezone = 'TimeHourWithTimezone', // h A z
}

export function formatMoment (time: Moment.Moment, format: TimeFormat, locale?: string): string {
  locale = locale || window.navigator.language;

  switch (format) {
    case TimeFormat.DayOfMonth: {
      return time.locale(locale).format('D');
    }
    case TimeFormat.LongDayOfWeekMonthDay: {
      return new Intl.DateTimeFormat(locale, { weekday: 'long', month: 'long', day: 'numeric', timeZone: time.tz() }).format(time.toDate());
    }
    case TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone: {
      const date = new Intl.DateTimeFormat(locale, { weekday: 'long', month: 'long', day: 'numeric', timeZone: time.tz() }).format(time.toDate());
      return `${date} at ${time.locale(locale).format('LT z')}`;
    }
    case TimeFormat.LongDayOfWeekMonthDayWithTimeAndComma: {
      const date = new Intl.DateTimeFormat(locale, { weekday: 'long', month: 'long', day: 'numeric', timeZone: time.tz() }).format(time.toDate());
      return `${date}, ${time.locale(locale).format('LT')}`;
    }
    case TimeFormat.LongDayOfWeekMonthDayWithTimeAndTimezoneAndComma: {
      const date = new Intl.DateTimeFormat(locale, { weekday: 'long', month: 'long', day: 'numeric', timeZone: time.tz() }).format(time.toDate());
      return `${date}, ${time.locale(locale).format('LT z')}`;
    }
    case TimeFormat.LongDayOfWeekMonthDayYear: {
      return new Intl.DateTimeFormat(locale, { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', timeZone: time.tz() }).format(time.toDate());
    }
    case TimeFormat.LongDayOfWeekMonthDayYearWithTimeAndComma: {
      const date = new Intl.DateTimeFormat(locale, { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', timeZone: time.tz() }).format(time.toDate());
      return `${date}, ${time.locale(locale).format('LT')}`;
    }
    case TimeFormat.LongDayOfWeekMonthDayYearWithTimeAndTimezoneAndComma: {
      const date = new Intl.DateTimeFormat(locale, { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', timeZone: time.tz() }).format(time.toDate());
      return `${date}, ${time.locale(locale).format('LT z')}`;
    }
    case TimeFormat.LongMonthDay: {
      return new Intl.DateTimeFormat(locale, { month: 'long', day: 'numeric', timeZone: time.tz() }).format(time.toDate());
    }
    case TimeFormat.LongMonthDayYear: {
      return time.locale(locale).format('LL');
    }
    case TimeFormat.LongMonthDayYearWithTime: {
      return time.locale(locale).format('LLL');
    }
    case TimeFormat.LongMonthDayYearWithTimeAndTimezone: {
      return time.locale(locale).format('LLL z');
    }
    case TimeFormat.MonthDayYearWithSlashes: {
      return time.locale(locale).format('l');
    }
    case TimeFormat.ShortDayOfWeek: {
      return time.locale(locale).format('ddd');
    }
    case TimeFormat.ShortDayOfWeekMonthDay: {
      return new Intl.DateTimeFormat(locale, { weekday: 'short', month: 'short', day: 'numeric', timeZone: time.tz() }).format(time.toDate());
    }
    case TimeFormat.ShortDayOfWeekMonthDayWithTimeAndTimezoneAndComma: {
      const date = new Intl.DateTimeFormat(locale, { weekday: 'short', month: 'short', day: 'numeric', timeZone: time.tz() }).format(time.toDate());
      return `${date}, ${time.locale(locale).format('LT z')}`;
    }
    case TimeFormat.ShortMonthDay: {
      return new Intl.DateTimeFormat(locale, { month: 'short', day: 'numeric', timeZone: time.tz() }).format(time.toDate());
    }
    case TimeFormat.ShortMonthDayWithTimeAndComma: {
      const date = new Intl.DateTimeFormat(locale, { month: 'short', day: 'numeric', timeZone: time.tz() }).format(time.toDate());
      return `${date}, ${time.locale(locale).format('LT')}`;
    }
    case TimeFormat.ShortMonthDayWithTimeAndTimezoneAndComma: {
      const date = new Intl.DateTimeFormat(locale, { month: 'short', day: 'numeric', timeZone: time.tz() }).format(time.toDate());
      return `${date}, ${time.locale(locale).format('LT z')}`;
    }
    case TimeFormat.ShortMonthDayYear: {
      return time.locale(locale).format('ll');
    }
    case TimeFormat.ShortMonthDayYearWithTimeAndTimezone: {
      return time.locale(locale).format('lll z');
    }
    case TimeFormat.ShortMonthDayYearWithTimeAndTimezoneAndComma: {
      return time.locale(locale).format('ll, LT z');
    }
    case TimeFormat.Time: {
      return time.locale(locale).format('LT');
    }
    case TimeFormat.TimeWithTimezone: {
      return time.locale(locale).format('LT z');
    }
    case TimeFormat.TimeHourWithTimezone: {
      return time.locale(locale).format('h A z');
    }
  }
}
