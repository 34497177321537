import type {
  Candidate,
  InterviewerFilterableType,
  RoomFilterExpressionFilterableType,
} from '../../../../../types';
import type { ConflictTotals, GenerateSchedulesPayload } from '../../../../../hooks/queries/schedules';
import type { EditableBusinessHour } from '../../../../../types/business-hours';
import type {
  PotentialRoom,
  PotentialUser,
  PotentialZoomHost,
} from '../../../../library/data-display/CalendarSchedule/types';
import type { ZoomHostFilterableType } from '../../../../../types';

export const MAX_ALLOWED_INTERVIEWS_FOR_MULTI_BLOCK_SCHEDULING = 7;
export const MAX_ALLOWED_SCHEDULE_BLOCKS = 2;

export interface TimeSlot {
  start_time: Date;
  end_time: Date;
}

export type GenerateSchedulesPayloadBase = Omit<
  GenerateSchedulesPayload,
  | 'minimum_blocks'
  | 'maximum_blocks'
  | 'offset'
  | 'page_size'
>;

export enum ConflictType {
  Room = 'room',
  Interviewer = 'interviewer',
  User = 'user',
}

export interface Conflict {
  event: {
    id: string;
    title: string;
    start_time: string;
    end_time: string;
    private?: boolean;
    buffer_time?: boolean;
  };
  interview: {
    name: string;
    start_time: string;
    duration_minutes: number;
  };
  resourceId: string;
  type: `${ConflictType}`;
  isZoomConflict?: boolean;
}

export interface RoomFilter {
  room_filter_expressions: {
    negated: boolean;
    filterable_id: string;
    filterable_type: `${RoomFilterExpressionFilterableType}`;
  }[];
}

export interface InterviewTemplate {
  id?: string;
  name: string;
  duration_minutes: number;
  live_coding_enabled: boolean;
  /**
   * @deprecated
   */
  position?: number;
  positions?: number[];
  time_window_start?: string;
  time_window_end?: string;
  candidate_facing_name?: string;
  candidate_facing_details?: string;
  interviewer_templates: {
    description?: string;
    optional: boolean;
    include_past_interviewers: boolean;
    interviewer_filters: {
      position: number;
      interviewer_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: `${InterviewerFilterableType}`;
      }[];
    }[];
  }[];
}

export interface StageInterview {
  id: string;
  in_schedule: boolean;
  name: string;
  ats_id?: string;
  ats_duration_minutes: number | null;
  ats_interviewer_ids: string[] | null;
  ats_schedulable: boolean | null;
  feedback_form_id?: string;
  interview_template: InterviewTemplate;
}

export interface Interview {
  id?: string;
  name: string;
  stage_interview_id: string;
  scorecard_stage_interview_id?: string;
  interviewer_event_ical_uid?: string;
  start_time: string;
  interview_template: {
    name?: string;
    duration_minutes: number;
    live_coding_enabled?: boolean;
    positions?: number[];
    time_window_start?: string;
    time_window_end?: string;
    candidate_facing_name?: string;
    candidate_facing_details?: string;
  };
  feedback_form_id?: string;
  interviewers: {
    id?: string;
    interviewer_template: {
      description?: string;
      optional: boolean;
      include_past_interviewers: boolean;
      interviewer_filters: {
        interviewer_filter_expressions: {
          negated: boolean;
          filterable_id: string;
          filterable_type: `${InterviewerFilterableType}`;
        }[];
      }[];
    };
    potential_users: PotentialUser[];
    selected_user: PotentialUser;
  }[];
  video_conferencing_passcode?: string;
}

export interface Block {
  schedule_id?: string;
  interviews: Interview[];
  potential_rooms?: PotentialRoom[];
  selected_room?: PotentialRoom;
  potential_zoom_hosts?: PotentialZoomHost[];
  selected_zoom_host?: PotentialZoomHost;
  // These are set from existing held schedules.
  video_conferencing_link?: string;
  current_zoom_host_id?: string;
}

export interface ScheduleOptionWithTimezone extends Partial<ScheduleOption> {
  timezone: string;
}

export interface ScheduleOption {
  conflicts: number;
  blocks: Block[];
  stage: {
    id: string;
    job_id: string;
  };
  // These are set from existing held schedules.
  candidate_event_ical_uid?: string;
  candidate_event_location?: string;
}

export type ScheduleOptionFilter = (option: ScheduleOption) => boolean;

export interface GeneratedOption {
  lastGenerateSchedulesPayload: GenerateSchedulesPayloadBase | null;
  nextOffset: number;
  fetchedAllData: boolean;
  scheduleOptions: ScheduleOption[];
  scheduleOptionsConflictTotals: ConflictTotals;
  selectedScheduleOptionIndices: number[];
  totalScheduleOptionsCount?: number;
  unloaded?: boolean;
}

export interface Schedule {
  id?: string;
  block_id?: string;
  timezone: string;
  application_id: string;
  scheduling_calendar_email: string;
  candidate_scheduling_calendar_email?: string;
  candidate_timezone?: string;
  availabilities: TimeSlot[];
  submittedAvailabilities: TimeSlot[];
  hold: boolean;
  schedule_template: {
    candidate_calendar_event_template?: {
      id?: string;
      title: string;
      description: string;
      location?: string;
      additional_attendees?: string[];
      additional_optional_attendees?: string[];
    };
    business_hours: EditableBusinessHour[];
    onsite: boolean;
    mark_interviewer_events_as_private: boolean;
    mark_candidate_events_as_private: boolean;
    video_conferencing_enabled: boolean;
    zoom_host_filters?: {
      zoom_host_filter_expressions: {
        filterable_id: string;
        filterable_type: `${ZoomHostFilterableType}`;
        negated: boolean;
      }[];
    }[];
    create_hiring_channel: boolean;
    confirmation_email_template?: {
      id?: string;
      name: string;
      subject: string;
      sender_name: string;
      sender_email: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body: string;
      attachments?: ({
        name: string;
        new?: boolean;
        index?: number;
        file?: File;
      } | File)[];
    };
    multi_block_confirmation_email_template?: {
      id?: string;
      name: string;
      subject: string;
      sender_name: string;
      sender_email: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body: string;
      attachments?: ({
        name: string;
        new?: boolean;
        index?: number;
        file?: File;
      } | File)[];
    };
    room_filters?: RoomFilter[];
  };
  stage: {
    id: string;
    job_id: string;
  };
  stage_interviews: StageInterview[];
  generatedOptionsByNumberOfBlocks: Record<string, GeneratedOption>;
}

// This is really only used in the Review Step, and its helpers. It's just a
// combination of other types.
export interface SelectedSchedule {
  id?: string;
  application_id: string;
  candidate: Candidate;
  scheduling_calendar_email: string;
  candidate_scheduling_calendar_email?: string;
  timezone: string;
  candidate_timezone?: string;
  schedule_template: Schedule['schedule_template'];
  blocks: Block[];
  index: number;
}
