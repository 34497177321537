import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { useState } from 'react';

import InterviewerTemplatesForm from '../../../library/inputs/InterviewerTemplatesForm';
import MultiStepFormStep from '../../../library/inputs/MultiStepFormStep';
import { Step, useNewInterviewTemplate } from './use-new-interview-template';
import { correctPath } from 'libraries/gem';

const InterviewersStep = () => {
  const {
    completedStep,
    interviewTemplate,
    setCompletedStep,
    setInterviewTemplate,
  } = useNewInterviewTemplate();

  const [interviewerTemplates, setInterviewerTemplates] = useState(interviewTemplate.interviewer_templates);

  const handleNext = () => {
    setInterviewTemplate((prev) => ({
      ...prev,
      interviewer_templates: interviewerTemplates,
    }));
    setCompletedStep(Step.Interviewers + 1);
  };

  if (completedStep < 1) {
    return <Redirect to={correctPath('/app/interview-templates/create/details')} />;
  }

  return (
    <Breadcrumb
      data={{
        title: '2. Interviewers',
        pathname: correctPath('/app/interview-templates/create/interviewers'),
      }}
    >
      <MultiStepFormStep
        backLocation={correctPath('/app/interview-templates/create/details')}
        nextButtonValue="Review"
        nextLocation={correctPath('/app/interview-templates/create/review')}
        onNext={handleNext}
      >
        <Helmet>
          <title>2. Interviewers | Create Interview Template | InterviewPlanner</title>
        </Helmet>
        <InterviewerTemplatesForm
          interviewerTemplates={interviewerTemplates}
          setInterviewerTemplates={setInterviewerTemplates}
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default InterviewersStep;
