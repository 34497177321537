import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AttendeePool from '../../../../../library/data-display/AttendeePool';
import AvailabilityPicker from 'components/library/inputs/AvailabilityPicker';
import ExpandableCheckboxInput from 'components/library/inputs/ExpandableCheckboxInput';
import Flash from 'components/library/utils/Flash';
import HiringMeetingTemplateSelectInput from 'components/library/inputs/HiringMeetingTemplateSelectInput';
import ZoomHostSelectInput from '../../../../../library/inputs/ZoomHostSelectInput';
import { BusinessHourReferenceType } from 'types/business-hours';
import { ScheduleStatus, VideoConferencingTool } from 'types';
import { Step, useNewHiringMeeting } from '../use-new-hiring-meeting';
import { StyledHiringMeetingAttendeeSelectInput } from './styles';
import { StyledMultiStepFormStep } from '../styles';
import { defaultBusinessHours } from 'libraries/business-hours';
import { resolveZoomHostsParams } from '../../../../../../hooks/queries/zoom';
import { useApplication } from 'hooks/queries/applications';
import { useEvents } from 'hooks/use-events';
import { useHiringMeetingTemplate } from 'hooks/queries/hiring-meeting-templates';
import { useQueryClient } from 'react-query';
import { useResolveHiringMeetingAttendees } from '../../../../../../hooks/queries/hiring-meetings';
import { useSession } from 'hooks/use-session';

import type { ChangeEvent } from 'react';
import type { EditableHiringMeetingAttendeeFilter, ZoomHostType } from 'types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as ZoomHostOption } from '../../../../../library/inputs/ZoomHostSelectInput';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { TimeSlot } from 'components/library/inputs/AvailabilityPicker/types';
import { correctPath } from 'libraries/gem';

const PreferencesStep = () => {
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();

  const { account } = useSession();
  const application = useApplication(id).data!;
  const { fetchEvents } = useEvents();

  const {
    completedStep,
    hiringMeeting,
    setCompletedStep,
    setHiringMeeting,
  } = useNewHiringMeeting();

  const [hiringMeetingTemplateId, setHiringMeetingTemplateId] = useState(hiringMeeting.hiring_meeting_template.id);
  const [isVideoConferencingEnabled, setIsVideoConferencingEnabled] = useState(hiringMeeting.hiring_meeting_template.video_conferencing_enabled);
  const [zoomHostId, setZoomHostId] = useState(hiringMeeting.zoom_host_id);
  const [zoomHostType, setZoomHostType] = useState<ZoomHostType | undefined>(hiringMeeting.zoom_host_type as ZoomHostType);
  const [attendeeFilters, setAttendeeFilters] = useState<EditableHiringMeetingAttendeeFilter[]>(hiringMeeting.hiring_meeting_template.hiring_meeting_attendee_filters || []);
  const [timeSlot, setTimeSlot] = useState<TimeSlot[]>(hiringMeeting.start_time ? [{
    start_time: Moment(hiringMeeting.start_time).toDate(),
    end_time: Moment(hiringMeeting.start_time).add(hiringMeeting.hiring_meeting_template.duration_minutes, 'minutes').toDate(),
  }] : []);
  const [selectedDate, setSelectedDate] = useState<Date>(() => new Date());
  const [timezone, setTimezone] = useState(Moment.tz.guess());
  const [eventsAreLoading, setEventsAreLoading] = useState(false);

  const { data: selectedTemplate } = useHiringMeetingTemplate(hiringMeetingTemplateId);

  const backgroundEventTimeSlots = useMemo<TimeSlot[]>(() => {
    return (application.all_schedules || [])
    .filter((schedule) => schedule.status === ScheduleStatus.Confirmed || schedule.status === ScheduleStatus.Confirming)
    .map((schedule) => {
      const firstInterview = schedule.interviews[0];
      const lastInterview = schedule.interviews[schedule.interviews.length - 1];
      return {
        title: schedule.stage.name,
        start_time: firstInterview.start_time,
        end_time: Moment(lastInterview.start_time).add(lastInterview.interview_template.duration_minutes, 'minutes').format(),
      };
    });
  }, [application]);

  useEffect(() => {
    if (completedStep >= Step.Event && !hiringMeetingTemplateId) {
      // If they've come back to this step, when they first get here, there will be no template ID, so we don't want to
      // clear out what was already set. We only want to update values if the user explicitly selected a new hiring
      // meeting template from the dropdown.
      return;
    }

    setIsVideoConferencingEnabled(selectedTemplate?.video_conferencing_enabled || false);
    setAttendeeFilters((selectedTemplate?.hiring_meeting_attendee_filters || []).map(({ hiring_meeting_attendee_filter_expressions }) => ({
      hiring_meeting_attendee_filter_expressions: hiring_meeting_attendee_filter_expressions.map(({ negated, filterable_id, filterable_type }) => ({
        negated,
        filterable_id,
        filterable_type,
      })),
    })));

    if (selectedTemplate?.zoom_host_filters && account?.video_conferencing_type === VideoConferencingTool.Zoom) {
      (async () => {
        const zoomHosts = await queryClient.fetchQuery(resolveZoomHostsParams({
          zoomHostFilters: (selectedTemplate.zoom_host_filters || []).map((filter) => ({
            zoom_host_filter_expressions: filter.zoom_host_filter_expressions.map((exp) => ({
              negated: exp.negated,
              filterable_id: exp.filterable_id,
              filterable_type: exp.filterable_type,
            })),
          })),
        }));
        const zoomHostIds = zoomHosts.map(({ id }) => id);
        let zoomHostIndex = -1;
        setZoomHostId((prev) => {
          const index = zoomHostIds.indexOf(prev!);
          if (index !== -1) {
            // The currently selected Zoom host is still part of this pool, so we leave it as is.
            zoomHostIndex = index;
            return prev;
          }
          zoomHostIndex = 0;
          return zoomHosts[zoomHostIndex]?.id;
        });
        setZoomHostType(() => {
          return zoomHosts[zoomHostIndex]?.type;
        });
      })();
    }
  }, [selectedTemplate, account]);

  const { data: attendees } = useResolveHiringMeetingAttendees({
    applicationId: application.id,
    hiringMeetingAttendeeFilters: attendeeFilters,
  });

  useEffect(() => {
    if (!attendees || attendees.length === 0) {
      return;
    }
    const userIds = attendees.map(({ id }) => id);
    const start = Moment(selectedDate).tz(timezone).startOf('week');
    // TODO: It would be nice if the backend supported a range of dates, which
    // isn't hard to do, but if it did, we'd have to update useEvents to handle
    // that.
    for (let i = 0; i < 7; i++) {
      const date = start.clone().add(i, 'day').format('YYYY-MM-DD');
      const fetchInterviewerEvents = async () => {
        setEventsAreLoading(true);
        await fetchEvents({
          date,
          timezone,
          user_ids: userIds,
          // While we do load Zoom meetings, we don't actually display them in the picker. Right now, we just display
          // events.
          zoom_host_ids: zoomHostId ? [zoomHostId] : [],
        });
        setEventsAreLoading(false);
      };
      fetchInterviewerEvents();
    }
  }, [selectedDate, attendees, timezone, zoomHostId]);

  const handleHiringMeetingTemplateChange = (option: OnChangeValue<Option<string>, false>) => {
    setHiringMeetingTemplateId(option?.value);
    if (!option?.value) {
      setIsVideoConferencingEnabled(false);
      setZoomHostId(undefined);
      setZoomHostType(undefined);
      setAttendeeFilters([]);
    }
  };

  const handleIsVideoConferencingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsVideoConferencingEnabled(e.target.checked);

  const handleZoomHostChange = (option: OnChangeValue<ZoomHostOption, false>) => {
    setZoomHostId(option?.value);
    setZoomHostType(option?.type as ZoomHostType);
  };

  const handleAttendeeFiltersChange = (filters: EditableHiringMeetingAttendeeFilter[]) => setAttendeeFilters(filters);
  const handleTimezoneChange = (option: OnChangeValue<Option<string>, false>) => setTimezone(option ? option.value : '');

  const handleNext = () => {
    const selectedTime = timeSlot[0];
    setHiringMeeting((prev) => ({
      ...prev,
      start_time: Moment(selectedTime?.start_time).format(),
      timezone,
      zoom_host_id: zoomHostId,
      zoom_host_type: zoomHostType,
      hiring_meeting_template: {
        ...prev.hiring_meeting_template,
        name: selectedTemplate?.name || 'Inline Hiring Meeting Template',
        duration_minutes: Moment.duration(Moment.utc(selectedTime.end_time).diff(Moment(selectedTime.start_time))).asMinutes(),
        video_conferencing_enabled: isVideoConferencingEnabled,
        zoom_host_filters: account?.video_conferencing_type === VideoConferencingTool.Zoom ? (selectedTemplate?.zoom_host_filters || []).map(({ zoom_host_filter_expressions }) => ({
          zoom_host_filter_expressions: zoom_host_filter_expressions.map(({ negated, filterable_id, filterable_type }) => ({
            negated,
            filterable_id,
            filterable_type,
          })),
        })) : undefined,
        hiring_meeting_attendee_filters: attendeeFilters,
        calendar_event_template: completedStep < Step.Event && selectedTemplate?.calendar_event_template ? {
          title: selectedTemplate.calendar_event_template.title,
          description: selectedTemplate.calendar_event_template.description,
          location: selectedTemplate.calendar_event_template.location,
          additional_attendees: selectedTemplate.calendar_event_template.additional_attendees,
          additional_optional_attendees: selectedTemplate.calendar_event_template.additional_optional_attendees,
        } : prev.hiring_meeting_template.calendar_event_template,
      },
      hiring_meeting_attendee_ids: attendees?.map(({ id }) => id),
    }));
    setCompletedStep(Step.Preferences + 1);
  };

  return (
    <Breadcrumb
      data={{
        title: '1. Preferences',
        pathname: correctPath(`/app/candidates/${id}/schedule-hiring-meeting/preferences`),
      }}
    >
      <StyledMultiStepFormStep
        isFirstStep
        nextButtonIsDisabled={!timeSlot[0]}
        nextButtonValue="Event"
        nextLocation={correctPath(`/app/candidates/${id}/schedule-hiring-meeting/event`)}
        onNext={handleNext}
      >
        <Helmet>
          <title>1. Preferences | Schedule hiring meeting for {application.candidate.name || 'Unknown'} | InterviewPlanner</title>
        </Helmet>
        <Flash
          message="Select attendees and a time for the hiring meeting. The candidate will not be invited or notified."
          showFlash
          type="info"
        />
        <HiringMeetingTemplateSelectInput
          label="Hiring Meeting Template"
          onChange={handleHiringMeetingTemplateChange}
          value={hiringMeetingTemplateId}
        />
        <ExpandableCheckboxInput
          helperText="We will create a video conferencing link and add it to the event."
          isChecked={isVideoConferencingEnabled}
          label="Include video conferencing."
          onChange={handleIsVideoConferencingEnabledChange}
        >
          {isVideoConferencingEnabled && account?.video_conferencing_type === 'zoom' && (
            <ZoomHostSelectInput
              label="Zoom Meeting Host"
              onChange={handleZoomHostChange}
              selectedZoomHostId={zoomHostId}
              selectedZoomHostType={zoomHostType}
              zoomHostFilters={selectedTemplate?.zoom_host_filters || []}
            />
          )}
        </ExpandableCheckboxInput>
        <StyledHiringMeetingAttendeeSelectInput
          onChange={handleAttendeeFiltersChange}
          value={attendeeFilters}
        />
        {attendees && attendees?.length > 0 && <AttendeePool attendees={attendees} />}
        <AvailabilityPicker
          availabilities={timeSlot}
          backgroundEventTimeSlots={backgroundEventTimeSlots}
          businessHours={defaultBusinessHours(BusinessHourReferenceType.AvailabilityTemplate)}
          controlledTimezone={timezone}
          directory={account?.directory_type}
          eventTitle="Hiring Meeting"
          interviewerEventsAreLoading={eventsAreLoading}
          interviewerIds={attendees?.map(({ id }) => id)}
          isMulti={false}
          isRequired
          isSimplifiedViewByDefault
          minDuration={selectedTemplate?.duration_minutes ?? hiringMeeting.hiring_meeting_template.duration_minutes}
          onDateChange={(date) => setSelectedDate(date)}
          onTimezoneChange={handleTimezoneChange}
          setAvailabilities={setTimeSlot}
          showAvailabilitiesSummary={false}
          showEventWarnings={false}
          showInterviewerEventFilters
          showQuickSelectOptions={false}
        />
        <Flash
          message="Select a time for the hiring meeting by clicking or dragging on the calendar."
          showFlash={!timeSlot[0]}
          type="info"
        />
      </StyledMultiStepFormStep>
    </Breadcrumb>
  );
};

export default PreferencesStep;
