import classnames from 'classnames';
import { uniqueId } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import CheckboxInput from './CheckboxInput';
import EditorInput from './EditorInput';
import EmailTemplateAdvancedSettings from './EmailTemplateAdvancedSettings';
import EmailTemplateSelectInput from './EmailTemplateSelectInput';
import Flash from '../utils/Flash';
import TextInput from './TextInput';
import TokenInput from './TokenInput';
import Tooltip from '../utils/Tooltip';
import { DEFAULT_EMAIL_CONTENT, NEW_EMAIL_TEMPLATE } from '../../Application/EmailTemplates/EmailTemplateCreate/helpers';
import { EditorType } from '../../../types';
import { EmailTemplateType, Token } from '../../../types';
import { emailTemplateParams } from '../../../hooks/queries/email-templates';
import { getTokensFromSlateValue, slateValueToHtml } from '../../../libraries/editor/slate-value-to-html';
import { htmlToSlateValue } from '../../../libraries/editor/html-to-slate-value';
import { slateValueToText } from '../../../libraries/editor/slate-value-to-text';
import { textToSlateValue } from '../../../libraries/editor/text-to-slate-value';
import { translateSlateValueBetweenEditorTypes } from '../../../libraries/editor/translate-slate-value-between-editor-types';
import { useSlateEditor } from '../../../hooks/use-slate-editor';

import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './SelectInput/types';
import type { TokensResponse, EditorAttachment, CreatableEmailTemplate } from '../../../types';

// These are required token prefixes.
const requiredTokens: { [key: string]: Token[] } = {
  [EmailTemplateType.AvailabilityRequestEmail]: [Token.AvailabilityRequestLink],
  [EmailTemplateType.SelfSchedulingRequestEmail]: [Token.SelfSchedulingRequestLink],
};

interface Props {
  additionalRequiredTokenPrefixes?: string[];
  emailTemplate?: CreatableEmailTemplate;
  enabledCheckboxLabel?: string;
  isDisabled?: boolean;
  isEnabled?: boolean;
  pendingPreviewMessage: string;
  setEmailTemplate: Dispatch<SetStateAction<CreatableEmailTemplate | undefined>>;
  setErrorTokens: Dispatch<SetStateAction<Token[]>>;
  setHasRequiredTokens?: Dispatch<SetStateAction<boolean>>;
  setIsEnabled?: Dispatch<SetStateAction<boolean>>;
  showNameInput?: boolean;
  tokens?: TokensResponse;
  type: `${EmailTemplateType}`;
}

// This is the Select Input that should be used when the result will be sending
// an email to the candidate. If you want a Select Input for changing the email
// template on another template (e.g. schedule template, availability template,
// etc), use PreferencesEmailTemplateSelectInput.
// This also doesn't work well when email template is managed both in this
// component and in the parent as well. This is because we're not watching to
// see if subject and body change on the email template. I tried adding that and
// there was a lot of infinite loops since we watch the editor state and update
// the email template.
const EmailTemplateForm = ({
  additionalRequiredTokenPrefixes = [],
  emailTemplate,
  enabledCheckboxLabel = 'Send email to candidate through InterviewPlanner.',
  isDisabled = false,
  isEnabled = true,
  pendingPreviewMessage,
  setEmailTemplate,
  setErrorTokens,
  setHasRequiredTokens,
  setIsEnabled,
  showNameInput = false,
  tokens,
  type,
}: Props) => {
  const queryClient = useQueryClient();

  const [error, setError] = useState('');
  const [emailSubjectSlateEditor, emailSubjectSlateValue, setEmailSubjectSlateValue, setEmailSubject] = useSlateEditor(emailTemplate?.subject || '', true);
  const [emailBodySlateEditor, emailBodySlateValue, setEmailBodySlateValue, setEmailBody] = useSlateEditor(emailTemplate?.body || '');

  const tokensUsedInSubject = useMemo(() => getTokensFromSlateValue(emailSubjectSlateValue), [emailSubjectSlateValue]);
  const tokensUsedInBody = useMemo(() => getTokensFromSlateValue(emailBodySlateValue), [emailBodySlateValue]);
  const errorTokens = useMemo(() => {
    const errorSubjectTokens = (
      tokens && tokensUsedInSubject ?
        tokensUsedInSubject.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    const errorBodyTokens = (
      tokens && tokensUsedInBody ?
        tokensUsedInBody.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    return [
      ...errorSubjectTokens,
      ...errorBodyTokens,
    ];
  }, [tokens, tokensUsedInSubject, tokensUsedInBody]);

  useEffect(() => {
    // If it's not enabled, always say that there aren't any error tokens. This
    // makes it easier to use in the parent component so that you dont have to
    // keep checking for isEnabled.
    setErrorTokens(isEnabled ? errorTokens : []);
  }, [errorTokens, isEnabled]);

  useEffect(() => {
    if (setHasRequiredTokens) {
      const allRequiredTokenPrefixes = requiredTokens[type] ? [
        ...requiredTokens[type],
        ...additionalRequiredTokenPrefixes,
      ] : additionalRequiredTokenPrefixes;

      if (isEnabled && allRequiredTokenPrefixes.length > 0) {
        const hasRequiredTokens = allRequiredTokenPrefixes.every((requiredTokenPrefix) => {
          return tokensUsedInBody.some((name) => name.startsWith(requiredTokenPrefix));
        });
        setHasRequiredTokens(hasRequiredTokens);
      } else {
        // It's either not enabled or there are no required tokens for this type,
        // so we just say it has all required tokens to prevent progression.
        setHasRequiredTokens(true);
      }
    }
  }, [additionalRequiredTokenPrefixes, isEnabled, setHasRequiredTokens, tokensUsedInBody, type]);

  useEffect(() => {
    if (type === EditorType.MultiBlockConfirmationEmail) {
      // Translate the email subject and body slate values to use multi-block tokens.
      // The next two useEffects after this one take care of updating the email template with the multi-block content.
      // If the email already has multi-block tokens, like when confirming a schedule on hold, the translation step
      // will not change the content.
      setEmailSubjectSlateValue(
        translateSlateValueBetweenEditorTypes(
          textToSlateValue(emailTemplate?.subject || ''),
          EditorType.ConfirmationEmail,
          EditorType.MultiBlockConfirmationEmail
        )
      );
      setEmailBodySlateValue(
        translateSlateValueBetweenEditorTypes(
          htmlToSlateValue(emailTemplate?.body || ''),
          EditorType.ConfirmationEmail,
          EditorType.MultiBlockConfirmationEmail
        )
      );
    }
  }, [type, emailTemplate?.id]);

  useEffect(() => {
    setEmailTemplate((prevTemplate) => (prevTemplate ? {
      ...prevTemplate,
      subject: slateValueToText(emailSubjectSlateValue),
    } : undefined));
  }, [emailSubjectSlateValue]);

  useEffect(() => {
    setEmailTemplate((prevTemplate) => (prevTemplate ? {
      ...prevTemplate,
      body: slateValueToHtml(emailBodySlateValue),
    } : undefined));
  }, [emailBodySlateValue]);

  const handleIsEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsEnabled?.(isChecked);
    if (isChecked && (!emailTemplate || Object.keys(emailTemplate).length <= 2)) {
      // If we're enabling the email, but there is no email template provided,
      // the email template could have values for subject and body (because of
      // the useEffects down below). Is this is the case, reset the email
      // template with the new template object.
      setEmailTemplate(NEW_EMAIL_TEMPLATE);
    }
  };

  const handleEmailTemplateChange = async (option: OnChangeValue<Option<string>, false>) => {
    let newEmailTemplate = NEW_EMAIL_TEMPLATE;
    if (option) {
      try {
        newEmailTemplate = await queryClient.fetchQuery(emailTemplateParams(option.value)) as CreatableEmailTemplate;
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
        return;
      }
    }

    setEmailTemplate(newEmailTemplate);
    if (type !== EditorType.MultiBlockConfirmationEmail) {
      setEmailSubject(newEmailTemplate?.subject || '');
      setEmailBody(newEmailTemplate?.body || '');
    }
  };

  const handleEmailNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailTemplate((prevTemplate) => {
      if (!prevTemplate) {
        return;
      }
      return {
        ...prevTemplate,
        name: e.target.value,
      };
    });
  };

  const handleEmailAttachmentsChange = (fn: SetStateAction<EditorAttachment[]>) => {
    setEmailTemplate((prevTemplate) => {
      if (!prevTemplate) {
        return;
      }
      const attachments = typeof fn === 'function' ? fn(prevTemplate.attachments || []) : fn;
      return {
        ...prevTemplate,
        attachments,
      };
    });
  };

  const hasEnabledCheckbox = Boolean(setIsEnabled);

  return (
    <div className="email-template-form">
      {hasEnabledCheckbox && (
        <CheckboxInput
          isChecked={isEnabled}
          isDisabled={isDisabled}
          label={enabledCheckboxLabel}
          onChange={handleIsEnabledChange}
          tooltip={isDisabled ? (
            <Tooltip
              id={uniqueId('email-form-disabled-')}
              value="Sending emails is disabled until Gem is granted email access."
            />
          ) : undefined}
        />
      )}
      <Flash
        message={error}
        showFlash={Boolean(error)}
        type="danger"
      />
      {isEnabled && emailTemplate && (
        <div className={classnames(hasEnabledCheckbox && 'email-template-form-expanded')}>
          <EmailTemplateSelectInput
            isDisabled={isDisabled}
            onChange={handleEmailTemplateChange}
            type={type === EditorType.MultiBlockConfirmationEmail ? EditorType.ConfirmationEmail : type}
            value={emailTemplate.id}
          />
          {showNameInput && (
            <TextInput
              isDisabled={isDisabled}
              isRequired={isEnabled}
              label="Template Name"
              onChange={handleEmailNameChange}
              value={emailTemplate.name}
            />
          )}
          <div className="form-container">
            {tokens && (
              <TokenInput
                editor={emailSubjectSlateEditor}
                isDisabled={isDisabled}
                isRequired={isEnabled}
                label="Email Subject"
                pendingPreviewMessage={pendingPreviewMessage}
                setValue={setEmailSubjectSlateValue}
                tokens={tokens}
                type={type}
                value={emailSubjectSlateValue}
              />
            )}
          </div>
          {tokens && (
            <EditorInput
              allowImages
              attachments={emailTemplate.attachments}
              editor={emailBodySlateEditor}
              exampleHtmlContent={DEFAULT_EMAIL_CONTENT[type]?.body}
              isDisabled={isDisabled}
              isRequired={isEnabled}
              label="Email Body"
              pendingPreviewMessage={pendingPreviewMessage}
              setAttachments={handleEmailAttachmentsChange}
              setValue={setEmailBodySlateValue}
              tokens={tokens}
              type={type}
              value={emailBodySlateValue}
            />
          )}
          <EmailTemplateAdvancedSettings
            emailTemplate={emailTemplate}
            isDisabled={isDisabled}
            setEmailTemplate={setEmailTemplate}
          />
        </div>
      )}
    </div>
  );
};

export default EmailTemplateForm;
