import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../../../../library/inputs/Button';
import Flash from '../../../../library/utils/Flash';
import Section from '../../../../library/layout/Section';
import TextInput from '../../../../library/inputs/TextInput';
import Tooltip from '../../../../library/utils/Tooltip';
import { StyledAddPhaseButton, StyledNumberOfInterviewsTextInput, StyledTable } from '../../styles';
import { StyledTabContainer } from '../styles';
import { useTrainingProgram, useUpdateTrainingProgram } from '../../../../../hooks/queries/training-programs';

import type { EditableTrainingPhase } from '../../../../../types';
import type { TableSchema } from '../../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const TrainingProgramDetails = () => {
  const { id: trainingProgramId } = useParams<{ id: string }>();

  const trainingProgram = useTrainingProgram(trainingProgramId).data!;

  const [phases, setPhases] = useState<EditableTrainingPhase[]>(trainingProgram.training_phases || [{}]);
  const [isEditing, setIsEditing] = useState(false);

  const updateTrainingProgramMutation = useUpdateTrainingProgram();

  const phasesSchema = useMemo<TableSchema<EditableTrainingPhase>>(() => [{
    header: '',
    getCellClassName: () => 'training-phase-table-index',
    displayValue: (_, i) => i + 1,
  }, {
    header: 'Phase Name',
    displayValue: ({ name }) => name,
    displayEditValue: ({ name }, i) => (
      <TextInput
        isAutofocus={i === 0}
        isDisabled={updateTrainingProgramMutation.isLoading}
        isRequired
        onChange={(e) => setPhases((prev) => prev.map((phase, index) => (
          i === index ?
            { ...phase, name: e.target.value } :
            phase
        )))}
        value={name}
      />
    ),
  }, {
    header: 'Number of Interviews',
    getCellClassName: () => 'training-phase-table-number-of-interviews',
    displayValue: ({ number_of_interviews }) => number_of_interviews,
    displayEditValue: ({ number_of_interviews }, i) => (
      <StyledNumberOfInterviewsTextInput
        isDisabled={updateTrainingProgramMutation.isLoading}
        isRequired
        numberMax={100}
        numberMin={0}
        onChange={(e) => setPhases((prev) => prev.map((phase, index) => (
          i === index ?
            { ...phase, number_of_interviews: e.target.value ? parseInt(e.target.value, 10) : undefined } :
            phase
        )))}
        type="number"
        value={number_of_interviews}
      />
    ),
  }, isEditing && {
    header: '',
    getCellClassName: () => 'training-phase-table-actions',
    isClickable: true,
    displayEditValue: (_, i) => (
      <Button
        className="btn-delete"
        color="gray"
        iconRight={<FontAwesomeIcon icon={faTrashAlt} />}
        isDisabled={updateTrainingProgramMutation.isLoading}
        onClick={() => setPhases((prev) => prev.filter((_, index) => i !== index))}
        size="large"
        tooltip={
          <Tooltip
            id={`${i}-delete-button`}
            position="top"
            value="Remove phase"
          />
        }
      />
    ),
  }], [isEditing, updateTrainingProgramMutation]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    updateTrainingProgramMutation.reset();
    setPhases(trainingProgram.training_phases || [{}]);
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateTrainingProgramMutation.reset();

    try {
      updateTrainingProgramMutation.mutateAsync({
        id: trainingProgramId,
        payload: {
          training_phases: phases.map((phase) => ({
            id: phase.id,
            name: phase.name!, // required
            approver: phase.approver,
            number_of_interviews: phase.number_of_interviews!, // required
          })),
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Breadcrumb
      data={{
        title: 'Details',
        pathname: correctPath(`/app/training-programs/${trainingProgramId}/details`),
      }}
    >
      <StyledTabContainer>
        <Helmet>
          <title>{trainingProgram.eligibility} | Details | Training Programs | InterviewPlanner</title>
        </Helmet>
        <Section
          isEditable
          isEditing={isEditing}
          onCancel={handleCancel}
          onEdit={handleEdit}
          onSave={handleSave}
          title="Phases"
        >
          <Flash
            message={updateTrainingProgramMutation.error?.message}
            showFlash={Boolean(updateTrainingProgramMutation.error)}
            type="danger"
          />
          <Flash
            message="Successfully updated!"
            showFlash={updateTrainingProgramMutation.isSuccess}
            type="success"
          />
          <StyledTable
            data={phases}
            isEditing={isEditing}
            layout="vertical"
            schema={phasesSchema}
          />
          {isEditing && (
            <StyledAddPhaseButton
              color="no-outline"
              iconLeft={<FontAwesomeIcon icon={faPlus} />}
              isDisabled={updateTrainingProgramMutation.isLoading}
              onClick={() => setPhases((prev) => [...prev, {}])}
              size="small"
              value="Add Training Phase"
            />
          )}
        </Section>
      </StyledTabContainer>
    </Breadcrumb>
  );
};

export default TrainingProgramDetails;
