import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import Interviewer from './Interviewer';
import InterviewerList from './InterviewerList';
import { correctPath } from 'libraries/gem';

const Interviewers = () =>
  <Breadcrumb
    data={{
      title: <><FontAwesomeIcon icon={faUsers} />Interviewers</>,
      pathname: correctPath('/app/interviewers'),
    }}
  >
    <Switch>
      <Route component={InterviewerList} exact path={correctPath('/app/interviewers')} />
      <Route component={Interviewer} path={correctPath('/app/interviewers/:id')} />
    </Switch>
  </Breadcrumb>
;

export default Interviewers;
