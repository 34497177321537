import { createContext, useContext, useMemo, useState } from 'react';

import type { Dispatch, SetStateAction, ReactNode } from 'react';
import type { EditableAllowedTime, ZoomHostFilterableType } from '../../../../../types';
import type { BusinessHourDay } from '../../../../../types/business-hours';

export enum Step {
  Preferences,
  EventsAndEmails,
  Review,
  SendLink,
}

export interface SelfSchedulingLink {
  application_id: string;
  scheduling_calendar_email: string;
  candidate_scheduling_calendar_email?: string;
  schedule_template: {
    candidate_calendar_event_template: {
      id?: string;
      title: string;
      description: string;
      location?: string;
      additional_attendees?: string[];
      additional_optional_attendees?: string[];
    };
    business_hours: {
      day: BusinessHourDay;
      start_time: string;
      end_time: string;
      timezone?: string | null;
    }[];
    allowed_times: EditableAllowedTime[];
    scheduling_interval_minutes: number;
    onsite: boolean;
    mark_interviewer_events_as_private: boolean;
    mark_candidate_events_as_private: boolean;
    video_conferencing_enabled: boolean;
    zoom_host_filters?: {
      zoom_host_filter_expressions: {
        filterable_id: string;
        filterable_type: `${ZoomHostFilterableType}`;
        negated: boolean;
      }[];
    }[];
    create_hiring_channel: boolean;
    confirmation_email_template?: {
      id?: string;
      name: string;
      subject: string;
      sender_name: string;
      sender_email: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body: string;
      attachments?: ({
        name: string;
        new?: boolean;
        index?: number;
        file?: File;
      } | File)[];
    };
    self_scheduling_advanced_notice_hours: number;
    self_scheduling_reschedule_enabled: boolean;
    self_scheduling_reschedule_notice_hours?: number;
    self_scheduling_cancel_enabled: boolean;
    self_scheduling_cancel_notice_hours?: number;
    self_scheduling_email_follow_up_templates?: {
      delay_days: number;
    }[];
    self_scheduling_request_email_template?: {
      id?: string;
      name: string;
      subject: string;
      sender_name: string;
      sender_email: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body: string;
      attachments?: ({
        name: string;
        new?: boolean;
        index?: number;
        file?: File;
      } | File)[];
    };
    room_filters?: {
      room_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: string;
      }[];
    }[];
  };
  stage_interview: {
    id: string;
    feedback_form_id?: string;
    interview_template: {
      name: string;
      duration_minutes: number;
      live_coding_enabled?: boolean;
      positions?: number[];
      time_window_start?: string;
      time_window_end?: string;
      candidate_facing_name?: string;
      candidate_facing_details?: string;
      interviewer_templates?: {
        description?: string;
        optional: boolean;
        // This isn't usually on an interviewer template and isn't sent to the server, but it's used on the preferences
        // step.
        interviewer_pool_ids: string[];
        interviewer_filters: {
          interviewer_filter_expressions: {
            negated: boolean;
            filterable_id: string;
            filterable_type: string;
          }[];
        }[];
      }[];
    };
  };
}

interface NewSelfSchedulingLinkContextState {
  completedStep: Step;
  selfSchedulingLink: SelfSchedulingLink;
  selfSchedulingLinkId: string;
  setCompletedStep: Dispatch<SetStateAction<Step>>;
  setSelfSchedulingLink: Dispatch<SetStateAction<SelfSchedulingLink>>;
  setSelfSchedulingLinkId: Dispatch<SetStateAction<string>>;
}

function createEmptySelfSchedulingLink (): SelfSchedulingLink {
  return {
    application_id: '',
    scheduling_calendar_email: '',
    schedule_template: {
      candidate_calendar_event_template: {
        title: '',
        description: '',
      },
      business_hours: [],
      allowed_times: [],
      scheduling_interval_minutes: 15,
      onsite: false,
      mark_interviewer_events_as_private: false,
      mark_candidate_events_as_private: false,
      video_conferencing_enabled: false,
      create_hiring_channel: false,
      self_scheduling_advanced_notice_hours: 0,
      self_scheduling_reschedule_enabled: false,
      self_scheduling_cancel_enabled: false,
    },
    stage_interview: {
      id: '',
      interview_template: {
        name: '',
        duration_minutes: 0,
      },
    },
  };
}

// This is the context for keeping track of the new self-scheduling link. You
// should keep track of the new link with a useState call in the same component
// that use use the Provider, recreate an array using useMemo, and then pass it
// into the Provider. Make sure you don't pass an array literal into the
// Provider. This can cause re-rendering issues as noted here:
// https://reactjs.org/docs/context.html#caveats.
const NewSelfSchedulingLinkContext = createContext<NewSelfSchedulingLinkContextState>({
  completedStep: Step.Preferences,
  selfSchedulingLink: createEmptySelfSchedulingLink(),
  selfSchedulingLinkId: '',
  setCompletedStep: () => {},
  setSelfSchedulingLink: () => {},
  setSelfSchedulingLinkId: () => {},
});

interface Props {
  children: ReactNode;
}

export const NewSelfSchedulingLinkProvider = ({ children }: Props) => {
  const [selfSchedulingLink, setSelfSchedulingLink] = useState(createEmptySelfSchedulingLink());
  const [selfSchedulingLinkId, setSelfSchedulingLinkId] = useState('');
  const [completedStep, setCompletedStep] = useState(Step.Preferences);

  const contextValue = useMemo<NewSelfSchedulingLinkContextState>(() => ({
    completedStep,
    selfSchedulingLink,
    selfSchedulingLinkId,
    setCompletedStep,
    setSelfSchedulingLink,
    setSelfSchedulingLinkId,
  }), [completedStep, selfSchedulingLink, selfSchedulingLinkId]);

  return (
    <NewSelfSchedulingLinkContext.Provider value={contextValue}>
      {children}
    </NewSelfSchedulingLinkContext.Provider>
  );
};

export const useNewSelfSchedulingLink = () => {
  return useContext(NewSelfSchedulingLinkContext);
};
