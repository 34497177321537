import type { CreateSelfSchedulingLinkSchedulePayload } from '../../hooks/queries/self-scheduling-links';
import type { ScheduleOptionsPayload, UpdateSelfSchedulingLinkSchedulePayload } from '../../hooks/queries/self-scheduling-links';
import type { SelfSchedulingLinkInterview, ScheduleOption, SelfSchedulingLink } from '../../types';

export const constructOptionsPayload = (month: string, timezone: string, selfSchedulingLinkPreview?: SelfSchedulingLink): ScheduleOptionsPayload => ({
  month,
  // TODO: Eventually, we should pass in timezone to the generate endpoint so that it returns valid times in the
  //  requested timezone rather than in the scheduling timezone.
  // timezone,
  ...(selfSchedulingLinkPreview && {
    application_id: selfSchedulingLinkPreview.application_id,
    interview_template: {
      duration_minutes: selfSchedulingLinkPreview.interview_template.duration_minutes,
      candidate_facing_name: selfSchedulingLinkPreview.interview_template.candidate_facing_name,
      interviewer_templates: selfSchedulingLinkPreview.interview_template.interviewer_templates?.map((template) => ({
        optional: template.optional,
        interviewer_filters: (template.interviewer_filters || []).map((filter) => ({
          interviewer_filter_expressions: (filter.interviewer_filter_expressions || []).map((exp) => ({
            negated: exp.negated,
            filterable_id: exp.filterable_id,
            filterable_type: exp.filterable_type,
          })),
        })),
      })),
    },
    schedule_template: {
      business_hours: (selfSchedulingLinkPreview.schedule_template.business_hours || []).map((bh) => ({
        day: bh.day,
        start_time: bh.start_time || '00:00',
        end_time: bh.end_time || '24:00',
        // All business hours should have a timezone by this point.
        timezone: bh.timezone!,
      })),
      allowed_times: selfSchedulingLinkPreview.schedule_template.allowed_times || [],
      scheduling_interval_minutes: selfSchedulingLinkPreview.schedule_template.scheduling_interval_minutes,
      video_conferencing_enabled: selfSchedulingLinkPreview.schedule_template.video_conferencing_enabled,
      zoom_host_filters: selfSchedulingLinkPreview.schedule_template.zoom_host_filters || undefined,
      self_scheduling_advanced_notice_hours: selfSchedulingLinkPreview.schedule_template.self_scheduling_advanced_notice_hours,
    },
  }),
});

export const constructCreateSchedulePayload = (timezone: string, selfSchedulingLink: SelfSchedulingLink, scheduleOption: ScheduleOption): CreateSelfSchedulingLinkSchedulePayload => ({
  candidate_timezone: timezone,
  interviews: [{
    interview_template_id: selfSchedulingLink.interview_template_id,
    stage_interview_id: selfSchedulingLink.stage_interview_id,
    feedback_form_id: selfSchedulingLink.feedback_form_id,
    room_id: scheduleOption.room_id,
    zoom_host_id: scheduleOption.zoom_host?.id,
    zoom_host_type: scheduleOption.zoom_host?.type,
    start_time: scheduleOption.start_time,
    interviewers: scheduleOption.interviewers?.map((interviewer) => ({
      user_id: interviewer.user_id,
      interviewer_template_id: interviewer.interviewer_template_id,
    })),
  }],
});

export const constructUpdateSchedulePayload = (timezone: string, selfSchedulingLink: SelfSchedulingLink, scheduleOption: ScheduleOption): UpdateSelfSchedulingLinkSchedulePayload => {
  const stageInterviewIdToInterview = selfSchedulingLink.schedule!.interviews.reduce<{ [key: string]: SelfSchedulingLinkInterview }>((map, interview) => {
    return {
      ...map,
      [interview.stage_interview_id]: interview,
    };
  }, {});

  // This will eventually be in a .map once we have more than one interview.
  const existingInterview = stageInterviewIdToInterview[selfSchedulingLink.stage_interview_id];

  return {
    candidate_timezone: timezone,
    interviews: [{
      id: existingInterview?.id,
      interview_template_id: selfSchedulingLink.interview_template_id,
      stage_interview_id: selfSchedulingLink.stage_interview_id,
      feedback_form_id: selfSchedulingLink.feedback_form_id,
      room_id: scheduleOption.room_id,
      zoom_host_id: scheduleOption.zoom_host?.id,
      zoom_host_type: scheduleOption.zoom_host?.type,
      start_time: scheduleOption.start_time,
      interviewers: scheduleOption.interviewers?.map((interviewer) => ({
        user_id: interviewer.user_id,
        interviewer_template_id: interviewer.interviewer_template_id,
      })),
    }],
  };
};
