import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import Flash from '../../../library/utils/Flash';
import JobStatusIndicator from '../../../library/utils/JobStatusIndicator';
import LoadingSpinner from '../../../library/utils/LoadingSpinner';
import Section from '../../../library/layout/Section';
import Table from '../../../library/data-display/Table';
import { useInterviewTemplate } from '../../../../hooks/queries/interview-templates';
import { useStageInterviews } from '../../../../hooks/queries/stage-interviews';

import type { StageInterview } from '../../../../types';
import type { TableSchema } from '../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const columns: TableSchema<StageInterview> = [{
  header: 'Name',
  displayValue: ({ name }) => name,
  hasLinkStyleOnHover: true,
}, {
  header: 'Stage',
  displayValue: ({ stage }) => stage.name,
}, {
  header: 'Job',
  displayValue: ({ stage: { job } }) => job.name,
}, {
  header: 'Job Status',
  displayValue: ({ stage: { job } }) => <JobStatusIndicator status={job.status} />,
}];

const getRowLink = ({ stage }: StageInterview) => correctPath(`/app/jobs/${stage.job.id}/stages/${stage.id}/interviews`);

const InterviewTemplateLinkedInterviews = () => {
  const { id } = useParams<{ id: string }>();

  const { data: interviewTemplate } = useInterviewTemplate(id);

  const {
    data: stageInterviews,
    error,
    isLoading,
  } = useStageInterviews({ interview_template: id });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Linked Interviews',
        pathname: correctPath(`/app/interview-templates/${id}/linked-interviews`),
      }}
    >
      <Section
        className="interview-template-linked-interviews"
        title="Linked interviews"
      >
        <Helmet>
          <title>Linked Interviews | {interviewTemplate?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        {stageInterviews &&
          <Table
            data={stageInterviews.stage_interviews}
            dataDescriptor="interviews"
            getRowLink={getRowLink}
            layout="vertical"
            schema={columns}
            showResultsCount
            totalCount={stageInterviews.total}
          />
        }
      </Section>
    </Breadcrumb>
  );
};

export default InterviewTemplateLinkedInterviews;
