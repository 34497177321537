import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { find, flatten } from 'lodash';
import { useCallback, useMemo } from 'react';

import Flash from '../../library/utils/Flash';
import Section from '../../library/layout/Section';
import SelectInput from '../../library/inputs/SelectInput';
import TasksTable from './TasksTable';
import UserSelectInput from '../../library/inputs/UserSelectInput';
import useSyncStateWithQuery from '../../../hooks/use-sync-state-with-query';
import { useNotifications } from '../../../hooks/queries/notifications';
import { useSession } from '../../../hooks/use-session';

import type { ChangeEvent } from 'react';
import type { NotificationType } from '../../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../library/inputs/SelectInput/types';
import { correctPath } from 'libraries/gem';

enum Category {
  AvailabilityRequestCancelled = 'availability_request_cancelled',
  BrokenIntegration = 'broken_integration',
  CandidateDeclined = 'candidate_declined',
  InterviewerDeclined = 'interviewer_declined',
  JobNewlyImported = 'job_newly_imported',
  ReadyToRequestAvailability = 'ready_to_request_availability',
  ReadyToSchedule = 'ready_to_schedule',
  RoomDeclined = 'room_declined',
  ScheduleCancelled = 'schedule_cancelled',
  StageInterviewNewlyImported = 'stage_interview_newly_imported',
  StageNewlyImported = 'stage_newly_imported',
}

const categoryOptions: Option<`${Category}`>[] = [
  { value: Category.ReadyToRequestAvailability, label: 'Ready to Request Availability' },
  { value: Category.AvailabilityRequestCancelled, label: 'Availability Request Cancelled' },
  { value: Category.CandidateDeclined, label: 'Candidate Declined' },
  { value: Category.BrokenIntegration, label: 'Broken Integration' },
  { value: Category.InterviewerDeclined, label: 'Interviewer Declined' },
  { value: Category.JobNewlyImported, label: 'New Job' },
  { value: Category.ReadyToSchedule, label: 'Ready to Schedule' },
  { value: Category.ScheduleCancelled, label: 'Schedule Cancelled' },
  { value: Category.RoomDeclined, label: 'Room Declined' },
  { value: Category.StageInterviewNewlyImported, label: 'New Interview' },
  { value: Category.StageNewlyImported, label: 'New Stage' },
];

const categoryMapping: { [key: string]: `${NotificationType}`[] } = {
  broken_integration: [
    'google_token_error',
    'greenhouse_api_key_error',
    'greenhouse_permission_error',
    'lever_api_key_error',
    'lever_permission_error',
    'microsoft365_auth_error',
    'zoom_token_error',
  ],
};

function notificationTypes (categories: string[]) {
  return flatten(categories.map((category) => categoryMapping[category] || category));
}

const Tasks = () => {
  const { currentUser } = useSession();

  const [assigned, queryAssigned, setAssigned] = useSyncStateWithQuery<string>('assigned', currentUser!.id);
  const [showCompleted, queryShowCompleted, setShowCompleted] = useSyncStateWithQuery<string>('show_completed', 'false');
  const [category, queryCategory, setCategory] = useSyncStateWithQuery<string[]>('category', [], { array: true });
  const [pageNumber, queryPageNumber, setPageNumber] = useSyncStateWithQuery<string>('page', '1');

  const {
    data: notifications,
    error,
  } = useNotifications({
    read: queryShowCompleted === 'true',
    user: queryAssigned,
    limit: 10,
    offset: 10 * (parseInt(queryPageNumber, 10) - 1),
    type: notificationTypes(queryCategory),
  });

  const handleAssignedChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    // This select input isn't clearable, so option should always be defined.
    setAssigned(option?.value || '');
  }, []);

  const handleShowCompletedChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setShowCompleted(JSON.stringify(e.target.checked));
  }, []);

  const handleCategoryChange = useCallback((option: OnChangeValue<Option<`${Category}`>, true>) => {
    setCategory(option && option.length > 0 ? option.map((category) => category.value) : []);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value.toString());
  }, []);

  const selectedCategories = useMemo<Option<`${Category}`>[]>(() => {
    if (!category) {
      return [];
    }
    return category
    .map((category) => find(categoryOptions, ['value', category]))
    .filter((c): c is Option<`${Category}`> => Boolean(c));
  }, [category]);

  return (
    <Breadcrumb
      data={{
        title: <><FontAwesomeIcon icon={faTasks} />Tasks</>,
        pathname: correctPath('/app/tasks'),
      }}
    >
      <div className="tasks-container">
        <Helmet>
          <title>Tasks | InterviewPlanner</title>
        </Helmet>
        <Section
          headerActions={<UserSelectInput
            annotateCurrentUser
            className="header-user-select-input"
            currentUserFirst
            label="Assigned to"
            layout="horizontal"
            name="tasks-select-input"
            onChange={handleAssignedChange}
            value={assigned}
          />}
          title="Tasks"
        >
          <div className="filters-container">
            <SelectInput
              isClearable
              isMulti
              label="Category"
              onChange={handleCategoryChange}
              options={categoryOptions}
              value={selectedCategories}
            />
          </div>
          <Flash
            message={error?.message}
            showFlash={Boolean(error)}
            type="danger"
          />
          {notifications && (
            <TasksTable
              onPageNumberChange={handlePageNumberChange}
              onShowCompletedChange={handleShowCompletedChange}
              pageNumber={parseInt(pageNumber, 10)}
              showCompleted={showCompleted === 'true'}
              tasks={notifications.notifications}
              totalCount={notifications.total}
            />
          )}
        </Section>
      </div>
    </Breadcrumb>
  );
};

export default Tasks;
