import { Breadcrumb } from 'react-breadcrumbs';
import { faCaretDown, faCaretUp, faCheck, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIndex, flatten, isEmpty, isEqual, mapValues, orderBy, partition, uniq } from 'lodash';
import { Helmet } from 'react-helmet-async';
import Moment from 'moment-timezone';
import { Redirect, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import Button from 'components/library/inputs/Button';
import CheckboxInput from 'components/library/inputs/CheckboxInput';
import Flash from 'components/library/utils/Flash';
import MultiStepFormStep from 'components/library/inputs/MultiStepFormStep';
import ProgressBar from 'components/library/utils/ProgressBar';
import Tooltip from 'components/library/utils/Tooltip';
import { useAccount } from 'hooks/queries/accounts';
import { useApplication } from 'hooks/queries/applications';
import { useEvents } from 'hooks/use-events';
import { generateSchedulesParams, transformGenerateSchedulesResponse } from 'hooks/queries/schedules';
import { useLDFlags } from 'hooks/use-ld-flags';
import { useSession } from 'hooks/use-session';
import { useUsersMap } from 'hooks/queries/users';
import { formatList } from 'libraries/formatters';
import { hoursOfAvailability } from 'libraries/analytics';
import ExplainAlgorithmModal from './ExplainAlgorithmModal';
import {
  constructGeneratePayload,
  formatInterviewNamesToList,
  getConflicts,
  getEventsForDates,
  getOptionalInterviewerConflicts,
  getZoomHostConflicts, mergeConflictTotals,
  sortScheduleOptions,
  transformGeneratedSchedulesForOptionsViewer,
} from './helpers';
import ScheduleBlockOptionsBar from './ScheduleBlockOptionsBar';
import ScheduleConflictList from './ScheduleConflictList';
import ScheduleOptionsConflictSummary from './ScheduleOptionsConflictSummary';
import ScheduleOptionsViewer from './ScheduleOptionsViewer';
import SelectedHoldsInfoFlash from './SelectedHoldsInfoFlash';
import { Step, useNewSchedule } from '../use-new-schedule';

import type { ChangeEvent, ReactNode } from 'react';
import type { EventsCache } from 'hooks/use-events';
import type { GenerateSchedulesPayload } from 'hooks/queries/schedules';
import type { Block, GeneratedOption, ScheduleOption, ScheduleOptionWithTimezone } from '../types';
import { correctPath } from 'libraries/gem';

const GENERATE_PAGE_SIZE = 1_000_000 as const;
const INITIAL_FETCHING_DURATION_SECONDS = 20 as const;

const ScheduleStep = () => {
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();

  const { interviewerTrainingPlatform, preferShorterBlockGap, progressiveScheduleGeneration } = useLDFlags();

  const users = useUsersMap({ archived: true });
  const currentUser = useSession().currentUser!;
  const account = useAccount().data!;
  const isVideoConferencingHost = Boolean(currentUser.video_conferencing_id);
  const application = useApplication(id).data!;

  const { setEvents } = useEvents();

  const {
    completedStep,
    schedule,
    setCompletedStep,
    setSchedule,
  } = useNewSchedule();

  const { events } = useEvents();

  const [error, setError] = useState<ReactNode>(null);
  const [hold, setHold] = useState(schedule.hold);

  const [isExplainAlgorithmModalOpen, setIsExplainAlgorithmModalOpen] = useState(false);

  const generateSchedulesPayload = useMemo(() => constructGeneratePayload(schedule), [schedule]);

  const [selectedNumberOfBlocks, setSelectedNumberOfBlocks] = useState<number>(() => {
    const selectedNumOfBlocks = Object.keys(schedule.generatedOptionsByNumberOfBlocks).find(
      (numberOfBlocks) => schedule.generatedOptionsByNumberOfBlocks[numberOfBlocks]?.selectedScheduleOptionIndices?.length > 0
    );
    return selectedNumOfBlocks ? parseInt(selectedNumOfBlocks, 10) : 1;
  });
  const [allGeneratedOptions, setAllGeneratedOptions] = useState(
    (!schedule.id && !isEqual(
      generateSchedulesPayload,
      schedule.generatedOptionsByNumberOfBlocks[selectedNumberOfBlocks]?.lastGenerateSchedulesPayload
    )) ? {} : schedule.generatedOptionsByNumberOfBlocks
  );

  const optionsForSelectedNumberOfBlocks: GeneratedOption = allGeneratedOptions[selectedNumberOfBlocks] || {
    lastGenerateSchedulesPayload: null,
    nextOffset: 0,
    fetchedAllData: false,
    scheduleOptions: [],
    scheduleOptionsConflictTotals: {},
    selectedScheduleOptionIndices: [],
    totalScheduleOptionsCount: undefined,
    unloaded: true,
  };
  const scheduleOptions = optionsForSelectedNumberOfBlocks.scheduleOptions;
  const setScheduleOptions = (newScheduleOptions: ScheduleOption[]) => {
    setAllGeneratedOptions((prevState) => ({
      ...prevState,
      [selectedNumberOfBlocks]: {
        ...prevState[selectedNumberOfBlocks],
        scheduleOptions: newScheduleOptions,
      },
    }));
  };
  const conflictTotals = optionsForSelectedNumberOfBlocks.scheduleOptionsConflictTotals;
  const selectedScheduleIndices = optionsForSelectedNumberOfBlocks.selectedScheduleOptionIndices;
  const setSelectedScheduleIndices = (newIndices: number[]) => {
    setAllGeneratedOptions((prevState) => ({
      ...prevState,
      [selectedNumberOfBlocks]: {
        ...prevState[selectedNumberOfBlocks],
        selectedScheduleOptionIndices: newIndices,
      },
    }));
  };
  const [focusedScheduleIndex, setFocusedScheduleIndex] = useState<number | null>(null);
  const [viewIgnoredConflicts, setViewIgnoredConflicts] = useState(false);
  const [viewIgnoredOptionalInterviewerConflicts, setViewIgnoredOptionalInterviewerConflicts] = useState(false);

  const [keepGoing, setKeepGoing] = useState(false);
  const [generateError, setGenerateError] = useState<Error>();
  // We'll use the mount start time (in seconds since epoch) to determine if we should be done with the initial set of fetching.
  const [fetchingStartTime, setFetchingStartTime] = useState(() => Moment().unix());

  // Reset focused schedule as soon as a new block number is selected
  useEffect(() => {
    const selectedOptionIndices = allGeneratedOptions[selectedNumberOfBlocks]?.selectedScheduleOptionIndices || [];
    setFocusedScheduleIndex(selectedOptionIndices.length > 0 ? selectedOptionIndices[0] : null);
    setFetchingStartTime(Moment().unix());
  }, [selectedNumberOfBlocks]);

  const secondsSinceStartedFetching = Moment().unix() - fetchingStartTime;
  const shouldGenerate = completedStep >= Step.Schedule &&
    !schedule.id &&
    (
      // Re-generate when the availability, interview plan, interviewers, etc. have changed.
      !isEqual(generateSchedulesPayload, optionsForSelectedNumberOfBlocks.lastGenerateSchedulesPayload) ||
      // For generation without the feature flag, continue until all options are fetched
      (!progressiveScheduleGeneration && !optionsForSelectedNumberOfBlocks.fetchedAllData)
      ||
      // For progressive generation, continue if requested by user or, if it's the initial fetch, until a zero-conflict option is found or for a max of 20 seconds.
      (
        progressiveScheduleGeneration &&
        !optionsForSelectedNumberOfBlocks.fetchedAllData &&
        (
          keepGoing ||
          (
            secondsSinceStartedFetching <= INITIAL_FETCHING_DURATION_SECONDS &&
            (!optionsForSelectedNumberOfBlocks.scheduleOptions[0] || optionsForSelectedNumberOfBlocks.scheduleOptions[0].conflicts > 0)
          )
        )
      )
    );

  useEffect(() => {
    if (!shouldGenerate) {
      return;
    }

    (async () => {
      const payload: GenerateSchedulesPayload = {
        ...generateSchedulesPayload,
        minimum_blocks: selectedNumberOfBlocks,
        maximum_blocks: selectedNumberOfBlocks,
        offset: optionsForSelectedNumberOfBlocks.nextOffset,
        page_size: GENERATE_PAGE_SIZE,
      };
      try {
        const response = await queryClient.fetchQuery(generateSchedulesParams(payload));

        // Set events.
        const convertedEvents: EventsCache = {};
        Object.keys(response.events).forEach((date) => {
          convertedEvents[date] = {
            [payload.timezone]: {
              users: mapValues(response.events[date].users, (item, id) => ({ id, ...item })),
              rooms: mapValues(response.events[date].rooms, (item, id) => ({ id, ...item })),
              zoom_hosts: mapValues(response.events[date].zoom_hosts, (item, id) => ({ id, ...item })),
            },
          };
        });
        setEvents(convertedEvents);

        // Transform data.
        const generateData = transformGenerateSchedulesResponse(response, payload);

        // Set local state.
        setFocusedScheduleIndex(null);
        setAllGeneratedOptions((prevState) => ({
          ...prevState,
          ...mapValues(generateData, (optionsForBlockNumber, blockNumber) => ({
            lastGenerateSchedulesPayload: generateSchedulesPayload,
            nextOffset: optionsForBlockNumber.number_completed,
            fetchedAllData: optionsForBlockNumber.completed,
            scheduleOptions: sortScheduleOptions([
              ...(prevState[blockNumber]?.scheduleOptions || []),
              ...transformGeneratedSchedulesForOptionsViewer(optionsForBlockNumber.schedules, schedule.stage),
            ], schedule.timezone, preferShorterBlockGap),
            totalScheduleOptionsCount: optionsForBlockNumber.total,
            scheduleOptionsConflictTotals: mergeConflictTotals((prevState[blockNumber]?.scheduleOptionsConflictTotals || {}), optionsForBlockNumber.conflicted_totals),
            selectedScheduleOptionIndices: [],
          })),
        }));
      } catch (err) {
        if (err instanceof Error) {
          setGenerateError(err);
        }
      }

      setKeepGoing(false);
    })();
  }, [
    generateSchedulesPayload,
    optionsForSelectedNumberOfBlocks.nextOffset,
    preferShorterBlockGap,
    schedule.stage,
    selectedNumberOfBlocks,
    shouldGenerate,
  ]);

  useEffect(() => {
    if (error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [error]);

  const allSelectedOptions = useMemo<(ScheduleOption & { label: string })[]>(() => {
    const schedules: (ScheduleOption & { label: string })[] = [];
    Object.values(allGeneratedOptions).forEach(({ scheduleOptions, selectedScheduleOptionIndices }) => {
      selectedScheduleOptionIndices.forEach((index) => {
        schedules.push(({
          ...scheduleOptions[index],
          label: `Option ${index + 1}`,
        }));
      });
    });
    return schedules;
  }, [allGeneratedOptions]);

  const handleHoldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHold(e.target.checked);
    if (e.target.checked) {
      // When enabling holds, we don't want the focused schedule to
      // automatically be checked, so we need to clear out any selected
      // schedules.
      setSelectedScheduleIndices([]);
    } else {
      // When disabling holds, we need to set the selected schedule to be the
      // focused schedule if there is a focused schedule. If there isn't, we
      // still need to make sure the focused schedule is in sync with the
      // selected one, so we reduce the selected schedule to just one index and
      // set the focused schedule to be that selected schedule.
      if (focusedScheduleIndex !== null) {
        setSelectedScheduleIndices([focusedScheduleIndex]);
      } else if (selectedScheduleIndices.length > 0) {
        const index = selectedScheduleIndices[0];
        setSelectedScheduleIndices([index]);
        setFocusedScheduleIndex(index);
      }
    }
  };

  const handleScheduleFocus = (i: number) => {
    setFocusedScheduleIndex(i);
    setViewIgnoredConflicts(false);
    if (!hold) {
      // Clear selected schedules for all blocks and set selected option for current number of blocks.
      setAllGeneratedOptions(
        (prevState) => mapValues(prevState, (prevOptions, numberOfBlocks) => ({
          ...prevOptions,
          selectedScheduleOptionIndices: parseInt(numberOfBlocks, 10) === selectedNumberOfBlocks ? [i] : [],
        }))
      );
    }
  };

  const handleScheduleHoldChange = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    if (e.target.checked) {
      // add index
      setSelectedScheduleIndices([...selectedScheduleIndices, i]);
    } else {
      // remove index
      const indexToRemove = findIndex(selectedScheduleIndices, (index) => index === i);
      // If we just checked for indexToRemove, we wouldn't be able to uncheck
      // the first option because indexToRemove would be 0 which is false-y.
      if (indexToRemove >= 0) {
        setSelectedScheduleIndices([
          ...selectedScheduleIndices.slice(0, indexToRemove),
          ...selectedScheduleIndices.slice(indexToRemove + 1),
        ]);
      }
    }
  };

  const toggleViewIgnoredConflicts = () => {
    setViewIgnoredConflicts(!viewIgnoredConflicts);
  };

  const toggleViewIgnoredOptionalInterviewerConflicts = () => {
    setViewIgnoredOptionalInterviewerConflicts(!viewIgnoredOptionalInterviewerConflicts);
  };

  const setScheduleOption = (index: number, blockIndex: number, newScheduleOption: Block) => {
    setScheduleOptions(scheduleOptions.map((scheduleOption, i) => (
      i === index ?
        {
          ...scheduleOption,
          interviews: undefined,
          blocks: scheduleOption.blocks.map((block, j) => (j === blockIndex ? {
            ...block,
            ...newScheduleOption,
          } : block)),
        } :
        scheduleOption
    )));
  };

  const handleNext = () => {
    setError(null);

    // Make sure that none of the schedule options don't have any unselected
    // interviewers (i.e. they added an interview but didn't fill in the
    // interviewers).
    interface OptionsWithInterviewsMissingInterviewers {
      index: number;
      interviewsMissingInterviewers: Block['interviews'];
    }
    const optionsWithInterviewsMissingInterviewers: OptionsWithInterviewsMissingInterviewers[] = selectedScheduleIndices.map((index) => {
      const schedule = scheduleOptions[index];
      const interviews = flatten(schedule.blocks.map((block) => block.interviews));
      const interviewsMissingInterviewers = interviews.filter(({ interviewers }) => {
        return (interviewers || []).some(({ selected_user }) => !selected_user.user_id);
      });
      if (interviewsMissingInterviewers.length > 0) {
        return { index, interviewsMissingInterviewers };
      }
    }).filter((o): o is OptionsWithInterviewsMissingInterviewers => Boolean(o));

    if (optionsWithInterviewsMissingInterviewers.length > 0) {
      const interviewNames = uniq(flatten(optionsWithInterviewsMissingInterviewers.map(({ interviewsMissingInterviewers }) => interviewsMissingInterviewers.map(({ name }) => name))));
      const singular = optionsWithInterviewsMissingInterviewers.length === 1 && interviewNames.length === 1;
      const options = orderBy(optionsWithInterviewsMissingInterviewers.map(({ index }) => index + 1));

      setError(<>There {singular ? 'is an interviewer' : 'are interviewers'} for {formatInterviewNamesToList(interviewNames)} that {singular ? 'has' : 'have'} not been selected yet for Option{options.length === 1 ? '' : 's'} {formatList(options)}. Either select someone, or remove the interviewer.</>);
      return false;
    }

    setSchedule((prev) => ({
      ...prev,
      hold,
      generatedOptionsByNumberOfBlocks: allGeneratedOptions,
    }));
    setCompletedStep(Step.Schedule + 1);
  };

  if (completedStep < Step.Schedule) {
    return <Redirect to={correctPath(`/app/candidates/${id}/schedule/interview-plan`)} />;
  }

  const hasFocusedSchedule = focusedScheduleIndex !== null && !isEmpty(scheduleOptions);
  const focusedScheduleWithTimezone: ScheduleOptionWithTimezone | null = hasFocusedSchedule ? { ...scheduleOptions[focusedScheduleIndex], timezone: schedule.timezone } : null;

  const dates = hasFocusedSchedule
    && scheduleOptions[focusedScheduleIndex]?.blocks.map(({ interviews }) => Moment.tz(interviews[0].start_time, schedule.timezone).format('YYYY-MM-DD'))
    || [];
  const userEvents = getEventsForDates(events, dates, schedule.timezone, 'users');
  const roomEvents = getEventsForDates(events, dates, schedule.timezone, 'rooms');
  const zoomHostMeetings = getEventsForDates(events, dates, schedule.timezone, 'zoom_hosts');

  const interviewerEventIds = hasFocusedSchedule && focusedScheduleWithTimezone ? flatten((focusedScheduleWithTimezone.blocks || []).map((block) => block.interviews)).map((interview) => interview.interviewer_event_ical_uid).filter((id): id is string => Boolean(id)) : [];

  const allFocusedScheduleConflicts = hasFocusedSchedule && focusedScheduleWithTimezone ? getConflicts(focusedScheduleWithTimezone, userEvents, roomEvents, interviewerEventIds, users) : [];
  const [focusedScheduleIgnoredConflicts, focusedScheduleConflicts] = partition(allFocusedScheduleConflicts, 'event.ignored');
  const focusedScheduleZoomHostConflicts = hasFocusedSchedule && focusedScheduleWithTimezone ? getZoomHostConflicts(focusedScheduleWithTimezone, zoomHostMeetings) : [];
  const focusedScheduleOptionalInterviewerConflicts = hasFocusedSchedule && focusedScheduleWithTimezone ? getOptionalInterviewerConflicts(focusedScheduleWithTimezone, userEvents, interviewerEventIds, users) : [];
  const focusedScheduleSelectedZoomHost = hasFocusedSchedule ? scheduleOptions[focusedScheduleIndex]?.blocks[0].selected_zoom_host : null;

  const KeepGoingButton = useMemo(() => {
    return (
      optionsForSelectedNumberOfBlocks.fetchedAllData ? (
        <Button
          color="green"
          iconRight={<FontAwesomeIcon icon={faCheck} />}
          isDisabled
          size="small"
          tooltip={
            <Tooltip
              id="keep-going-button"
              position="top"
              value="We've looked through all possible interview combinations."
            />
          }
          value="All Options Calculated"
        />
      ) : (
        <Button
          color="gem-outline"
          iconRight={<FontAwesomeIcon icon={faRefresh} />}
          onClick={() => {
            analytics.track('Calculate More Button Clicked', {
              availability_hours: hoursOfAvailability(schedule.availabilities),
              block_count: selectedNumberOfBlocks,
              number_completed: optionsForSelectedNumberOfBlocks.nextOffset,
              interviews: generateSchedulesPayload.stage_interviews.length,
              seconds_since_started_fetching: Moment().unix() - fetchingStartTime,
              total_count: optionsForSelectedNumberOfBlocks.totalScheduleOptionsCount,
            });
            setKeepGoing(true);
          }}
          size="small"
          tooltip={
            <Tooltip
              id="keep-going-button"
              position="top"
              value="There are more options, though they may not split interviewing time as evenly across blocks."
            />
          }
          value="Calculate More"
        />
      )
    );
  }, [optionsForSelectedNumberOfBlocks.fetchedAllData, optionsForSelectedNumberOfBlocks.scheduleOptions?.length]);

  return (
    <Breadcrumb
      data={{
        title: '4. Schedule',
        pathname: correctPath(`/app/candidates/${id}/schedule/schedule`),
      }}
    >
      <MultiStepFormStep
        backLocation={correctPath(`/app/candidates/${id}/schedule/availability${schedule.id ? `?schedule=${schedule.id}` : ''}`)}
        className="form-step-schedule"
        nextButtonIsDisabled={shouldGenerate || Boolean(generateError)}
        nextButtonValue="Events & Emails"
        nextLocation={correctPath(`/app/candidates/${id}/schedule/events-and-emails${schedule.id ? `?schedule=${schedule.id}` : ''}`)}
        onNext={handleNext}
      >
        <Helmet>
          <title>4. Schedule | Schedule {application.candidate.name || 'Unknown'} for {application.current_stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message={error}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message={generateError?.message}
          showFlash={Boolean(generateError)}
          type="danger"
        />
        {!schedule.id &&
          <div className="form-control">
            <CheckboxInput
              helperText="At the end of the workflow, we will not schedule the candidate. Instead, we will save the selected schedule(s) with a status of On Hold and send [HOLD] calendar events to interviewers. You can confirm the schedule when you are ready."
              isChecked={hold}
              label="Select schedule(s) to put on hold."
              name="hold"
              onChange={handleHoldChange}
            />
          </div>
        }
        <Flash
          message="Within the demo, conflicts are randomly created each time you come to this page. So you can go back to the availability step and try again to see if a conflict-free schedule appears."
          showFlash={account.demo && !shouldGenerate && !isEmpty(conflictTotals) && !conflictTotals['0']}
          type="warning"
        />
        <Flash
          message={`${hold ? 'Select schedules to put on hold below.' : 'Select a schedule below.'} You can switch out interviewers or rooms by clicking on them.`}
          showFlash={!shouldGenerate && !generateError && !isEmpty(scheduleOptions) && isEmpty(allSelectedOptions) && focusedScheduleIndex === null}
          type="info"
        />
        <SelectedHoldsInfoFlash
          selectedScheduleOptions={allSelectedOptions}
          showFlash={hold}
          timezone={schedule.timezone}
        />
        <Flash
          message="New schedules can't be generated since you're confirming a held schedule, but you can make any final changes down below."
          showFlash={!shouldGenerate && Boolean(schedule.id)}
          type="info"
        />
        <ScheduleBlockOptionsBar
          allGeneratedOptions={allGeneratedOptions}
          isLoading={shouldGenerate && !generateError}
          selectedNumberOfBlocks={selectedNumberOfBlocks}
          setSelectedNumberOfBlocks={(numberOfBlocks) => {
            setGenerateError(undefined);
            setSelectedNumberOfBlocks(numberOfBlocks);
          }}
        />
        {shouldGenerate && !generateError &&
          <div className="spinner-container">
            <img alt="Schedules loading" className="loading-gif" src="/scheduling/src/assets/images/schedules-loading.gif" />
            <div className="progress-bar-container">
              <ProgressBar />
            </div>
            <br />
            <span>
              {selectedNumberOfBlocks > 1 ?
                'This may take a few minutes as we look through all of the possible options!' :
                'Comparing calendars, shuffling interviews, and calculating the best schedules!'
              }
            </span>
            {optionsForSelectedNumberOfBlocks.totalScheduleOptionsCount && (
              <Button
                color="no-outline"
                onClick={() => {
                  analytics.track('Explain Algorithm Modal Opened', {
                    availability_hours: hoursOfAvailability(schedule.availabilities),
                    block_count: selectedNumberOfBlocks,
                    number_completed: optionsForSelectedNumberOfBlocks.nextOffset,
                    interviews: generateSchedulesPayload.stage_interviews.length,
                    seconds_since_started_fetching: Moment().unix() - fetchingStartTime,
                    total_count: optionsForSelectedNumberOfBlocks.totalScheduleOptionsCount,
                  });
                  setIsExplainAlgorithmModalOpen(true);
                }}
                size="small"
                value="What's taking so long?"
              />
            )}
          </div>
        }
        <Flash
          message={`Not enough availability was provided to generate a ${selectedNumberOfBlocks}-block schedule.`}
          showFlash={!shouldGenerate && !generateError && !optionsForSelectedNumberOfBlocks.unloaded && isEmpty(scheduleOptions)}
          type="danger"
        />
        <ExplainAlgorithmModal
          isOpen={isExplainAlgorithmModalOpen}
          numberOfBlocks={selectedNumberOfBlocks}
          numberOfOptions={optionsForSelectedNumberOfBlocks.totalScheduleOptionsCount || 0}
          onToggle={() => setIsExplainAlgorithmModalOpen((prev) => !prev)}
        />
        {!shouldGenerate && !isEmpty(conflictTotals) &&
          <ScheduleOptionsConflictSummary conflictTotals={conflictTotals} />
        }
        <Flash
          message={`Option ${(focusedScheduleIndex || 0) + 1} has no conflicts.`}
          showFlash={!shouldGenerate && focusedScheduleIndex !== null && focusedScheduleConflicts.length === 0 && focusedScheduleZoomHostConflicts.length < account.zoom_host_concurrent_meeting_limit}
          type="success"
        />
        <Flash
          message={`${isVideoConferencingHost ? '' : 'You cannot host this interview because you don\'t have a Zoom account. '}You must either select a Zoom host or go back to the Availability step and disable video conferencing.`}
          showFlash={!shouldGenerate && focusedScheduleIndex !== null && schedule.schedule_template.video_conferencing_enabled && account.video_conferencing_type === 'zoom' && Boolean(!focusedScheduleSelectedZoomHost || !focusedScheduleSelectedZoomHost.id)}
          type="warning"
        />
        <Flash
          message={
            <span>
              The Zoom host is hosting other meetings during the selected schedule. The video connection will be interrupted. Continue if you are sure you can move these meetings.
              <ScheduleConflictList conflicts={focusedScheduleZoomHostConflicts} timezone={schedule.timezone} />
            </span>
          }
          showFlash={!shouldGenerate && focusedScheduleIndex !== null && focusedScheduleZoomHostConflicts.length >= account.zoom_host_concurrent_meeting_limit}
          type="warning"
        />
        <Flash
          message={
            <span>
              Your selected schedule has conflicts. Continue if you are sure you can ignore these conflicts.
              <ScheduleConflictList conflicts={focusedScheduleConflicts} timezone={schedule.timezone} />
            </span>
          }
          showFlash={!shouldGenerate && focusedScheduleIndex !== null && focusedScheduleConflicts.length > 0}
          type="warning"
        />
        <Flash
          message={
            <span>
              We ignored {focusedScheduleIgnoredConflicts.length} conflict{focusedScheduleIgnoredConflicts.length === 1 ? '' : 's'} based on your conflict settings.
              <Button
                color="no-outline"
                iconRight={<FontAwesomeIcon icon={viewIgnoredConflicts ? faCaretUp : faCaretDown} />}
                onClick={toggleViewIgnoredConflicts}
                size="small"
                value={`${viewIgnoredConflicts ? 'Hide' : 'Show'} ignored conflicts`}
              />
              {viewIgnoredConflicts && <ScheduleConflictList conflicts={focusedScheduleIgnoredConflicts} timezone={schedule.timezone} />}
            </span>
          }
          showFlash={!shouldGenerate && focusedScheduleIndex !== null && focusedScheduleIgnoredConflicts.length > 0}
          type="info"
        />
        <Flash
          message={
            <span>
              We don&apos;t count conflicts for optional interviewers{interviewerTrainingPlatform ? ' or trainees' : ''}.
              <Button
                color="no-outline"
                iconRight={<FontAwesomeIcon icon={viewIgnoredOptionalInterviewerConflicts ? faCaretUp : faCaretDown} />}
                onClick={toggleViewIgnoredOptionalInterviewerConflicts}
                size="small"
                value={`${viewIgnoredOptionalInterviewerConflicts ? 'Hide' : 'Show'} ignored conflicts`}
              />
              {viewIgnoredOptionalInterviewerConflicts && <ScheduleConflictList conflicts={focusedScheduleOptionalInterviewerConflicts} timezone={schedule.timezone} />}
            </span>
          }
          showFlash={!shouldGenerate && focusedScheduleIndex !== null && focusedScheduleOptionalInterviewerConflicts.length > 0}
          type="info"
        />
        {!shouldGenerate && !isEmpty(scheduleOptions) &&
          <ScheduleOptionsViewer
            allSelectedScheduleOptions={allSelectedOptions}
            applicationId={application.id}
            businessHours={schedule.schedule_template.business_hours}
            focusedScheduleIndex={focusedScheduleIndex}
            keepGoingButton={progressiveScheduleGeneration ? KeepGoingButton : undefined}
            numberOfBlocks={selectedNumberOfBlocks}
            onScheduleFocus={handleScheduleFocus}
            onScheduleHoldChange={handleScheduleHoldChange}
            schedules={scheduleOptions}
            selectedScheduleIndices={selectedScheduleIndices}
            setScheduleOption={setScheduleOption}
            showHoldInput={hold}
            showZoomHost={schedule.schedule_template.video_conferencing_enabled && account.video_conferencing_type === 'zoom'}
            timezone={schedule.timezone}
          />
        }
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default ScheduleStep;
