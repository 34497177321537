import { find } from 'lodash';
import { useMemo } from 'react';

import FavoriteButton from './FavoriteButton';
import JobStatusIndicator from '../utils/JobStatusIndicator';
import ListItem from '../data-display/ListItem';
import SelectInput from './SelectInput';
import { FavoriteResourceType } from '../../../types';
import { useJobs } from '../../../hooks/queries/jobs';

import type { ActionMeta } from 'react-select/dist/declarations/src/types';
import type { Job, JobStatus } from '../../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as SelectOption } from './SelectInput/types';

export interface Option extends SelectOption<string> {
  status: JobStatus;
  favorite?: boolean;
}

interface Props<M extends boolean> {
  className?: string;
  filterJob?: (job: Job) => boolean;
  helperText?: string;
  isDisabled?: boolean;
  isMulti?: M;
  label?: string;
  name?: string;
  onChange: (newValue: OnChangeValue<Option, M>, actionMeta: ActionMeta<Option>) => void;
  placeholder?: string;
  value?: string | string[];
}

const JobSelectInput = <M extends boolean = false>({
  className,
  filterJob = () => true,
  helperText,
  isDisabled = false,
  isMulti,
  label,
  onChange,
  placeholder = 'Select a job',
  value,
}: Props<M>) => {
  const { data: jobs } = useJobs();

  const options = useMemo<Option[]>(() => (jobs?.jobs || [])
  .filter(filterJob)
  .map((job) => ({
    value: job.id,
    label: job.name,
    status: job.status,
    favorite: job.favorite,
  })), [jobs]);

  const selected = useMemo<OnChangeValue<Option, M> | undefined>(() => {
    if (!isMulti) {
      return value ? find(options, ['value', value])! as OnChangeValue<Option, M> : undefined;
    }

    let selectedJobs: string[] | undefined;
    if (typeof value === 'string') {
      selectedJobs = [value];
    } else {
      selectedJobs = value;
    }
    return selectedJobs ?
      // The types with a dynamic isMulti is a bit weird. We need to explicitly
      // cast this to be compatible.
      selectedJobs.map((value) => find(options, ['value', value])!) as unknown as OnChangeValue<Option, M> :
      undefined;
  }, [isMulti, options, value]);

  return (
    <SelectInput
      className={className}
      formatOptionLabel={(option, { context }) => (
        context === 'menu' ?
          <ListItem
            label={(
              <div className="favorite-container">
                {option.favorite ? (
                  <FavoriteButton
                    favorite={option.favorite}
                    isDisabled
                    resourceId={option.value}
                    resourceType={FavoriteResourceType.Job}
                  />
                ) : undefined}
                {option.label}
              </div>
            )}
            secondaryText={<JobStatusIndicator status={option.status} />}
          /> :
          option.label
      )}
      helperText={helperText}
      isClearable
      isDisabled={isDisabled}
      isMulti={isMulti}
      label={label}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      value={selected}
    />
  );
};

export default JobSelectInput;
