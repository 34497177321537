import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';

import Button from '../../../library/inputs/Button';
import EmailTemplateListTable from './EmailTemplateListTable';
import EmailTemplateSelectInput from '../../../library/inputs/EmailTemplateSelectInput';
import Flash from '../../../library/utils/Flash';
import LoadingSpinner from '../../../library/utils/LoadingSpinner';
import Section from '../../../library/layout/Section';
import SelectBaseTemplateModal from '../../../library/data-display/SelectBaseTemplateModal';
import TextInput from '../../../library/inputs/TextInput';
import useSyncStateWithQuery from '../../../../hooks/use-sync-state-with-query';
import { StyledHeaderActions } from './styles';
import { useEmailTemplates } from '../../../../hooks/queries/email-templates';

import type { ChangeEvent } from 'react';
import { correctPath } from 'libraries/gem';

const EmailTemplateList = () => {
  const [search, querySearch, setSearch] = useSyncStateWithQuery<string>('q', '', { delay: 200 });
  const [showArchived, , setShowArchived] = useSyncStateWithQuery<string>('show_archived', 'false');
  const [pageNumber, queryPageNumber, setPageNumber] = useSyncStateWithQuery<string>('page', '1');

  const [isSelectBaseTemplateModalOpen, setIsSelectBaseTemplateModalOpen] = useState<boolean>(false);

  const {
    data: emailTemplates,
    error,
    isFetching,
  } = useEmailTemplates({
    archived: showArchived === 'true',
    name: querySearch, // This has to be querySearch so it doesn't make a lot of requests when you type fast.
    limit: 10,
    offset: 10 * (parseInt(queryPageNumber, 10) - 1),
  });

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handleShowArchivedChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setShowArchived(JSON.stringify(e.target.checked));
  }, []);

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value.toString());
  }, []);

  return (
    <div className="email-template-list-container">
      <Helmet>
        <title>Email Templates | Gem Scheduling</title>
      </Helmet>
      <Section
        headerActions={
          <StyledHeaderActions>
            <Button
              color="gem-outline"
              iconLeft={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => setIsSelectBaseTemplateModalOpen(true)}
              value="New template from base"
            />
            <Link to={correctPath('/app/email-templates/create')}>
              <Button
                color="gem-blue"
                iconLeft={<FontAwesomeIcon icon={faPlus} />}
                value="New template"
              />
            </Link>
          </StyledHeaderActions>
        }
        title="Email templates"
      >
        <SelectBaseTemplateModal
          isOpen={isSelectBaseTemplateModalOpen}
          onToggle={() => setIsSelectBaseTemplateModalOpen(!isSelectBaseTemplateModalOpen)}
          redirectPath={correctPath('/app/email-templates/create')}
          resourceName="email template"
          selectInputComponent={EmailTemplateSelectInput}
        />
        <div className="filters-container">
          <TextInput
            id="text-input-email-template-filter"
            isAutofocus
            label="Search"
            leftIcon={isFetching ? <LoadingSpinner /> : <FontAwesomeIcon icon={faSearch} />}
            onChange={handleSearchChange}
            placeholder="Email template name"
            value={search}
          />
        </div>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        {emailTemplates &&
          <EmailTemplateListTable
            emailTemplates={emailTemplates.email_templates}
            onPageNumberChange={handlePageNumberChange}
            onShowArchivedChange={handleShowArchivedChange}
            pageNumber={parseInt(pageNumber, 10)}
            showArchived={showArchived === 'true'}
            totalCount={emailTemplates.total}
          />
        }
      </Section>
    </div>
  );
};

export default EmailTemplateList;
