import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import AvailabilityRequestAdvancedSettings from '../../../../library/inputs/AvailabilityRequestAdvancedSettings';
import BusinessHoursInput from '../../../../library/inputs/BusinessHoursInput';
import CheckboxInput from '../../../../library/inputs/CheckboxInput';
import Flash from '../../../../library/utils/Flash';
import MultiStepFormStep from '../../../../library/inputs/MultiStepFormStep';
import RollingWindowDaysInput from '../../../../library/inputs/RollingWindowDaysInput';
import { Step, useNewAvailability } from './use-new-availability';
import { useApplication } from '../../../../../hooks/queries/applications';

import type { ChangeEvent } from 'react';
import type { EditableBusinessHour } from '../../../../../types/business-hours';
import { correctPath } from 'libraries/gem';

const PreferencesStep = () => {
  const { id } = useParams<{ id: string }>();

  const application = useApplication(id).data!;

  const {
    availability,
    setAvailability,
    setCompletedStep,
  } = useNewAvailability();

  const [rollingWindowDays, setRollingWindowDays] = useState(availability.availability_template!.rolling_window_days);
  const [businessHours, setBusinessHours] = useState<EditableBusinessHour[]>(availability.availability_template!.business_hours.map((bh) => {
    return {
      ...bh,
      start_time: bh.start_time === '00:00' ? '' : bh.start_time,
      end_time: bh.end_time === '24:00' ? '' : bh.end_time,
    };
  }));
  const [suggestTimes, setSuggestTimes] = useState(availability.availability_template!.suggest_times);
  const [advancedNoticeHours, setAdvancedNoticeHours] = useState(availability.availability_template!.advanced_notice_hours);
  const [minimumDurationMinutes, setMinimumDurationMinutes] = useState(availability.availability_template!.minimum_duration_minutes);
  const [totalDurationMinutes, setTotalDurationMinutes] = useState(availability.availability_template!.total_duration_minutes);

  const handleSuggestTimesChange = (e: ChangeEvent<HTMLInputElement>) => setSuggestTimes(e.target.checked);
  const handleRollingWindowDaysChange = (e: ChangeEvent<HTMLInputElement>) => setRollingWindowDays(parseInt(e.target.value, 10));
  const handleAdvancedNoticeHoursChange = (duration: number) => setAdvancedNoticeHours(duration / 60);
  const handleMinimumDurationMinutesChange = (duration: number) => setMinimumDurationMinutes(duration);
  const handleTotalDurationMinutesChange = (duration: number) => setTotalDurationMinutes(duration);

  const advancedNoticeDays = advancedNoticeHours / 24;
  const isValidAdvancedNoticeAndRollingWindow = advancedNoticeDays < rollingWindowDays;

  const handleNext = () => {
    setAvailability((prev) => ({
      ...prev,
      availability_template: {
        ...prev.availability_template!,
        rolling_window_days: rollingWindowDays,
        business_hours: businessHours.map((bh) => ({
          day: bh.day,
          start_time: bh.start_time || '00:00',
          end_time: bh.end_time || '24:00',
          timezone: bh.timezone,
        })),
        suggest_times: suggestTimes,
        advanced_notice_hours: advancedNoticeHours,
        minimum_duration_minutes: minimumDurationMinutes,
        total_duration_minutes: totalDurationMinutes,
      },
    }));
    // If we don't want to suggest times, we skip over that step.
    const increment = suggestTimes ? 1 : 2;
    setCompletedStep(Step.Preferences + increment);
  };

  return (
    <Breadcrumb
      data={{
        title: '1. Preferences',
        pathname: correctPath(`/app/candidates/${id}/request-availability/preferences`),
      }}
    >
      <MultiStepFormStep
        className="form-step-preferences"
        isFirstStep
        nextButtonIsDisabled={!isValidAdvancedNoticeAndRollingWindow}
        nextButtonValue={`${suggestTimes ? 'Suggested Times' : 'Email'}`}
        nextLocation={correctPath(`/app/candidates/${id}/request-availability/${suggestTimes ? 'suggested-times' : 'email'}`)}
        onNext={handleNext}
      >
        <Helmet>
          <title>1. Preferences | Request availability from {application.candidate.name || 'Unknown'} for {application.current_stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message="Select the dates and times for which the candidate should submit their availability."
          showFlash
          type="info"
        />
        <RollingWindowDaysInput
          isRequired
          label="Preferred Date Window"
          max={180}
          min={1}
          onChange={handleRollingWindowDaysChange}
          value={rollingWindowDays}
        />
        <BusinessHoursInput
          businessHours={businessHours}
          helperText="Leave start and end blank to allow the entire day."
          label="Allowed Times"
          setBusinessHours={setBusinessHours}
        />
        <CheckboxInput
          className="suggest-times-input"
          helperText="Suggest times that are preferred by your team. The candidate will still be able to submit availability outside these times."
          isChecked={suggestTimes}
          label="Suggest specific times."
          onChange={handleSuggestTimesChange}
        />
        <AvailabilityRequestAdvancedSettings
          advancedNoticeHours={advancedNoticeHours}
          handleAdvancedNoticeHoursChange={handleAdvancedNoticeHoursChange}
          handleMinimumDurationMinutesChange={handleMinimumDurationMinutesChange}
          handleTotalDurationMinutesChange={handleTotalDurationMinutesChange}
          minimumDurationMinutes={minimumDurationMinutes}
          totalDurationMinutes={totalDurationMinutes}
        />
        <Flash
          message={<span>Your advanced notice requirement of {advancedNoticeHours} hours (<b>{advancedNoticeDays.toFixed(1)} day{advancedNoticeDays === 1 ? '' : 's'}</b>) exceeds the preferred date window (<b>{rollingWindowDays} day{rollingWindowDays === 1 ? '' : 's'}</b>). Increase the preferred date window to continue.</span>}
          showFlash={!isValidAdvancedNoticeAndRollingWindow}
          type="warning"
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default PreferencesStep;
