import { isEmpty } from 'lodash';
import Moment from 'moment-timezone';
import { useMemo } from 'react';

import { Calendar, momentLocalizer } from '../../../../libraries/react-big-calendar';
import withDragAndDrop from '../../../../libraries/react-big-calendar/addons/dragAndDrop';
import { formatMoment, TimeFormat } from 'libraries/time';
import CalendarScheduleEvent from './CalendarScheduleEvent';
import CalendarScheduleHeader from './CalendarScheduleHeader';
import { CalendarScheduleProvider } from './use-context';

import type { ComponentType, SyntheticEvent } from 'react';
import type { CalendarProps, SlotInfo, SlotPropGetter, stringOrDate } from '../../../../libraries/react-big-calendar';
import type { withDragAndDropProps } from '../../../../libraries/react-big-calendar/addons/dragAndDrop';
import type { Event } from './types';
import type { CalendarScheduleContextState } from './use-context';

const localizer = momentLocalizer(Moment);

const DragAndDropCalendar = withDragAndDrop<Event>(Calendar);

interface Props extends CalendarScheduleContextState {
  date?: stringOrDate;
  events: Event[];
  isDraggable?: boolean;
  maxTime?: Date;
  minTime?: Date;
  onEventChange: (args: { event: Event; start: stringOrDate; end: stringOrDate; isAllDay: boolean }) => void;
  onSelectCalendarTime?: (slotInfo: SlotInfo) => void;
  onSelectEvent?: (event: Event, e: SyntheticEvent<HTMLElement>) => void;
  timezone: string;
}

const CalendarScheduleCalendar = ({
  availabilityTimeSlots = [],
  date,
  eventConflictCheckFunctions,
  events,
  headerDate,
  headerTitle,
  isDraggable = false,
  maxTime,
  minTime,
  onDateChange,
  onEventChange,
  onRoomChange,
  onSelectCalendarTime,
  onSelectEvent,
  onZoomHostChange,
  potentialZoomHosts,
  scheduleId,
  selectedRoomId,
  selectedZoomHostId,
  showZoomHost,
  timezone,
}: Props) => {
  const CalendarComponent: ComponentType<CalendarProps<Event> & withDragAndDropProps<Event>> = useMemo(() => isDraggable ? DragAndDropCalendar : Calendar, [isDraggable]);

  const getSlotClassName: SlotPropGetter = (date): { className?: string } => {
    if (isEmpty(availabilityTimeSlots)) {
      return {};
    }
    if (availabilityTimeSlots!.some(({ start, end }) => Moment(date).isBetween(start, end, undefined, '[)' /* including start and excluding end */))) {
      return {};
    }
    return { className: 'unavailable' };
  };

  return (
    <CalendarScheduleProvider
      availabilityTimeSlots={availabilityTimeSlots}
      eventConflictCheckFunctions={eventConflictCheckFunctions}
      headerDate={headerDate}
      headerTitle={headerTitle}
      onDateChange={onDateChange}
      onRoomChange={onRoomChange}
      onZoomHostChange={onZoomHostChange}
      potentialZoomHosts={potentialZoomHosts}
      scheduleId={scheduleId}
      selectedRoomId={selectedRoomId}
      selectedZoomHostId={selectedZoomHostId}
      showZoomHost={showZoomHost}
      timezone={timezone}
    >
      <div className="calendar-container calendar-schedule">
        <CalendarComponent
          components={{
            day: {
              event: CalendarScheduleEvent,
              header: CalendarScheduleHeader,
            },
          }}
          date={date}
          dayLayoutAlgorithm="no-overlap"
          defaultDate={date}
          defaultView="day"
          drilldownView={null}
          endAccessor="end"
          events={events}
          formats={{
            timeGutterFormat: (date) => formatMoment(Moment.tz(date, timezone), TimeFormat.TimeWithTimezone),
            selectRangeFormat: ({ start, end }) => `${formatMoment(Moment.tz(start, timezone), TimeFormat.Time)}–${formatMoment(Moment.tz(end, timezone), TimeFormat.TimeWithTimezone)}`,
            eventTimeRangeFormat: ({ start, end }) => `${formatMoment(Moment.tz(start, timezone), TimeFormat.Time)}–${formatMoment(Moment.tz(end, timezone), TimeFormat.TimeWithTimezone)}`,
          }}
          localizer={localizer}
          max={maxTime}
          min={minTime}
          onEventDrop={isDraggable ? onEventChange : undefined}
          onEventResize={isDraggable ? onEventChange : undefined}
          onNavigate={() => {}}
          onSelectEvent={isDraggable ? onSelectEvent : undefined}
          onSelectSlot={isDraggable ? onSelectCalendarTime : undefined}
          resizable={isDraggable}
          selectable={isDraggable ? 'ignoreEvents' : undefined}
          showMultiDayTimes
          slotPropGetter={getSlotClassName}
          startAccessor="start"
          step={5}
          timeslots={12}
          toolbar={false}
          useMinForDayView
          views={['day']}
        />
      </div>
    </CalendarScheduleProvider>
  );
};

export default CalendarScheduleCalendar;
