import Moment from 'moment-timezone';
import { some } from 'lodash';
import { useMemo } from 'react';

import { useUsersMap } from 'hooks/queries/users';
import { useEvents } from 'hooks/use-events';
import { isConflict } from 'libraries/schedule';
import { formatMoment, TimeFormat } from 'libraries/time';
import { RSVP } from 'types';
import { businessHourDayOrdering } from 'types/business-hours';
import ResourceCalendarScheduleEvent from './ResourceCalendarScheduleEvent';
import ResourceCalendarScheduleHeader from './ResourceCalendarScheduleHeader';
import { Calendar, momentLocalizer } from '../../../../libraries/react-big-calendar';

import type { SlotPropGetter } from 'libraries/react-big-calendar';
import type { Attendee } from 'types';
import type { ResourceCalendarEvent, ResourceCalendarResource } from './types';
import type { Schedule } from '../CalendarSchedule/types';

const localizer = momentLocalizer(Moment);

interface Props {
  maxTime?: Date;
  minTime?: Date;
  schedule: Schedule;
  selectedUserIds: string[];
}

const ResourceCalendarSchedules = ({ maxTime, minTime, schedule, selectedUserIds }: Props) => {
  const users = useUsersMap({ archived: true });

  const date = useMemo(() => Moment.tz(schedule.interviews[0].start_time, schedule.timezone).startOf('day').toDate(), [schedule.interviews[0].start_time]);

  const { events: allEvents } = useEvents();
  const allUserEvents = useMemo(() => {
    if (!date) {
      return {};
    }
    const formattedDate = Moment.tz(date, schedule.timezone).format('YYYY-MM-DD');
    return allEvents[formattedDate]?.[schedule.timezone]?.users || {};
  }, [date, allEvents, schedule.timezone]);
  const allZoomMeetings = useMemo(() => {
    if (!date) {
      return {};
    }
    const formattedDate = Moment.tz(date, schedule.timezone).format('YYYY-MM-DD');
    return allEvents[formattedDate]?.[schedule.timezone]?.zoom_hosts || {};
  }, [date, allEvents, schedule.timezone]);

  const events = useMemo<ResourceCalendarEvent[]>(() => {
    const eventsToDisplay: ResourceCalendarEvent[] = [];

    schedule.interviews.forEach(({ name, title, start_time, interview_template, interviewers, interviewer_event_id, stage_interview_id }) => {
      interviewers.forEach(({ selected_user: { user_id } }) => {
        if (user_id && selectedUserIds.includes(user_id)) {
          const user = users[user_id];
          const validBusinessHours = (user?.business_hours || user?.default_business_hours || []).filter((bh) => bh.timezone || user?.default_timezone);
          const userTimezone = validBusinessHours.filter((bh) => businessHourDayOrdering[bh.day] === date.getDay())[0]?.timezone || user?.default_timezone;
          eventsToDisplay.push({
            userId: user_id,
            id: `${interviewer_event_id || stage_interview_id}-${user_id}`,
            duration: interview_template.duration_minutes,
            title: title || name,
            start: Moment.utc(start_time).toDate(),
            end: Moment.utc(start_time).add(interview_template.duration_minutes, 'minutes').toDate(),
            attendees: interviewers.map(({ selected_user }) => (selected_user.user_id ? {
              name: users[selected_user.user_id].name,
              email: users[selected_user.user_id].email,
            } as Attendee : null)).filter((a): a is Attendee => Boolean(a)),
            timezone: userTimezone || schedule.timezone,
            isConflict: false,
            isInterview: true,
            isOutsideWorkingHours: false,
            buffer_time: false,
          });
        }
      });
    });

    Object.entries(allUserEvents).forEach(([userId, value]) => {
      const userEvents = value?.events || [];
      if (selectedUserIds.includes(userId)) {
        userEvents?.forEach((userEvent) => {
          const isInterview = some(schedule.interviews, (interview) => interview.interviewer_event_ical_uid === userEvent.ical_uid);
          const duration = Moment.duration(Moment.utc(userEvent.end_time).diff(Moment.utc(userEvent.start_time))).asMinutes();
          const isMultiDayEvent = duration >= 24 * 60;
          const user = users[userId];
          const validBusinessHours = (user?.business_hours || user?.default_business_hours || []).filter((bh) => bh.timezone || user?.default_timezone);
          const userTimezone = validBusinessHours.filter((bh) => businessHourDayOrdering[bh.day] === date.getDay())[0]?.timezone || user?.default_timezone;
          if (userEvent.title !== 'Outside Working Hours' && !isInterview) {
            eventsToDisplay.push({
              userId,
              userAvatarColor: user?.color,
              id: `${userEvent.id}-${userId}`,
              duration,
              title: userEvent.title,
              start: Moment.utc(userEvent.start_time).add(isMultiDayEvent ? 1 : 0, 'seconds').toDate(),
              end: Moment.utc(userEvent.end_time).toDate(),
              attendees: userEvent.attendees || [],
              color: userEvent.color,
              private: userEvent.private,
              timezone: userTimezone || schedule.timezone,
              isConflict: some(schedule.interviews, (interview) => (
                some(interview.interviewers, ({ selected_user }) => {
                  return selected_user.user_id === userId && isConflict(userEvent, interview, schedule, [interview.interviewer_event_ical_uid], true, users[selected_user.user_id]?.email);
                })
              )),
              isInterview: false,
              isOutsideWorkingHours: false,
              buffer_time: userEvent.buffer_time,
              show_as: userEvent.show_as,
              rsvp: userEvent.attendees?.length === 0 ? RSVP.Accepted : (userEvent.attendees || []).find(({ email }) => email === user?.email)?.rsvp,
            });
          }
        });
      }
    });

    Object.entries(allZoomMeetings).forEach(([userId, value]) => {
      const zoomMeetings = value?.meetings || [];
      if (selectedUserIds.includes(`${userId}:zoom`)) {
        zoomMeetings?.forEach((zoomMeeting) => {
          const isMultiDayEvent = zoomMeeting.duration >= 24 * 60;
          const user = users[userId];
          const validBusinessHours = (user?.business_hours || user?.default_business_hours || []).filter((bh) => bh.timezone || user?.default_timezone);
          const userTimezone = validBusinessHours.filter((bh) => businessHourDayOrdering[bh.day] === date.getDay())[0]?.timezone || user?.default_timezone;
          eventsToDisplay.push({
            userId: `${userId}:zoom`,
            userAvatarColor: user?.color,
            id: `${zoomMeeting.id}-${userId}`,
            duration: zoomMeeting.duration,
            title: zoomMeeting.topic,
            start: Moment.utc(zoomMeeting.start_time).add(isMultiDayEvent ? 1 : 0, 'seconds').toDate(),
            end: Moment.utc(zoomMeeting.end_time).toDate(),
            attendees: [],
            timezone: userTimezone || schedule.timezone,
            isConflict: some(schedule.interviews, (interview) => (
              some(interview.interviewers, ({ selected_user }) => selected_user.user_id === userId) &&
                isConflict(zoomMeeting, interview, schedule, [interview.interviewer_event_ical_uid], true))
            ),
            isInterview: false,
            isOutsideWorkingHours: false,
            buffer_time: zoomMeeting.buffer_time,
          });
        });
      }
    });

    return eventsToDisplay;
  }, [allUserEvents, schedule, selectedUserIds, users]);

  const selectedUsers = useMemo(() => selectedUserIds.map((id) => ({
    id,
    name: users[id.replaceAll(':zoom', '')]?.name || users[id.replaceAll(':zoom', '')]?.email,
  })), [selectedUserIds, users]);

  const getSlotClassName: SlotPropGetter = (date, userId) => {
    if (!userId) {
      return {};
    }
    const user = users[userId];

    const validBusinessHours = (user?.business_hours || user?.default_business_hours || []).filter((bh) => bh.timezone || user?.default_timezone);
    const businessHoursForDay = validBusinessHours.filter((bh) => businessHourDayOrdering[bh.day] === date.getDay());

    if (!user || ((user?.business_hours || user?.default_business_hours || []).length > 0 && validBusinessHours.length === 0)) {
      // In the event of no "valid" windows (we can't resolve the timezone for the user), we assume that they don't have
      // business hours set and all times are valid rather than no times are valid.
      return {};
    }

    const isInWorkingHours = businessHoursForDay.some((bh) => {
      // We filter above so by this point, timezone should always be defined.
      const userTimezone = bh.timezone || user.default_timezone as string;

      const slotTime = Moment.tz(date, userTimezone).format('HH:mm');
      return bh.start_time <= slotTime && slotTime < bh.end_time;
    });

    if (!isInWorkingHours) {
      return { className: 'disabled' };
    }

    return {};
  };

  return (
    <div className="calendar-container resource-calendar-schedules">
      <Calendar<ResourceCalendarEvent, ResourceCalendarResource>
        components={{
          event: ResourceCalendarScheduleEvent,
          resourceHeader: ResourceCalendarScheduleHeader,
        }}
        date={date}
        dayLayoutAlgorithm="no-overlap"
        defaultDate={date}
        defaultView="day"
        drilldownView={null}
        endAccessor="end"
        events={events}
        formats={{
          eventTimeRangeFormat: ({ start, end }) => `${formatMoment(Moment.tz(start, schedule.timezone), TimeFormat.Time)}–${formatMoment(Moment.tz(end, schedule.timezone), TimeFormat.TimeWithTimezone)}`,
        }}
        localizer={localizer}
        max={maxTime}
        min={minTime}
        onNavigate={() => {}}
        resourceAccessor="userId"
        resourceTitleAccessor="name"
        resources={selectedUsers}
        showMultiDayTimes
        slotPropGetter={getSlotClassName}
        startAccessor="start"
        step={5}
        timeslots={12}
        toolbar={false}
        useMinForDayView
        views={['day']}
      />
    </div>
  );
};

export default ResourceCalendarSchedules;
