import { createContext, useContext, useMemo } from 'react';

import type { ReactNode } from 'react';
import type { DateRange, Option } from './types';

interface DateRangeContextState {
  dateRange?: Option;
  setDateRange: (value: DateRange) => void;
}

const DateRangeContext = createContext<DateRangeContextState>({
  dateRange: undefined,
  setDateRange: () => {},
});

interface Props {
  children: ReactNode;
  dateRange?: Option;
  setDateRange: (value: DateRange) => void;
}

export const DateRangeProvider = ({ children, dateRange, setDateRange }: Props) => {
  const contextValue = useMemo<DateRangeContextState>(() => ({
    dateRange,
    setDateRange,
  }), [dateRange, setDateRange]);

  return (
    <DateRangeContext.Provider value={contextValue}>
      {children}
    </DateRangeContext.Provider>
  );
};

export const useDateRange = () => {
  return useContext(DateRangeContext);
};
