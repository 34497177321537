import { Helmet } from 'react-helmet-async';

import OutboundLink from '../library/navigation/OutboundLink';

const Roadmap = () => {
  return (
    <div className="roadmap-container">
      <Helmet>
        <title>Roadmap | InterviewPlanner</title>
      </Helmet>
      <h1>
        Our product roadmap is public.
      </h1>
      <p>
        We strongly believe that, in order to create a scheduling platform that you trust, we must be transparent about where our product is headed and how we prioritize features.
      </p>
      <p>
        We also know that the real experts here are all of you&mdash;the recruiting teams who schedule candidates day in and day out. If our goal is to build the best interview scheduling platform out there, <b>we can&apos;t do it without your help</b>.
      </p>
      <p>
        We welcome your votes, feedback, and suggestions on&nbsp;
        <OutboundLink
          href="https://app.loopedin.io/interviewplanner-roadmap"
          label="Roadmap Page"
        >
          our roadmap
        </OutboundLink>
        .
      </p>
    </div>
  );
};

export default Roadmap;
