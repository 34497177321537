import { Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useEffect, useLayoutEffect, useState } from 'react';

import About from './About';
import Compare from './Compare';
import Consent from './Microsoft/Consent';
import Contact from './Contact';
import Footer from './Footer';
import GlobalStyles from '../../theme/interviewplanner/styles';
import Home from './Home';
import Integrations from './Integrations';
import InterviewerTraining from './InterviewerTraining';
import interviewPlannerTheme from '../../theme/interviewplanner';
import Navbar from './Navbar';
import NotFound from './WebsiteNotFound';
import Pricing from './Pricing';
import Privacy from './Privacy';
import Roadmap from './Roadmap';
import Terms from './Terms';
import { correctPath } from 'libraries/gem';

const Website = () => {
  const { hash, pathname } = useLocation();

  const [renderPage, setRenderPage] = useState(false);

  useEffect(() => {
    require('/src/styles/website/index.scss');
    setRenderPage(true);
  }, []);

  useLayoutEffect(() => {
    if (hash) {
      const navbarContainerElement = document.querySelector<HTMLElement>('.navbar-container');
      const hashElement = document.querySelector<HTMLElement>(hash);

      if (hashElement && navbarContainerElement) {
        setTimeout(() => {
          window.scrollTo({
            top: hashElement.offsetTop - navbarContainerElement.offsetHeight,
            behavior: 'smooth',
          });
        }, 10);
      }
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  }, [pathname, hash]);

  if (!renderPage) {
    return null;
  }

  return (
    <ThemeProvider theme={interviewPlannerTheme}>
      <GlobalStyles />
      <div className="website-container">
        <Navbar />
        <div className="content-container">
          <Switch>
            <Route component={Home} exact path="/" />
            <Route component={About} path="/about" />
            <Route component={Compare} path="/compare" />
            <Route component={Contact} path="/contact" />
            <Route component={Integrations} path="/features/integrations" />
            <Route component={InterviewerTraining} path="/features/interviewer-training" />
            <Route component={Pricing} path="/pricing" />
            <Route component={Privacy} path="/privacy" />
            <Route component={Roadmap} path="/roadmap" />
            <Route component={Terms} path="/terms" />
            <Route component={Consent} path={correctPath('/microsoft/consent')} />
            <Route component={() => {
              window.location.href = 'https://support.gem.com/hc/en-us/articles/23489941223447-How-do-I-connect-my-Zoom-account';
              return null;
            }} path="/support/zoom" />
            <Route component={() => {
              window.location.href = 'https://support.gem.com/hc/en-us/categories/23477652183703-Gem-Scheduling';
              return null;
            }} path="/support" />
            <Route component={NotFound} path="/" />
          </Switch>
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Website;
