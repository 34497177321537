import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { difference, find, flatten, groupBy, isEmpty, orderBy } from 'lodash';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useMemo, useState } from 'react';

import DateRangeInput from '../../../../library/inputs/DateRangeInput';
import Flash from '../../../../library/utils/Flash';
import JobSelectInput from '../../../../library/inputs/JobSelectInput';
import ListItem from '../../../../library/data-display/ListItem';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import Section from '../../../../library/layout/Section';
import SelectInput from '../../../../library/inputs/SelectInput';
import TextInput from '../../../../library/inputs/TextInput';
import UtilizationSummaryTable from './UtilizationSummaryTable';
import useSyncStateWithQuery from '../../../../../hooks/use-sync-state-with-query';
import { ScheduleStatus } from '../../../../../types';
import { useInterviews } from '../../../../../hooks/queries/interviews';
import { useStageInterviews } from '../../../../../hooks/queries/stage-interviews';
import { useUsersMap } from '../../../../../hooks/queries/users';

import type { ActionMeta } from 'react-select';
import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as DateRangeOption } from '../../../../library/inputs/DateRangeInput/types';
import type { Option as JobOption } from '../../../../library/inputs/JobSelectInput';
import type { Option } from '../../../../library/inputs/SelectInput/types';

const UtilizationSummarySection = () => {
  const [dateRange, queryDateRange, setDateRange] = useSyncStateWithQuery<string>('range', 'mtd', { stripDefault: false });
  const [startDate, setStartDate] = useState(dateRange?.includes(':') ? dateRange.split(':')[0] : undefined);
  const [endDate, setEndDate] = useState(dateRange?.includes(':') ? dateRange.split(':')[1] : undefined);

  const [search, querySearch, setSearch] = useSyncStateWithQuery<string>('q', '', { delay: 200 });
  const [job, queryJob, setJob] = useSyncStateWithQuery<string[]>('job', [], { array: true });
  const [interview, queryInterview, setInterview] = useSyncStateWithQuery<string[]>('interview', [], { array: true });
  const [pageNumber, , setPageNumber] = useSyncStateWithQuery<string>('page', '1');

  const users = useUsersMap({ archived: true });

  const { data: stageInterviews } = useStageInterviews({ jobs: job }, {
    enabled: Boolean(job?.length > 0),
  });

  const stageInterviewOptions = useMemo(() => {
    if (!stageInterviews) {
      return null;
    }
    const groupedStageInterviews = groupBy(orderBy(stageInterviews.stage_interviews, ['stage.position', 'position']), 'stage.job_id');
    return orderBy(Object.keys(groupedStageInterviews).map((jobGroup) => ({
      label: groupedStageInterviews[jobGroup][0].stage.job.name,
      options: groupedStageInterviews[jobGroup].map((stageInterview) => ({
        value: stageInterview.id,
        label: stageInterview.name,
        secondaryText: stageInterview.stage.name,
        // Save the job ID so we can remove the corresponding interviews
        // when a job is removed.
        jobId: jobGroup,
      })),
      // Order the job groupings by their position in the job filter.
      jobIndex: job.indexOf(jobGroup),
    })), ['jobIndex']);
  }, [stageInterviews]);

  const isValidCommonDateRange = useMemo(() => (
    queryDateRange === 'mtd' ?
      Moment(startDate).isSame(Moment().startOf('month')) && Moment(endDate).isSame(Moment().endOf('month')) :
      Boolean(!queryDateRange.includes(':') && startDate && endDate)
  ), [queryDateRange, startDate, endDate]);

  const [startTimeStart, startTimeEnd] = useMemo(() => {
    return [
      startDate ? Moment(startDate).startOf('isoWeek').format() : undefined,
      endDate ? Moment(endDate).endOf('isoWeek').format() : undefined,
    ];
  }, [startDate, endDate]);

  const {
    data: interviews,
    error: interviewsError,
    isFetching: isInterviewsFetching,
  } = useInterviews({
    schedule_status: [ScheduleStatus.Confirmed],
    jobs: queryJob,
    stage_interviews: queryInterview,
    start_time_start: startTimeStart,
    start_time_end: startTimeEnd,
  }, {
    enabled: Boolean(!queryDateRange || queryDateRange.includes(':') || queryDateRange === 'mtd' || isValidCommonDateRange),
  });

  const selectedInterviews = useMemo(() => {
    return interview && stageInterviewOptions ? interview.map((interview) => {
      return find(flatten(stageInterviewOptions.map(({ options }) => options)), ['value', interview])!;
    }) : undefined;
  }, [interview, stageInterviewOptions]);

  const handleDateRangeChange = useCallback((option: OnChangeValue<DateRangeOption, false>) => {
    setStartDate(option ? option.startDate : undefined);
    setEndDate(option ? option.endDate : undefined);
    if (option) {
      const formattedStartDate = option.startDate ? Moment(option.startDate).format('YYYY-MM-DD') : '';
      const formattedEndDate = option.endDate ? Moment(option.endDate).format('YYYY-MM-DD') : '';
      setDateRange(option.value === 'custom' ? `${formattedStartDate}:${formattedEndDate}` : option.value);
    } else {
      setDateRange('');
    }

    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handleJobChange = useCallback((option: OnChangeValue<JobOption, true>, actionMeta: ActionMeta<JobOption>) => {
    setJob(option?.length > 0 ? option.map((job) => job.value) : []);
    if (interview) {
      if (actionMeta.action === 'clear') {
        // Remove all filtered stage interviews.
        setInterview([]);
      } else if (actionMeta.action === 'remove-value') {
        // Remove the stage interview filters that are corresponding to the
        // removed job.
        const interviewsToRemove = (selectedInterviews || []).filter(({ jobId }) => actionMeta.removedValue.value === jobId).map(({ value }) => value);
        setInterview((prev) => difference(prev, interviewsToRemove));
      }
    }
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [interview, selectedInterviews, pageNumber]);

  const handleInterviewChange = useCallback((option: OnChangeValue<Option<string>, true>) => {
    setInterview(option?.length > 0 ? option.map((interview) => interview.value) : []);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const isLoading = isInterviewsFetching || isEmpty(users);

  return (
    <Section
      className="interviewer-report utilization-summary"
      title="Utilization summary"
    >
      <Helmet>
        <title>Utilization Summary | Interviewer Reports | InterviewPlanner</title>
      </Helmet>
      <Flash
        message={interviewsError?.message}
        showFlash={Boolean(interviewsError)}
        type="danger"
      />
      <div className="filters-container">
        <div className="filter-row">
          <DateRangeInput
            isLoading={isLoading}
            label="Date Range"
            onChange={handleDateRangeChange}
            selectedDateRange={{
              value: dateRange && dateRange.includes(':') ? 'custom' : dateRange,
              startDate,
              endDate,
            }}
          />
          <TextInput
            label="Search"
            leftIcon={isLoading ? <LoadingSpinner /> : <FontAwesomeIcon icon={faSearch} />}
            onChange={handleSearchChange}
            placeholder="Interviewer name or email"
            value={search}
          />
        </div>
        <div className="filter-row">
          <JobSelectInput
            isMulti
            label="Job"
            onChange={handleJobChange}
            placeholder="Filter by job"
            value={job}
          />
          {job?.length > 0 && stageInterviewOptions &&
            <SelectInput<string, Option<string>, true>
              formatOptionLabel={(option, { context }) => (
                context === 'menu' ?
                  <ListItem
                    label={option.label}
                    secondaryText={option.secondaryText}
                  /> :
                  option.label
              )}
              isClearable
              isMulti
              label="Interview"
              onChange={handleInterviewChange}
              options={stageInterviewOptions}
              placeholder="Filter by interview"
              value={selectedInterviews}
            />
          }
        </div>
      </div>
      <UtilizationSummaryTable
        endDate={endDate}
        interviews={interviews?.interviews || []}
        pageNumber={parseInt(pageNumber, 10)}
        search={querySearch}
        setPageNumber={setPageNumber}
        startDate={startDate}
      />
    </Section>
  );
};

export default UtilizationSummarySection;
