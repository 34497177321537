import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import SummarySection from './SummarySection';
import TokensSection from './TokensSection';
import { useUser } from '../../../../../hooks/queries/users';
import { correctPath } from 'libraries/gem';

const InterviewerDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { data: user } = useUser(id);

  return (
    <Breadcrumb
      data={{
        title: 'Details',
        pathname: correctPath(`/app/interviewers/${id}/details`),
      }}
    >
      <div className="interviewer-details-container">
        <Helmet>
          <title>Details | {user?.name} | InterviewPlanner</title>
        </Helmet>
        <SummarySection />
        <TokensSection />
      </div>
    </Breadcrumb>
  );
};

export default InterviewerDetails;
