import { Breadcrumb } from 'react-breadcrumbs';
import { Redirect } from 'react-router-dom';
import { capitalize } from 'lodash';

import Flash from '../../library/utils/Flash';
import LoadingSpinner from '../../library/utils/LoadingSpinner';
import useQueryState from '../../../hooks/use-query-state';
import { ATS } from '../../../types';
import { useApplications } from '../../../hooks/queries/applications';
import { useCandidates } from '../../../hooks/queries/candidates';
import { useSession } from '../../../hooks/use-session';
import { correctPath } from 'libraries/gem';

const atsUsingApplications = [ATS.Gem, ATS.Greenhouse];
const atsUsingCandidates = [ATS.Lever];

const CandidateATSRedirect = () => {
  const [id] = useQueryState<string>('id', '');

  const { account } = useSession();

  const {
    data: applications,
    isLoading: isApplicationsLoading,
  } = useApplications({ ats_id: id }, {
    enabled: Boolean(id && account?.ats_type && atsUsingApplications.includes(account.ats_type)),
  });
  const {
    data: candidates,
    isLoading: isCandidatesLoading,
  } = useCandidates({ ats_id: id }, {
    enabled: Boolean(id && account?.ats_type && atsUsingCandidates.includes(account.ats_type)),
  });

  if (!id) {
    return <Redirect to={correctPath('/app/candidates')} />;
  }

  if (isApplicationsLoading || isCandidatesLoading) {
    return <LoadingSpinner />;
  }

  if (applications?.applications && applications.applications.length > 0) {
    analytics.track('ATS Redirect Succeeded', { id });
    return <Redirect to={correctPath(`/app/candidates/${applications.applications[0].id}`)} />;
  }

  if (candidates?.candidates && candidates.candidates.length > 0 && candidates.candidates[0].applications?.length > 0) {
    analytics.track('ATS Redirect Succeeded', { id });
    return <Redirect to={correctPath(`/app/candidates/${candidates.candidates[0].applications[0].id}`)} />;
  }

  analytics.track('ATS Redirect Failed', { id });

  return (
    <Breadcrumb
      data={{
        title: id,
        pathname: correctPath('/app/candidates/ats'),
      }}
    >
      <Flash
        message={`It doesn't look like that we know what that ${account?.ats_type ? capitalize(account.ats_type) : 'ATS'} ID is. It might not have synced to InterviewPlanner yet. If this continues to happen, contact support.`}
        showFlash
        type="danger"
      />
    </Breadcrumb>
  );
};

export default CandidateATSRedirect;
