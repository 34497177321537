import Moment from 'moment';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { find } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import DateRangeInputMenuList from './DateRangeInputMenuList';
import ListItem from '../../data-display/ListItem';
import LoadingSpinner from '../../utils/LoadingSpinner';
import SelectInput from '../SelectInput';
import { DateRangeProvider } from './use-date-range';
import { formatDateRange } from '../../../../libraries/formatters';

import type { DateRange, Option } from './types';
import type { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { ReactNode } from 'react';

const DEFAULT_OPTIONS: Option[] = [{
  label: 'This year',
  value: 'ytd',
  startDate: Moment().startOf('year').format(),
  endDate: Moment().endOf('year').format(),
}, {
  label: 'This month',
  value: 'mtd',
  startDate: Moment().startOf('month').format(),
  endDate: Moment().endOf('month').format(),
}, {
  label: 'This week',
  value: 'wtd',
  startDate: Moment().startOf('isoWeek').format(),
  endDate: Moment().startOf('isoWeek').add(4, 'days').format(),
}];

export enum DateRangeInputLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

interface Props {
  className?: string;
  defaultOptions?: Option[];
  helperText?: ReactNode;
  id?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  layout?: `${DateRangeInputLayout}`;
  onChange: (newValue: OnChangeValue<Option, false>) => void;
  selectedDateRange: Option;
}

const DateRangeInput = ({
  className,
  defaultOptions = DEFAULT_OPTIONS,
  helperText,
  id,
  isDisabled = false,
  isLoading = false,
  label,
  layout = DateRangeInputLayout.Vertical,
  onChange,
  selectedDateRange,
}: Props) => {
  const options = useMemo<Option[]>(() => [...defaultOptions, {
    label: 'Custom range',
    value: 'custom',
  }], [defaultOptions]);

  const calendarIcon = useMemo(() => (isLoading ?
    <LoadingSpinner /> :
    <FontAwesomeIcon icon={faCalendarAlt} />
  ), [isLoading]);

  useEffect(() => {
    if (selectedDateRange.value && selectedDateRange.value !== 'custom') {
      const selectedOption = find(options, ['value', selectedDateRange.value]);
      if (selectedOption && (!Moment(selectedOption.startDate).isSame(selectedDateRange.startDate) || !Moment(selectedOption.endDate).isSame(selectedDateRange.endDate))) {
        onChange(selectedOption);
      }
    }
  }, [selectedDateRange.value]);

  const formatOptionLabel = useCallback((option: Option, { context }: FormatOptionLabelMeta<Option>) => {
    if (context !== 'menu' && option.value === 'custom') {
      return (
        <span>
          {option.label}&nbsp;({formatDateRange(selectedDateRange.startDate, selectedDateRange.endDate)})
        </span>
      );
    }

    if (context === 'menu' && option.value !== 'custom') {
      return (
        <ListItem
          label={option.label}
          secondaryText={formatDateRange(option.startDate, option.endDate)}
        />
      );
    }

    return option.label;
  }, [selectedDateRange]);

  const setDateRange = useCallback((days: DateRange) => {
    onChange({ value: 'custom', ...days });
  }, [onChange]);

  return (
    <DateRangeProvider dateRange={selectedDateRange} setDateRange={setDateRange}>
      <SelectInput<string, Option, false>
        className={classnames('date-range-input', `layout-${layout}`, className)}
        closeMenuOnSelect={false}
        components={{ MenuList: DateRangeInputMenuList }}
        formatOptionLabel={formatOptionLabel}
        helperText={helperText}
        icon={calendarIcon}
        id={id}
        isClearable
        isDisabled={isDisabled}
        label={label}
        onChange={onChange}
        options={options}
        placeholder="All time"
        value={selectedDateRange.value ? find(options, ['value', selectedDateRange.value]) : undefined}
      />
    </DateRangeProvider>
  );
};

export default DateRangeInput;
