import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import InterviewsScheduledSection from './InterviewsScheduledSection';
import useSavedQueryParams from '../../../hooks/use-saved-query-params';
import { correctPath } from 'libraries/gem';

const Overview = () => {
  const location = useLocation();

  const [params, setParams] = useSavedQueryParams('/app/overview');

  useEffect(() => {
    if (location.search !== params) {
      setParams(location.search);
    }
  }, [params, location.search, setParams]);

  return (
    <Breadcrumb
      data={{
        title: <><FontAwesomeIcon icon={faHome} />Overview</>,
        pathname: correctPath('/app/overview'),
      }}
    >
      <div className="overview-container">
        <Helmet>
          <title>Overview | Gem Scheduling</title>
        </Helmet>
        <InterviewsScheduledSection />
      </div>
    </Breadcrumb>
  );
};

export default Overview;
