import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, useParams } from 'react-router-dom';
import { flatten, isEmpty, sumBy } from 'lodash';
import { useEffect, useState } from 'react';

import AvailabilityPicker from '../../../../library/inputs/AvailabilityPicker';
import BusinessHoursInput from '../../../../library/inputs/BusinessHoursInput';
import CheckboxInput from '../../../../library/inputs/CheckboxInput';
import Flash from '../../../../library/utils/Flash';
import MultiStepFormStep from '../../../../library/inputs/MultiStepFormStep';
import RoomFiltersBuilder from '../../../../library/inputs/RoomFiltersBuilder';
import TimezoneSelectInput from '../../../../library/inputs/TimezoneSelectInput';
import ZoomHostFiltersBuilder from '../../../../library/inputs/ZoomHostFiltersBuilder';
import { Step, useNewSchedule } from './use-new-schedule';
import { createEmptyRoomFilter } from '../../../../../libraries/rooms';
import { createEmptyZoomHostFilter } from '../../../../../libraries/zoom-hosts';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useSession } from '../../../../../hooks/use-session';

import type { ChangeEvent } from 'react';
import type { EditableRoomFilter, EditableZoomHostFilter } from '../../../../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../library/inputs/SelectInput/types';
import type { TimeSlot } from '../../../../library/inputs/AvailabilityPicker/types';
import { correctPath } from 'libraries/gem';

const AvailabilityStep = () => {
  const { id } = useParams<{ id: string }>();

  const { account } = useSession();
  const hasVideoConferencingIntegration = Boolean(account?.video_conferencing_type);
  const application = useApplication(id).data!;

  const {
    completedStep,
    schedule,
    setCompletedStep,
    setSchedule,
  } = useNewSchedule();

  const [businessHours, setBusinessHours] = useState(schedule.schedule_template.business_hours);
  const [candidateTimezone, setCandidateTimezone] = useState(schedule.candidate_timezone);
  const [isVideoConferencingEnabled, setIsVideoConferencingEnabled] = useState(schedule.schedule_template.video_conferencing_enabled);
  const [zoomHostFilters, setZoomHostFilters] = useState<EditableZoomHostFilter[]>(schedule.schedule_template.zoom_host_filters || createEmptyZoomHostFilter());
  const [roomFilters, setRoomFilters] = useState<EditableRoomFilter[]>(schedule.schedule_template.room_filters || createEmptyRoomFilter());
  const [availabilities, setAvailabilities] = useState<TimeSlot[]>(schedule.availabilities);
  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [error]);

  const handleIsVideoConferencingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsVideoConferencingEnabled(isChecked);
  };

  const handleZoomHostFiltersChange = (filters: EditableZoomHostFilter[]) => {
    setZoomHostFilters(filters);
  };

  const handleRoomFiltersChange = (filters: EditableRoomFilter[]) => {
    setRoomFilters(filters);
  };

  const handleCandidateTimezoneChange = (option: OnChangeValue<Option<string>, false>) => {
    setCandidateTimezone(option?.value);
  };

  const handleNext = () => {
    setError('');

    if (businessHours.length === 0) {
      setError('You need to pick at least one allowed day under "Scheduling Windows" below.');
      return false;
    }

    setSchedule((prev) => ({
      ...prev,
      availabilities: availabilities.map(({ start_time, end_time }) => ({
        start_time: start_time instanceof Date ? start_time : Moment(start_time).toDate(),
        end_time: end_time instanceof Date ? end_time : Moment(end_time).toDate(),
      })),
      timezone: businessHours[0].timezone!,
      candidate_timezone: candidateTimezone,
      schedule_template: {
        ...prev.schedule_template,
        business_hours: businessHours,
        room_filters: (roomFilters || []).filter(({ room_filter_expressions }) => !isEmpty(room_filter_expressions)).map(({ room_filter_expressions }) => ({
          room_filter_expressions: room_filter_expressions.map((exp) => ({
            filterable_id: exp.filterable_id,
            filterable_type: exp.filterable_type,
            negated: exp.negated,
          })),
        })),
        video_conferencing_enabled: isVideoConferencingEnabled,
        zoom_host_filters: isVideoConferencingEnabled && account?.video_conferencing_type === 'zoom' && (zoomHostFilters || []).map(({ zoom_host_filter_expressions }) => ({
          zoom_host_filter_expressions: zoom_host_filter_expressions.map((exp) => ({
            filterable_id: exp.filterable_id,
            filterable_type: exp.filterable_type,
            negated: exp.negated,
          })),
        })) || undefined,
      },
    }));
    setCompletedStep(Step.Availability + 1);
  };

  if (completedStep < Step.Availability) {
    return <Redirect to={correctPath(`/app/candidates/${id}/schedule/interview-plan`)} />;
  }

  const hasPreFilledAvailabilities = (application.active_availabilities ?
    flatten(
      application.active_availabilities.filter(({ status }) => status === 'submitted')
      .map(({ availability_time_slots }) => availability_time_slots || [])
    ).filter(({ end_time }) => Moment(end_time).isAfter(Moment())).length > 0 :
    false
  );

  const sumOfInterviewDurations = sumBy(schedule.stage_interviews.filter(({ in_schedule }) => in_schedule), ({ interview_template }) => interview_template ? interview_template.duration_minutes : 0);
  const isConfirmingHeldSchedule = Boolean(schedule.id);

  return (
    <Breadcrumb
      data={{
        title: '3. Availability',
        pathname: correctPath(`/app/candidates/${id}/schedule/availability`),
      }}
    >
      <MultiStepFormStep
        backLocation={correctPath(`/app/candidates/${id}/schedule/interviewers${schedule.id ? `?schedule=${schedule.id}` : ''}`)}
        className="form-step-availability"
        nextButtonValue="Schedule"
        nextLocation={correctPath(`/app/candidates/${id}/schedule/schedule${schedule.id ? `?schedule=${schedule.id}` : ''}`)}
        onNext={handleNext}
      >
        <Helmet>
          <title>3. Availability | Schedule {application.candidate.name || 'Unknown'} for {application.current_stage?.name} | InterviewPlanner</title>
        </Helmet>
        <Flash
          message={`Select rooms and times where we can schedule this interview. ${hasPreFilledAvailabilities ? 'We have pre-filled availabilities submitted by the candidate. Modify them if needed.' : 'Fill in the candidate\'s availabilities.'}`}
          showFlash={!isConfirmingHeldSchedule}
          type="info"
        />
        <Flash
          message={<span>These are settings for generating new schedules. Since you&apos;re confirming a held schedule, generating new schedules is disabled. However, you can still make changes to the date and times on <Link to={correctPath(`/app/candidates/${application.id}/schedule/schedule${schedule.id ? `?schedule=${schedule.id}` : ''}`)}>the Schedule step</Link>.</span>}
          showFlash={isConfirmingHeldSchedule}
          type="warning"
        />
        <Flash
          message={<span>To schedule video interviews, you must set up a video conferencing integration <Link to={correctPath('/app/integrations')}>here.</Link></span>}
          showFlash={!hasVideoConferencingIntegration && isVideoConferencingEnabled}
          type="warning"
        />
        <Flash
          message={error}
          showFlash={Boolean(error)}
          type="danger"
        />
        <CheckboxInput
          helperText={account?.video_conferencing_type === 'zoom' ? 'We will favor schedules that do not conflict with other hosted meetings.' : 'We will create a video conferencing link and add it to all events.'}
          isChecked={isVideoConferencingEnabled}
          isDisabled={isConfirmingHeldSchedule}
          label="Include video conferencing links."
          onChange={handleIsVideoConferencingEnabledChange}
        />
        {isVideoConferencingEnabled && account?.video_conferencing_type === 'zoom' &&
          <ZoomHostFiltersBuilder
            filters={zoomHostFilters}
            isDisabled={isConfirmingHeldSchedule}
            label="Zoom Meeting Host"
            onChange={handleZoomHostFiltersChange}
          />
        }
        <RoomFiltersBuilder
          filters={roomFilters}
          helperText="We will generate schedules that take place in one of these rooms. All interviews in a schedule will be in the same room."
          isDisabled={isConfirmingHeldSchedule}
          label="Interview Rooms"
          onChange={handleRoomFiltersChange}
        />
        <BusinessHoursInput
          businessHours={businessHours}
          className="business-hours-input"
          helperText="We will generate schedules within these windows. Leave start and end blank to allow the entire day."
          label="Scheduling Windows"
          setBusinessHours={setBusinessHours}
        />
        <TimezoneSelectInput
          helperText="This is automatically set based on any availability we've received from the candidate. We will use this for certain tokens in the event descriptions and emails."
          isClearable
          isDisabled={isConfirmingHeldSchedule}
          label="Candidate Timezone"
          onChange={handleCandidateTimezoneChange}
          placeholder="Candidate timezone"
          value={candidateTimezone}
        />
        <AvailabilityPicker
          allowPastTimes
          availabilities={availabilities}
          businessHours={businessHours.map((bh) => ({
            ...bh,
            start_time: bh.start_time || '00:00',
            end_time: bh.end_time || '24:00',
          }))}
          isDraggable={!isConfirmingHeldSchedule}
          isRequired={!isConfirmingHeldSchedule}
          label="Candidate Availabilities"
          minDuration={sumOfInterviewDurations}
          setAvailabilities={setAvailabilities}
          showEventWarnings={false}
          submittedAvailabilities={schedule.submittedAvailabilities}
        />
        <Flash
          isDismissible
          message={<span>The availability submitted by the candidate has expired. <Link to={correctPath(`/app/candidates/${application.id}/availabilities`)}>View it here.</Link></span>}
          showFlash={!isConfirmingHeldSchedule && Boolean(application.active_availabilities && application.active_availabilities.filter(({ status }) => status === 'submitted').length > 0) && !hasPreFilledAvailabilities}
          type="warning"
        />
        <Flash
          message="You have not filled in the candidate's timezone. You won't be able to use tokens that convert dates and times to their timezone for a better candidate experience. Continue if you do not need to use these tokens or if you do not know the candidate's timezone."
          showFlash={!candidateTimezone}
          type="warning"
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default AvailabilityStep;
