import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';

import CopyToClipboardButton from '../../../../library/inputs/CopyToClipboardButton';
import Flash from '../../../../library/utils/Flash';
import MultiStepFormStep from '../../../../library/inputs/MultiStepFormStep';
import Table from '../../../../library/data-display/Table';
import { Step, useNewSelfSchedulingLink } from './use-new-self-scheduling-link';
import { useApplication } from '../../../../../hooks/queries/applications';
import { correctPath } from 'libraries/gem';

const SendLinkStep = () => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const application = useApplication(id).data!;

  const {
    completedStep,
    selfSchedulingLinkId,
  } = useNewSelfSchedulingLink();

  if (completedStep < Step.SendLink) {
    return <Redirect to={correctPath(`/app/candidates/${id}/self-schedule/preferences`)} />;
  }

  const handleNext = async () => {
    history.push(correctPath(`/app/candidates/${id}/schedules/${selfSchedulingLinkId}`));
  };

  return (
    <Breadcrumb
      data={{
        title: '4. Send Link',
        pathname: correctPath(`/app/candidates/${id}/self-schedule/send`),
      }}
    >
      <MultiStepFormStep
        className="form-step-send"
        isFirstStep
        isLastStep
        nextButtonValue="Done"
        onNext={handleNext}
      >
        <Helmet>
          <title>4. Send Link | Send {application.candidate.name || 'Unknown'} a link to schedule {application.current_stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message="Self-scheduling link successfully created!"
          showFlash
          type="success"
        />
        <Table
          data={[{ link: correctPath(`/schedule/${selfSchedulingLinkId}`) }]}
          layout="horizontal"
          schema={[{
            header: 'Link',
            displayValue: /* eslint-disable react/display-name, react/prop-types */ ({ link }) => (
              <div className="send-link">
                <Link
                  target="_blank"
                  to={link}
                >
                  {window.origin}{link}
                </Link>
                <CopyToClipboardButton textToCopy={`${window.origin}${link}`} />
              </div>
            ),
          }]}
        />
        <p>
          Now, you can send the candidate an email with their unique link.
        </p>
        <p>
          When you are finished sending your email, click Done to exit the workflow.
        </p>
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default SendLinkStep;
