import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { capitalize, groupBy, some, sortBy } from 'lodash';
import { faCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import Avatar from '../../../../../library/data-display/Avatar';
import Button from '../../../../../library/inputs/Button';
import CandidateCalendarEvents from './CandidateCalendarEvents';
import DeleteHeldSchedulesModal from './DeleteHeldSchedulesModal';
import EmailPreview from '../../../../../library/data-display/EmailPreview';
import Flash from '../../../../../library/utils/Flash';
import InterviewTable from './InterviewTable';
import ListItem from '../../../../../library/data-display/ListItem';
import LoadingSpinner from '../../../../../library/utils/LoadingSpinner';
import MultiStepFormStep from '../../../../../library/inputs/MultiStepFormStep';
import PutSchedulesOnHoldModal from './PutSchedulesOnHoldModal';
import Table from '../../../../../library/data-display/Table';
import Tag from '../../../../../library/data-display/Tag';
import pluralize from '../../../../../../libraries/pluralize';
import { Step, useNewSchedule } from '../use-new-schedule';
import { constructSchedulePayload, constructScheduleTokens, constructScheduleUpdatePayloads } from '../helpers';
import { formatDate, formatDateTimeRange } from '../../../../../../libraries/formatters';
import { resolveHiringRole } from '../../../../../../libraries/email';
import { useApplication } from '../../../../../../hooks/queries/applications';
import { useCalendars } from '../../../../../../hooks/queries/calendars';
import { useCreateSchedule, useUpdateSchedule } from '../../../../../../hooks/queries/schedules';
import { useLDFlags } from 'hooks/use-ld-flags';
import { useMultipleRenders } from '../../../../../../hooks/queries/tokens';
import { useRoomsMap } from '../../../../../../hooks/queries/rooms';
import { useSession } from '../../../../../../hooks/use-session';
import { useUsersMap } from '../../../../../../hooks/queries/users';

import type { CreateSchedulePayload } from '../../../../../../hooks/queries/schedules';
import type { ScheduleOption, SelectedSchedule } from '../types';
import type { TableSchema } from '../../../../../library/data-display/Table';
import { Directory } from '../../../../../../types';
import { correctPath } from 'libraries/gem';

const ReviewStep = () => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const { saveSettingsInWorkflow } = useLDFlags();
  const { account, currentUser } = useSession();
  const application = useApplication(id).data!;
  const { data: calendars } = useCalendars();
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const {
    completedStep,
    schedule,
  } = useNewSchedule();

  const createScheduleMutation = useCreateSchedule();
  const updateScheduleMutation = useUpdateSchedule();

  const [clickedHold, setClickedHold] = useState(false);
  const [clickedCreate, setClickedCreate] = useState(false);

  const selectedScheduleOptions = useMemo<ScheduleOption[]>(() => {
    const schedules: ScheduleOption[] = [];
    Object.values(schedule.generatedOptionsByNumberOfBlocks).forEach(({ scheduleOptions, selectedScheduleOptionIndices }) => {
      selectedScheduleOptionIndices.forEach((index) => {
        schedules.push(scheduleOptions[index]);
      });
    });
    return schedules;
  }, [schedule.generatedOptionsByNumberOfBlocks]);

  const numberOfBlocks = selectedScheduleOptions[0]?.blocks.length;
  const isMultiBlock = numberOfBlocks > 1;
  const confirmationEmailTemplateType = isMultiBlock ? 'multi_block_confirmation_email' : 'confirmation_email';
  const confirmationEmailTemplate = schedule.schedule_template[`${confirmationEmailTemplateType}_template`];

  // confirmation email subjects
  const {
    data: renderedEmailSubjects,
    errors: emailSubjectErrors,
  } = useMultipleRenders(selectedScheduleOptions.map((scheduleOption) => ({
    type: confirmationEmailTemplateType,
    plain_text: true,
    ...(isMultiBlock ? {
      schedules: constructScheduleTokens(application, schedule, scheduleOption.blocks, rooms, users),
    } : {
      schedule: constructScheduleTokens(application, schedule, scheduleOption.blocks, rooms, users)?.[0],
    }),
    text: confirmationEmailTemplate?.subject,
  })), {
    enabled: completedStep >= Step.Review && Boolean(confirmationEmailTemplate),
  });

  // confirmation email bodies
  const {
    data: renderedEmailBodies,
    errors: emailBodyErrors,
  } = useMultipleRenders(selectedScheduleOptions.map((scheduleOption) => ({
    type: confirmationEmailTemplateType,
    plain_text: true,
    ...(isMultiBlock ? {
      schedules: constructScheduleTokens(application, schedule, scheduleOption.blocks, rooms, users),
    } : {
      schedule: constructScheduleTokens(application, schedule, scheduleOption.blocks, rooms, users)?.[0],
    }),
    text: confirmationEmailTemplate?.body,
  })), {
    enabled: completedStep >= Step.Review && Boolean(confirmationEmailTemplate),
  });

  useEffect(() => {
    if (updateScheduleMutation.isError || createScheduleMutation.isError) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [updateScheduleMutation.isError || createScheduleMutation.isError]);

  if (completedStep < Step.Review) {
    return <Redirect to={correctPath(`/app/candidates/${id}/schedule/interview-plan`)} />;
  }

  const selectedSchedules = selectedScheduleOptions.map<SelectedSchedule>((selectedScheduleOption, index) => ({
    id: schedule.id, // This is assuming that when there is an ID, there will only be one selected option.
    application_id: application.id,
    candidate: application.candidate,
    scheduling_calendar_email: schedule.scheduling_calendar_email,
    candidate_scheduling_calendar_email: schedule.candidate_scheduling_calendar_email,
    timezone: schedule.timezone,
    candidate_timezone: schedule.candidate_timezone,
    schedule_template: schedule.schedule_template,
    blocks: sortBy(selectedScheduleOption.blocks, [({ interviews }) => interviews[0].start_time]),
    index,
  }));

  const numberOfOtherHeldSchedules = schedule.block_id ?
    application.held_schedules?.filter(({ block_id }) => block_id !== schedule.block_id).length || 0 :
    application.held_schedules?.filter(({ id }) => id !== schedule.id).length || 0;

  const createSchedule = useCallback(async (payload: CreateSchedulePayload) => {
    try {
      const data = await createScheduleMutation.mutateAsync({ payload });
      history.push(correctPath(`/app/candidates/${id}/schedules/${data.schedules[0].id}${saveSettingsInWorkflow ? '?success=true' : ''}`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  }, [createScheduleMutation, id, history]);

  const confirmSchedule = useCallback(async (deleteHeldSchedules?: boolean) => {
    const updatePayloads = constructScheduleUpdatePayloads(selectedSchedules[0]);

    try {
      for (const [i, updatePayload] of updatePayloads.entries()) {
        await updateScheduleMutation.mutateAsync({
          id: selectedSchedules[0].blocks[i].schedule_id!,
          payload: updatePayload,
        });
      }
    } catch (err) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
      return;
    }

    const createPayload = constructSchedulePayload(selectedSchedules, false, account!, deleteHeldSchedules);
    await createSchedule(createPayload);
  }, [
    account,
    createSchedule,
    schedule,
    selectedSchedules,
    updateScheduleMutation,
  ]);

  const handleNext = async (hold: boolean) => {
    updateScheduleMutation.reset();
    createScheduleMutation.reset();

    if (schedule.id) {
      // If confirming a held schedule, we don't care about the hold value.
      setClickedCreate(true);

      if (numberOfOtherHeldSchedules === 0) {
        // If this is the only held schedule, just confirm it. If not, we want to show a modal to ask if we should
        // delete the other held schedules.
        await confirmSchedule();
      }

      return;
    }

    // If creating a new schedule, if they clicked the hold button, open the hold modal. We don't open the hold modal
    // for Microsoft accounts though since the hold modal only allows for customizing email notifications, which
    // Microsoft can't do. If we update the modal to do more things, this logic should be updated.
    setClickedCreate(account?.directory_type === Directory.Microsoft ? true : !hold);
    setClickedHold(account?.directory_type === Directory.Microsoft ? false : hold);

    // If creating a new schedule that's not on hold (or it's a Microsoft account), create it immediately.
    if (account?.directory_type === Directory.Microsoft || !hold) {
      const payload = constructSchedulePayload(selectedSchedules, hold, account!);
      await createSchedule(payload);
    }
  };

  const scheduleSchema = useMemo<TableSchema<SelectedSchedule>>(() => [{
    header: pluralize('Interview Date', numberOfBlocks),
    displayValue: ({ blocks, timezone }) => {
      const blockTimes = blocks.map(({ interviews }) => {
        const firstInterview = interviews[0];
        const lastInterview = interviews[interviews.length - 1];
        return {
          startTime: Moment.tz(firstInterview.start_time, timezone),
          endTime: Moment.tz(lastInterview.start_time, timezone).add(lastInterview.interview_template.duration_minutes, 'minutes'),
        };
      });
      return blockTimes.map(({ startTime, endTime }) => (
        <Fragment key={`${startTime.format()}-${endTime.format()}`}>
          {formatDateTimeRange(startTime, endTime, timezone)}
          <br />
        </Fragment>
      ));
    },
  }, schedule.schedule_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom' && {
    header: pluralize('Zoom Host', numberOfBlocks),
    displayValue: ({ blocks }) => {
      const zoomHosts = blocks.map(({ selected_zoom_host }) => selected_zoom_host);

      if (some(zoomHosts)) {
        return zoomHosts.map((zoomHost, i) => {
          if (zoomHost) {
            return (
              zoomHost.type === 'user' ?
                <ListItem
                  className="zoom-host-list-item"
                  key={`zoom-host-${i}`}
                  label={users[zoomHost.id!]?.name || users[zoomHost.id!]?.email}
                  leftIcon={<Avatar
                    showUserTooltip={false}
                    size="small"
                    userId={zoomHost.id!}
                  />}
                /> :
                <Tag
                  key={`zoom-host-${i}`}
                  type="room"
                  value={zoomHost.id!}
                />
            );
          }
          return <i key={`zoom-host-${i}`}>No host</i>;
        });
      }

      return <i>No {pluralize('host', numberOfBlocks)}</i>;
    },
  }, {
    header: pluralize('Room', numberOfBlocks),
    displayValue: ({ blocks }) => {
      const selectedRooms = blocks.map(({ selected_room }) => selected_room);

      if (some(selectedRooms)) {
        return selectedRooms.map((selectedRoom, i) => (
          selectedRoom?.room_id ?
            <Tag
              key={`${selectedRoom.room_id}-${i}`}
              type="room"
              value={selectedRoom.room_id}
            /> :
            <i key={`${i}`}>No room</i>
        ));
      }

      return <i>No {pluralize('room', numberOfBlocks)}</i>;
    },
  }, {
    header: 'Candidate Timezone',
    displayValue: ({ candidate_timezone }) => candidate_timezone || <i>No timezone set</i>,
  }, {
    header: 'Scheduling Calendar',
    displayValue: ({ scheduling_calendar_email }) => calendars?.calendars[scheduling_calendar_email]?.name,
  }, {
    header: 'Scheduling Calendar for Candidate Events',
    displayValue: ({ scheduling_calendar_email, candidate_scheduling_calendar_email }) => calendars?.calendars[candidate_scheduling_calendar_email || scheduling_calendar_email]?.name,
  }, Boolean(account?.chat_type) && {
    header: 'Create Hiring Channel',
    displayValue: ({ schedule_template }) => (
      schedule_template.create_hiring_channel ?
        <FontAwesomeIcon className="check" icon={faCheck} /> :
        <span className="empty">&mdash;</span>
    ),
  }, account?.directory_type === Directory.Google && {
    header: 'Private Interviewer Events',
    displayValue: ({ schedule_template }) => (
      schedule_template.mark_interviewer_events_as_private ?
        <FontAwesomeIcon className="check" icon={faCheck} /> :
        <span className="empty">&mdash;</span>
    ),
  }, account?.directory_type === Directory.Google && {
    header: 'Private Candidate Events',
    displayValue: ({ schedule_template }) => (
      schedule_template.mark_candidate_events_as_private ?
        <FontAwesomeIcon className="check" icon={faCheck} /> :
        <span className="empty">&mdash;</span>
    ),
  }, {
    header: 'Schedule',
    displayValue: ({ blocks, schedule_template, timezone }) => {
      if (isMultiBlock) {
        const blocksPerDay = groupBy(blocks, ({ interviews }) => formatDate(interviews[0].start_time, timezone));
        return Object.entries(blocksPerDay).map(([formattedDate, dayBlocks], dayIndex) => (
          <div className="block-interview-table-container" key={`schedule-day-${dayIndex}`}>
            <h6>{formattedDate}</h6>
            {dayBlocks.map((block, blockIndex) => (
              <InterviewTable
                block={block}
                key={`interview-table-${blockIndex}`}
                scheduleTemplate={schedule_template}
                timezone={timezone}
              />
            ))}
          </div>
        ));
      }

      return (
        <InterviewTable
          block={blocks[0]}
          scheduleTemplate={schedule_template}
          timezone={timezone}
        />
      );
    },
  }, {
    header: pluralize('Candidate Calendar Event', numberOfBlocks),
    displayValue: ({ blocks, schedule_template, timezone }) => (
      <CandidateCalendarEvents
        blocks={blocks}
        scheduleTemplate={schedule_template}
        timezone={timezone}
      />
    ),
  }, confirmationEmailTemplate && {
    header: 'Candidate Confirmation Email',
    displayValue: ({ index }) => {
      return (
        <>
          <Flash
            message={emailSubjectErrors?.[index]?.message}
            showFlash={Boolean(emailSubjectErrors?.[index])}
            type="danger"
          />
          <Flash
            message={emailBodyErrors?.[index]?.message}
            showFlash={Boolean(emailBodyErrors?.[index])}
            type="danger"
          />
          <EmailPreview
            attachments={confirmationEmailTemplate.attachments}
            bccEmails={confirmationEmailTemplate.bcc_emails?.map((email) => resolveHiringRole(email, 'email', currentUser, application.job, application, users))}
            body={renderedEmailBodies?.[index]?.rendered_text || <LoadingSpinner />}
            ccEmails={confirmationEmailTemplate.cc_emails?.map((email) => resolveHiringRole(email, 'email', currentUser, application.job, application, users))}
            senderEmail={resolveHiringRole(confirmationEmailTemplate.sender_email, 'email', currentUser, application.job, application, users)}
            senderName={resolveHiringRole(confirmationEmailTemplate.sender_name, 'name', currentUser, application.job, application, users)}
            subject={renderedEmailSubjects?.[index]?.rendered_text || <LoadingSpinner />}
            to={application.candidate.email}
          />
        </>
      );
    },
  }], [
    account,
    calendars,
    emailBodyErrors,
    emailSubjectErrors,
    renderedEmailBodies,
    renderedEmailSubjects,
    schedule.schedule_template,
    users,
  ]);

  const multiSchema = useMemo<TableSchema<SelectedSchedule>>(() => [{
    header: pluralize('Interview Date', numberOfBlocks),
    displayValue: ({ blocks, timezone }) => {
      const blockTimes = blocks.map(({ interviews }) => {
        const firstInterview = interviews[0];
        const lastInterview = interviews[interviews.length - 1];
        return {
          startTime: Moment.tz(firstInterview.start_time, timezone),
          endTime: Moment.tz(lastInterview.start_time, timezone).add(lastInterview.interview_template.duration_minutes, 'minutes'),
        };
      });
      return blockTimes.map(({ startTime, endTime }) => (
        <Fragment key={`${startTime.format()}-${endTime.format()}`}>
          {formatDateTimeRange(startTime, endTime, timezone)}
          <br />
        </Fragment>
      ));
    },
  }, schedule.schedule_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom' && {
    header: pluralize('Zoom Host', numberOfBlocks),
    displayValue: ({ blocks }) => {
      const zoomHosts = blocks.map(({ selected_zoom_host }) => selected_zoom_host);

      if (some(zoomHosts)) {
        return zoomHosts.map((zoomHost, i) => {
          if (zoomHost) {
            return (
              zoomHost.type === 'user' ?
                <ListItem
                  className="zoom-host-list-item"
                  key={`zoom-host-${i}`}
                  label={users[zoomHost.id!]?.name || users[zoomHost.id!]?.email}
                  leftIcon={<Avatar
                    showUserTooltip={false}
                    size="small"
                    userId={zoomHost.id!}
                  />}
                /> :
                <Tag
                  key={`zoom-host-${i}`}
                  type="room"
                  value={zoomHost.id!}
                />
            );
          }
          return <i key={`zoom-host-${i}`}>No host</i>;
        });
      }

      return <i>No {pluralize('host', numberOfBlocks)}</i>;
    },
  }, {
    header: pluralize('Room', numberOfBlocks),
    displayValue: ({ blocks }) => {
      const selectedRooms = blocks.map(({ selected_room }) => selected_room);

      if (some(selectedRooms)) {
        return selectedRooms.map((selectedRoom, i) => (
          selectedRoom?.room_id ?
            <Tag
              key={`${selectedRoom.room_id}-${i}`}
              type="room"
              value={selectedRoom.room_id}
            /> :
            <i key={`${i}`}>No room</i>
        ));
      }

      return <i>No {pluralize('room', numberOfBlocks)}</i>;
    },
  }], [
    schedule.schedule_template.video_conferencing_enabled,
    account?.video_conferencing_type,
    users,
  ]);

  const isLoading = updateScheduleMutation.isLoading || createScheduleMutation.isLoading;

  return (
    <Breadcrumb
      data={{
        title: '6. Review',
        pathname: correctPath(`/app/candidates/${id}/schedule/review`),
      }}
    >
      <MultiStepFormStep
        backLocation={correctPath(`/app/candidates/${id}/schedule/events-and-emails${schedule.id ? `?schedule=${schedule.id}` : ''}`)}
        className="form-step-review"
        rightButtons={(
          <>
            {!schedule.id && <Button
              color="gem-outline"
              iconRight={clickedHold && isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : undefined}
              isDisabled={isLoading}
              onClick={() => handleNext(true)}
              size="large"
              value={clickedHold && isLoading ? 'Submitting...' : `Put ${pluralize('Schedule', selectedScheduleOptions.length)} On Hold`}
            />}
            {!schedule.hold && <Button
              color="gem-blue"
              iconRight={clickedCreate && isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : undefined}
              isDisabled={isLoading}
              onClick={() => handleNext(false)}
              size="large"
              value={clickedCreate && isLoading ? 'Submitting...' : `${schedule.id ? 'Confirm' : 'Create'} Schedule`}
            />}
          </>
        )}
      >
        <Helmet>
          <title>6. Review | Schedule {application.candidate.name || 'Unknown'} for {application.current_stage?.name} | Gem Scheduling</title>
        </Helmet>
        <PutSchedulesOnHoldModal
          createSchedule={createSchedule}
          isOpen={clickedHold}
          onToggle={() => setClickedHold(!clickedHold)}
          schedulePayload={constructSchedulePayload(selectedSchedules, true, account!)}
        />
        <DeleteHeldSchedulesModal
          application={application}
          confirmSchedule={confirmSchedule}
          isOpen={clickedCreate && Boolean(schedule.id) && numberOfOtherHeldSchedules > 0}
          onToggle={() => setClickedCreate((prev) => !prev)}
          schedule={schedule}
        />
        <Flash
          message={updateScheduleMutation.error?.message}
          showFlash={updateScheduleMutation.isError}
          type="danger"
        />
        <Flash
          message={createScheduleMutation.error?.message}
          showFlash={createScheduleMutation.isError}
          type="danger"
        />
        <Flash
          message={`You have selected ${selectedScheduleOptions.length === 1 ? 'a' : selectedScheduleOptions.length} ${pluralize('schedule', selectedScheduleOptions.length)} to put on hold. This means we'll create [HOLD] events on all of the interviewers' calendars, but we won't send the calendar ${pluralize('event', selectedScheduleOptions[0].blocks.length)}${confirmationEmailTemplate ? ' or email' : ''} to the candidate or save the schedule in ${capitalize(account?.ats_type)} yet.`}
          showFlash={schedule.hold}
          type="info"
        />
        {selectedSchedules.length === 1 ?
          <Table
            data={selectedSchedules}
            layout="horizontal"
            schema={scheduleSchema}
          /> :
          <Table
            data={selectedSchedules}
            displayExpandedContent={(schedule) => (
              <Table
                data={[schedule]}
                layout="horizontal"
                schema={scheduleSchema}
              />
            )}
            layout="vertical"
            schema={multiSchema}
          />
        }
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default ReviewStep;
