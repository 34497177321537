import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { maxBy } from 'lodash';
import { useParams } from 'react-router-dom';

import EmailSection from './EmailSection';
import Flash from '../../../../../library/utils/Flash';
import LoadingSpinner from '../../../../../library/utils/LoadingSpinner';
import PreferencesSection from './PreferencesSection';
import SuggestedTimesSection from './SuggestedTimesSection';
import SummarySection from './SummarySection';
import TimesSection from './TimesSection';
import { useApplication } from '../../../../../../hooks/queries/applications';
import { useAvailability } from '../../../../../../hooks/queries/availabilities';
import { correctPath } from 'libraries/gem';

const CandidateAvailability = () => {
  const { id, availabilityId } = useParams<{ id: string; availabilityId: string }>();

  const { data: application } = useApplication(id);
  const {
    data: availability,
    error: availabilityError,
    isLoading: isAvailabilityLoading,
  } = useAvailability(availabilityId);

  if (isAvailabilityLoading) {
    return (
      <div className="candidate-availability-container">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Breadcrumb
      data={{
        title: availabilityError || !availability ? availabilityId : availability.stage.name,
        pathname: correctPath(`/app/candidates/${id}/availabilities/${availabilityId}`),
      }}
    >
      {availabilityError || !availability ?
        <Flash
          message={availabilityError?.message}
          showFlash={Boolean(availabilityError)}
          type="danger"
        /> :
        <div className="candidate-availability-container">
          <Helmet>
            <title>Availability for {availability.stage.name} | {application?.candidate.name || 'Unknown'} ({application?.job.name}) | InterviewPlanner</title>
          </Helmet>
          <Flash
            message="This availability has been cancelled."
            showFlash={availability.status === 'cancelled'}
            type="danger"
          />
          <Flash
            message="This availability has expired. All available times are in the past."
            showFlash={
              Boolean(
                availability.status === 'submitted' &&
                availability.stage_id === application?.current_stage_id &&
                availability.availability_time_slots &&
                availability.availability_time_slots.length > 0 &&
                Moment(maxBy(availability.availability_time_slots, 'end_time')!.end_time).isBefore(Moment())
              )
            }
            type="warning"
          />
          <SummarySection />
          {availability.availability_template && !availability.manual && <PreferencesSection />}
          <TimesSection />
          {availability.availability_template && !availability.manual && <SuggestedTimesSection />}
          {availability.availability_template && !availability.manual && <EmailSection />}
        </div>
      }
    </Breadcrumb>
  );
};

export default CandidateAvailability;
