import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { QueryKey as ApplicationQueryKey } from './applications';
import { QueryKey as CalendarEventTemplateQueryKey } from './calendar-event-templates';
import { QueryKey as EmailTemplateQueryKey } from './email-templates';
import { QueryKey as JobQueryKey } from './jobs';

import type { SchedulingWorkflow, Stage, ZoomHostFilterableType } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListStages = 'ListStages',
  RetrieveStage = 'RetrieveStage',
}

export const useStage = (jobId?: string, stageId?: string, options: UseQueryOptions<Stage, Error> = {}) => {
  return useQuery<Stage, Error>([QueryKey.RetrieveStage, jobId, stageId], () => {
    return InterviewPlanner.request('GET', `/jobs/${jobId}/stages/${stageId}`);
  }, {
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(jobId && stageId),
  });
};

interface ListStagesQuery {
  deleted?: boolean;
  job?: string;
  calendar_event_template?: string;
  email_template?: string;
  limit?: number;
  offset?: number;
}

interface ListStagesData {
  stages: Stage[];
  total: number;
}

export const useStages = (query: ListStagesQuery = {}, options: UseQueryOptions<ListStagesData, Error> = {}) => {
  return useQuery<ListStagesData, Error>([QueryKey.ListStages, query], () => {
    return InterviewPlanner.request('GET', '/stages', null, query);
  }, {
    ...options,
  });
};

export interface UpdateStagePayload {
  schedulable?: boolean;
  default_workflow?: `${SchedulingWorkflow}`;
  scheduling_calendar_email?: string;
  candidate_scheduling_calendar_email?: string;
  scheduling_interval_minutes?: number;
  candidate_event_title?: string;
  candidate_event_location?: string;
  candidate_event_description?: string;
  candidate_event_additional_attendees?: string[];
  candidate_event_additional_optional_attendees?: string[];
  candidate_calendar_event_template_id?: string;
  confirmation_email_template_id?: string;
  business_hours?: {
    day: string;
    start_time: string;
    end_time: string;
    timezone?: string;
  }[]; // Set to empty array to null it out.
  onsite?: boolean;
  mark_interviewer_events_as_private?: boolean;
  mark_candidate_events_as_private?: boolean;
  video_conferencing_enabled?: boolean;
  create_hiring_channel?: boolean;
  zoom_host_filters?: {
    zoom_host_filter_expressions: {
      filterable_id: string;
      filterable_type: `${ZoomHostFilterableType}`;
      negated: boolean;
    }[];
  }[];
  self_scheduling_automatic_sends_enabled?: boolean;
  self_scheduling_automatic_requester?: string;
  self_scheduling_advanced_notice_hours?: number;
  self_scheduling_reschedule_enabled?: boolean;
  self_scheduling_reschedule_notice_hours?: number;
  self_scheduling_cancel_enabled?: boolean;
  self_scheduling_cancel_notice_hours?: number;
  self_scheduling_request_email_template_id?: string;
  self_scheduling_email_follow_up_enabled?: boolean;
  self_scheduling_email_follow_up_delay_days?: number;
  room_filters?: {
    room_filter_expressions: {
      negated: boolean;
      filterable_id: string;
      filterable_type: string;
    }[];
  }[];
  enable_request_availability?: boolean;
  enforce?: boolean;
  automatic_sends_enabled?: boolean;
  automatic_requester?: string;
  rolling_window_days?: number;
  advanced_notice_hours?: number;
  suggest_times?: boolean;
  minimum_duration_minutes?: number;
  total_duration_minutes?: number;
  availability_request_email_template_id?: string;
}

interface UpdateStageMutationVariables {
  id: string;
  jobId: string;
  payload: UpdateStagePayload;
}

export const useUpdateStage = () => {
  const queryClient = useQueryClient();

  return useMutation<Stage, Error, UpdateStageMutationVariables>(({ id, jobId, payload }) => {
    return InterviewPlanner.request('POST', `/jobs/${jobId}/stages/${id}`, payload);
  }, {
    onSuccess: (data, { jobId, payload }) => {
      queryClient.setQueryData([QueryKey.RetrieveStage, jobId, data.id], data);
      queryClient.invalidateQueries([JobQueryKey.RetrieveJob, jobId]);

      // Depending on how the stage is updated, it could update the scheduling statuses of applications, so we
      // invalidate them as well.
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication]);

      // If an email template was updated, we need to refresh it so that it has
      // an accurate number for linked_resources.
      if (payload.availability_request_email_template_id) {
        queryClient.invalidateQueries([EmailTemplateQueryKey.RetrieveEmailTemplate, payload.availability_request_email_template_id]);
        queryClient.invalidateQueries([EmailTemplateQueryKey.ListEmailTemplates]);
      }
      if (payload.self_scheduling_request_email_template_id) {
        queryClient.invalidateQueries([EmailTemplateQueryKey.RetrieveEmailTemplate, payload.self_scheduling_request_email_template_id]);
        queryClient.invalidateQueries([EmailTemplateQueryKey.ListEmailTemplates]);
      }
      if (payload.confirmation_email_template_id) {
        queryClient.invalidateQueries([EmailTemplateQueryKey.RetrieveEmailTemplate, payload.confirmation_email_template_id]);
        queryClient.invalidateQueries([EmailTemplateQueryKey.ListEmailTemplates]);
      }
      if (payload.candidate_calendar_event_template_id) {
        queryClient.invalidateQueries([CalendarEventTemplateQueryKey.RetrieveCalendarEventTemplate, payload.candidate_calendar_event_template_id]);
        queryClient.invalidateQueries([CalendarEventTemplateQueryKey.ListCalendarEventTemplates]);
      }
    },
  });
};

interface CopyStagePayload {
  source_stage_id: string;
  copy_scheduling_preferences: boolean;
  copy_interviews?: {
    action: 'keep' | 'add';
    destination_stage_interview_id?: string;
    source_stage_interview_id?: string;
  }[];
  copy_availability_preferences: boolean;
}

interface CopyStageMutationVariables {
  id: string;
  jobId: string;
  payload: CopyStagePayload;
}

export const useCopyStage = () => {
  const queryClient = useQueryClient();

  return useMutation<Stage, Error, CopyStageMutationVariables>(({ id, jobId, payload }) => {
    return InterviewPlanner.request('POST', `/jobs/${jobId}/stages/${id}/copy`, payload);
  }, {
    onSuccess: (data, { jobId }) => {
      queryClient.setQueryData([QueryKey.RetrieveStage, jobId, data.id], data);
      queryClient.invalidateQueries([JobQueryKey.RetrieveJob, jobId]);
      queryClient.invalidateQueries([EmailTemplateQueryKey.ListEmailTemplates]);
      queryClient.invalidateQueries([CalendarEventTemplateQueryKey.ListCalendarEventTemplates]);
      // TODO: Invalidate more queries?
    },
  });
};
