import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';

import Button from '../library/inputs/Button';
import Flash from '../library/utils/Flash';
import LoadingSpinner from '../library/utils/LoadingSpinner';
import TextAreaInput from '../library/inputs/TextAreaInput';
import TextInput from '../library/inputs/TextInput';
import Zendesk from '../../libraries/zendesk';
import { useSession } from '../../hooks/use-session';

import type { ChangeEvent, FormEvent } from 'react';

const Contact = () => {
  const { currentUser } = useSession();

  const [name, setName] = useState(currentUser?.name || '');
  const [email, setEmail] = useState(currentUser?.email || '');
  const [message, setMessage] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setName(currentUser?.name || '');
    setEmail(currentUser?.email || '');
  }, [Boolean(currentUser)]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setIsSubmitting(true);
    setIsSuccess(false);
    setError('');

    analytics.identify(email, {
      language: window.navigator.language,
    });

    try {
      await Zendesk.createRequest(name, email, message, 'Contact - General Inquiry');
      setIsSuccess(true);
      analytics.track('Support Request Created');
    } catch (err) {
      if (err instanceof Error) {
        analytics.track('Support Request Created - Failed', { error: err.message });
        setError(err.message);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact | InterviewPlanner</title>
      </Helmet>
      <h1>
        Say hello.
      </h1>
      <p>
        Curious to know how you could reduce your time-to-hire? Want to learn more?
        <br />
        Contact us with your questions, suggestions, and comments.
      </p>
      <form className="contact-form" onSubmit={handleSubmit}>
        <Flash
          message="We have received your message! Thanks for reaching out. We will get back to you within the next business day."
          showFlash={isSuccess}
          type="success"
        />
        <Flash
          message={error}
          showFlash={Boolean(error)}
          type="danger"
        />
        <TextInput
          isDisabled={isSubmitting}
          isRequired
          label="Name"
          onChange={handleNameChange}
          placeholder="Jane Doe"
          value={name}
        />
        <TextInput
          isDisabled={isSubmitting}
          isRequired
          label="Email"
          onChange={handleEmailChange}
          placeholder="you@company.com"
          type="email"
          value={email}
        />
        <TextAreaInput
          isDisabled={isSubmitting}
          isRequired
          label="Message"
          maxLength={1000}
          onChange={handleMessageChange}
          placeholder="Tell me more about InterviewPlanner!"
          value={message}
        />
        <Button
          color="orange"
          iconRight={isSubmitting ? <LoadingSpinner /> : undefined}
          isDisabled={isSubmitting}
          size="large"
          type="submit"
          value={isSubmitting ? 'Sending...' : 'Send'}
        />
      </form>
      <p>
        To report a vulnerability, email <a href="mailto:security@interviewplanner.com">security@interviewplanner.com</a>.
      </p>
    </div>
  );
};

export default Contact;
