import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router-dom';
import { capitalize, pick } from 'lodash';
import { useEffect, useState } from 'react';

import EmailTemplateForm from '../../../../library/inputs/EmailTemplateForm';
import ErrorTokenFlash from '../../../../library/utils/ErrorTokenFlash';
import Flash from '../../../../library/utils/Flash';
import MultiStepFormStep from '../../../../library/inputs/MultiStepFormStep';
import OutboundLink from '../../../../library/navigation/OutboundLink';
import { AtsHrefType, constructAtsHref } from '../../../../../libraries/candidates';
import { Step, useNewAvailability } from './use-new-availability';
import { constructAvailabilityTokens } from './helpers';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useSession } from '../../../../../hooks/use-session';
import { useTokens } from '../../../../../hooks/queries/tokens';
import { useUsersMap } from '../../../../../hooks/queries/users';

import type { CreatableEmailTemplate } from '../../../../../types';
import type { Token } from '../../../../../types';
import { correctPath } from 'libraries/gem';

const EmailStep = () => {
  const { id } = useParams<{ id: string }>();

  const { account, currentUser } = useSession();
  const application = useApplication(id).data!;
  const users = useUsersMap({ archived: true });

  const {
    availability,
    completedStep,
    setAvailability,
    setCompletedStep,
  } = useNewAvailability();

  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);

  const [error, setError] = useState<Error | null>(null);
  const [isEmailEnabled, setIsEmailEnabled] = useState(Boolean(canSendEmail && application.candidate.email && availability.availability_template!.availability_request_email_template));
  const [emailTemplate, setEmailTemplate] = useState<CreatableEmailTemplate | undefined>(availability.availability_template!.availability_request_email_template);
  const [errorTokens, setErrorTokens] = useState<Token[]>([]);

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({
    type: 'availability_request_email',
    availability: constructAvailabilityTokens(application, users),
  }, {
    enabled: completedStep >= Step.Email,
  });

  useEffect(() => {
    if (error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [error]);

  const handleNext = () => {
    setError(null);

    // Check that availability request link is included in availability request email
    if (isEmailEnabled && !/{{\s*AvailabilityRequest\.Link\s*}}/.test(emailTemplate!.body)) {
      setError(new Error('The template must include the AvailabilityRequest.Link token.'));
      return false;
    }

    setAvailability((prev) => ({
      ...prev,
      availability_template: {
        ...prev.availability_template!,
        availability_request_email_template: isEmailEnabled ? {
          ...pick(emailTemplate!, [
            'id',
            'name',
            'subject',
            'sender_name',
            'sender_email',
            'cc_emails',
            'bcc_emails',
            'body',
            'attachments',
          ]),
          name: emailTemplate!.name || 'Availability Request Email',
        } : undefined,
      },
    }));
    setCompletedStep(Step.Email + 1);
  };

  if (completedStep < Step.Email) {
    return <Redirect to={correctPath(`/app/candidates/${id}/request-availability/preferences`)} />;
  }

  return (
    <Breadcrumb
      data={{
        title: '2. Email',
        pathname: correctPath(`/app/candidates/${id}/request-availability/email`),
      }}
    >
      <MultiStepFormStep
        backLocation={correctPath(`/app/candidates/${id}/request-availability/${availability.availability_template!.suggest_times ? 'suggested-times' : 'preferences'}`)}
        className="form-step-email"
        nextButtonIsDisabled={errorTokens.length > 0}
        nextButtonValue="Review"
        nextLocation={correctPath(`/app/candidates/${id}/request-availability/review`)}
        onNext={handleNext}
      >
        <Helmet>
          <title>2. Email | Request availability from {application.candidate.name || 'Unknown'} for {application.current_stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message={tokensError?.message}
          showFlash={Boolean(tokensError)}
          type="danger"
        />
        <Flash
          message={
            <span>
              This candidate doesn&apos;t have an email address associated to them, so we can&apos;t send an email. You can add an email address in <OutboundLink externalLinkIcon={false} href={constructAtsHref(account!, AtsHrefType.Candidate, { applicationAtsId: application.id, candidateAtsId: application.candidate.ats_id })} label="Add Email Warning">{capitalize(account?.ats_type)}</OutboundLink>. If you want to create the availability request link without sending an email, you can continue to the next step.
            </span>
          }
          showFlash={!application.candidate.email}
          type="warning"
        />
        <ErrorTokenFlash errorTokens={errorTokens} />
        <EmailTemplateForm
          emailTemplate={emailTemplate}
          isDisabled={!application.candidate.email || !canSendEmail}
          isEnabled={isEmailEnabled}
          pendingPreviewMessage="This token will be filled in when the request is sent to the candidate."
          setEmailTemplate={setEmailTemplate}
          setErrorTokens={setErrorTokens}
          setIsEnabled={setIsEmailEnabled}
          tokens={tokens}
          type="availability_request_email"
        />
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default EmailStep;
