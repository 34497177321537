import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';

import IntegrationsATSSection from './IntegrationsATSSection';
import IntegrationsCalendarSection from './IntegrationsCalendarSection';
import IntegrationsChatSection from './IntegrationsChatSection';
import IntegrationsLiveCodingSection from './IntegrationsLiveCodingSection';
import IntegrationsVideoConferencingSection from './IntegrationsVideoConferencingSection';
import LoadingSpinner from '../../library/utils/LoadingSpinner';
import { useAccount } from '../../../hooks/queries/accounts';
import { correctPath } from 'libraries/gem';

const Integrations = () => {
  const { isLoading } = useAccount();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: <><FontAwesomeIcon icon={faProjectDiagram} />Integrations</>,
        pathname: correctPath('/app/integrations'),
      }}
    >
      <div className="integrations-container">
        <Helmet>
          <title>Integrations | Gem Scheduling</title>
        </Helmet>
        <IntegrationsATSSection />
        <IntegrationsCalendarSection />
        <IntegrationsVideoConferencingSection />
        <IntegrationsChatSection />
        <IntegrationsLiveCodingSection />
      </div>
    </Breadcrumb>
  );
};

export default Integrations;
