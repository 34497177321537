import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ceil, flatten, floor, isEmpty, max, min, some, uniq } from 'lodash';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Button from 'components/library/inputs/Button';
import CalendarSchedule from 'components/library/data-display/CalendarSchedule';
import CheckboxInput from 'components/library/inputs/CheckboxInput';
import ListItem from 'components/library/data-display/ListItem';
import SelectInput from 'components/library/inputs/SelectInput';
import Tooltip from 'components/library/utils/Tooltip';
import { formatMoment, TimeFormat } from 'libraries/time';
import { useLDFlags } from 'hooks/use-ld-flags';
import { useSession } from 'hooks/use-session';
import { VideoConferencingTool } from 'types';
import ScheduleOptionsViewerFilters from './ScheduleOptionsViewerFilters';
import { useNewSchedule } from '../../use-new-schedule';

import type { ChangeEvent } from 'react';
import type { EditableBusinessHour } from 'types/business-hours';
import type { Block, ScheduleOption, ScheduleOptionFilter } from '../../types';

const calculatePageSize = (numberOfBlocks: number): number => {
  const optionWidth = 300 /* calendar schedule width */ * numberOfBlocks;
  return floor((window.innerWidth - 400) / optionWidth) || 1;
};

interface Props {
  allSelectedScheduleOptions: ScheduleOption[];
  applicationId: string;
  businessHours?: EditableBusinessHour[];
  focusedScheduleIndex: number | null;
  keepGoingButton?: JSX.Element;
  numberOfBlocks: number;
  onScheduleFocus: (index: number) => void;
  onScheduleHoldChange: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
  schedules: ScheduleOption[];
  selectedScheduleIndices: number[];
  setScheduleOption: (index: number, blockIndex: number, newScheduleOption: Block) => void;
  showHoldInput: boolean;
  showZoomHost: boolean;
  timezone: string;
}

const ScheduleOptionsViewer = ({
  allSelectedScheduleOptions,
  applicationId,
  businessHours,
  focusedScheduleIndex,
  keepGoingButton,
  numberOfBlocks,
  onScheduleFocus,
  onScheduleHoldChange,
  schedules,
  selectedScheduleIndices,
  setScheduleOption,
  showHoldInput,
  showZoomHost,
  timezone,
}: Props) => {
  const { unifiedMultiBlock } = useLDFlags();
  const { account } = useSession();

  const [filters, setFilters] = useState<ScheduleOptionFilter[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(calculatePageSize(numberOfBlocks));
  const isSmallScreen = useMediaQuery({ query: '(max-width: 800px)' });
  const { schedule: { availabilities } } = useNewSchedule();

  const scheduleOptions = useMemo(() => schedules.map((schedule, i) => ({
    value: i,
    label: `Option ${i + 1}, ${schedule.blocks.map(({ interviews }) => formatMoment(Moment.tz(interviews[0].start_time, timezone), TimeFormat.ShortDayOfWeekMonthDayWithTimeAndTimezoneAndComma)).join(', ')}`,
    schedule,
    index: i,
    isDisabled: isEmpty(filters) ? false : filters.some((filter) => !filter(schedule)), // disabled if there is a filter condition that the schedule doesn't pass
  })), [filters, schedules]);

  const filteredScheduleOptions = useMemo(() => scheduleOptions.filter(({ isDisabled }) => !isDisabled), [scheduleOptions]);

  useEffect(() => {
    setPageNumber(1);
  }, [filters]);

  useEffect(() => {
    setPageNumber(1);
    setPageSize(calculatePageSize(numberOfBlocks));
  }, [numberOfBlocks]);

  const handleResize = useCallback(() => {
    setPageSize(calculatePageSize(numberOfBlocks));
  }, [numberOfBlocks]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return (() => window.removeEventListener('resize', handleResize));
  }, [handleResize]);

  const pageIndex = pageNumber - 1;
  const pageCount = filteredScheduleOptions.length === 0 ? 1 : ceil(filteredScheduleOptions.length / pageSize);
  const offset = pageSize * pageIndex;

  const resultRangeStart = offset + 1;
  const resultRangeEnd = min([offset + pageSize, filteredScheduleOptions.length]);

  const page = filteredScheduleOptions.slice(offset, offset + pageSize);

  const handlePreviousPageClick = useCallback(() => {
    pageNumber > 1 && setPageNumber(pageNumber - 1);
  }, [pageNumber]);

  const handleNextPageClick = useCallback(() => {
    pageNumber < pageCount && setPageNumber(pageNumber + 1);
  }, [pageNumber, pageCount]);

  const handleJumpToOption = useCallback((optionIndexInUnfilteredSchedules: number) => {
    const indexInFilteredSchedules = filteredScheduleOptions.findIndex(({ index }) => index === optionIndexInUnfilteredSchedules);
    setPageNumber(floor(indexInFilteredSchedules / pageSize) + 1);
  }, [filters, pageSize]);

  const blocks = flatten(page.map((scheduleOption) => scheduleOption.schedule.blocks));
  const minTime = min(blocks.map((block) => {
    const firstInterview = block.interviews[0];
    const startTime = Moment.tz(firstInterview.start_time, timezone);
    return Moment.tz(timezone).hours(startTime.hours()).minutes(startTime.minutes());
  }));
  const maxTime = max(blocks.map((block) => {
    const startTime = block.interviews[0].start_time;
    const lastInterview = block.interviews[block.interviews.length - 1];
    const endTime = Moment.tz(lastInterview.start_time, timezone).add(lastInterview.interview_template.duration_minutes, 'minutes');
    const baseDate = Moment.tz(timezone);
    if (!endTime.isSame(startTime, 'date')) {
      baseDate.add(1, 'days');
    }
    return baseDate.hours(endTime.hours()).minutes(endTime.minutes());
  }));
  const businessHoursStart = minTime ? minTime.format('HH:mm') : businessHours?.[0].start_time;
  let businessHoursEnd: string | undefined;
  if (maxTime) {
    // If the maxTime is on the next day, set the hours portion of businessHoursEnd to be
    // greater than 24 to represent that the times should range past 12am.
    // For example, take the following schedule options where PST is the scheduling timezone:
    //   Option 1 | 1:00 AM PST - 5:00 AM PST
    //   Option 2 | 10:00 PM PST - 2:00 AM PST (next day)
    // The minTime is 1:00 AM and the maxTime is 2:00 AM (next day).
    // If we simply format this as 'HH:mm', we would get '01:00' and '02:00,' which
    // doesn't let the child components know that we mean 02:00 AM the next day
    // (and the resulting time range would only be one hour).
    // So, we add 24 to the hours portion of the businessHoursEnd.
    const maxTimeHours = maxTime.isSame(minTime, 'date') ? maxTime.hours() : maxTime.hours() + 24;
    businessHoursEnd = `${maxTimeHours}:${maxTime.minutes()}`;
  }

  const availabilityTimeSlots = useMemo(() => availabilities.map(({ start_time, end_time }) => ({
    start: start_time,
    end: end_time,
  })), [availabilities]);

  // Prevent placing holds on multi-block and single block schedules in the same workflow.
  const areHoldsDisabled = numberOfBlocks === 1 ?
    some(allSelectedScheduleOptions, ({ blocks }) => blocks.length > 1) :
    some(allSelectedScheduleOptions, ({ blocks }) => blocks.length === 1);

  return (
    <div className="schedule-options-viewer">
      <div className="schedule-options-viewer-header">
        <div className="count-container">
          <span>
            <b>{resultRangeStart}&ndash;{resultRangeEnd}</b> of <b>{filteredScheduleOptions.length}</b> options
          </span>
          {filteredScheduleOptions.length !== scheduleOptions.length && (
            <>
              <span className="separator">|</span>
              <span><b>{scheduleOptions.length}</b> total options</span>
            </>
          )}
          {keepGoingButton}
          {focusedScheduleIndex !== null && <span className="separator">|</span>}
          {focusedScheduleIndex !== null &&
            <Button
              className="focused-schedule"
              color="gem-outline"
              isDisabled={scheduleOptions.find(({ index }) => index === focusedScheduleIndex)?.isDisabled}
              onClick={() => handleJumpToOption(focusedScheduleIndex)}
              size="small"
              tooltip={
                <Tooltip
                  id="selected-option-button"
                  position="top"
                  value={'Jump to selected option'}
                />
              }
              value={`Option ${focusedScheduleIndex + 1}: ${schedules[focusedScheduleIndex]?.blocks.map(({ interviews }) => formatMoment(Moment.tz(interviews[0].start_time, timezone), TimeFormat.ShortDayOfWeekMonthDayWithTimeAndTimezoneAndComma)).join(', ')}`}
            />
          }
        </div>
        <div className="buttons-container">
          <Button
            color="gray"
            iconRight={<FontAwesomeIcon icon={faCaretLeft} />}
            isDisabled={pageNumber === 1}
            onClick={handlePreviousPageClick}
            size="large"
            tooltip={pageNumber === 1 ?
              undefined :
              <Tooltip
                id="previous-page-button"
                position="top"
                value={'Previous options'}
              />
            }
          />
          <Button
            color="gray"
            iconRight={<FontAwesomeIcon icon={faCaretRight} />}
            isDisabled={pageNumber === pageCount}
            onClick={handleNextPageClick}
            size="large"
            tooltip={pageNumber === pageCount ?
              undefined :
              <Tooltip
                id="next-page-button"
                position="top"
                value={'More options'}
              />
            }
          />
          {!isSmallScreen &&
            <SelectInput
              formatOptionLabel={(option, { context }) => (
                context === 'menu' ?
                  <ListItem
                    label={`Option ${option.index + 1}`}
                    secondaryText={
                      <span>
                        {option.schedule.blocks.map(({ interviews }) => formatMoment(Moment.tz(interviews[0].start_time, timezone), TimeFormat.ShortDayOfWeekMonthDayWithTimeAndTimezoneAndComma)).join(', ')}, <span className={option.schedule.conflicts > 0 ? 'warning' : undefined}>{option.schedule.conflicts || '0'}</span> conflict{option.schedule.conflicts === 1 ? '' : 's'}
                      </span>
                    }
                  /> :
                  `Option ${option.index + 1}`
              )}
              id="select-input-schedule-options-viewer-header"
              label="Jump to option"
              maxMenuHeight={300}
              onChange={(option) => handleJumpToOption(option!.index)}
              options={scheduleOptions}
              placeholder="Select an option"
              value={filteredScheduleOptions[resultRangeStart - 1]}
            />
          }
        </div>
      </div>
      <ScheduleOptionsViewerFilters
        numberOfBlocks={numberOfBlocks}
        schedules={schedules}
        setFilters={setFilters}
        timezone={timezone}
      />
      <div className="schedule-options-v2">
        <input
          checked={allSelectedScheduleOptions.length > 0}
          className="schedule-options-viewer-input-hidden-validation"
          name="option"
          onChange={() => {}}
          required
          type="radio"
        />
        {page.map((scheduleOption, i) => (
          <div
            className={`schedule-option${scheduleOption.index === focusedScheduleIndex ? ' selected' : ''}`}
            key={`schedule-option-${scheduleOption.index}`}
            onClick={() => onScheduleFocus(scheduleOption.index)}
          >
            <div className="schedule-option-headers-container">
              { // Extra spacing to correctly center the headers for the first block,
                // as the first block is the only one that has the time column
                i === 0 && <div className="time-header-gutter-placeholder" />
              }
              <div className="schedule-option-headers">
                <div className="option-header">
                  Option {scheduleOption.index + 1}
                </div>
                {showHoldInput && (
                  <div className="hold-checkbox-header">
                    <CheckboxInput
                      isChecked={selectedScheduleIndices.includes(scheduleOption.index)}
                      isDisabled={areHoldsDisabled}
                      label="Put on hold."
                      name={`schedule-${scheduleOption.index}-hold`}
                      onChange={(e) => onScheduleHoldChange(e, scheduleOption.index)}
                      tooltip={areHoldsDisabled ? (
                        <Tooltip
                          id={`disabled-holds-${scheduleOption.index}`}
                          position="top"
                          value="You cannot put multi-block schedules and single-block schedules on hold at the same time."
                        />
                      ) : undefined}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="schedule-option-blocks-container">
              {scheduleOption.schedule.blocks.map((block, blockIndex) => (
                <CalendarSchedule
                  allowAddAndRemoveInterviewers
                  allowCompareCalendars
                  allowSelectOptionsOutsidePool
                  analyticsLabel={`Generated Schedule Calendar - Option ${offset + i + 1}`}
                  availabilityTimeSlots={availabilityTimeSlots}
                  compareCalendarsDefaultSelectedUserIds={unifiedMultiBlock ? () => {
                    return uniq(scheduleOption.schedule.blocks.flatMap((block) => {
                      return [
                        ...block.interviews.flatMap(({ interviewers }) => (interviewers || []).map(({ selected_user }) => selected_user?.user_id)),
                        account?.video_conferencing_type === VideoConferencingTool.Zoom && block.selected_zoom_host ? `${block.selected_zoom_host.id}:zoom` : null,
                      ];
                    }))
                    .filter((id): id is string => Boolean(id)) ;
                  } : undefined}
                  id={`calendar-schedule-${offset + i}-block-${blockIndex}`}
                  isDraggable
                  key={`block-${blockIndex}`}
                  schedule={{
                    id: block.schedule_id,
                    application_id: applicationId,
                    timezone,
                    candidate_event_ical_uid: scheduleOption.schedule.candidate_event_ical_uid, // used for conflict checking for held schedules
                    candidate_event_location: scheduleOption.schedule.candidate_event_location, // used for conflict checking for held schedules
                    schedule_template: {
                      business_hours: (businessHours || [])?.map((bh) => ({
                        ...bh,
                        start_time: businessHoursStart || '00:00',
                        end_time: businessHoursEnd || '24:00',
                      })),
                    },
                    stage: {
                      id: scheduleOption.schedule.stage?.id,
                      job_id: scheduleOption.schedule.stage?.job_id,
                    },
                    selected_zoom_host: block.selected_zoom_host,
                    potential_zoom_hosts: block.potential_zoom_hosts || [],
                    selected_room: block.selected_room,
                    potential_rooms: block.potential_rooms || [],
                    interviews: block.interviews.map((interview, interviewIndex) => ({
                      ...interview,
                      // id needs to be set to handle the drag events on
                      // CalendarSchedule, and they just need to be unique within a
                      // schedule, so it should be sufficient to use the stage
                      // interview ID here since generated schedules (and their
                      // associated interviews) don't have any IDs. We also need to
                      // use the interview ID if it exists (in the case of held
                      // schedules).
                      id: interview.id || `${i}-${interview.stage_interview_id}-${interviewIndex}`,
                    })),
                  }}
                  setSchedule={(newSchedule) => setScheduleOption(scheduleOption.index, blockIndex, newSchedule)}
                  showZoomHost={showZoomHost}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleOptionsViewer;
