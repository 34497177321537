import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { isEqual, min, range } from 'lodash';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import NavbarLinks from 'components/library/navigation/NavbarLinks';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import Tooltip from 'components/library/utils/Tooltip';
import { MAX_ALLOWED_INTERVIEWS_FOR_MULTI_BLOCK_SCHEDULING, MAX_ALLOWED_SCHEDULE_BLOCKS } from '../../types';
import { StyledContainer, StyledLoadedIcon } from './styles';
import { calculatePossibleNumberOfScheduleBlocks, constructGeneratePayload } from '../helpers';
import { useNewSchedule } from '../../use-new-schedule';

import type { GeneratedOption } from '../../types';
import { useLDFlags } from '../../../../../../../hooks/use-ld-flags';

interface Props {
  allGeneratedOptions: {
    [numberOfBlocks: number]: GeneratedOption;
  };
  isLoading: boolean;
  selectedNumberOfBlocks: number;
  setSelectedNumberOfBlocks: (numberOfBlocks: number) => void;
}

const ScheduleBlockOptionsBar = ({ allGeneratedOptions, isLoading, selectedNumberOfBlocks, setSelectedNumberOfBlocks }: Props) => {
  const { url } = useRouteMatch();

  const { schedulingWithoutBlocks } = useLDFlags();

  const { schedule }: { schedule: any } = useNewSchedule();
  const interviews = schedule?.stage_interviews.filter(({ in_schedule }: { in_schedule: boolean }) => in_schedule) || [];

  // Do not show multi-block scheduling for single interviews.
  if (interviews.length <= 1) {
    return null;
  }

  // Do not show multi-block scheduling for more than 7 interviews.
  if (interviews.length > MAX_ALLOWED_INTERVIEWS_FOR_MULTI_BLOCK_SCHEDULING) {
    return null;
  }

  const maxAllowedBlocks = MAX_ALLOWED_SCHEDULE_BLOCKS;
  const maxNumberOfBlocks = min([interviews.length, maxAllowedBlocks]);
  const possibleNumbersOfBlocks = calculatePossibleNumberOfScheduleBlocks(interviews, /* schedule.availabilities */);
  const currentGenerateSchedulesPayload = useMemo(() => constructGeneratePayload(schedule), [schedule]);

  return (
    <StyledContainer>
      <NavbarLinks
        links={
          [
            ...range(1, maxNumberOfBlocks + 1),
            ...(schedulingWithoutBlocks && interviews.length > MAX_ALLOWED_SCHEDULE_BLOCKS ? [interviews.length] : []),
          ].map(
            (numberOfBlocks: number) => {
              const isSelectable = possibleNumbersOfBlocks.includes(numberOfBlocks);
              let secondaryText;
              if (isSelectable) {
                if (isLoading && numberOfBlocks === selectedNumberOfBlocks) {
                  secondaryText = <span><i>Loading...</i> <LoadingSpinner /></span>;
                } else if (
                  isEqual(
                    allGeneratedOptions[numberOfBlocks]?.lastGenerateSchedulesPayload,
                    currentGenerateSchedulesPayload
                  )
                ) {
                  secondaryText = <span>Loaded <StyledLoadedIcon icon={faCheckCircle} /></span>;
                } else {
                  secondaryText = <span role="button"><i>Click to load</i></span>;
                }
              }
              const isNoBlockOption = numberOfBlocks > maxNumberOfBlocks;
              return {
                label: isNoBlockOption ? 'Separate Interview Options' : `${numberOfBlocks}-Block Options`,
                secondaryText,
                location: url,
                isActive: numberOfBlocks === selectedNumberOfBlocks,
                isDisabled: !isSelectable,
                onClick: () => setSelectedNumberOfBlocks(numberOfBlocks),
                tooltip: isSelectable ? (
                  isNoBlockOption ? (
                    <Tooltip
                      helperText="To decrease load times, limit availability on the previous step."
                      id="no-block-options-tooltip"
                      position="top"
                      value="Options where interviews are not required to be back-to-back."
                    />
                  ) : undefined
                ) : (
                  <Tooltip
                    id={`disabled-${numberOfBlocks}-tooltip`}
                    position="top"
                    value={`It is not possible to create a ${numberOfBlocks}-block schedule given the lengths of the candidate's availabilities.`}
                  />
                ),
              };
            }
          )
        }
      />
    </StyledContainer>
  );
};

export default ScheduleBlockOptionsBar;
