import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div className="terms-container">
      <Helmet>
        <title>Terms of Service | Gem Scheduling</title>
      </Helmet>
      <h1>
        Terms of Service
      </h1>
      <p>
        <b>Effective:</b> April 4, 2024
      </p>
      <p>
        These Terms of Service (&quot;<b>Terms</b>&quot;) are a legal agreement (&quot;<b>Agreement</b>&quot;) between you (&quot;<b>you</b>&quot;) and InterviewPlanner, LLC (&quot;<b>InterviewPlanner</b>&quot;, &quot;<b>we</b>&quot;, &quot;<b>us</b>&quot;, or &quot;<b>our</b>&quot;) and dictate your use of our website and our service (&quot;<b>Platform</b>&quot;). To be able to use the Platform, you must first read and accept these Terms. If you are accepting the Terms on behalf of your employer or another entity (&quot;<b>your Company</b>&quot;), you hereby represent and warrant that you are authorized to enter into these Terms on behalf of your employer or other entity. If you do not agree to these terms, you do not have our permission to use our Platform.
      </p>
      <h2 id="1-use-of-the-platform">
        1. Use of the Platform
      </h2>
      <p>
        Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable, revocable right and license to access and use the Platform for your Company&apos;s internal use. You may not give access to the Platform to anyone that is not a part of your Company, except for any third-parties that are acting on behalf of your Company, such as recruiting firms and agencies. You may not sell, resell, rent, or lease access to the Platform. You are responsible for preventing unauthorized access to the account provisioned by you or another member of your Company that allows you to access the Platform (&quot;<b>InterviewPlanner Account</b>&quot;) and notifying us immediately if you become aware of any unauthorized access or use.
      </p>
      <h2 id="2-fees-and-payment">
        2. Fees and Payment
      </h2>
      <p id="2-1-fees">
        <b>2.1. Fees.</b> You agree to pay all applicable fees related to your Company&apos;s use of the Platform which are described fully on <Link to="/pricing">our pricing page</Link>. By providing a payment method, you expressly authorize us to charge the applicable fees on said payment method as well as taxes and other charges incurred at regular intervals, all of which depend on your particular subscription. The subscription period can be month-to-month or annually. Accepted payment methods include credit card (for customers on the Startup tier), ACH direct debit, and ACH credit transfer.
      </p>
      <p id="2-2-renewals">
        <b>2.2. Renewals.</b> For renewals, InterviewPlanner will automatically charge your payment method in the amount of the then-current fee for your type of account. If your account no longer qualifies for the subscription purchased at the previous billing cycle, you will automatically be moved into the next available tier with due notice. All payments are non-refundable at our discretion. You must provide a written notice of non-renewal within 30 days of the end of the subscription term to cancel it. You must cancel it before it renews in order to avoid billing of the next periodic subscription fee to your account.
      </p>
      <p id="2-3-delinquent-accounts">
        <b>2.3. Delinquent Accounts.</b> InterviewPlanner may suspend or terminate access to the Platform for any account for which any amount is due but unpaid. In addition to the amount due for the Platform, a delinquent account will be charged with fees or charges that are incidental to any chargeback or collection of any the unpaid amount, including collection fees.
      </p>
      <p id="2-4-credits">
        <b>2.4. Credits.</b> Any credits due to your Company will be applied on the next billing cycle against amounts then due. If there are no future billing cycles expected, we will issue a payment to your Company for credits due.
      </p>
      <h2 id="3-intellectual-property-and-ownership">
        3. Intellectual Property and Ownership
      </h2>
      <p id="3-1-interviewplanners-intellectual-property">
        <b>3.1. InterviewPlanner&apos;s Intellectual Property.</b> Other than those expressly set forth in these Terms, we reserve and retain all right, title, and interest (including intellectual property and proprietary rights) in and to the Platform. You and your Company&apos;s use of the Platform doesn&apos;t grant you ownership interest or rights in the Platform.
      </p>
      <p id="3-2-your-intellectual-property">
        <b>3.2. Your Intellectual Property.</b> Other than those expressly set forth in these Terms, you reserve and retain all right, title, and interest (including intellectual property and proprietary rights) in and to your content and data. This includes data that is entered into the Platform directly and data that is imported from third-party services such as your Applicant Tracking System (&quot;<b>ATS</b>&quot;).
      </p>
      <p id="3-3-feedback">
        <b>3.3. Feedback.</b> You acknowledge that any suggestions, comments, or other feedback that you provide to InterviewPlanner with respect to the Platform (collectively, &quot;<b>Feedback</b>&quot;) will be owned by InterviewPlanner, including all intellectual property rights therein, and will be and become InterviewPlanner&apos;s Confidential Information (as defined below). You acknowledge and agree that we will be free to use, disclose, reproduce, license, and otherwise distribute and exploit the Feedback as we see fit, without obligation or restriction of any kind. At our request and expense, you agree to execute documents or take such further actions as we may reasonably request to help us acquire, perfect, and maintain our rights in the Feedback.
      </p>
      <h2 id="4-confidentiality">
        4. Confidentiality
      </h2>
      <p id="4-1-definition">
        <b>4.1. Definition.</b> &quot;<b>Confidential Information</b>&quot; is defined as all information disclosed by a Party (&quot;<b>Disclosing Party</b>&quot;) to the other Party (&quot;<b>Receiving Party</b>&quot;), either orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure. This includes, without limitations, non-public company data, non-public products and services, and non-public financial information.
      </p>
      <p id="4-2-protection">
        <b>4.2. Protection.</b> The Receiving Party will use the same degree of care to protect the Disclosing Party’s Confidential Information that it uses to protect the confidentiality of its own Confidential Information of like kind (but no less than reasonable care). The Receiving Party agrees (i) not to use any Confidential Information of the Disclosing Party for any purpose outside the scope of these Terms, and (ii) except as otherwise authorized by the Disclosing Party in writing, to limit access to Confidential Information of the Disclosing Party to those of its employees, contractors and agents who need such access for purposes consistent with these Terms and who have signed confidentiality agreements with the Receiving Party containing protections no less stringent than those mentioned in these Terms.
      </p>
      <p id="4-3-compelled-disclosure">
        <b>4.3. Compelled Disclosure.</b> The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent compelled by law to do so, provided that the Receiving Party gives the Disclosing Party prior notice of the compelled disclosure (to the extent legally permitted) and reasonable assistance, at the Disclosing Party&apos;s cost, if the Disclosing Party wishes to contest the disclosure. If the Receiving Party is compelled by law to disclose the Disclosing Party’s Confidential Information as part of a civil proceeding to which the Disclosing Party is a party, and the Disclosing Party is not contesting the disclosure, the Disclosing Party will reimburse the Receiving Party for its reasonable cost of compiling and providing secure access to that Confidential Information.
      </p>
      <h2 id="5-terms-and-termination">
        5. Terms and Termination
      </h2>
      <p id="5-1-term">
        <b>5.1. Term.</b> These Terms will apply to you starting on the Effective Date at the top of these Terms and will continue for as long as you or any member of your Company is accessing or using the Platform (&quot;<b>Term</b>&quot;).
      </p>
      <p id="5-2-termination">
        <b>5.2. Termination.</b> If either Party commits a material breach or default in the performance of any of its obligations under these Terms, then the other Party may terminate the Agreement, provided that the terminating Party gives the breaching or defaulting Party written notice of termination specifying the underlying breach or default within thirty (30) days of discovery of such breach or default, and such breach or default remains uncured thirty (30) days after the breaching or defaulting Party receives the notice.
      </p>
      <p id="5-3-effect-of-termination">
        <b>5.3. Effect of Termination.</b> Upon the termination of these Terms, you and all members of your Company shall immediately discontinue use of and/or access to the Platform. No termination will affect your obligation to pay all fees that have become due before the effective date of such expiration or termination, or entitle you to any refund.
      </p>
      <p id="5-4-data">
        <b>5.4. Data.</b> Upon the termination of these Terms, you have the ability to ask us to purge all data relating to you and your Company that resides within our Platform. To do so, submit a written request through our <Link to="/contact">contact form</Link> or email <a href="mailto:privacy@interviewplanner.com">privacy@interviewplanner.com</a>.
      </p>
      <p id="5-5-survival">
        <b>5.5. Survival.</b> All terms and provisions of these Terms, which by their nature are intended to survive any termination or expiration of these Terms, will so survive.
      </p>
      <h2 id="6-indemnification">
        6. Indemnification
      </h2>
      <p>
        You agree, at your sole expense, to defend, indemnify and hold InterviewPlanner (and its directors, officers, employees, consultants and agents) harmless from and against any and all actual or threatened suits, actions, proceedings (whether at law or in equity), claims, damages, payments, deficiencies, fines, judgments, settlements, liabilities, losses, costs and expenses (including, without limitation, reasonable attorneys&apos; fees, costs, penalties, interest and disbursements) arising out of or relating to your Company&apos;s use of the Platform or any other actual or alleged breach of any of your obligations under these Terms.
      </p>
      <h2 id="7-disclaimer">
        7. Disclaimer
      </h2>
      <p>
        <b>Except as expressly set forth in these Terms, the Platform is provided &quot;as is&quot; and on an &quot;as available&quot; basis without warranty of any kind, and InterviewPlanner makes no promises, representations, or warranties, whether express, implied, statutory, or otherwise, with respect to the Platform, including its condition, conformity to any representation or description, or the existence of any latent or patent defects. InterviewPlanner specifically disclaims all implied warranties of merchantability, non-infringement and fitness for a particular purpose, and all other implied or statutory warranties, as well as any local jurisdictional analogues to the above. InterviewPlanner does not warrant that the Platform will be error-free or that the Platform will work without interruptions.</b>
      </p>
      <h2 id="8-limitation-of-liability">
        8. Limitation of Liability
      </h2>
      <p id="8-1-limitation-of-damages">
        <b>8.1. Limitation of Damages.</b> <b>To the fullest extent permitted by law, neither party will be liable for any indirect, special, incidental, punitive, or consequential damages of any kind (including lost profits), regardless of the form of action, whether in contract, tort (including negligence), strict liability or otherwise, even if informed of the possibility of such damages in advance.</b>
      </p>
      <p id="8-2-limitation-of-liability">
        <b>8.2. Limitation of Liability.</b> <b>In no event will either party or their respective directors, officers, agents, or employees, be liable to the other party for any reason, whether in contract or in tort, for any claims, suits, liability or damages arising out of or based upon these Terms, in the aggregate, exceed the amount actually paid by you in the twelve (12) months preceding the first incident out of which the liability arose, regardless of the form in which any legal or equitable action may be brought.</b>
      </p>
      <p id="8-3-limitation-of-claims">
        <b>8.3. Limitation of Claims.</b> Any claim or cause of action arising out of or related to use of the Platform or to these Terms must be filed within one (1) year after such claim or cause of action arose, or be forever waived.
      </p>
      <h2 id="9-disputes">
        9. Disputes
      </h2>
      <p id="9-1-notice-of-disputes">
        <b>9.1. Notice of Disputes.</b> For any claims against you, your Company, or InterviewPlanner relating to services or products provided or billed to you or your Company (each, a &quot;<b>Dispute</b>&quot;), you must first give notice by either submitting through our <Link to="/contact">contact form</Link> or emailing <a href="mailto:legal@interviewplanner.com">legal@interviewplanner.com</a>. This notice should include 1) your name and your Company&apos;s name, 2) your Company&apos;s address, 3) a written description of the Dispute, and 4) a written description of the specific relief you seek.
      </p>
      <p id="9-2-negotiation">
        <b>9.2. Negotiation.</b> In the event of a Dispute, the involved parties will use their best efforts to settle the Dispute. They will negotiate in good faith and attempt to reach a satisfactory solution for all involved parties.
      </p>
      <p id="9-3-binding-arbitration">
        <b>9.3. Binding Arbitration.</b> If the parties do not reach an agreeable solution within sixty (60) days of the notice outlined in Section 9.1., either party may initiate binding arbitration proceedings. <b>The acceptance of these Terms constitutes a waiver of your right to litigate claims and all opportunity to be heard by a judge or jury.</b> It also constitutes the agreement that the arbitration will take place in San Francisco, California unless agreed upon by you and InterviewPlanner. The arbitration shall be conducted in English, on a confidential basis pursuant to the Commercial Arbitration Rules of the American Arbitration Association. Any decision or award as a result of any such arbitration proceeding shall be in writing and shall provide an explanation for all conclusions of law and fact and shall include the assessment of costs, expenses, and reasonable attorneys’ fees. The prevailing party shall be entitled to an award of reasonable attorney fees. An award of arbitration may be confirmed in a court of competent jurisdiction sitting in San Francisco County, California.
      </p>
      <p id="9-4-class-action-waiver">
        <b>9.4. Class Action Waiver.</b> <b>The arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a class or representative proceeding or claims (such as a class action, consolidated action or private attorney general action).</b>
      </p>
      <p id="9-5-enforceability">
        <b>9.5. Enforceability.</b> The provisions outlined in this Section will survive the termination of your InterviewPlanner Account and your discontinued use of the Platform. If any clause within this Section other than 9.4. is found to be unenforceable, that individual clause will be null and void and the remaining clauses will still be in effect. If 9.4. is found to be unenforceable, the entirety of Section 9 will be null and void.
      </p>
      <h2 id="10-miscellaneous">
        10. Miscellaneous
      </h2>
      <p id="10-1-notices">
        <b>10.1. Notices.</b> All notices, requests, consents, claims, demands, waivers, and other communications under this Agreement (each, a &quot;<b>Notice</b>&quot;) intended for InterviewPlanner must either be submitted through our <Link to="/contact">contact form</Link> or by emailing <a href="mailto:legal@interviewplanner.com">legal@interviewplanner.com</a>. Your email must match the one that was provided to us on the creation of your InterviewPlanner Account.
      </p>
      <p id="10-2-publicity">
        <b>10.2. Publicity.</b> Unless explicitly notified by contacting us <Link to="/contact">here</Link>, we may use the name and/or logo of your Company for promotional materials and case studies to market the Platform. If we intend to use your name and/or logo for any other purposes, we will request your written consent.
      </p>
      <p id="10-3-third-party-links">
        <b>10.3. Third-Party Links.</b> Our Platform may contain links to third-party services. We don&apos;t control the content of the linked websites and are not responsible for it. These links are only provided as a convenience to you in order to assist your use with the Platform. By following these links, you may be giving the third-party permission to use your data in ways not outlined in these Terms. Access these links at your own discretion.
      </p>
      <p id="10-4-force-majeure">
        <b>10.4. Force Majeure.</b> Neither you nor InterviewPlanner will be liable to the other for any delay or failure to perform any obligation under these Terms (except for a failure to pay fees) if the delay or failure is due to events which are beyond reasonable control, such as an act of war, hostility, or sabotage; natural disasters; electrical, internet, or telecommunication outage; government restrictions, strikes, or blockades.
      </p>
      <p id="10-5-assignment">
        <b>10.5. Assignment.</b> This Agreement will not be assigned by either party, whether voluntarily or involuntarily or by operation of law, in whole or in part, to any other entity without the prior written consent of the other party, which consent will not unreasonably be withheld, conditioned or delayed. Notwithstanding the foregoing, InterviewPlanner may assign this Agreement to a successor in interest upon a merger, acquisition, reorganization, change of control, or sale of all or virtually all of its assets, and any such assignment will not require your consent.
      </p>
      <h2 id="11-changes">
        11. Changes
      </h2>
      <p>
        We reserve the right to change or modify these Terms at any time with notice. We may provide that notice in a variety of ways, including but not limited to: sending you an email, posting a notice on the Platform, or posting the revised Terms on the Platform and revising the Effective Date at the top of these Terms. It&apos;s important for you to periodically review our Terms to make sure you still agree to them. Your continued use of the Platform constitutes your acceptance of the modified Terms.
      </p>
    </div>
  );
};

export default Terms;
