import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router-dom';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import CalendarEventTemplateForm from 'components/library/inputs/CalendarEventTemplateForm';
import CheckboxInput from 'components/library/inputs/CheckboxInput';
import ErrorTokenFlash from 'components/library/utils/ErrorTokenFlash';
import Flash from 'components/library/utils/Flash';
import SchedulingCalendarSelectInput from 'components/library/inputs/SchedulingCalendarSelectInput';
import { Directory } from 'types';
import { Step, useNewHiringMeeting } from './use-new-hiring-meeting';
import { StyledMultiStepFormStep } from './styles';
import { constructTokensPayload } from './helpers';
import { useApplication } from 'hooks/queries/applications';
import { useCalendars } from 'hooks/queries/calendars';
import { useSession } from 'hooks/use-session';
import { useTokens } from 'hooks/queries/tokens';
import { useUsersMap } from 'hooks/queries/users';

const pendingPreviewMessage = 'This token will be filled in when the hiring meeting is created.';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { Token } from 'types';
import { correctPath } from 'libraries/gem';

const EventStep = () => {
  const { id } = useParams<{ id: string }>();

  const { account } = useSession();
  const application = useApplication(id).data!;
  const { data: calendars } = useCalendars();
  const users = useUsersMap({ archived: true });

  const {
    completedStep,
    hiringMeeting,
    setCompletedStep,
    setHiringMeeting,
  } = useNewHiringMeeting();

  const [error, setError] = useState<Error | null>(null);
  const [schedulingCalendar, setSchedulingCalendar] = useState(hiringMeeting.scheduling_calendar_email);
  const [markEventAsPrivate, setMarkEventAsPrivate] = useState(hiringMeeting.mark_event_as_private || false);
  const [title, setTitle] = useState(hiringMeeting.hiring_meeting_template.calendar_event_template.title);
  const [description, setDescription] = useState(hiringMeeting.hiring_meeting_template.calendar_event_template.description);
  const [location, setLocation] = useState(hiringMeeting.hiring_meeting_template.calendar_event_template.location);
  const [additionalAttendees, setAdditionalAttendees] = useState(hiringMeeting.hiring_meeting_template.calendar_event_template.additional_attendees || []);
  const [additionalOptionalAttendees, setAdditionalOptionalAttendees] = useState(hiringMeeting.hiring_meeting_template.calendar_event_template.additional_optional_attendees || []);
  const [errorTokens, setErrorTokens] = useState<Token[]>([]);

  const {
    data: tokens,
    error: tokensError,
  } = useTokens(constructTokensPayload(application, users), {
    enabled: completedStep >= Step.Event,
  });

  useEffect(() => {
    if (error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [error]);

  const handleSchedulingCalendarChange = (option: OnChangeValue<Option<string>, false>) => setSchedulingCalendar(option?.value || '');
  const handleMarkEventAsPrivateChange = (e: ChangeEvent<HTMLInputElement>) => setMarkEventAsPrivate(e.target.checked);

  const handleNext = () => {
    setError(null);

    setHiringMeeting((prev) => ({
      ...prev,
      scheduling_calendar_email: schedulingCalendar,
      hiring_meeting_template: {
        ...prev.hiring_meeting_template!,
        calendar_event_template: {
          title,
          description,
          location,
          additional_attendees: additionalAttendees,
          additional_optional_attendees: additionalOptionalAttendees,
        },
      },
      mark_event_as_private: markEventAsPrivate,
    }));
    setCompletedStep(Step.Event + 1);
  };

  if (completedStep < Step.Event) {
    return <Redirect to={correctPath(`/app/candidates/${id}/schedule-hiring-meeting/preferences`)} />;
  }

  const isVideoConferencingEnabled = hiringMeeting.hiring_meeting_template.video_conferencing_enabled;

  return (
    <Breadcrumb
      data={{
        title: '2. Event',
        pathname: correctPath(`/app/candidates/${id}/schedule-hiring-meeting/event`),
      }}
    >
      <StyledMultiStepFormStep
        backLocation={correctPath(`/app/candidates/${id}/schedule-hiring-meeting/preferences`)}
        nextButtonIsDisabled={errorTokens.length > 0 || Boolean(schedulingCalendar && calendars && !calendars.calendars[schedulingCalendar])}
        nextButtonValue="Review"
        nextLocation={correctPath(`/app/candidates/${id}/schedule-hiring-meeting/review`)}
        onNext={handleNext}
      >
        <Helmet>
          <title>2. Event | Schedule hiring meeting for {application.candidate.name || 'Unknown'} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message={tokensError?.message}
          showFlash={Boolean(tokensError)}
          type="danger"
        />
        <Flash
          message="You don't have access to the currently set scheduling calendar. Please select a different one or ask someone who does have access to it to share it with you."
          showFlash={Boolean(schedulingCalendar && calendars && !calendars.calendars[schedulingCalendar])}
          type="danger"
        />
        <Flash
          message={<>Fill out the calendar event details.</>}
          showFlash
          type="info"
        />
        <ErrorTokenFlash errorTokens={errorTokens} />
        <SchedulingCalendarSelectInput
          helperText={<><FontAwesomeIcon className="helper-text-warning-icon" icon={faExclamationTriangle} /> This cannot be changed after you create the schedule.</>}
          isRequired
          onChange={handleSchedulingCalendarChange}
          schedulingCalendar={schedulingCalendar}
        />
        {account?.directory_type === Directory.Google && (
          <CheckboxInput
            helperText="Recommended when meeting about a sensitive candidate, e.g. an executive or existing employee."
            isChecked={markEventAsPrivate}
            label="Make calendar event private."
            onChange={handleMarkEventAsPrivateChange}
          />
        )}
        <CalendarEventTemplateForm
          additionalAttendees={additionalAttendees}
          additionalOptionalAttendees={additionalOptionalAttendees}
          description={description}
          hideAdvancedSettings
          isInlineOnly
          isVideoConferencingEnabled={isVideoConferencingEnabled}
          location={location}
          locationHelperText={isVideoConferencingEnabled ? 'We will add a video conferencing link when the hiring meeting is created.' : undefined}
          pendingPreviewMessage={pendingPreviewMessage}
          setAdditionalAttendees={setAdditionalAttendees}
          setAdditionalOptionalAttendees={setAdditionalOptionalAttendees}
          setDescription={setDescription}
          setErrorTokens={setErrorTokens}
          setLocation={setLocation}
          setTitle={setTitle}
          title={title}
          tokens={tokens}
          type="hiring_meeting_calendar_event"
        />
      </StyledMultiStepFormStep>
    </Breadcrumb>
  );
};

export default EventStep;
