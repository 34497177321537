import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import type { ChangeEvent, FormEvent } from 'react';
import { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Competitor, competitorLabels, competitorLogos } from 'types';
import Flash from 'components/library/utils/Flash';
import { formatList } from 'libraries/formatters';
import FullCompetitorComparisonModal from './FullCompetitorComparisonModal';
import GoodTime from './GoodTime';
import Greenhouse from './Greenhouse';
import Lever from './Lever';
import ModernLoop from './ModernLoop';
import Prelude from './Prelude';
import {
  StyledComparisonCard,
  StyledComparisonContainer,
  StyledComparisonsContainer,
  StyledComparisonsMenu,
  StyledContainer,
  StyledDivider,
  StyledForm,
  StyledLockIcon,
  StyledTitleSection,
} from './styles';
import TextInput from 'components/library/inputs/TextInput';

interface ProspectInfo {
  name: string;
  logo?: string;
  competitors?: Competitor[];
  currentPlatform: Competitor;
}

const PROSPECTS: { [company: string]: ProspectInfo } = {
  aiven: {
    name: 'Aiven',
    currentPlatform: Competitor.Greenhouse,
  },
  alloy: {
    name: 'Alloy',
    currentPlatform: Competitor.Greenhouse,
  },
  astranis: {
    name: 'Astranis',
    currentPlatform: Competitor.Greenhouse,
  },
  attentive: {
    name: 'Attentive',
    currentPlatform: Competitor.Lever,
    competitors: [Competitor.GoodTime, Competitor.ModernLoop],
  },
  click: {
    name: 'Click Therapeutics',
    currentPlatform: Competitor.Greenhouse,
    competitors: [Competitor.GoodTime, Competitor.Prelude],
  },
  cohere: {
    name: 'Cohere',
    currentPlatform: Competitor.Lever,
  },
  domino: {
    name: 'Domino Data Lab',
    currentPlatform: Competitor.GoodTime,
    competitors: [Competitor.Greenhouse],
  },
  forge: {
    name: 'Forge Biologics',
    currentPlatform: Competitor.Greenhouse,
    competitors: [Competitor.ModernLoop],
  },
  gem: {
    name: 'Gem',
    currentPlatform: Competitor.Greenhouse,
    competitors: [
      Competitor.Lever,
      Competitor.GoodTime,
      Competitor.Prelude,
      Competitor.ModernLoop,
    ],
  },
  ketch: {
    name: 'Ketch',
    currentPlatform: Competitor.Lever,
  },
  singularity6: {
    name: 'Singularity 6',
    currentPlatform: Competitor.Greenhouse,
    competitors: [Competitor.ModernLoop, Competitor.GoodTime],
  },
  simplyinsured: {
    name: 'SimplyInsured',
    currentPlatform: Competitor.Greenhouse,
  },
  teleport: {
    name: 'Teleport',
    currentPlatform: Competitor.Lever,
  },
  ironfish: {
    name: 'Iron Fish',
    currentPlatform: Competitor.Lever,
    competitors: [Competitor.Greenhouse],
  },
  interviewplanner: {
    name: 'InterviewPlanner',
    currentPlatform: Competitor.Greenhouse,
    competitors: [Competitor.Lever, Competitor.GoodTime, Competitor.Prelude, Competitor.ModernLoop],
  },
  valon: {
    name: 'Valon',
    currentPlatform: Competitor.Greenhouse,
  },
  vercel: {
    name: 'Vercel',
    currentPlatform: Competitor.GoodTime,
    competitors: [Competitor.Greenhouse],
  },
};

const Compare = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 700px)' });

  const [company, setCompany] = useState<string>('');
  const [prospectInfo, setProspectInfo] = useState<ProspectInfo>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showComparison = useCallback((competitor: Competitor) => {
    return prospectInfo?.currentPlatform === competitor || prospectInfo?.competitors?.includes(competitor);
  }, [prospectInfo]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const prospect = PROSPECTS[company.toLowerCase()];
    if (prospect) {
      setProspectInfo(prospect);
      analytics.track('Viewed Comparison Guide', { company: prospect.name });
    } else {
      setErrorMessage('Sorry, we couldn\'t find a comparison guide for you! If you are evaluating InterviewPlanner, please reach out to your point of contact for a password.');
    }
  };

  return (
    <StyledContainer>
      <Helmet>
        <title>Compare | InterviewPlanner</title>
      </Helmet>
      {prospectInfo ? (
        <>
          <StyledTitleSection>
            <h1>
              Hello, {prospectInfo.name} team!
            </h1>
            <p>
              So you&apos;re exploring alternatives to {competitorLabels[prospectInfo.currentPlatform]} for scheduling interviews.&nbsp;
              {prospectInfo.competitors && (
                <span>
                  Thanks for letting me know that you&apos;re evaluating InterviewPlanner alongside {formatList(prospectInfo.competitors.map((competitor) => competitorLabels[competitor]))}. Since it can be difficult to differentiate between scheduling tools with similar feature sets, here&apos;s a <a onClick={() => setIsModalOpen(true)} role="button">feature comparison table</a>, and below is a guide that dives into some of the nitty-gritty details.&nbsp;
                </span>
              )}
              Hope this helps you make a more informed decision.
            </p>
          </StyledTitleSection>
          {prospectInfo.competitors && (
            <FullCompetitorComparisonModal
              competitors={[prospectInfo.currentPlatform, ...prospectInfo.competitors]}
              isOpen={isModalOpen}
              onToggle={() => setIsModalOpen((prev) => !prev)}
            />
          )}
          <StyledDivider />
          <StyledComparisonsContainer>
            {prospectInfo.competitors && (
              <StyledComparisonsMenu>
                <NavLink to={`/compare/${prospectInfo.currentPlatform}`}>
                  <StyledComparisonCard>
                    <img src={competitorLogos[prospectInfo.currentPlatform]} />
                    {!isSmallScreen && competitorLabels[prospectInfo.currentPlatform]}
                  </StyledComparisonCard>
                </NavLink>
                {prospectInfo.competitors?.map((competitor) => (
                  <NavLink key={competitor} to={`/compare/${competitor}`}>
                    <StyledComparisonCard>
                      <img src={competitorLogos[competitor]} />
                      {!isSmallScreen && competitorLabels[competitor]}
                    </StyledComparisonCard>
                  </NavLink>
                ))}
              </StyledComparisonsMenu>
            )}
            <StyledComparisonContainer>
              <Switch>
                {prospectInfo.currentPlatform && <Redirect exact from="/compare" to={`/compare/${prospectInfo.currentPlatform}`} />}
                {showComparison(Competitor.GoodTime) && <Route component={GoodTime} path="/compare/goodtime" />}
                {showComparison(Competitor.Greenhouse) && <Route component={Greenhouse} path="/compare/greenhouse" />}
                {showComparison(Competitor.Lever) && <Route component={Lever} path="/compare/lever" />}
                {showComparison(Competitor.ModernLoop) && <Route component={ModernLoop} path="/compare/modernloop" />}
                {showComparison(Competitor.Prelude) && <Route component={Prelude} path="/compare/prelude" />}
                <Redirect to="/compare" />
              </Switch>
              <Flash
                message="Scheduling platforms are constantly evolving. If you notice anything out-of-date, please let us know so that this comparison guide can serve as an accurate resource for teams evaluating InterviewPlanner."
                showFlash
                type="info"
              />
            </StyledComparisonContainer>
          </StyledComparisonsContainer>
        </>
      ) : (
        <StyledForm onSubmit={handleSubmit}>
          <StyledLockIcon icon={faLock} />
          <Flash
            message={errorMessage}
            showFlash={Boolean(errorMessage)}
            type="danger"
          />
          <TextInput
            isAutofocus
            onChange={(e: ChangeEvent<HTMLInputElement>) => setCompany(e.target.value)}
            type="password"
            value={company}
          />
        </StyledForm>
      )}
    </StyledContainer>
  );
};

export default Compare;
