import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Flash from '../../../../library/utils/Flash';
import JobStatusIndicator from '../../../../library/utils/JobStatusIndicator';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import OutboundLink from '../../../../library/navigation/OutboundLink';
import Section from '../../../../library/layout/Section';
import Table from '../../../../library/data-display/Table';
import pluralize from '../../../../../libraries/pluralize';
import { StyledTabContainer } from '../styles';
import { useInterviewTemplates } from '../../../../../hooks/queries/interview-templates';
import { useStageInterviews } from '../../../../../hooks/queries/stage-interviews';
import { useTrainingProgram } from '../../../../../hooks/queries/training-programs';

import type { InterviewTemplate, StageInterview } from '../../../../../types';
import type { TableSchema } from '../../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const getRowLink = ({ stage }: StageInterview) => correctPath(`/app/jobs/${stage.job_id}/stages/${stage.id}/interviews`);

const TrainingProgramLinkedInterviews = () => {
  const { id: trainingProgramId } = useParams<{ id: string }>();

  const trainingProgram = useTrainingProgram(trainingProgramId).data!;

  const {
    data: stageInterviews,
    isLoading,
  } = useStageInterviews({
    training_eligibility: trainingProgram.eligibility,
  });

  const {
    data: interviewTemplates,
  } = useInterviewTemplates({
    training_eligibility: trainingProgram.eligibility,
  });

  const unusedInterviewTemplates = useMemo<InterviewTemplate[]>(() => {
    if (!stageInterviews || !interviewTemplates) {
      return [];
    }

    const usedInterviewTemplates = stageInterviews.stage_interviews.reduce<Record<string, boolean>>((acc, stageInterview) => {
      return {
        ...acc,
        [stageInterview.interview_template_id!]: true,
      };
    }, {});

    return interviewTemplates.interview_templates.filter(({ id }) => !usedInterviewTemplates[id]);
  }, [stageInterviews, interviewTemplates]);

  const schema = useMemo<TableSchema<StageInterview>>(() => [{
    header: 'Name',
    displayValue: ({ name }) => name,
  }, {
    header: 'Stage',
    displayValue: ({ stage }) => stage.name,
  }, {
    header: 'Job',
    displayValue: ({ stage }) => stage.job.name,
  }, {
    header: 'Job Status',
    displayValue: ({ stage }) => <JobStatusIndicator status={stage.job.status} />,
  }, {
    header: 'Interview Template',
    isClickable: true,
    displayValue: ({ interview_template }) => (
      <OutboundLink
        href={correctPath(`/app/interview-templates/${interview_template?.id}`)}
        label="Training Program Linked Interview"
      >
        {interview_template?.name}
      </OutboundLink>
    ),
  }], []);

  return (
    <Breadcrumb
      data={{
        title: 'Linked Interviews',
        pathname: correctPath(`/app/training-programs/${trainingProgramId}/linked-interviews`),
      }}
    >
      <StyledTabContainer>
        <Helmet>
          <title>{trainingProgram.eligibility} | Linked Interviews | Training Programs | Gem Scheduling</title>
        </Helmet>
        <Section title="Linked interviews">
          {isLoading && <LoadingSpinner />}
          <Flash
            message={(
              <>
                This training program is used in the following interview {pluralize('template', unusedInterviewTemplates.length)}, which {pluralize('is', unusedInterviewTemplates.length)} not currently linked to any interviews.
                <ul>
                  {unusedInterviewTemplates.map((template) => (
                    <li key={template.id}><OutboundLink href={correctPath(`/app/interview-templates/${template?.id}`)} label="Training Program Unused Interview Template">{template.name}</OutboundLink></li>
                  ))}
                </ul>
              </>
            )}
            showFlash={unusedInterviewTemplates.length > 0}
            type="info"
          />
          {stageInterviews && (
            <Table
              data={stageInterviews.stage_interviews}
              getRowLink={getRowLink}
              layout="vertical"
              schema={schema}
            />
          )}
        </Section>
      </StyledTabContainer>
    </Breadcrumb>
  );
};

export default TrainingProgramLinkedInterviews;
